import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AppContext } from './Context';
import { useAuth } from '../utils/hooks';
import dayjs, { Dayjs } from 'dayjs';
import { config } from '../config';
import InactivityPopup from './popup/InactivityPopup';

const CheckInactivity = () => {
    const { userData, speedyLogout, ...ctx } = useContext(AppContext);
    const { logout } = useAuth();
    const [checking, setChecking] = useState(false);

    const [lastActivity, setLastActivity] = useState(dayjs(localStorage.getItem('lastActivity') || dayjs().format()));
    const [inactivityInSeconds, setInactivityInSeconds] = useState(0);

    const limitSeconds = useMemo(
        () => (speedyLogout ? config.TEST_MAX_INACTIVITY_TOTAL_TIME : config.MAX_INACTIVITY_TOTAL_TIME),
        [speedyLogout]
    );

    const limitPopupSeconds = useMemo(
        () => limitSeconds - (speedyLogout ? config.TEST_MAX_INACTIVITY_POPUP_TIME : config.MAX_INACTIVITY_POPUP_TIME),
        [speedyLogout, limitSeconds]
    );

    const checkInactivity = () => {
        setInactivityInSeconds(dayjs().diff(lastActivity, 'seconds'));
    };

    const showPopup = useMemo(
        () => limitPopupSeconds - inactivityInSeconds <= 0,
        [limitPopupSeconds, inactivityInSeconds]
    );

    const timeToLogout = useMemo(() => limitSeconds - inactivityInSeconds, [limitSeconds, inactivityInSeconds]);

    useEffect(() => {
        if (checking) {
            if (inactivityInSeconds >= limitSeconds) {
                logout();
            }
        }
        // eslint-disable-next-line
    }, [checking, ctx, inactivityInSeconds, limitPopupSeconds]);

    useEffect(() => {
        if (checking) {
            const timer = setInterval(() => checkInactivity(), 1000);
            return () => {
                clearInterval(timer);
            };
        }
        // eslint-disable-next-line
    }, [checking, inactivityInSeconds, lastActivity, limitSeconds]);

    const saveLastActivity = (value: Dayjs) => {
        localStorage.setItem('lastActivity', value.format());
        setInactivityInSeconds(0);
        setLastActivity(value);
    };

    const isAgentAlive = useDebouncedCallback(() => {
        if (!showPopup) {
            saveLastActivity(dayjs());
        }
    }, 250);

    useEffect(() => {
        isAgentAlive();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.addEventListener('mousemove', isAgentAlive);
        window.addEventListener('keyup', isAgentAlive);
        window.addEventListener('click', isAgentAlive);

        return () => {
            window.removeEventListener('mousemove', isAgentAlive);
            window.removeEventListener('keyup', isAgentAlive);
            window.removeEventListener('click', isAgentAlive);
        };
        // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
        saveLastActivity(dayjs());
        setInactivityInSeconds(0);
    };

    useEffect(() => {
        setChecking(!!userData);
    }, [userData]);

    useEffect(() => {
        if (showPopup) {
            ctx.showPopup(null);
        }
        // eslint-disable-next-line
    }, [showPopup]);

    if (userData && showPopup) {
        return <InactivityPopup timeToLogout={timeToLogout} onSubmit={onSubmit} />;
    }

    return null;
};

export default CheckInactivity;
