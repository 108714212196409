import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAttachOnlinePolicyAttachmentByCodeProps {
    data: {
        verificationCode?: string;
        onlineAttachmentInfoDto?: {
            attachmentData: any;
            externalId: string;
            fileName: string;
            displayName?: string;
            pageNumber: number;
            onlinePolicyAttachmentVerificatorTypeCode?: string;
            onlinePolicyAttachmentTypeCode: string;
        };
        onlineAttachments?: Array<{
            attachmentData: any;
            externalId: string;
            fileName: string;
            displayName?: string;
            pageNumber: number;
            onlinePolicyAttachmentVerificatorTypeCode?: string;
            onlinePolicyAttachmentTypeCode: string;
        }>;
        clientPersonDtoJson?: string;
    };
}

export const callAttachOnlinePolicyAttachmentByCode = ({
    data,
}: CallAttachOnlinePolicyAttachmentByCodeProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/attach-online-policy-attachment-by-code`,
        data,
    });
