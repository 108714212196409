import React, { useEffect, useState } from 'react';
import { Client, ClientDetail } from '../types';
import { callMyClientDetail } from '../../../../apis/dashboard';
import ClientsToggleForm from './ClientsToggleForm';

interface MyClientsToggleProps {
    data: Client;
}

const MyClientsToggle = ({ data }: MyClientsToggleProps) => {
    const token = localStorage.getItem('token');
    const [clientData, setClientData] = useState<ClientDetail | null>(null);

    useEffect(() => {
        if (token) {
            callMyClientDetail({
                data: {
                    clientExternalId: data.ClientBaseExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => setClientData(result));
        }
    }, [token, data]);

    return <ClientsToggleForm data={data} clientData={clientData} />;
};

export default MyClientsToggle;
