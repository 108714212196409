import cn from 'classnames';
import { ReactElement } from 'react';

import { Title } from './common/Title';

interface HeadProps {
    heading1: string;
    heading2?: string | null;
    hasMultipleHead?: boolean;
    afterHeadingContent?: ReactElement;
    className?: string;
}

export const Head = ({
    heading1,
    heading2,
    hasMultipleHead,
    afterHeadingContent,
    className,
}: HeadProps): ReactElement => {
    return (
        <div className={cn(className, 'mb-4 flex flex-col gap-y-3', !heading2 ? 'md:mb-[4.5rem]' : 'md:mb-12')}>
            <Title tag={hasMultipleHead ? 'h2' : 'h1'} size="lg" isCenter data-test="title" className="flex flex-col gap-y-2 md:flex-row">
                {heading1}
                {afterHeadingContent && <div>{afterHeadingContent}</div>}
            </Title>

            {heading2 && (
                <Title tag={hasMultipleHead ? 'h3' : 'h2'} size="md" fontWeight="regular" isCenter data-test="subtitle">
                    {heading2}
                </Title>
            )}
        </div>
    );
};
