import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { ClientConsentType } from '../../types';

interface CallConfirmEmailVerificationProps {
    data: {
        tokenExternalId: string;
        tokenData: string | null;
        clientConsents: ClientConsentType[];
    };
}

export const callConfirmEmailVerification = ({
    data,
}: CallConfirmEmailVerificationProps): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/confirm-email-verification`,
        data,
    });
