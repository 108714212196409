import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ImagePeople from '../assets/images/people.png';
import ImagePhone from '../assets/images/phone.png';
import { Head } from '../components/Head';
import { PackageCard } from '../components/PackageCard';
import { CardsWrapper } from '../components/CardsWrapper';
import { getHigherStep, prepareJsonOnlineModelData } from '../utils';
import modelIntialState from '../data/model-initial-state.json';
import { ArrangementKind } from '../types/enums/ArrangementKind';
import { AppContext } from '../components/Context';
import { ROUTE, STEP_CODE } from '../constants';
import { useAppNavigate } from '../utils/hooks';
import { Layout } from '../components/Layout';
import IsPepDialog from '../components/IsPepDialog';

export const Conclusion = () => {
    const { t } = useTranslation();
    const { initData, predefinedIsPep, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;

    const { navigateTo } = useAppNavigate();

    const handleChoiceArrandementType = (kind: number) => {
        if (predefinedIsPep && kind === ArrangementKind.Remote) {
            return;
        }

        if (modelIntialState) {
            const data = prepareJsonOnlineModelData(modelIntialState, initData);

            const newData = {
                ...data,
                Settings: {
                    ...data.Settings,
                    ArrangementType: kind,
                    CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.CONCLUSION),
                },
            };

            // předdefinováno je pep
            if (predefinedIsPep !== undefined) {
                newData.Participants[0].IsPep = predefinedIsPep;
            }

            ctx.setCurrentModel(newData);

            navigateTo(ROUTE.QUESTIONNAIRE, {
                externalId: newData.ExternalId,
                ignoreLimits: true,
            });
        }
    };

    return (
        <Layout hideSaveButton withoutPolicy hideFooter>
            <Head heading1={t('pages.conclusion.title')} heading2={t('pages.conclusion.subtitle')} />

            <CardsWrapper className="mb-12">
                <PackageCard
                    image={ImagePeople}
                    title={t('pages.conclusion.cards.0.title')}
                    onClick={() => handleChoiceArrandementType(ArrangementKind.F2F)}
                    hasButton
                    isSelected={data?.Settings?.ArrangementType === ArrangementKind.F2F}
                    data-test="cardF2F"
                />
                <PackageCard
                    image={ImagePhone}
                    title={t('pages.conclusion.cards.1.title')}
                    onClick={() => handleChoiceArrandementType(ArrangementKind.Remote)}
                    hasButton
                    isDisabled={predefinedIsPep}
                    isSelected={data?.Settings?.ArrangementType === ArrangementKind.Remote}
                    data-test="cardRemote"
                />
            </CardsWrapper>

            <div>
                <p className="mb-6 text-center" data-test="conclusionText1">
                    <strong>
                        <Trans
                            i18nKey="pages.conclusion.texts.title"
                            components={{
                                link1: (
                                    <a
                                        href="https://www.simplea.pl/userfiles/rynek-docelowy-2024_17074776014191.pdf"
                                        target="_blank"
                                        className="underline"
                                        rel="noreferrer"
                                        data-test="link"
                                    >
                                        .
                                    </a>
                                ),
                            }}
                        />
                    </strong>
                    <br />
                    <br />
                    <strong>{t('pages.conclusion.texts.row1')}</strong>
                    <br />
                    {t('pages.conclusion.texts.row2')}
                </p>
                <p className="text-center" data-test="conclusionText2">
                    <strong>{t('pages.conclusion.texts.row3')}</strong>
                </p>
            </div>
            {predefinedIsPep === undefined && <IsPepDialog />}
        </Layout>
    );
};
