import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { callGetOnlinePolicyMetadataByCode } from '../../apis/support';
import { IdSnapper } from '../../components/IdSnapper/IdSnapper';
import Loader from '../../components/common/Loader';
import { ROUTE } from '../../constants';

export const VerifySnap = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const { identityId } = useParams();
    const [accessChecked, setAccessChecked] = useState(false);

    const invalidAccess = () => {
        navigate(ROUTE.INVALID_LINK);
    };

    useEffect(() => {
        if (identityId) {
            // kontrola souhlasů
            callGetOnlinePolicyMetadataByCode({
                data: {
                    verificationCode: identityId,
                },
            })
                .then(() => setAccessChecked(true))
                .catch(() => invalidAccess());
        } else {
            invalidAccess();
        }
        // eslint-disable-next-line
    }, []);

    return accessChecked ? <IdSnapper currentStep={step} setCurrentStep={setStep} /> : <Loader />;
};
