import React, { useContext } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Input } from '../../components/common/Input';
import { SMS_CODE_LENGTH } from '../../constants';
import { Link } from '../../components/common/Link';
import { ContactContext } from './ContactContext';

interface WaitingForClientSMSProps {
    values: any;
    onResendCode: (values: any) => void;
}

const WaitingForClientSMS = ({ values, onResendCode }: WaitingForClientSMSProps) => {
    const { t } = useTranslation();
    const { resendEnabled } = useContext(ContactContext);

    return (
        <Card className="mt-8" data-test="waitForClientCode">
            <Title isCenter tag="h2" size="md" fontWeight="medium" className="mb-8 md:mb-12" data-test="waitTitle">
                {t('pages.contactConfirmation.subtitle')}
            </Title>

            <div className="mb-8 text-xs font-medium md:mb-12 md:text-sm">
                <div className="text-center">
                    <p
                        data-test="waitDescription"
                        dangerouslySetInnerHTML={{
                            __html: t<string>('pages.contactConfirmation.emailNotAccessibleText', {
                                interpolation: { escapeValue: false },
                                phoneNumberPrefix: values.phoneNumberPrefix.label,
                                phoneNumber: values.phoneNumber,
                            }),
                        }}
                    ></p>
                </div>
            </div>

            <div className="mx-auto mb-10 w-full max-w-[20rem] md:mb-12">
                <Field
                    component={Input}
                    type="smsCode"
                    name="smsCode"
                    maxLength={SMS_CODE_LENGTH}
                    label={t('common.formFields.enterSmsCode')}
                    value={values.smsCode}
                    isCenter
                    autoFocus
                    data-test="smsCode"
                />
            </div>

            <Link
                variant="dark"
                className="mx-auto font-semibold"
                isDisabled={!resendEnabled}
                onClick={() => onResendCode(values)}
                data-test="resendNewCodeLink"
            >
                {t('common.sendNewSmsCode')}
            </Link>
        </Card>
    );
};

export default WaitingForClientSMS;
