import React, { useEffect, useState } from 'react';
import { Policy, PolicyDetail } from '../types';
import { callAgencyPolicyDetail } from '../../../../apis/dashboard';
import PolicyToggleForm from './PolicyToggleForm';

interface AgencyPolicyToggleProps {
    data: Policy;
}

const AgencyPolicyToggle = ({ data }: AgencyPolicyToggleProps) => {
    const token = localStorage.getItem('token');
    const [policyData, setPolicyData] = useState<PolicyDetail | null>(null);

    useEffect(() => {
        if (token) {
            callAgencyPolicyDetail({
                data: {
                    policyExternalId: data.PolicyExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => setPolicyData(result));
        }
    }, [token, data]);

    return <PolicyToggleForm policyData={policyData} />;
};

export default AgencyPolicyToggle;
