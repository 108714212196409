import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Link } from '../../components/common/Link';
import { Button } from '../../components/common/Button';
import FooterMobileMenu from '../../components/FooterMobileMenu';
import FooterMobileMenuItem from '../../components/FooterMobileMenuItem';
import { AppContext } from '../../components/Context';
import { deleteStoredValues } from '../../utils';
import { callGetIllustrationDocument } from '../../apis/documents';
import { callRecalculate } from '../../apis/calculations';
import { ModelProps } from '../../types/model';
import { PackageAdjustmentErrors } from '.';
import { hasErrors } from './helpers';
import { Icon } from '../../components/Icon';
import ButtonMobileMore from '../../components/common/ButtonMobileMore';

interface FooterContentProps {
    loading: boolean;
    errors: PackageAdjustmentErrors | null;
    mustRecalculate: boolean;
    formValues: any;
    blockReset: boolean;
    setLoading: (value: boolean) => void;
    onUpdateModel: (data: any) => ModelProps | null;
    onCallValidations: (data: ModelProps) => Promise<boolean>;
}

const FooterContent = ({
    loading,
    errors,
    mustRecalculate,
    formValues,
    blockReset,
    setLoading,
    onUpdateModel,
    onCallValidations,
}: FooterContentProps) => {
    const { t } = useTranslation();
    const { coverChangedCategories, coverChangedVariants, setCoversChanged, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const [printLoading, setPrintLoading] = useState(false);
    const token = localStorage.getItem('token');

    const showLoaders = (value: boolean) => {
        setLoading(value);
        setPrintLoading(value);
    };

    const resetToOriginalPackage = useCallback(() => {
        ctx.setCurrentModel(ctx.basicSuggestedInsurace);
        deleteStoredValues('packageAdjustment');
        // eslint-disable-next-line
    }, []);

    const handlePrintCalculation = () => {
        if (token && data) {
            showLoaders(true);
            ctx.saveCurrentModel(data)
                .then(() =>
                    callGetIllustrationDocument({ data: { jsonOnlineModel: JSON.stringify(data) }, token })
                        .then((response) => {
                            // Získání názvu souboru z hlaviček odpovědi (pokud je k dispozici)
                            const contentDisposition = response.headers['content-disposition'];
                            const contentType = response.headers['content-type'];
                            // Výchozí název souboru
                            let fileName = 'Zestawienie swiadczen.pdf';

                            // Najdi část hlavičky, která obsahuje zakódovaný název souboru.
                            const regex = /filename\*?=(UTF-8['']?[^;\r\n]*)/i;
                            const match = contentDisposition?.match(regex);

                            //@TODO: Refactor following code
                            if (match) {
                                // Získej zakódovaný název souboru.
                                let encodedFilename = match[1].trim();

                                // Rozděl název souboru podle apostrofů (single quotes).
                                const parts = encodedFilename.split("''");

                                // Pokud máme dva díly, druhý díl je zakódovaný název souboru v UTF-8.
                                if (parts.length === 2) encodedFilename = parts[1];

                                // Dekóduj zakódovaný název souboru.
                                const decodedFilename = decodeURIComponent(encodedFilename);

                                // Odstraň případné uvozovky kolem názvu souboru.
                                fileName = decodedFilename.replace(/"/g, '');
                            }

                            // Vytvořte Blob objekt z binárních dat
                            const blob = new Blob([response.data], { type: contentType });

                            // Vytvoření odkazu pro stažení souboru s názvem z hlavičky response
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = window.URL.createObjectURL(blob);
                            a.download = fileName;
                            document.body.appendChild(a);
                            a.click();
                        })
                        .catch((err) => console.error('callGetIllustrationDocument', err))
                        .finally(() => showLoaders(false))
                )
                .catch((err) => console.error(err))
                .finally(() => showLoaders(false));
        }
    };

    const handleRecalculate = async () => {
        if (token && data) {
            const newModelData = onUpdateModel(formValues);

            if (newModelData) {
                setLoading(true);

                try {
                    // Save model even when invalid
                    ctx.setCurrentModel(newModelData);
                    ctx.saveCurrentModel(newModelData);

                    const recalculateResult = await callRecalculate({
                        data: { jsonOnlineModel: JSON.stringify(newModelData) },
                        token,
                    });

                    if (recalculateResult) {
                        ctx.setCurrentModel(recalculateResult.data);
                        // validace
                        await onCallValidations(recalculateResult.data);
                    }
                    setCoversChanged([], []);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    return (
        <div className="flex grow">
            <div className="flex grow flex-nowrap items-center lgmax:hidden">
                <Link
                    isDisabled={loading || blockReset}
                    variant="dark"
                    onClick={resetToOriginalPackage}
                    className="mr-4 max-w-[12rem]"
                    data-test="coversResetToOriginPackageLink"
                >
                    {t('common.resetToOriginalPackage')}
                </Link>

                <Button
                    isDisabled={loading || mustRecalculate || blockReset || hasErrors(errors)}
                    onClick={handlePrintCalculation}
                    data-test="coversPrintCalculationButton"
                >
                    {printLoading && <Icon name="spinner" stroke="#FFF" />}
                    {t<string>('common.printCalculation')}
                </Button>

                {mustRecalculate ? (
                    <>
                        <span className="mx-4 text-xs" data-test="coversMustRecalculateDescription">
                            {t('pages.packageAdjustment.faceAmountsChanged')}
                        </span>

                        <Button
                            isOutlined
                            isDisabled={loading || blockReset}
                            onClick={handleRecalculate}
                            data-test="coversRecalculateButton[NotMobile]"
                        >
                            {t('common.recalculate')}
                        </Button>
                    </>
                ) : (
                    <p
                        className={cn('ml-auto mr-4 text-right', loading && 'opacity-50')}
                        data-test="coversTotalMonthlyPremium[NotMobile]"
                    >
                        <span>{t('common.totalMonthlyPremium')}</span>
                        <strong className="block text-purple lg:text-lg" data-test="coversTotalMonthlyPremiumValue">
                            {data?.InsuredPersons[0]?.SuggestedInsurance?.PremiumTotal?.Monthly}{' '}
                            {data?.Payment.CurrencyCode}
                        </strong>
                    </p>
                )}
            </div>

            <div className="relative ml-auto lg:hidden">
                <FooterMobileMenu
                    control={
                        <ButtonMobileMore
                            caption={t('common.more')}
                            isDisabled={loading}
                            data-test="coversMoreButton"
                        />
                    }
                >
                    <FooterMobileMenuItem onClick={handlePrintCalculation} disabled={loading}>
                        {t('common.printCalculation')}
                    </FooterMobileMenuItem>
                    <FooterMobileMenuItem onClick={resetToOriginalPackage} disabled={loading || blockReset}>
                        {t('common.resetToOriginalPackage')}
                    </FooterMobileMenuItem>
                </FooterMobileMenu>
            </div>

            {mustRecalculate && (
                <Button
                    isOutlined
                    isDisabled={loading || blockReset}
                    onClick={handleRecalculate}
                    className="ml-2 lg:hidden"
                    data-test="coversRecalculateButton[Mobile]"
                >
                    {t('common.recalculate')}
                </Button>
            )}
        </div>
    );
};

export default FooterContent;
