import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallSaveClientConsentByCodeProps {
    data: {
        verificationCode: string;
        clientConsents: Array<{
            consentPurposeTypeCode: string;
            consentForCompanyCode: string;
            consentOperationTypeCode: string;
        }>;
    };
}

export const callSaveClientConsentByCode = ({
    data,
}: CallSaveClientConsentByCodeProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/save-client-consent-by-code`,
        data,
    });
