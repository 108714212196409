import React, { ChangeEvent, useContext } from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { RadioToggle } from '../common/RadioToggle';
import { AppContext } from '../Context';
import { RiderGroupFamiliesProps } from '../../pages/PackageAdjustment';

interface PartnerSelectProps {
    isDisabled?: boolean;
    group: RiderGroupFamiliesProps;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PartnerSelect = ({ isDisabled, group, onChange }: PartnerSelectProps) => {
    const { t } = useTranslation();
    const { coverChangedCategories, coverChangedVariants, setCoversChanged } = useContext(AppContext);

    const handleChangePartner = (e: ChangeEvent<HTMLInputElement>) => {
        setCoversChanged(
            !coverChangedCategories.includes(group.Code)
                ? [...coverChangedCategories, group.Code]
                : coverChangedCategories,
            coverChangedVariants
        );
        onChange && onChange(e);
    };

    return (
        <div
            className={cn(
                'flex flex-col border-b-2 border-white px-4 py-4 text-center text-xs last:border-b-0 md:grid-cols-7 md:px-6 md:text-sm'
            )}
            data-test="selectPartnerBox"
        >
            <div className="flex flex-row items-center gap-x-4">
                <Field
                    component={RadioToggle}
                    type="radio"
                    id="married"
                    value="married"
                    name="partnerType"
                    label={t('common.spouse')}
                    isDisabled={isDisabled}
                    onChange={handleChangePartner}
                    data-test="selectPartnerMarried"
                />
                <Field
                    component={RadioToggle}
                    type="radio"
                    id="partner"
                    value="partner"
                    name="partnerType"
                    label={t('common.partner')}
                    isDisabled={isDisabled}
                    onChange={handleChangePartner}
                    data-test="selectPartnerPartner"
                />
            </div>
        </div>
    );
};

export default PartnerSelect;
