import { ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icon';

interface ButtonProps {
    children?: ReactNode;
    href?: string;
    onClick?: () => void;
    isOutlined?: boolean;
    isFixedSize?: boolean;
    size?: 'lg' | 'md' | 'sm';
    isSubmit?: boolean;
    isDark?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    icon?: string;
    className?: string;
    innerSpan?: boolean;
}

export const Button = ({
    children,
    href,
    onClick,
    isOutlined,
    isSubmit,
    size = 'lg',
    isDark,
    isDisabled,
    icon,
    isFixedSize = true,
    isHidden = false,
    className,
    innerSpan = false,
    ...props
}: ButtonProps) => {
    const cls = cn(
        twMerge(
            'btn-base',
            'gap-x-2',
            size === 'sm'
                ? 'px-5 py-1'
                : size === 'md'
                ? 'px-5 py-3'
                : isFixedSize
                ? 'px-8 py-3 md:py-5'
                : 'px-5 py-2 md:py-4',
            isOutlined ? 'btn-primary-outlined' : 'btn-primary',
            isDark && isOutlined && 'btn-dark-outlined',
            !isSubmit && !onClick && !href && 'pointer-events-none',
            isDisabled && 'opacity-50 pointer-events-none',
            isHidden && 'pointer-events-none select-none opacity-0 invisible',
            innerSpan && 'shrink',
            className
        )
    );

    if (isHidden) {
        return (
            <div className={cls} {...props}>
                {icon && <Icon name={icon} />}
                {children}
            </div>
        );
    }

    if (href) {
        return (
            <Link to={isDisabled ? '#' : href} className={cls} {...props}>
                {icon && <Icon name={icon} />}
                {children}
            </Link>
        );
    }

    return (
        <button
            className={cls}
            onClick={onClick}
            type={isSubmit ? 'submit' : 'button'}
            disabled={isDisabled}
            {...props}
        >
            {icon && <Icon name={icon} />}
            {!innerSpan ? (
                children
            ) : (
                <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">{children}</span>
            )}
        </button>
    );
};
