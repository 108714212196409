import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallValidateCoverageSumInsuredProps {
    data: {
        JsonOnlineModel: string;
        CheckSuggestedInsurance: boolean;
        CheckAcceptedCoverage: boolean;
    };
    token: string;
}

export interface CallValidateCoverageSumInsuredResponse {
    Passed: boolean;
    ValidationResults: [
        {
            Path: string;
            Message: string;
        }
    ];
}

export const callValidateCoverageSumInsured = ({
    data,
    token,
}: CallValidateCoverageSumInsuredProps): Promise<AxiosResponse<CallValidateCoverageSumInsuredResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}validations/validate-coverage-sum-insured`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
