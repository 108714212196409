import React from 'react';
import cn from 'classnames';
import { Icon } from '../Icon';

interface ChevronIndicatorProps {
    isActive?: boolean;
}

const ChevronIndicator = ({ isActive }: ChevronIndicatorProps) => (
    <Icon
        name="chevron-up"
        className={cn('shrink-0 transition-transform', isActive ? 'rotate-0' : 'rotate-180')}
        data-test="chevronIndicator"
    />
);

export default ChevronIndicator;
