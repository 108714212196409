import React, { Fragment } from 'react';
import Question from './Question';
import { Field } from 'formik';
import { Checkbox } from '../../components/common/Checkbox';
import { Radio } from '../../components/common/Radio';
import HelperText from '../../components/HelperText';
import OpenSection from './OpenSection';

interface LongTermCareFormProps {
    values: any;
}

const LongTermCareForm = ({ values }: LongTermCareFormProps) => {
    return (
        <Fragment>
            <Question name="longTermCare" text="Splñuji podminky závislosti na dlouhodobé péci" />
            {values.longTermCare === 'yes' && (
                <OpenSection>
                    <div className="mb-5 grid gap-y-8">
                        <div>
                            <div className="flex">
                                <div className=" w-[65%] items-center">
                                    <Field
                                        component={Checkbox}
                                        name="checkbox1"
                                        label="Mám závislost na dlouhodobé péči již přiznanou, a to ve stupni"
                                        labelClassName="font-normal"
                                        className="my-4"
                                    />
                                </div>
                                <div className="flex w-[35%] items-center justify-center gap-x-6 text-center">
                                    <Field
                                        component={Radio}
                                        name="invalidityLevel"
                                        type="radio"
                                        value="1"
                                        label="1. st."
                                        isDisabled={!values.checkbox1}
                                    />
                                    <Field
                                        component={Radio}
                                        name="invalidityLevel"
                                        type="radio"
                                        value="2"
                                        label="2. st."
                                        isDisabled={!values.checkbox1}
                                    />
                                    <Field
                                        component={Radio}
                                        name="invalidityLevel"
                                        type="radio"
                                        value="3"
                                        label="3. st."
                                        isDisabled={!values.checkbox1}
                                    />
                                    <Field
                                        component={Radio}
                                        name="invalidityLevel"
                                        type="radio"
                                        value="4"
                                        label="4. st."
                                        isDisabled={!values.checkbox1}
                                    />
                                </div>
                            </div>
                            <HelperText>
                                Pokud ano, přiložte prosím rozhodnutí o přiznání stupně závislosti na dlouhodobé péči
                                vydané Úřadem práce ČR.
                            </HelperText>
                        </div>
                        <div>
                            <Field
                                component={Checkbox}
                                name="checkbox2"
                                label="Mám zažádáno o přiznání stupně závislosti na dlouhodobé péči"
                                labelClassName="font-normal"
                                className="my-4"
                            />
                            <HelperText>
                                Pokud ano, přiložte prosím žádost o přiznání stupně závislosti na dlouhodobé péči
                                podanou na Úřad práce ČR.
                            </HelperText>
                        </div>
                        <Field
                            component={Checkbox}
                            name="checkbox3"
                            label="Nemám přiznaný stupeň závislosti na dlouhodobé péči a ani o něj nemám zažádáno"
                            labelClassName="font-normal"
                        />
                        <Field
                            component={Checkbox}
                            name="checkbox4"
                            label="Požaduji umístění v pobytovém zařízení"
                            labelClassName="font-normal"
                        />
                    </div>
                </OpenSection>
            )}
        </Fragment>
    );
};

export default LongTermCareForm;
