import React, { ReactNode } from 'react';

interface OpenSectionProps {
    children: ReactNode;
}

const OpenSection = ({ children }: OpenSectionProps) => {
    return <div className="pt-5">{children}</div>;
};

export default OpenSection;
