import { Fragment, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { getFormatedAddress } from '../../utils';

export const getPersonalInformation = (ownerParticipant: any, details: any, isMissingMojeIdData?: boolean) => {
    const rows: ReactNode[] = [];

    if (isMissingMojeIdData) {
        rows.push(
            <span key="missingData" data-test="missingData" className="italic">
                <Trans>pages.summary.dataWillBeAddedToSoon</Trans>
            </span>
        );
        rows.push(
            <p key="birthDate" data-test="birthDate">
                {dayjs(ownerParticipant?.Identification.BirthDt || '-').format('DD. MM. YYYY')}
            </p>
        );
    } else {
        rows.push(
            <p key="fullName" data-test="fullName">
                {details?.FirstName || '-'} {details?.LastName || '-'}
            </p>
        );
        rows.push(
            <p key="identifier" data-test="identifier">
                {details?.PersonalIdentifier || '-'}
            </p>
        );
        rows.push(
            <p key="birthDate" data-test="birthDate">
                {dayjs(ownerParticipant?.Identification.BirthDt || '-').format('DD. MM. YYYY')},{' '}
                {details?.Birthplace || '-'}, {details?.BirthCountryCode || '-'}
            </p>
        );
        rows.push(
            <p key="nationality" data-test="nationality">
                {details?.CitizenshipCode || '-'} <Trans>common.nationality</Trans>
            </p>
        );
    }

    rows.push(
        <p key="email" data-test="email">
            {ownerParticipant?.Contact.Email}
        </p>
    );
    rows.push(
        <p key="phone" data-test="phone">
            {ownerParticipant?.Contact.PhonePrefix.Prefix}
            {ownerParticipant?.Contact.Phone}
        </p>
    );

    return <Fragment>{rows}</Fragment>;
};

export const getPersonalAddress = (data: any, isMissingMojeIdData?: boolean) => {
    const rows: ReactNode[] = [];

    if (isMissingMojeIdData) {
        rows.push(
            <span key="missingData" data-test="missingData" className="italic">
                <Trans>pages.summary.dataWillBeAddedToSoon</Trans>
            </span>
        );
    } else {
        rows.push(
            <p key="street" data-test="street">
                <Trans>common.streetCode</Trans> {data?.StreetName || '-'} {data?.HouseNo || '-'}
            </p>
        );
        rows.push(
            <p key="zipCity" data-test="zipCity">
                {data?.Zip || '-'} {data?.CityName || '-'}
            </p>
        );
        rows.push(
            <p key="countryCode" data-test="countryCode">
                {data?.CountryCode || '-'}
            </p>
        );
    }

    return <Fragment>{rows}</Fragment>;
};

export const getBeneficiariesList = (participants: any[], getBeneficiarySplitPct: (id: string) => number) => {
    const rows: ReactNode[] = [];

    if (participants.length === 0) {
        rows.push(
            <p key="accordingToLaw" data-test="accordingToLaw">
                <Trans>common.accordingToLaw</Trans>
            </p>
        );
    } else {
        rows.push(
            <p key="byExactName" data-test="byExactName">
                <Trans>common.byExactName</Trans>
            </p>
        );
        participants.forEach((p: any, index: number) => {
            rows.push(<hr key={`divider${index}`} className="order-b-1 my-2 border-purple" />);
            rows.push(
                <p key={`participant[${index}]FullName`} data-test={`participant[${index}]FullName`}>
                    {p.Identification.PersonalIdentificationDetail?.FirstName}{' '}
                    {p.Identification.PersonalIdentificationDetail?.LastName}
                </p>
            );
            rows.push(
                <p key={`participant[${index}]Identifier`} data-test={`participant[${index}]Identifier`}>
                    {p.Identification.PersonalIdentificationDetail?.PersonalIdentifier || '-'}
                </p>
            );
            rows.push(
                <p key={`participant[${index}]PercentageShare`} data-test={`participant[${index}]PercentageShare`}>
                    {getBeneficiarySplitPct(p.ExternalId)} %
                </p>
            );
        });
    }

    return <Fragment>{rows}</Fragment>;
};

export const getPartnerInformation = (partner: any) => {
    const rows: ReactNode[] = [];

    rows.push(
        <p key="partnerFullName" data-test="partnerFullName">
            {partner?.PersonalIdentificationDetail?.FirstName || '-'}{' '}
            {partner?.PersonalIdentificationDetail?.LastName || '-'}
        </p>
    );
    rows.push(
        <p key="partnerIdentifier" data-test="partnerIdentifier">
            {partner?.PersonalIdentificationDetail?.PersonalIdentifier || '-'}
        </p>
    );

    return <Fragment>{rows}</Fragment>;
};

export const getBankInformation = (
    initData: any,
    bank: any,
    getBeneficiarySplitPct: (id: string) => number,
    getBeneficiaryNote: (id: string) => string | null
) => {
    const rows: ReactNode[] = [];

    rows.push(
        <p key="bankName" data-test="bankName">
            {bank.CompanyIdentification.Name}
        </p>
    );
    rows.push(
        <p key="bankIdentifier" data-test="bankIdentifier">
            {bank.CompanyIdentification.IdentificationNo || '-'}
        </p>
    );
    rows.push(
        <p key="bankAddress" data-test="bankAddress">
            {getFormatedAddress(initData, bank.CompanyIdentification.ResidenceAddress)}
        </p>
    );
    rows.push(
        <p key="bankPercentageShare" data-test="bankPercentageShare">
            {getBeneficiarySplitPct(bank.ExternalId)} %
        </p>
    );

    if (getBeneficiaryNote(bank.ExternalId)) {
        rows.push(
            <div key="bankFreeText" className="truncate">
                <small data-test="bankFreeText">({getBeneficiaryNote(bank.ExternalId)})</small>
            </div>
        );
    }

    return <Fragment>{rows}</Fragment>;
};
