import React from 'react';
import ReactPaginate from 'react-paginate';
import { ReactComponent as ArrowLeft } from '../../../assets/images/pagination-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/pagination-arrow-right.svg';

interface PaginationProps {
    page: number;
    recordsCount: number;
    recordsPerPage?: number;
    handleChange: (page: number) => void;
}
export default function Pagination({ page, recordsCount, recordsPerPage = 25, handleChange }: PaginationProps) {
    const pageCount = Math.ceil(recordsCount / recordsPerPage);

    if (pageCount === 0) {
        return null;
    }

    return (
        <div className="float-right" data-test="pagination">
            <ReactPaginate
                className="pagination list-style-none flex list-none"
                breakLabel="..."
                forcePage={page}
                nextLabel={<ArrowRight />}
                onPageChange={(event) => handleChange(event.selected)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={<ArrowLeft />}
                renderOnZeroPageCount={null}
            />
        </div>
    );
}
