import { FormikValues } from 'formik';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { getBirthDtOfPesel, getSexKindOfPesel } from '../../utils';
import { IdentificationType } from '../../types/enums/IdentificationType';
import { SexKind } from '../../types/enums/SexKind';
import { BeneficiaryPersonDataType } from './types';
import { BankDataProps } from './BankDataForm';
import { ModelProps, ParticipantsType } from '../../types/model';

export const createBeneficiariesData = (values: FormikValues): any[] => {
    return (values.beneficiaries || []).reduce((acc: any[], b: BeneficiaryPersonDataType, i: number) => {
        const birthDtOfPesel = getBirthDtOfPesel(values[`pesel-${i}`]) || null;
        const sexKindOfPesel = getSexKindOfPesel(values[`pesel-${i}`]) || null;
        acc.push({
            $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.ParticipantPerson, Eden.Paris.OnlineManagerService.SharedModels',
            ExternalId: uuid(),
            ClientExternalId: uuid(),
            Identification: {
                $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationFull, Eden.Paris.OnlineManagerService.SharedModels',
                PersonalIdentificationDetail: {
                    $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationDetail, Eden.Paris.OnlineManagerService.SharedModels',
                    FirstName: values[`name-${i}`] ?? '',
                    LastName: values[`surName-${i}`] ?? '',
                    PersonalIdentifier: values[`pesel-${i}`] ?? '',
                    PersonalIdentifierType: IdentificationType.Pesel,
                },
                BirthDt: birthDtOfPesel,
                CurrentAge: Math.floor(dayjs().diff(birthDtOfPesel, 'year', true)),
                Sex: sexKindOfPesel ?? SexKind.NotSpecified,
            },
        });

        return acc;
    }, []);
};

export const createBankData = (currentBank: BankDataProps | null) => {
    return {
        $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.ParticipantCompany, Eden.Paris.OnlineManagerService.SharedModels',
        ExternalId: uuid(),
        ClientExternalId: uuid(),
        CompanyIdentification: {
            Name: currentBank?.Name ?? null,
            IdentificationNo: currentBank?.Regon ?? null,
            TaxNo: currentBank?.Nip ?? null,
            ResidenceAddress: {
                $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.Address, Eden.Paris.OnlineManagerService.SharedModels',
                CityName: currentBank?.ContactAddress?.CityName,
                CountryCode: currentBank?.ContactAddress?.CountryCode,
                HouseNo: currentBank?.ContactAddress?.HouseNo,
                StreetName: currentBank?.ContactAddress?.StreetName,
                Zip: currentBank?.ContactAddress?.Zip,
            },
        },
        Contact: null,
    };
};

export const updatePartnerData = (values: FormikValues, data: ModelProps, id?: string): ParticipantsType | null => {
    const target = data?.Participants?.find((p) => p.ExternalId === id);

    if (target) {
        const birthDtOfPesel = getBirthDtOfPesel(values['partnerPesel']);
        const sexKindOfPesel = getSexKindOfPesel(values['partnerPesel']);
        return {
            ...target,
            Identification: {
                $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationFull, Eden.Paris.OnlineManagerService.SharedModels',
                // @ts-ignore
                PersonalIdentificationDetail: {
                    $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationDetail, Eden.Paris.OnlineManagerService.SharedModels',
                    FirstName: values['partnerName'] ?? '',
                    LastName: values['partnerSurname'] ?? '',
                    PersonalIdentifier: values['partnerPesel'] ?? '',
                    PersonalIdentifierType: IdentificationType.Pesel,
                },
                BirthDt: birthDtOfPesel ?? '',
                CurrentAge: Math.floor(dayjs().diff(birthDtOfPesel, 'year', true)),
                Sex: sexKindOfPesel ?? SexKind.NotSpecified,
            },
        };
    }

    return null;
};
