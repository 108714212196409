import React, { ReactNode, useContext, useEffect } from 'react';
import { AppContext } from './Context';
import { useAppNavigate } from '../utils/hooks';
import { getRouteByStepPosition, getStepPosition } from '../utils';

interface LimitedAccessProps {
    minStep: string;
    children: ReactNode;
}

const LimitedAccess = ({ minStep, children }: LimitedAccessProps) => {
    const ctx = useContext(AppContext);
    const { navigateTo } = useAppNavigate();
    const data = ctx?.currentModel;

    useEffect(() => {
        if (data && data?.Settings) {
            const currentStepCode = data?.Settings?.CurrentStepCode;
            const currentStepCodePosition = getStepPosition(currentStepCode);

            if (minStep) {
                const minStepPosition = getStepPosition(minStep);
                if (minStepPosition > currentStepCodePosition) {
                    const route = getRouteByStepPosition(currentStepCodePosition + 1);
                    if (route) {
                        navigateTo(route.path);
                    }
                }
            }
        }
    }, [minStep, ctx, data, navigateTo]);

    return <>{children}</>;
};

export default LimitedAccess;
