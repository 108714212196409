import React from 'react';
import { KnowledgeDocumentFileName, ProductSpecificationItemType } from './types';
import { Trans } from 'react-i18next';
import { Link } from '../../../../../components/common/Link';
import { onClickOpenDocument } from './Documents';

interface ProductSpecificationItemProps {
    data: ProductSpecificationItemType;
}

const ProductSpecificationItem = ({ data }: ProductSpecificationItemProps) => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: data?.content }} data-test="specificationHeader" />
            <ul className="pm-0 mb-0 list-none" data-test="specificationList">
                {data?.list?.map((item, index: number) => (
                    <li key={index} className="border-t-1 pb-4 pt-4" data-test={`specificationItem${index}`}>
                        <div className="flex">
                            <div className="w-2/5 pr-4">
                                <strong>{item.title}</strong>
                            </div>
                            <div className="w-3/5">
                                <Trans
                                    i18nKey={item.content}
                                    components={{
                                        ul: <ol className="list-decimal">.</ol>,
                                        ol: <ol className="lower-alpha">.</ol>,
                                        pl: <ul className="list-disc">.</ul>,
                                        li: <li>.</li>,
                                        nowrap: <span className="whitespace-nowrap">.</span>,
                                        link1: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.ZalacznikNr1OWU)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link2: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.ZalacznikNr2OWU)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link3: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.ZalacznikNr3OWU)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link4: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.ZalacznikNr4OWU)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link5: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(
                                                    KnowledgeDocumentFileName.ZalacznikNr5Assistance
                                                )}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link11: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.Uposazony)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link12: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(KnowledgeDocumentFileName.OkresKarencji)}
                                            >
                                                .
                                            </Link>
                                        ),
                                        link13: (
                                            <Link
                                                className="text-black font-medium underline"
                                                onClick={onClickOpenDocument(
                                                    KnowledgeDocumentFileName.WylaczeniaOdpowiedzialnosci
                                                )}
                                            >
                                                .
                                            </Link>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default ProductSpecificationItem;
