import React from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/arrow-up.svg';

interface ToggleArrowProps {
    down: boolean;
    onClick: () => void;
}

const ToggleArrow = ({ down, onClick }: ToggleArrowProps) => (
    <div className="cursor-pointer" onClick={onClick} data-test="toggleArrow">
        {down ? <ArrowDown /> : <ArrowUp />}
    </div>
);

export default ToggleArrow;
