import React, { ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_UPLOAD_FILE_TYPES } from '../../constants';

interface UploadManuallyProps {
    onChange: () => void;
}

const UploadManually = React.forwardRef(
    ({ onChange }: UploadManuallyProps, ref: ForwardedRef<HTMLFormElement | null>) => {
        const { t } = useTranslation();

        return (
            <form ref={ref} className="mx-auto mb-4 mt-4" onChange={onChange}>
                <label className="cursor-pointer text-sm font-bold underline" data-test="manuallyUpload">
                    {t('pages.clientVerifyIdentitySnap.loadFromStorage')}
                    <input
                        id="file"
                        name="file"
                        type="file"
                        accept={SUPPORTED_UPLOAD_FILE_TYPES}
                        className="input-hidden"
                        data-test="fileInput"
                    />
                </label>
            </form>
        );
    }
);

export default UploadManually;
