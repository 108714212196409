import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { getDataTest } from '../../utils';

interface DragAndDropUploadProps {
    labelUpload?: string;
    accept?: string;
    multiple?: boolean;
    onUpload: (files: FileList | null) => void;
}

const DragAndDropUpload = ({ labelUpload, accept, multiple = false, onUpload, ...props }: DragAndDropUploadProps) => {
    const { t } = useTranslation();

    return (
        <div className="mt-4 flex w-full items-center justify-center" data-test={getDataTest(props, 'DropZone')}>
            <label
                htmlFor="dropzone-file"
                className="dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 relative flex w-full cursor-pointer flex-col items-center justify-center rounded-full border-2 border-dashed border-purple bg-[#FDF3E7]"
            >
                <div className="flex flex-col items-center justify-center pb-3 pt-5">
                    <p className="text-gray-500 dark:text-gray-400 mb-2 flex text-sm">
                        <PlusIcon className="mr-2" /> {labelUpload || t('common.dragAndDrop')}
                    </p>
                </div>
                <input
                    id="dropzone-file"
                    type="file"
                    accept={accept}
                    multiple={multiple}
                    className="absolute inset-0 border-1 opacity-0"
                    onChange={(e) => onUpload(e.target.files)}
                />
            </label>
        </div>
    );
};

export default DragAndDropUpload;
