import cn from 'classnames';
import { FieldProps } from 'formik';
import { ChangeEvent, ReactElement } from 'react';

import { Help } from './Help';
import { getDataTest } from '../../utils';

interface RadioProps {
    field: FieldProps['field'];
    id: string;
    label: string;
    error?: string;
    isDisabled?: boolean;
    helpText?: string;
    className?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = ({
    field,
    label,
    id,
    isDisabled,
    helpText,
    className,
    onChange,
    ...props
}: RadioProps): ReactElement => {
    const { ...rest } = field;
    return (
        <div
            className={cn(className, 'relative flex cursor-pointer select-none items-center')}
            data-test={getDataTest(props)}
        >
            <label
                className={cn(
                    'flex flex-row items-center gap-x-2',
                    isDisabled && 'pointer-events-none opacity-50',
                    !isDisabled && 'cursor-pointer'
                )}
            >
                <div
                    className={cn(
                        'relative h-5 w-5 shrink-0 rounded-full border-2 border-purple bg-white [&:after]:absolute [&:after]:left-[0.125rem] [&:after]:top-[0.125rem] [&:after]:h-3 [&:after]:w-3 [&:after]:rounded-full [&:after]:bg-purple [&:after]:opacity-0 [&:after]:transition-opacity [&:after]:content-[""]',
                        field.checked && '[&:after]:opacity-100'
                    )}
                />
                <input
                    className="input-hidden"
                    id={id}
                    checked={field.value}
                    type="radio"
                    disabled={isDisabled}
                    {...rest}
                    onChange={(e) => {
                        onChange ? onChange(e) : field.onChange(e);
                    }}
                    data-test={getDataTest(props, 'Input')}
                />

                {label && (
                    <div data-test={getDataTest(props, 'Label')}>
                        <span className="relative">
                            <span className={cn(helpText && 'mr-2')}>{label}</span>
                        </span>
                    </div>
                )}
            </label>
            {helpText && <Help className="bottom-0" text={helpText} data-test={getDataTest(props, 'HelperText')} />}
        </div>
    );
};
