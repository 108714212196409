import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { isDevelopment } from './utils';
import metadata from './metadata.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + metadata.lastBuildDtt,
        },
        debug: isDevelopment(),
        fallbackLng: 'en',
    });

export default i18n;
