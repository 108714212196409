import { t } from 'i18next';

import Puzzle from '../assets/images/puzzle.png';
import { Card } from '../components/common/Card';
import { Title } from '../components/common/Title';
import { Button } from '../components/common/Button';
import { Layout } from '../components/Layout';

export const AutopayPolicyPaid = () => {
    return (
        <Layout hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <Card className="flex flex-col items-center md:flex-row" data-test="autopayPolicyPaidCard">
                <img src={Puzzle} alt="" className="w-full max-w-[24rem]" data-test="autopayPolicyPaidImage" />
                <div className="flex flex-col items-center gap-y-6 text-center md:gap-y-16">
                    <Title isCenter tag="h2" size="md" fontWeight="medium" data-test="autopayPolicyPaidTitle">
                        {t('pages.autopayPolicyPaid.title')}
                    </Title>

                    <p data-test="autopayPolicyPaidText">{t('pages.autopayPolicyPaid.text')}</p>

                    <Button href="https://simplea.pl" data-test="autopayPolicyPaidButton">
                        {t('common.continueSimplea')}
                    </Button>
                </div>
            </Card>
        </Layout>
    );
};
