import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAutopayGetGatewayListProps {
    data: {
        policyExternalId: string;
    };
}

export interface AutopayGateway {
    GatewayId: number;
    GatewayName: string;
    IconUrl: string;
}

interface CallAutopayGetGatewayListResponse {
    IsPolicyExternalIdValid: boolean;
    Gateways: Array<AutopayGateway>;
}

export const callAutopayGetGatewayList = ({
    data,
}: CallAutopayGetGatewayListProps): Promise<
    AxiosResponse<CallAutopayGetGatewayListResponse, CallAutopayGetGatewayListResponse>
> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}autopay/autopay-get-gateway-list`,
        data,
    });
