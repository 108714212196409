import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MyPolicyList from './components/MyPolicyList';
import Main from './components/Main';
import DraftsList from './components/DraftsList';
import MyClientsList from './components/MyClientsList';
import Contacts from './components/Contacts';
import Context from './components/Context';
import { AppContext } from '../../../components/Context';
import { ProtectedRoute } from '../../../components/common/ProtectedRoute';
import { AGENT_PORTAL_RIGHTS } from '../../../constants';
import AgencyPolicyList from './components/AgencyPolicyList';
import AgencyClientsList from './components/AgencyClientsList';
import { Layout } from '../../../components/Layout';
import Knowledge from './components/Knowledge/Knowledge';
import WaitingCaseList from './components/WaitingCaseList';
import WaitingCaseDetail from './components/WaitingCaseDetail';

const Dashboard = () => {
    const { clearCurrentModel } = useContext(AppContext);

    useEffect(() => {
        clearCurrentModel(true);
        // eslint-disable-next-line
    }, []);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideFooter>
            <Context>
                <Routes>
                    <Route path="main" Component={Main} />
                    <Route
                        path="my-policy"
                        element={
                            <ProtectedRoute isAuthorizationProtected permission={AGENT_PORTAL_RIGHTS.POLICY_LIST}>
                                <MyPolicyList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="agency-policy"
                        element={
                            <ProtectedRoute
                                isAuthorizationProtected
                                permission={AGENT_PORTAL_RIGHTS.POLICY_LIST_AGENCY}
                            >
                                <AgencyPolicyList />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="waiting-case" element={<WaitingCaseList />} />
                    <Route path="waiting-case-detail/:caseId" element={<WaitingCaseDetail />} />
                    <Route
                        path="drafts"
                        element={
                            <ProtectedRoute isAuthorizationProtected permission={AGENT_PORTAL_RIGHTS.DRAFT_LIST}>
                                <DraftsList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="my-clients"
                        element={
                            <ProtectedRoute isAuthorizationProtected permission={AGENT_PORTAL_RIGHTS.CLIENT_LIST}>
                                <MyClientsList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="agency-clients"
                        element={
                            <ProtectedRoute
                                isAuthorizationProtected
                                permission={AGENT_PORTAL_RIGHTS.CLIENT_LIST_AGENCY}
                            >
                                <AgencyClientsList />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="knowledge/:section" Component={Knowledge} />
                    <Route path="contacts" Component={Contacts} />
                    <Route path="*" element={<Navigate to="main" replace />} />
                </Routes>
            </Context>
        </Layout>
    );
};

export default Dashboard;
