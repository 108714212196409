import cn from 'classnames';
import { ErrorMessage } from 'formik';

interface FormErrorProps {
    name?: string;
    error?: string | false;
    className?: string;
}

export const FormError = ({ name, error, className, ...props }: FormErrorProps) => {
    if (name) {
        return (
            <div className={cn(className, 'w-full overflow-hidden text-ellipsis text-sm text-red')} {...props}>
                <ErrorMessage name={name} />
            </div>
        );
    } else if (error) {
        return (
            <div className={cn(className, 'w-full overflow-hidden text-ellipsis text-sm text-red')} {...props}>
                {error}
            </div>
        );
    } else {
        return <></>;
    }
};
