import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';

import { Card } from '../components/common/Card';
import { Title } from '../components/common/Title';
import Headache from '../assets/images/headache.png';
import { Button } from '../components/common/Button';
import { ROUTE, STEP_CODE } from '../constants';
import { useAppNavigate } from '../utils/hooks';
import PopupButtons from '../components/common/PopupButtons';
import { AppContext } from '../components/Context';
import LimitedAccess from '../components/LimitedAccess';
import Loader from '../components/common/Loader';
import { Layout } from '../components/Layout';

export const SummaryFail = () => {
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const { navigateTo } = useAppNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');

    return (
        <Layout hideProgressBar hideSaveButton hideFooter>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.SUMMARY}>
                    <Card className="flex flex-col items-center bg-yellow lg:flex-row" data-test="summaryFailCard">
                        <img src={Headache} alt="" className="w-full max-w-[24rem]" data-test="summaryFailImage" />
                        <div className="flex flex-col items-center gap-y-6 text-center md:gap-y-16">
                            <Title isCenter tag="h2" size="md" fontWeight="medium" data-test="summaryFailTitle">
                                {t('pages.summaryFail.title')}
                            </Title>

                            {status && parseInt(status, 10) === 15 && (
                                <p data-test="summaryFailText1">{t(`pages.summaryFail.subtitle.status${status}`)}</p>
                            )}

                            {status && parseInt(status, 10) >= 8 && parseInt(status, 10) <= 13 && (
                                <p data-test="summaryFailText1">{t(`pages.summaryFail.subtitle.status${status}`)}</p>
                            )}

                            {status && parseInt(status, 10) === 11 && (
                                <p data-test="summaryFailText2">
                                    {t('pages.summaryFail.subtitle.status11Instructions')}
                                </p>
                            )}

                            <PopupButtons>
                                <Button
                                    className="bg-transparent"
                                    isOutlined
                                    onClick={() => navigateTo(ROUTE.CONCLUSION)}
                                    data-test="buttonStartOverAgain"
                                >
                                    {t('common.startOverAgain')}
                                </Button>
                                <Button
                                    onClick={() => navigateTo(ROUTE.DASHBOARD_MAIN)}
                                    data-test="buttonBackToMainMenu"
                                >
                                    {t('common.backToMainMenu')}
                                </Button>
                            </PopupButtons>
                        </div>
                    </Card>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
