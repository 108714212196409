import React, { ReactNode } from 'react';

interface ToggleProps {
    children: ReactNode;
}

export const Toggle = ({ children, ...props }: ToggleProps) => {
    return (
        <div className="grid grid-cols-12 gap-x-8" data-test="toggle" {...props}>
            {children}
        </div>
    );
};
