import React, { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';

interface FullTextSearchProps {
    value: string;
    disabled?: boolean;
    handleSubmit: (value: string) => void;
}

const FullTextSearch = ({ disabled = false, handleSubmit, ...props }: FullTextSearchProps) => {
    const { t } = useTranslation();

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'escape') {
            handleSubmit('');
        }
    };

    return (
        <div className="relative mb-3 flex rounded-sm bg-lightPurple p-4" data-test="fulltextSearch">
            <input
                type="text"
                className="w-full rounded-lg border-2 border-purple p-2 pl-5 pr-10"
                value={props.value}
                disabled={disabled}
                placeholder={t<string>('dashboard.common.fulltextPlaceholder')}
                onChange={(e) => handleSubmit(e.target.value)}
                onKeyUp={handleKeyUp}
                data-test="fulltext"
            />
            <SearchIcon
                className="absolute right-[28px] top-[25px] cursor-pointer"
                onClick={() => handleSubmit(props.value)}
                data-test="searchIcon"
            />
        </div>
    );
};

export default FullTextSearch;
