import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Webcam from 'react-webcam';
import cn from 'classnames';

import { config } from '../../config';
import { Icon } from '../Icon';
import { Button } from '../common/Button';
import { Tip } from '../common/Tip';
import { getBase64, getBase64FileContent, getFileExtension, isAllowedFileType, isSpecialFileType } from '../../utils';
import { callGetOnlinePolicyMetadataByCode, callAttachOnlinePolicyAttachmentByCode } from '../../apis/support';
import { BackButton } from '../common/BackButton';
import FooterMobileMenu from '../FooterMobileMenu';
import FooterMobileMenuItem from '../FooterMobileMenuItem';
import { ROUTE } from '../../constants';
import Alert from '../Alert';
import { Layout } from '../Layout';
import { Head } from '../Head';
import ImagePreview from './ImagePreview';
import Corners from './Corners';
import UploadManually from './UploadManually';
import ButtonMobileMore from '../common/ButtonMobileMore';

interface IdSnapperProps {
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    className?: string;
}

export const IdSnapper = ({ currentStep, setCurrentStep, className }: IdSnapperProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { identityId } = useParams();
    const webcamRef = useRef<Webcam>(null);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement | null>(null);

    const [cameraActive, setCameraActive] = useState(false);
    const [frontImage, setFrontImage] = useState<string | null>(null);
    const [backImage, setBackImage] = useState<string | null>(null);
    const [correctionMode, setCorrectionMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFileCheckError, setShowFileCheckError] = useState<string | null>(null);
    const [frontPreviewNotSupported, setFrontPreviewNotSupported] = useState<string | null>(null);
    const [backPreviewNotSupported, setBackPreviewNotSupported] = useState<string | null>(null);
    const [wrapperPosition, setWrapperPosition] = useState({ width: 0, height: 0 });
    const [cameraDenied, setCameraDenied] = useState(false);

    const pageTranslation: any = t('pages.clientVerifyIdentitySnap', { returnObjects: true });

    const FRONT_STEP = 1;
    const FRONT_CONFIRMED_STEP = 2;
    const BACK_STEP = 3;
    const BACK_CONFIRMED_STEP = 4;
    const FINAL_STEP = 5;

    const makePhoto = () => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        setShowFileCheckError(null);
        setBackPreviewNotSupported(null);
        setFrontPreviewNotSupported(null);
        if (imageSrc) {
            setCurrentStep(currentStep + 1);
            setCameraActive(false);
            if (currentStep === FRONT_STEP) {
                setFrontImage(imageSrc);
            } else if (currentStep === BACK_STEP) {
                setBackImage(imageSrc);
            }
        }
    };

    const getUploadedFile = (event: any, successCallback: () => void) => {
        const file = event.target.files?.[0];
        const extension = getFileExtension(file?.name);

        if (file) {
            if (file?.size > config.MAX_UPLOAD_SIZE) {
                setShowFileCheckError(t('pages.documentPhoto.largeFileSize'));
            } else if (!isAllowedFileType(file)) {
                setShowFileCheckError(t('pages.documentPhoto.wrongFileFormat'));
            } else {
                setShowFileCheckError(null);

                const img = getBase64(file);

                if (img) {
                    img.then((r: string) => {
                        if (currentStep === FRONT_STEP) {
                            setFrontImage(r);
                            if (isSpecialFileType(file)) {
                                setFrontPreviewNotSupported(extension || 'unknown');
                            }
                        } else if (currentStep === BACK_STEP) {
                            setBackImage(r);
                            if (isSpecialFileType(file)) {
                                setBackPreviewNotSupported(extension || 'unknown');
                            }
                        }

                        setCameraActive(false);

                        successCallback && successCallback();
                    });
                }
            }
        }
    };

    const handleReset = () => {
        setCameraActive(true);
        setFrontPreviewNotSupported(null);
        setBackPreviewNotSupported(null);
        setCurrentStep(currentStep - 1);
        formRef?.current?.reset();
    };

    const handleRepeatFront = () => {
        setCameraActive(true);
        setCurrentStep(FRONT_STEP);
        setCorrectionMode(true);
        setFrontPreviewNotSupported(null);
        formRef?.current?.reset();
    };

    const handleRepeatBack = () => {
        setCameraActive(true);
        setCurrentStep(BACK_STEP);
        setCorrectionMode(true);
        setBackPreviewNotSupported(null);
        formRef?.current?.reset();
    };

    const handleUploadImages = () => {
        if (identityId) {
            setLoading(true);

            const onlineAttachments = [];
            // přední strana
            if (frontImage) {
                onlineAttachments.push({
                    attachmentData: getBase64FileContent(frontImage),
                    externalId: uuid(),
                    fileName: `ID Card - front.${frontPreviewNotSupported || 'png'}`,
                    pageNumber: 1,
                    onlinePolicyAttachmentTypeCode: 'IdCard',
                });
            }

            // zadní strana
            if (backImage) {
                onlineAttachments.push({
                    attachmentData: getBase64FileContent(backImage),
                    externalId: uuid(),
                    fileName: `ID Card - back.${backPreviewNotSupported || 'png'}`,
                    pageNumber: 2,
                    onlinePolicyAttachmentTypeCode: 'IdCard',
                });
            }

            callAttachOnlinePolicyAttachmentByCode({
                data: {
                    verificationCode: identityId,
                    onlineAttachments,
                },
            })
                .then(() => {
                    // kontrola souhlasů
                    callGetOnlinePolicyMetadataByCode({
                        data: {
                            verificationCode: identityId,
                        },
                    })
                        .then((response) => response.data)
                        .then((result) => {
                            // nemáme souhlasy nebo dokumenty skenuje agent
                            if (
                                result?.ClientConsents?.length > 0 ||
                                result?.PurposeType === 2 ||
                                result?.IsOnlinePolicyOpened === false
                            ) {
                                navigate(ROUTE.CLIENT_VERIFY_IDENTITY_FINAL.replace(':identityId', identityId || ''));
                            } else {
                                navigate(`${ROUTE.CONFIRMATION_DATA}?verificationCode=${identityId}`);
                            }
                        })
                        .catch(() => navigate(ROUTE.INVALID_LINK))
                        .finally(() => setLoading(false));
                })
                .catch((err) => console.log('error', err))
                .finally(() => setLoading(false));
        }
    };

    const wrapperWidth = wrapperRef?.current?.getBoundingClientRect().width;
    const wrapperHeight = wrapperRef?.current?.getBoundingClientRect().height;

    const handleUserMediaError = (e: any) => {
        setCameraDenied(true);
    };

    const handleUploadManually = (e: any = {}) => {
        // If user uploads file, we will use it
        getUploadedFile(e, () => {
            // When user selects new file, go to next step automatically
            setCameraActive(false);
            setCurrentStep(currentStep + 1);
        });
    };

    useEffect(() => {
        if (wrapperWidth && wrapperWidth > 0 && wrapperHeight && wrapperHeight > 0) {
            setWrapperPosition({
                width: wrapperWidth,
                height: wrapperHeight,
            });
        }
    }, [wrapperWidth, wrapperHeight]);

    useEffect(() => {
        setCameraActive(true);
    }, []);

    return (
        <Layout
            hideNavigation
            hideUserMenu
            hideFooter
            headerContent={
                <BackButton
                    className="ml-auto"
                    text={t('common.startOver') || ''}
                    onClick={() => {
                        if (identityId) {
                            setCameraActive(false);
                            setBackPreviewNotSupported(null);
                            setFrontPreviewNotSupported(null);
                            navigate(ROUTE.CLIENT_VERIFY_IDENTITY.replace(':identityId', identityId));
                        }
                    }}
                />
            }
            continueDisabled={loading}
        >
            <div className={cn('flex flex-col justify-center', cameraDenied ? 'text-center' : 'grow')}>
                {cameraDenied && [FRONT_STEP, BACK_STEP].includes(currentStep) ? (
                    <Head
                        heading1={pageTranslation.steps[currentStep - 1].cameraDisabled.title}
                        heading2={pageTranslation.steps[currentStep - 1].cameraDisabled.subtitle}
                    />
                ) : (
                    <Head
                        heading1={pageTranslation.steps[currentStep - 1].title}
                        heading2={pageTranslation.steps[currentStep - 1].subtitle}
                    />
                )}
                {/* If final step, show both photos, otherwise show snapper */}
                {currentStep === FINAL_STEP && (
                    <>
                        {frontImage && <ImagePreview image={frontImage} withBorder />}
                        {backImage && <ImagePreview image={backImage} withBorder />}
                    </>
                )}
                <div
                    className={cn(
                        className,
                        `relative w-full shrink-0 p-1 ${config.CAPTURE_PHOTO_CLS}`,
                        currentStep === FINAL_STEP && 'hidden'
                    )}
                >
                    <div
                        className="align-center relative flex h-full w-full flex-col justify-center rounded-xs"
                        ref={wrapperRef}
                    >
                        {!cameraDenied && cameraActive && wrapperRef?.current && (
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                {...wrapperPosition}
                                screenshotFormat="image/png"
                                videoConstraints={{
                                    facingMode: 'environment',
                                }}
                                onUserMediaError={handleUserMediaError}
                                data-test="webCam"
                            />
                        )}

                        {!cameraActive && currentStep === FRONT_CONFIRMED_STEP && frontImage && (
                            <ImagePreview
                                image={frontImage}
                                style={{
                                    maxWidth: wrapperRef?.current?.getBoundingClientRect().width,
                                    maxHeight: wrapperRef?.current?.getBoundingClientRect().height,
                                }}
                            />
                        )}

                        {!cameraActive && currentStep === BACK_CONFIRMED_STEP && backImage && (
                            <ImagePreview
                                image={backImage}
                                style={{
                                    maxWidth: wrapperRef?.current?.getBoundingClientRect().width,
                                    maxHeight: wrapperRef?.current?.getBoundingClientRect().height,
                                }}
                            />
                        )}

                        {cameraDenied &&
                            (currentStep !== FRONT_CONFIRMED_STEP || !frontImage) &&
                            (currentStep !== BACK_CONFIRMED_STEP || !backImage) && (
                                <UploadManually ref={formRef} onChange={handleUploadManually} />
                            )}
                    </div>

                    {!cameraDenied && <Corners />}
                </div>
                {showFileCheckError && <Alert className="mt-4" description={showFileCheckError} data-test="alert" />}

                {/* Footer navigation / shutter */}
                <div
                    className={cn(
                        'fixed bottom-0 left-0 flex w-full items-center justify-center p-4 shadow-top',
                        cameraActive ? 'bg-lightGray2' : 'bg-white'
                    )}
                    data-test="footer"
                >
                    {cameraActive ? (
                        <>
                            {!cameraDenied && (
                                <Icon
                                    name="shutter"
                                    className={cn(
                                        'w-12 shrink-0 cursor-pointer text-darkPurple transition-transform active:rotate-90',
                                        cameraDenied && 'invisible'
                                    )}
                                    onClick={() => makePhoto()}
                                    data-test="makePhoto"
                                />
                            )}
                        </>
                    ) : (
                        <div className="flex w-full justify-center gap-x-3">
                            {/* Show final button */}
                            {currentStep === FINAL_STEP ? (
                                <>
                                    <div className="relative ml-auto md:hidden">
                                        <FooterMobileMenu
                                            control={
                                                <ButtonMobileMore
                                                    caption={t('common.repeat')}
                                                    isDisabled={loading}
                                                    data-test="repeat"
                                                />
                                            }
                                        >
                                            <FooterMobileMenuItem disabled={loading} onClick={handleRepeatFront}>
                                                {t('pages.clientVerifyIdentitySnap.repeatFrontImage')}
                                            </FooterMobileMenuItem>
                                            <FooterMobileMenuItem disabled={loading} onClick={handleRepeatBack}>
                                                {t('pages.clientVerifyIdentitySnap.repeatBackImage')}
                                            </FooterMobileMenuItem>
                                        </FooterMobileMenu>
                                    </div>

                                    <Button
                                        className="mdmax:hidden"
                                        isOutlined
                                        isDisabled={loading}
                                        onClick={handleRepeatFront}
                                        data-test="repeatFrontImage"
                                    >
                                        {t('pages.clientVerifyIdentitySnap.repeatFrontImage')}
                                    </Button>
                                    <Button
                                        className="mdmax:hidden"
                                        isOutlined
                                        isDisabled={loading}
                                        onClick={handleRepeatBack}
                                        data-test="repeatBackImage"
                                    >
                                        {t('pages.clientVerifyIdentitySnap.repeatBackImage')}
                                    </Button>
                                    <Button
                                        innerSpan
                                        isDisabled={loading}
                                        onClick={handleUploadImages}
                                        data-test="confirmUpload"
                                    >
                                        {t('pages.clientVerifyIdentitySnap.confirmUploadShort')}
                                    </Button>
                                </>
                            ) : (
                                // Show repeat/back and continue buttons
                                <>
                                    {!correctionMode && (
                                        <Button isOutlined onClick={() => handleReset()} data-test="repeat">
                                            {t('common.repeat')}
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => {
                                            if (correctionMode) {
                                                setCameraActive(false);
                                                setCurrentStep(FINAL_STEP);
                                                setCorrectionMode(false);
                                            } else {
                                                setCameraActive(
                                                    currentStep === FRONT_CONFIRMED_STEP || currentStep === FINAL_STEP
                                                );
                                                setCurrentStep(currentStep + 1);
                                            }

                                            formRef?.current?.reset();
                                        }}
                                        data-test="continue"
                                    >
                                        {t('common.continue')}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </div>
                {/* Tip and upload link */}
                {!cameraDenied && cameraActive && (
                    <>
                        <Tip text={t('pages.clientVerifyIdentitySnap.snapTip')} className="mt-6" data-test="snapTips" />
                        <UploadManually ref={formRef} onChange={handleUploadManually} />
                    </>
                )}
            </div>
        </Layout>
    );
};
