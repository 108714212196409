import React, { ReactNode } from 'react';
import { getDataTest } from '../../../../../utils';

interface ToggleSectionProps {
    title: string;
    children?: ReactNode;
}

export const ToggleSection = ({ title, children, ...props }: ToggleSectionProps) => {
    return (
        <div className="toggle-section col-span-4 lgmax:col-span-12 lgmax:mb-8 lgmax:last:mb-0" {...props}>
            <p className="text-sm font-bold" data-test={getDataTest(props, 'Title')}>
                {title}
            </p>
            {children}
        </div>
    );
};
