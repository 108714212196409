import cn from 'classnames';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Card } from '../common/Card';
import { SuggestedProtectionProps } from '../../types/model';
import { RiderGroupFamiliesProps } from '../../pages/PackageAdjustment/PackageAdjustment';
import CoverageBoxItem from './CoverageBoxItem';
import ChevronIndicator from './ChevronIndicator';
import PartnerSelect from './PartnerSelect';

interface CoverageBoxProps {
    items: Array<SuggestedProtectionProps>;
    group: RiderGroupFamiliesProps;
    values?: any;
    startIndex: number;
    className?: string;
    isDisabled?: boolean;
    isEnterAllowed?: boolean;
    isWithPersonOfInsurableInterest?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    setBlockContinue: Dispatch<SetStateAction<Array<string>>>;
    blockContinue: Array<string>;
    setBlockReset: Dispatch<SetStateAction<boolean>>;
}

export const CoverageBox = ({
    items,
    group,
    values,
    startIndex,
    className,
    isDisabled = false,
    isEnterAllowed = true,
    isWithPersonOfInsurableInterest,
    onChange,
    setBlockContinue,
    blockContinue,
    setBlockReset,
}: CoverageBoxProps) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(true);

    let groupCodes: Array<string> = [];
    items.forEach((item) => item.Variants.forEach((v) => groupCodes.push(v.RiderGroup.Code)));

    useEffect(() => {
        const checked = groupCodes.filter((item) => values[item] === true);
        if (isWithPersonOfInsurableInterest) {
            setBlockContinue((prevState) => {
                let newState;

                if (checked.length > 0) {
                    newState = [...prevState.filter((x) => x !== group.Code), group.Code];
                } else {
                    newState = prevState.filter((x) => x !== group.Code);
                }

                return newState;
            });
        }
        // eslint-disable-next-line
    }, [values, isWithPersonOfInsurableInterest]);

    return items && items.length > 0 ? (
        <Card
            hasPadding={false}
            className={cn(className, 'flex flex-col rounded-b-sm font-medium')}
            data-test={`coverBox[${group.Code}]`}
        >
            <>
                {/* Toggleable group title */}
                <div
                    onClick={() => setActive(!active)}
                    className={cn(
                        'flex cursor-pointer flex-row items-center gap-x-8 border-white px-6 py-3 text-sm md:text-md',
                        active && 'border-b-2'
                    )}
                    data-test={`coverBox[${group.Code}]Header`}
                >
                    <ChevronIndicator isActive={active} />
                    {t(`pages.packageAdjustment.groups.${group.Code}`)}
                </div>
                {items.length > 0 && (
                    <AnimatePresence>
                        {active && (
                            <motion.div
                                initial={{
                                    height: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    height: 'auto',
                                    opacity: 1,
                                }}
                                exit={{
                                    height: 0,
                                    opacity: 0,
                                }}
                                data-test={`coverBox[${group.Code}]Items`}
                            >
                                {/* Partner selector for case of his/her death */}
                                {isWithPersonOfInsurableInterest && (
                                    <PartnerSelect
                                        group={group}
                                        isDisabled={!blockContinue.includes(group.Code) || isDisabled}
                                        onChange={onChange}
                                    />
                                )}

                                {/* Variants of protection group */}
                                {items.map((item, i) =>
                                    item.Variants?.map((variant) => (
                                        <CoverageBoxItem
                                            key={variant.SuggestedRiderVersion.Code}
                                            group={group}
                                            item={item}
                                            values={values}
                                            variant={variant}
                                            index={startIndex + i}
                                            isLast={i === items.length - 1}
                                            isDisabled={isDisabled}
                                            isEnterAllowed={isEnterAllowed}
                                            onChange={onChange}
                                            setBlockReset={setBlockReset}
                                        />
                                    ))
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                )}
            </>
        </Card>
    ) : null;
};
