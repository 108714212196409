import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallMojeIdCreateSamlResponse {
    MojeIdRedirectUrl: string;
    FormParamName: string;
    FormParamValueBase64: string;
}

export const callMojeIdCreateSaml = (): Promise<AxiosResponse<CallMojeIdCreateSamlResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}moje-id/create-saml-authnrequest`,
        data: {},
    });
