import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAutopayCheckPolicyPaymentProps {
    data: {
        policyExternalId: string;
    };
}

export interface CallAutopayCheckPolicyPaymentResponse {
    PolicyExternalId: string;
    IsPolicyExternalIdValid: boolean;
    IsPolicyPaid: boolean;
}

export const callAutopayCheckPolicyPayment = ({
    data,
}: CallAutopayCheckPolicyPaymentProps): Promise<
    AxiosResponse<CallAutopayCheckPolicyPaymentResponse, CallAutopayCheckPolicyPaymentResponse>
> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}autopay/autopay-check-policy-payment`,
        data,
    });
