import cn from 'classnames';
import { Link as RouterLink, To } from 'react-router-dom';
import { Icon } from '../Icon';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';

interface LinkProps {
    children: ReactNode;
    to?: To;
    variant?: 'dark' | 'light';
    onClick?: () => void;
    icon?: string;
    isDisabled?: boolean;
    className?: string;
    iconClassName?: string;
}

// @TODO: refactor weird logic, onClick -> button?, also "to" is always string which bypasses RouterLink
export const Link = ({
    to,
    onClick,
    icon,
    variant = 'light',
    isDisabled = false,
    className,
    iconClassName,
    children,
    ...props
}: LinkProps) => {
    const cls = cn(
        twMerge(
            'inline-flex gap-x-3 items-center font-semibold',
            variant === 'dark' ? 'text-darkPurple' : 'text-purple',
            !isDisabled && 'underline [&:hover]:no-underline',
            isDisabled && 'text-gray cursor-default',
            className
        )
    );

    const content = (
        <>
            {icon && <Icon name={icon} className={iconClassName} />}
            {children}
        </>
    );

    if (isDisabled) {
        return (
            <button className={cls} type="button" {...props}>
                {content}
            </button>
        );
    }

    if (onClick) {
        return (
            <button className={cls} onClick={onClick} type="button" {...props}>
                {content}
            </button>
        );
    }

    if (to) {
        if (typeof to === 'string') {
            return (
                <a href={to} className={cls} {...props}>
                    {content}
                </a>
            );
        }
        return (
            <RouterLink to={to} className={cls} {...props}>
                {content}
            </RouterLink>
        );
    }

    return null;
};
