import React from 'react';
import { useTranslation } from 'react-i18next';
import { SuggestedProtectionVariantProps } from '../../types/model';
import { getDataTest } from '../../utils';

interface PeriodProps {
    variant: SuggestedProtectionVariantProps;
}

const Period = ({ variant, ...props }: PeriodProps) => {
    const { t } = useTranslation();

    return (
        <div data-test={getDataTest(props, 'Period')}>
            {variant.SuggestedRiderVersion.WaitingPeriodInMonths ? (
                <>
                    <span className="hidden md:inline-block">
                        {variant.SuggestedRiderVersion.WaitingPeriodInMonths} {t('common.month')}
                    </span>
                    <span className="md:hidden">{variant.SuggestedRiderVersion.WaitingPeriodInMonths} m.</span>
                </>
            ) : (
                <span>{t('common.no').toLowerCase()}</span>
            )}
        </div>
    );
};

export default Period;
