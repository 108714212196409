import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DashboardContext } from './Context';
import DataTable from '../../../../components/DataTable/DataTable';
import { Column } from '../../../../components/DataTable';
import { useTableContext } from '../../../../utils/hooks';
import { HUMAN_DATE_FORMAT } from '../../../../constants';
import FullTextSearch from '../../../../components/FullTextSearch';
import { callWaitingCaseList } from '../../../../apis/dashboard';
import WaitingCaseToggle from './WaitingCaseToggle';
import { WaitingCase } from '../types';
import DashboardLayout from './DashboardLayout';
import WaitingCaseListCell from './WaitingCaseListCell';

const WaitingCaseList = () => {
    const { t } = useTranslation();
    const { waitingCase, setWaitingCase } = useContext(DashboardContext);

    const { fullText, handleFullTextSearchChange, ...tableProps } = useTableContext(
        waitingCase,
        setWaitingCase,
        callWaitingCaseList,
        null,
        {
            simpleFilterContent: (data: WaitingCase) => ({
                CaseBaseId: data.CaseBaseId,
                ExpirationDt: dayjs(data.ExpirationDt).format(HUMAN_DATE_FORMAT),
                BeginingAt: dayjs(data.BeginningDt).format(HUMAN_DATE_FORMAT),
                BirthDate: dayjs(data.OwnerBirthDt).format(HUMAN_DATE_FORMAT),
                ClientIdentificationVerificationTypeName: data.ClientIdentificationVerificationTypeName,
                OwnerPhoneNumber: [data.OwnerPhoneNumberPrefixConfirmed, data.OwnerPhoneNumberConfirmed].join(' '),
                OwnerEmailConfirmed: data.OwnerEmailConfirmed,
            }),
        }
    );

    return (
        <DashboardLayout>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            <DataTable<WaitingCase>
                // disablePagination
                // disableRecordsOnPage
                toggableRows
                {...tableProps}
                onToggleContent={(data) => <WaitingCaseToggle data={data} />}
                data-test="waitingCaseList"
            >
                <Column
                    // sortable
                    dataIndex="CaseBaseId"
                    label={t('dashboard.common.identificator')}
                    render={(data) => (
                        <WaitingCaseListCell data={data} displayAlert>
                            {data.CaseBaseId}
                        </WaitingCaseListCell>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="ExpirationDt"
                    label={t('dashboard.common.expirationDate')}
                    render={(data) => (
                        <WaitingCaseListCell data={data}>
                            {dayjs(data.ExpirationDt).format(HUMAN_DATE_FORMAT)}
                        </WaitingCaseListCell>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="BeginingAt"
                    label={t('dashboard.common.beginningDate')}
                    render={(data) => (
                        <WaitingCaseListCell data={data}>
                            {dayjs(data.BeginningDt).format(HUMAN_DATE_FORMAT)}
                        </WaitingCaseListCell>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="BirthDate"
                    label={t('dashboard.common.birthDate')}
                    render={(data: WaitingCase) => (
                        <WaitingCaseListCell data={data}>
                            {dayjs(data.OwnerBirthDt).format(HUMAN_DATE_FORMAT)}
                        </WaitingCaseListCell>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="ClientIdentificationVerificationTypeName"
                    label={t('dashboard.common.identifyVerificationType')}
                    render={(data) => (
                        <WaitingCaseListCell data={data}>
                            {data.ClientIdentificationVerificationTypeName}
                        </WaitingCaseListCell>
                    )}
                />
                {/* <Column
                    // sortable
                    dataIndex="IllustrationCustomName"
                    label={t('dashboard.common.calculationName')}
                /> */}
                <Column
                    // sortable
                    dataIndex="phoneNumber"
                    label={t('dashboard.common.phone')}
                    render={(data: WaitingCase) => (
                        <WaitingCaseListCell data={data}>
                            {[data.OwnerPhoneNumberPrefixConfirmed, data.OwnerPhoneNumberConfirmed].join('')}
                        </WaitingCaseListCell>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="OwnerEmailConfirmed"
                    label={t('dashboard.common.emailAddress')}
                    render={(data) => <WaitingCaseListCell data={data}>{data.OwnerEmailConfirmed}</WaitingCaseListCell>}
                />
            </DataTable>
        </DashboardLayout>
    );
};

export default WaitingCaseList;
