import React from 'react';
import cn from 'classnames';
import { ColumnProps } from '../Column';
import Actions, { ActionColumnClassName } from '../Actions';

interface DataProps {
    column: React.Component<ColumnProps>;
    data: any;
    index: number;
    onClick?: (e: any) => void;
}

const Data = ({ column, data, index, ...props }: DataProps) => {
    const { props: columnProps } = column;

    const onClick = () => {
        if (!(column && column instanceof Actions)) {
            if (props.onClick) {
                props.onClick(data);
            }
        }
    };

    return (
        <td
            {...props}
            className={cn(columnProps.className, column instanceof Actions && ActionColumnClassName)}
            onClick={onClick}
        >
            {/* @ts-ignore */}
            {columnProps.render ? (
                columnProps.render(data, index)
            ) : columnProps.dataIndex &&
              data &&
              columnProps.dataIndex in data &&
              typeof data[columnProps.dataIndex] !== 'object' ? (
                <>{data[columnProps.dataIndex]}</>
            ) : (
                <i />
            )}
        </td>
    );
};

export default Data;
