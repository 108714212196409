import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAttachAttachmentToWorkflowProps {
    data: {
        caseBaseExternalId: string;
        onlineAttachmentInfoDto?: {
            attachmentData: any;
            externalId: string;
            fileName: string;
            displayName?: string;
            pageNumber: number;
            onlinePolicyAttachmentVerificatorTypeCode?: string;
            onlinePolicyAttachmentTypeCode: string;
        };
        onlineAttachments?: Array<{
            attachmentData: any;
            externalId: string;
            fileName: string;
            displayName?: string;
            pageNumber: number;
            onlinePolicyAttachmentVerificatorTypeCode?: string;
            onlinePolicyAttachmentTypeCode: string;
        }>;
        clientPersonDtoJson?: string;
    };
    token: string;
}

export const callAttachAttachmentToWorkflow = ({
    data,
    token,
}: CallAttachAttachmentToWorkflowProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}documents/attach-attachment-to-workflow`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
