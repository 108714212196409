import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FullTextSearch from '../../../../../components/FullTextSearch';
import Accordion from '../../../../../components/common/Accordion';
import AccordionItem from '../../../../../components/common/AccordionItem';
import ProductSpecificationItem from './ProductSpecificationItem';
import { ProductSpecificationItemType } from './types';
import { fullTextSearch } from './helpers';

const ProductSpecification = () => {
    const { i18n } = useTranslation();

    const [fullText, setFullText] = useState('');

    const handleFullTextSearchChange = (v: string) => {
        setFullText(v);
    };

    const items = i18n.t('knowledgeBase.productSpecifications', {
        returnObjects: true,
    }) as ProductSpecificationItemType[];

    const filterFullTextSearch = useCallback(
        (item: ProductSpecificationItemType) => {
            if (fullText) {
                return (
                    fullTextSearch(item.title, fullText) ||
                    fullTextSearch(item.content, fullText) ||
                    item.list.find((l) => fullTextSearch(l.title, fullText) || fullTextSearch(l.content, fullText))
                );
            }
            return true;
        },
        [fullText]
    );

    const finalItems = useMemo(() => {
        return items ? items.filter(filterFullTextSearch) : items;
    }, [items, filterFullTextSearch]);

    if (!(items && Array.isArray(items))) {
        return null;
    }

    return (
        <>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            <Accordion data-test="productSpecificationItems">
                {finalItems?.map((item: ProductSpecificationItemType, index: number) => (
                    <AccordionItem
                        key={`product-specification-item-${index}`}
                        title={item.title}
                        data-test={`productSpecificationItem[${index}]`}
                    >
                        <ProductSpecificationItem data={item} />
                    </AccordionItem>
                ))}
            </Accordion>
            {fullText && finalItems?.length === 0 && (
                <p className="text-center" data-test="noRecordsFound">
                    <Trans>common.noRecordsFound</Trans>
                </p>
            )}
        </>
    );
};

export default ProductSpecification;
