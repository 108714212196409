import { Children, ReactElement, ReactNode, cloneElement, useEffect, useMemo, useRef, useState } from 'react';

import { useOutsideClickChecker } from '../utils/hooks';
import { Icon } from './Icon';

interface FooterMobileMenuProps {
    control?: ReactElement;
    children?: ReactNode;
}

const FooterMobileMenu = ({ control, children }: FooterMobileMenuProps) => {
    const [width, setWidth] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [menuMargin, setMenuMargin] = useState(0);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const childrenArray = useMemo(() => Children.toArray(children), [children]);

    const wrapperLeft = wrapperRef.current?.getBoundingClientRect().left;

    const toggleOpenMenu = () => {
        setOpenMenu((prev) => !prev);
    };

    useOutsideClickChecker(wrapperRef, () => {
        setOpenMenu(false);
    });

    useEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (wrapperLeft && wrapperLeft + 328 > width) {
            setMenuMargin(Math.round(width - (wrapperLeft + 328)));
        } else {
            setMenuMargin(0);
        }
    }, [wrapperLeft, width]);

    return (
        <div ref={wrapperRef}>
            {control &&
                cloneElement(control, {
                    onClick: toggleOpenMenu,
                })}
            {openMenu && (
                <div
                    ref={menuRef}
                    className="absolute bottom-full left-0 flex w-[20rem] -translate-y-[0.5rem] flex-col rounded-xs border-1 border-lightGray bg-white px-2 py-2"
                    style={{ marginLeft: `${menuMargin}px` }}
                >
                    {childrenArray.map((child: any, index: number) =>
                        cloneElement(child, {
                            className: index < childrenArray.length - 1 && 'border-b-1 border-gray',
                            onClick: () => {
                                child.props.onClick && child.props.onClick();
                                toggleOpenMenu();
                            },
                        })
                    )}

                    <Icon
                        name="close"
                        className="absolute right-1 top-1 cursor-pointer text-purple hover:text-darkPurple"
                        onClick={() => setOpenMenu(false)}
                        data-test="mobileMenuButton"
                    />
                </div>
            )}
        </div>
    );
};

export default FooterMobileMenu;
