import React, { useContext } from 'react';
import { PolicyStatusType } from '../../../../types';
import { AppContext } from '../../../../components/Context';

interface PolicyStatusProps {
    statusCode: string;
}

const PolicyStatus = ({ statusCode }: PolicyStatusProps) => {
    const { initData } = useContext(AppContext);
    const policyStatuses: Array<PolicyStatusType> = initData?.Lovs?.PolicyStatuses || [];
    const status = policyStatuses?.find((s: PolicyStatusType) => s.Code === statusCode);
    return <>{status ? status.NameTranslated : statusCode}</>;
};

export default PolicyStatus;
