import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import cn from 'classnames';

import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Head } from '../../components/Head';
import { CustomFormik } from '../../components/CustomFormik';
import { AppContext } from '../../components/Context';
import { List } from '../../components/common/List';
import { FileInput } from '../../components/common/FileInput';
import { callAttachDocument } from '../../apis/documents';
import {
    getBase64,
    getBase64FileContent,
    getFileExtension,
    getHigherStep,
    getIdCardSelectList,
    isAllowedFileType,
} from '../../utils';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { callCheckIdcardExistence } from '../../apis/support';
import Alert from '../../components/Alert';
import { ROUTE, STEP_CODE, SUPPORTED_UPLOAD_FILE_TYPES } from '../../constants';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import { Icon } from '../../components/Icon';
import { Select } from '../../components/common/Select';
import { config } from '../../config';
import Loader from '../../components/common/Loader';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import FooterContent from './FooterContent';

export const DocumentPhoto = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { navigateTo } = useAppNavigate();
    const { initData, ...ctx } = useContext(AppContext);
    const { isPep, withoutDocumentPhotos } = useContextHelpers();
    const data = ctx.currentModel;

    const [showDocumentCheckError, setShowDocumentCheckError] = useState(false);
    const [showFileCheckError, setShowFileCheckError] = useState<string | null>(null);
    const [manualUpload, setManualUpload] = useState(false);
    const pageTranslation: any = t('pages.documentPhoto', { returnObjects: true });
    const [loading, setLoading] = useState(true);
    const [imagesAlreadyUploaded, setImagesAlreadyUploaded] = useState(false);
    const [initialCheckCalled, setInitialCheckCalled] = useState(false);

    const myFormikRef = useRef<FormikProps<any>>(null);
    const token = localStorage.getItem('token');
    const idCards = getIdCardSelectList(initData);
    const idCardId = 9; // @TODO: IdCardKind not corresponding, therefore magic value
    const firstSideRef = useRef<HTMLInputElement | null>(null);
    const secondSideRef = useRef<HTMLInputElement | null>(null);
    const isF2F = data?.Settings.ArrangementType === ArrangementKind.F2F;

    const formSchema = Yup.object().shape({
        documentType: Yup.object().required(),
        firstSide: Yup.string(),
        secondSide: Yup.string().when('documentType', (x: Array<any>) =>
            x[0]?.value === idCardId ? Yup.string().required() : Yup.string()
        ),
    });

    const handleManualUpload = async (token: string) => {
        setShowFileCheckError(null);

        const method = myFormikRef?.current?.values?.documentType?.value;
        const firstFile = firstSideRef?.current?.files?.[0];
        const secondFile = secondSideRef?.current?.files?.[0];

        if (method === idCardId && (!firstFile || !secondFile)) {
            setShowFileCheckError(t('pages.documentPhoto.missingFileError'));
            setLoading(false);
        } else if (method !== idCardId && !firstFile) {
            setShowFileCheckError(t('pages.documentPhoto.missingPassportFileError'));
            setLoading(false);
        } else if (method === idCardId && firstFile?.name === secondFile?.name) {
            setShowFileCheckError(t('pages.documentPhoto.sameFileError'));
            setLoading(false);
        } else if (
            (firstFile && firstFile?.size > config.MAX_UPLOAD_SIZE) ||
            (secondFile && secondFile?.size > config.MAX_UPLOAD_SIZE)
        ) {
            setShowFileCheckError(t('pages.documentPhoto.largeFileSize'));
            setLoading(false);
        } else if (!isAllowedFileType(firstFile)) {
            setShowFileCheckError(t('pages.documentPhoto.wrongFileFormat'));
            setLoading(false);
        } else if (secondFile && !isAllowedFileType(secondFile)) {
            setShowFileCheckError(t('pages.documentPhoto.wrongFileFormat'));
            setLoading(false);
        } else {
            const firstFileContent = firstFile && (await getBase64(firstFile));
            const secondFileContent = secondFile && (await getBase64(secondFile));

            firstFileContent &&
                data &&
                callAttachDocument({
                    data: {
                        onlinePolicyExternalId: data.ExternalId,
                        attachmentData: getBase64FileContent(firstFileContent),
                        externalId: uuid(),
                        fileName: `ID Card - front.${getFileExtension(firstFile?.name)}`,
                        pageNumber: 1,
                        onlinePolicyAttachmentTypeCode: 'IdCard',
                    },
                    token,
                }).then(() => {
                    if (method === idCardId && secondFileContent) {
                        callAttachDocument({
                            data: {
                                onlinePolicyExternalId: data.ExternalId,
                                attachmentData: getBase64FileContent(secondFileContent),
                                externalId: uuid(),
                                fileName: `ID Card - back.${getFileExtension(secondFile?.name)}`,
                                pageNumber: 2,
                                onlinePolicyAttachmentTypeCode: 'IdCard',
                            },
                            token,
                        }).then(() => {
                            setShowFileCheckError(null);
                            continueProcess();
                        });
                    } else {
                        setShowFileCheckError(null);
                        continueProcess();
                    }
                });
        }
    };

    const continueProcess = () => {
        if (data) {
            const newModel = {
                ...data,
                Settings: {
                    ...data.Settings,
                    CurrentStepCode: getHigherStep(data.Settings.CurrentStepCode, STEP_CODE.DOCUMENT_PHOTO),
                },
            };
            ctx.setCurrentModel(newModel);
            ctx.saveCurrentModel(newModel);
            navigateTo(ROUTE.ID_DETAILS);
        }
    };

    const getIdCardSelectItem = () => idCards?.find((id) => id.value === idCardId);

    const checkIdCardExistenceCallback = (token: string) => {
        if (isF2F && isPep && !imagesAlreadyUploaded) {
            // validace na dostupnost dokumentů k MojeID od klienta
            callCheckIdcardExistence({
                data: {
                    onlinePolicyExternalId: data?.ExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => {
                    setShowFileCheckError(null);

                    // If we have successfully uploaded images from ID snapper
                    if (result.Exists === true) {
                        continueProcess();
                    } else {
                        setShowDocumentCheckError(true);
                    }
                })
                .catch(() => setShowDocumentCheckError(true))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
            continueProcess();
        }
    };

    useEffect(() => {
        if (data && token && !initialCheckCalled && isF2F && isPep) {
            setInitialCheckCalled(true);
            callCheckIdcardExistence({
                data: {
                    onlinePolicyExternalId: data?.ExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => {
                    if (result.Exists === true) {
                        setImagesAlreadyUploaded(true);
                    }
                    setLoading(false);
                })
                .catch((e) => console.error(e));
        } else {
            setLoading(false);
        }
    }, [data, token, initialCheckCalled, isF2F, isPep]);

    useEffect(() => {
        if (data && withoutDocumentPhotos) {
            navigate(`${ROUTE.ID_DETAILS}?id=${data.ExternalId}`);
        }
    }, [data, withoutDocumentPhotos, navigate]);

    return (
        <Layout
            continueText={isF2F ? t<string>('pages.documentPhoto.documentAttachedContinue') : undefined}
            continueDisabled={loading}
            footerContent={
                <FooterContent
                    loading={loading}
                    imagesAlreadyUploaded={imagesAlreadyUploaded}
                    setLoading={setLoading}
                />
            }
            continueCallback={() => {
                setShowDocumentCheckError(false);
                setLoading(true);

                if (token) {
                    if (manualUpload) {
                        handleManualUpload(token);
                    } else {
                        checkIdCardExistenceCallback(token);
                    }
                }
            }}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.IDENTITY_VERIFICATION}>
                    <Head heading1={t('pages.documentPhoto.title')} heading2={t('pages.documentPhoto.subtitle')} />

                    {loading && <Loader overflow />}

                    <CustomFormik
                        initialValues={{
                            firstSide: '',
                            secondSide: '',
                            documentType: getIdCardSelectItem(),
                        }}
                        onSubmit={undefined}
                        className="flex flex-col gap-y-10"
                        passedRef={myFormikRef}
                        validationSchema={formSchema}
                        customRender
                    >
                        {({ values, setFieldValue }: any) => (
                            <Form className={cn('flex flex-col gap-y-8', imagesAlreadyUploaded && 'my-auto')}>
                                <>
                                    {imagesAlreadyUploaded ? (
                                        <Card className="text-center font-bold" data-test="alreadyUploadedFilesCard">
                                            <>{t('pages.documentPhoto.alreadyUploadedFiles')}</>
                                        </Card>
                                    ) : (
                                        <Card data-test="documentPhotoCard">
                                            <Title
                                                tag="h2"
                                                size="md"
                                                fontWeight="medium"
                                                className="mb-8"
                                                data-test="documentPhotoTitle"
                                            >
                                                {pageTranslation.cards[0].title}
                                            </Title>

                                            <List
                                                type="ordered"
                                                items={pageTranslation.cards[0].list}
                                                className="mb-6 pl-2"
                                                data-test="documentPhotoDescription"
                                            />

                                            <div className="ml-2 flex flex-col gap-y-6">
                                                <p data-test="documentPhotoTroubles">
                                                    {pageTranslation.cards[0].troubles}
                                                </p>
                                                <p
                                                    className="cursor-pointer font-medium underline [&:hover]:no-underline"
                                                    onClick={() => {
                                                        setManualUpload(true);
                                                        setShowFileCheckError(null);
                                                        setShowDocumentCheckError(false);
                                                    }}
                                                    data-test="documentPhotoFormLink"
                                                >
                                                    {pageTranslation.cards[0].upload}
                                                </p>
                                            </div>
                                        </Card>
                                    )}

                                    {manualUpload && (
                                        <Card
                                            className="relative pt-[4rem] md:pt-[5rem]"
                                            data-test="documentPhotoUploadCard"
                                        >
                                            <Title
                                                tag="h2"
                                                size="md"
                                                fontWeight="medium"
                                                className="mb-4 md:mb-8"
                                                data-test="documentPhotoUploadTitle"
                                            >
                                                {pageTranslation.cards[1].documentsTitle}
                                            </Title>

                                            <Field
                                                name="documentType"
                                                component={Select}
                                                isCenter
                                                isSearchable={false}
                                                options={idCards}
                                                handleChange={() => {
                                                    setFieldValue('firstSide', '');
                                                    setFieldValue('secondSide', '');
                                                    setShowFileCheckError(null);
                                                }}
                                                className="mx-auto mb-4 max-w-[30rem] md:mb-8"
                                                data-test="documentType"
                                            />

                                            <Title
                                                tag="h2"
                                                size="md"
                                                fontWeight="medium"
                                                className="mb-4 md:mb-8"
                                                help={
                                                    values.documentType?.value === idCardId
                                                        ? pageTranslation.attachDocumentHelperText.idCard
                                                        : pageTranslation.attachDocumentHelperText.passport
                                                }
                                                data-test="documentTypeUploadImagesTitle"
                                            >
                                                {values.documentType?.value === idCardId
                                                    ? pageTranslation.cards[1].idTitle
                                                    : pageTranslation.cards[1].passportTitle}
                                            </Title>

                                            <div
                                                className={`grid grid-cols-1 lg:grid-cols-${
                                                    values.documentType?.value === idCardId ? 2 : 1
                                                } gap-4`}
                                            >
                                                <Field
                                                    component={FileInput}
                                                    className="m-auto"
                                                    name="firstSide"
                                                    label={t('common.formFields.firstSide')}
                                                    isCenter
                                                    disabled={loading}
                                                    accept={SUPPORTED_UPLOAD_FILE_TYPES}
                                                    inputRef={firstSideRef}
                                                    data-test="firstSide"
                                                />
                                                {values.documentType?.value === idCardId ? (
                                                    <Field
                                                        component={FileInput}
                                                        className="m-auto"
                                                        name="secondSide"
                                                        label={t('common.formFields.secondSide')}
                                                        isCenter
                                                        disabled={loading}
                                                        accept={SUPPORTED_UPLOAD_FILE_TYPES}
                                                        inputRef={secondSideRef}
                                                        data-test="secondSide"
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>

                                            <div
                                                className="group absolute right-3 top-3 flex  cursor-pointer  gap-x-4  md:right-7 md:top-7"
                                                onClick={() => {
                                                    if (!!values.firstSide || !!values.secondSide) {
                                                        ctx.showPopup('manual-upload-reset', () => {
                                                            setFieldValue('firstSide', '');
                                                            setFieldValue('secondSide', '');
                                                            setShowFileCheckError(null);
                                                            setManualUpload(false);
                                                        });
                                                    } else {
                                                        setManualUpload(false);
                                                    }
                                                }}
                                                data-test="documentPhotoCloseUploadCard"
                                            >
                                                <div className="flex h-[1.4rem] w-[1.4rem] place-content-center rounded-full bg-purple">
                                                    <Icon name="close-simple" className="w-[1rem] text-white" />
                                                </div>
                                                <p className="font-medium underline group-hover:no-underline">
                                                    {pageTranslation.cards[1].dontWantManualUpload}
                                                </p>
                                            </div>
                                        </Card>
                                    )}
                                    {(showFileCheckError || showDocumentCheckError) && (
                                        <div className="flex flex-col gap-y-4" data-test="documentPhotoError">
                                            {showDocumentCheckError && (
                                                <Alert description={t<string>('pages.documentPhoto.documentsError')} />
                                            )}

                                            {showFileCheckError && <Alert description={showFileCheckError} />}
                                        </div>
                                    )}
                                </>
                            </Form>
                        )}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
