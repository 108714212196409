import React, { ReactNode } from 'react';
import { Card } from './common/Card';
import cn from 'classnames';

interface HelperTextProps {
    className?: string;
    children: ReactNode;
}

const HelperText = ({ className, children }: HelperTextProps) => {
    return (
        <Card variant="secondary" hasPadding={false} className={cn('border-black-300 border-1 px-4 py-3', className)}>
            {children}
        </Card>
    );
};

export default HelperText;
