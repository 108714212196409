import React, { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/common/Button';
import DashboardMenu from './Menu/DashboardMenu';

interface DashboardLayoutProps {
    backButton?: {
        display: boolean;
        label?: string;
        path?: string;
        onClick?: (e?: MouseEvent) => void;
    };
    children: ReactNode;
}

const DashboardLayout = ({ backButton, children }: DashboardLayoutProps) => {
    const { t } = useTranslation();

    return (
        <div className="dashboard flex gap-x-4">
            {backButton?.display === true ? (
                <div className="w-[48px] lg:w-48">
                    <Button
                        isOutlined
                        href={backButton.path}
                        size="md"
                        icon="arrow-left"
                        className="w-full place-content-center p-2 font-light"
                        onClick={backButton.onClick}
                    >
                        <span className="hidden lg:block">{backButton.label || t('common.back')}</span>
                    </Button>
                </div>
            ) : (
                <div className="w-[48px] lg:w-64">
                    <DashboardMenu />
                </div>
            )}
            <div className="w-[calc(100%-48px]" style={{ width: 'calc(100% - 64px)' }}>
                {children}
            </div>
        </div>
    );
};

export default DashboardLayout;
