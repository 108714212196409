import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetIllustrationDocumentProps {
    data: {
        jsonOnlineModel: string;
    };
    token: string;
}

export const callGetIllustrationDocument = ({
    data,
    token,
}: CallGetIllustrationDocumentProps): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}documents/get-illustration-document`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
        responseType: 'arraybuffer', // Nastavíme responseType na 'arraybuffer' pro získání binárních dat
    });
