import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallLogoutAgentProps {
    data: {
        loginName: string;
    };
    token: string;
}

export const callLogoutAgent = (props: CallLogoutAgentProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/logout-agent`,
        data: props.data,
        headers: {
            'X-Simplea-Auth-Token': props.token,
        },
    });
