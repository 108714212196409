import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallInitDataProps {
    languageCode: 'pl' | 'en';
    token: string;
}

export const callInitData = ({ languageCode, token }: CallInitDataProps): Promise<AxiosResponse<any, any>> =>
    axios({
        url: `${getBaseUrl()}${API_PATH}initializations/init-data/${languageCode || 'pl'}/SIMPL_1_0`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
