import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallSaveModelDataProps {
    data: {
        jsonOnlineModel: string;
    };
}

export const callSaveModelData = (props: CallSaveModelDataProps, token: string): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}model-data/save`,
        data: props.data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
