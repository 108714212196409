import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { ValidationResultType } from '../../types';

interface CallValidatePersonalInformationProps {
    data: any;
    token: string;
}

export const callValidatePersonalInformation = (
    props: CallValidatePersonalInformationProps
): Promise<AxiosResponse<ValidationResultType, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}validations/validate-personal-information`,
        data: props.data,
        headers: {
            'X-Simplea-Auth-Token': props.token,
        },
    });
