import React, { ReactNode } from 'react';
import cn from 'classnames';

interface ToggleSectionFileItemProps {
    label: string;
    className?: string;
    children?: ReactNode;
}

export const ToggleSectionFileItem = ({ label, className, children, ...props }: ToggleSectionFileItemProps) => {
    return (
        <div className={cn('col-4 border-current border-b-1 border-solid pb-2 pt-1', className)} {...props}>
            <label className="font-lighter">{label}</label>
            <div className="truncate pt-1 text-sm">{children}</div>
        </div>
    );
};
