import React, { ReactNode } from 'react';

interface ToggableProps {
    component?: 'td' | 'th';
    className?: string;
    children?: ReactNode;
}

const Toggable = ({ component = 'td', className, children }: ToggableProps) =>
    component === 'td' ? <td className={className}>{children}</td> : <th className={className}>{children}</th>;

export default Toggable;
