import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallIsContactExistingAgentProps {
    data: {
        phone: string;
        phonePrefix: string;
        email: string;
        agentExternalId: string;
    };
    token: string;
}

export interface CallIsContactExistingAgentResponse {
    IsExistingAgent: boolean;
    AgentBaseExternalId: string;
}

export const callIsContactExistingAgent = ({
    data,
    token,
}: CallIsContactExistingAgentProps): Promise<AxiosResponse<CallIsContactExistingAgentResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/is-contact-existing-agent`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
