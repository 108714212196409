import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormikProps } from 'formik';
import { AxiosResponse } from 'axios';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { Head } from '../components/Head';
import { Card } from '../components/common/Card';
import { Grid } from '../components/Grid';
import { CustomFormik } from '../components/CustomFormik';
import { AppContext } from '../components/Context';
import { Title } from '../components/common/Title';
import { callCalculateSuggestedInsurance, callRecalculate } from '../apis/calculations';
import { addDate, getHigherStep, prevalidateValues, subtractDate, subtractsDate } from '../utils';
import { ModelProps, ParticipantPersonProps, PersonalIdentificationFullProps } from '../types/model';
import { DATE_FORMAT, IDENTIFICATION_FULL_TYPE, ROUTE, STEP_CODE } from '../constants';
import Loader from '../components/common/Loader';
import { SexKind } from '../types/enums/SexKind';
import { useAppNavigate } from '../utils/hooks';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';
import { config } from '../config';
import { DetectFormikChanged } from '../components/common/DetectFormikChanged';
import DateInput from '../components/common/DateInput';

interface BasicDatesFormData {
    birthDate: string;
    startDate: string;
}

export const BasicDates = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token');
    const ctx = useContext(AppContext);
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const data = ctx.currentModel;
    const myFormikRef = useRef<FormikProps<any>>(null);

    const suggestedInsuranceExists = data?.InsuredPersons?.[0]?.SuggestedInsurance !== null;

    const participant = data?.Participants[0] as ParticipantPersonProps<PersonalIdentificationFullProps>;

    const minBirthDate = subtractsDate([
        { value: config.MAX_AGE, unit: 'years' },
        { value: -1, unit: 'days' },
    ]);

    const formSchema = Yup.object().shape({
        birthDate: Yup.date().required().min(minBirthDate).max(subtractDate(config.MIN_AGE, 'years')),
        startDate: Yup.date().required().min(addDate(1, 'days')).max(addDate(config.MAX_START_INSURANCE_DAYS, 'days')),
    });

    const parseCalculateResponse = (res: AxiosResponse<ModelProps, any>) => {
        if (res.data) {
            ctx.setCurrentModel(res.data);
            ctx.saveCurrentModel(res.data);
            navigateTo(ROUTE.COVER_ADJUST, { ignoreLimits: true });
        }
        setSubmitting(true);
    };

    return (
        <Layout hideSaveButton continueDisabled={continueDisabled || !isValid}>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.PACKAGE_CHOICE}>
                    <Head heading1={t('pages.basicDates.title')} heading2={t('pages.basicDates.subtitle')} />

                    {!error ? (
                        <CustomFormik<BasicDatesFormData>
                            initialValues={{
                                birthDate: participant.Identification.BirthDt ?? '',
                                startDate: data?.BeginningDt ?? addDate(1, 'days'),
                            }}
                            onSubmit={() => {
                                data.Settings.CurrentStepCode = getHigherStep(
                                    data.Settings.CurrentStepCode,
                                    STEP_CODE.BASIC_DATA
                                );

                                ctx.setCurrentModel(data);
                                ctx.saveCurrentModel(data);

                                if (token) {
                                    const apiData = {
                                        jsonOnlineModel: JSON.stringify(data),
                                        chosenPackageType:
                                            data?.InsuredPersons?.[0]?.InsurancePackage?.ChosenPackageType,
                                    };

                                    setContinueDisabled(true);

                                    // v minulosti prošel endpointem /calculations/calculate-suggested-insurance
                                    if (suggestedInsuranceExists) {
                                        callRecalculate({
                                            data: { jsonOnlineModel: apiData.jsonOnlineModel },
                                            token,
                                        })
                                            .then((res) => {
                                                ctx.setBasicSuggestedInsurace(res.data);
                                                parseCalculateResponse(res);
                                            })
                                            .catch(() => setError(true))
                                            .finally(() => setContinueDisabled(false));
                                    } else {
                                        callCalculateSuggestedInsurance({
                                            data: apiData,
                                            token,
                                        })
                                            .then((res) => {
                                                ctx.setBasicSuggestedInsurace(res.data);
                                                parseCalculateResponse(res);
                                            })
                                            .catch(() => setError(true))
                                            .finally(() => setContinueDisabled(false));
                                    }
                                }
                            }}
                            validationSchema={formSchema}
                            passedRef={myFormikRef}
                            customRender
                        >
                            <DetectFormikChanged
                                onChange={(values: BasicDatesFormData) => {
                                    // validity check
                                    setIsValid(prevalidateValues(values, formSchema, false));
                                    // set data to model
                                    data.BeginningDt = values?.startDate;
                                    data.MaturityDt = dayjs(values?.startDate)
                                        .add(1, 'year')
                                        .add(-1, 'day')
                                        .format(DATE_FORMAT);

                                    const participant = data
                                        .Participants[0] as ParticipantPersonProps<PersonalIdentificationFullProps>;

                                    if (participant) {
                                        data.Participants[0] = {
                                            ...participant,
                                            Identification: {
                                                ...participant.Identification,
                                                $type: IDENTIFICATION_FULL_TYPE,
                                                BirthDt: values?.birthDate,
                                                CurrentAge: Math.floor(dayjs().diff(values?.birthDate, 'year', true)),
                                                Sex: participant.Identification.Sex || SexKind.NotSpecified,
                                            },
                                        };
                                    }

                                    ctx.setCurrentModel(data);
                                }}
                            />
                            <Form>
                                <Card data-test="basicInfoCard">
                                    {submitting ? (
                                        <Title tag="strong" size="md" data-test="loading">
                                            {t('common.loadingData')}...
                                        </Title>
                                    ) : (
                                        <Grid className="mb-4">
                                            <Field
                                                component={DateInput}
                                                name="birthDate"
                                                label={t('common.formFields.clientBirthDate')}
                                                minDate={new Date(minBirthDate)}
                                                maxDate={new Date(subtractDate(config.MIN_AGE, 'years'))}
                                                isCenter
                                                data-test="clientBirthDate"
                                            />
                                            <Field
                                                component={DateInput}
                                                name="startDate"
                                                label={t('common.formFields.startInsuranceDate')}
                                                isCenter
                                                helpText={t('pages.basicDates.startInsuranceDateHelp')}
                                                minDate={new Date(addDate(1, 'days'))}
                                                maxDate={new Date(addDate(config.MAX_START_INSURANCE_DAYS, 'days'))}
                                                data-test="startDate"
                                            />
                                        </Grid>
                                    )}
                                </Card>
                            </Form>
                        </CustomFormik>
                    ) : (
                        <Title tag="strong" size="md" data-test="serverError">
                            {t('common.serverError')}...
                        </Title>
                    )}
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
