import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { Card } from '../../components/common/Card';
import { Grid } from '../../components/Grid';
import { Input } from '../../components/common/Input';
import { AppContext } from '../../components/Context';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import { Select } from '../../components/common/Select';
import { Switch } from '../../components/common/Switch';
import { ReactSelectItemType } from '../../types';

interface ContactsFormProps {
    disabled: boolean;
}

const ContactsForm = ({ disabled }: ContactsFormProps) => {
    const { t } = useTranslation();

    const { initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;

    const prefixes = useMemo(() => initData?.InternationalPhonePrefixes || [], [initData]);

    const isRemote = data?.Settings?.ArrangementType === ArrangementKind.Remote;

    const formattedPrefixes = useMemo<Array<ReactSelectItemType>>(
        () => (prefixes || []).map((p: any) => ({ label: p.PhonePrefix, value: p.CountryId.toString() })),
        [prefixes]
    );

    return (
        <Card data-test="contactCard">
            <Grid className="mb-4 md:gap-y-0 mdmax:gap-y-0">
                <Field
                    component={Input}
                    type="email"
                    name="email"
                    label={t('common.email')}
                    isCenter
                    isDisabled={disabled}
                    className="mdmax:order-1"
                    data-test="email"
                />
                <div className="flex gap-x-2 mdmax:order-3">
                    {formattedPrefixes && (
                        <Field
                            component={Select}
                            name="phoneNumberPrefix"
                            isDisabled={disabled}
                            isSearchable={false}
                            className="mt-4 !w-[120px] shrink-0 pl-0"
                            options={formattedPrefixes}
                            data-test="phoneNumberPrefix"
                        />
                    )}
                    <Field
                        component={Input}
                        type="number"
                        name="phoneNumber"
                        label={t('common.phoneNumber')}
                        isCenter
                        isDisabled={disabled}
                        maxLength={9}
                        data-test="phoneNumber"
                    />
                </div>
                {!isRemote ? (
                    <Field
                        component={Switch}
                        name="noMailbox"
                        label={t('common.formFields.noMailboxAccess')}
                        isDisabled={disabled}
                        className="mb-6 mt-4 md:col-span-2 md:mt-8 mdmax:order-2"
                        data-test="noMailbox"
                    />
                ) : (
                    <></>
                )}
            </Grid>
        </Card>
    );
};

export default ContactsForm;
