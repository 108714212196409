import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { callAutopayConfirmationPayment } from '../apis/autopay';
import Loader from '../components/common/Loader';
import { Layout } from '../components/Layout';
import { Card } from '../components/common/Card';
import { Title } from '../components/common/Title';
import { Button } from '../components/common/Button';
import Puzzle from '../assets/images/puzzle.png';
import { ROUTE } from '../constants';

export const AutopayConfirmation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>(null);

    const serviceID = searchParams.get('ServiceID');
    const orderID = searchParams.get('OrderID');
    const hash = searchParams.get('Hash');

    useEffect(() => {
        if (!serviceID || !orderID || !hash) {
            navigate(ROUTE.ERROR_404);
        }
    }, [serviceID, orderID, hash, navigate]);

    useEffect(() => {
        if (serviceID && orderID && hash) {
            setLoading(true);
            setResponse(null);
            callAutopayConfirmationPayment({
                data: {
                    serviceID,
                    orderID,
                    hash,
                },
            })
                .then((response) => response.data)
                .then((data) => setResponse(data))
                .finally(() => setLoading(false));
        }
    }, [serviceID, orderID, hash]);

    return (
        <Layout hideProgressBar hideSaveButton hideUserMenu hideFooter>
            {loading ? (
                <Loader />
            ) : (
                <Card className="flex flex-col items-center md:flex-row" data-test="autopayConfirmationCard">
                    <img src={Puzzle} alt="" className="w-full max-w-[24rem]" data-test="autopayConfirmationTitle" />
                    <div className="flex w-full flex-col items-center gap-y-6 text-center md:gap-y-16">
                        <Title isCenter tag="h2" size="md" fontWeight="medium">
                            {t('pages.autopayConfirmation.title')}
                        </Title>

                        {response && (
                            <>
                                <p className="mx-auto" data-test="autopayConfirmationValidStatus">
                                    {response?.IsHashValid
                                        ? t('pages.autopayConfirmation.isValid')
                                        : t('pages.autopayConfirmation.isNotValid')}
                                </p>

                                <Button href="https://simplea.pl" data-test="autopayConfirmationButton">
                                    {t('common.continueSimplea')}
                                </Button>
                            </>
                        )}
                    </div>
                </Card>
            )}
        </Layout>
    );
};
