import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PolicyDetail, PolicyDetailBeneficiary } from '../types';
import { Toggle, ToggleSection, ToggleSectionItem } from './Toggle';
import Loader from '../../../../components/common/Loader';
import { HUMAN_DATE_FORMAT } from '../../../../constants';
import PolicyStatus from './PolicyStatus';
import Document from './Document';

interface PolicyToggleFormProps {
    policyData: PolicyDetail | null;
}

const PolicyToggleForm = ({ policyData }: PolicyToggleFormProps) => {
    const { t } = useTranslation();

    if (!policyData || !policyData.BasicInfo) {
        return (
            <Toggle>
                <div className="col-span-12">
                    <Loader className="text-center" data-test="loader" />
                </div>
            </Toggle>
        );
    }

    const uniqueBeneficiaries = policyData.Beneficiaries.reduce((acc: Array<any>, beneficiare) => {
        if (!acc.find((a) => a.ClientBaseExternalId === beneficiare.ClientBaseExternalId)) {
            acc.push(beneficiare);
        }
        return acc;
    }, []);

    const getBeneficiaryCompany = (b: PolicyDetailBeneficiary) => (
        <>
            {b.CompanyBeneficiaryName} ({b.SplitPct} %)
            {b.Note ? (
                <>
                    <br />
                    <small data-test="freeText">({b.Note})</small>
                </>
            ) : null}
        </>
    );

    return (
        <Toggle>
            <ToggleSection title={t('dashboard.common.basicInformation')} data-test="basicInformation">
                <ToggleSectionItem label={t('dashboard.common.policyNumber')} data-test="policyNumber">
                    {policyData.BasicInfo.PolicyNo}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.policyholder')} data-test="policyHolder">
                    {policyData.BasicInfo.OwnerLastName} {policyData.BasicInfo.OwnerFirstName}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.currentStatus')} data-test="currentStatus">
                    <PolicyStatus statusCode={policyData.BasicInfo.PolicyStatusCode} />
                </ToggleSectionItem>
                <ToggleSectionItem
                    label={t('dashboard.common.beneficiaries')}
                    disableTruncate
                    data-test="beneficiaries"
                >
                    {uniqueBeneficiaries.length > 0
                        ? uniqueBeneficiaries.map((b: PolicyDetailBeneficiary, index) => (
                              <div key={`beneficiary-${index}`} data-test={`beneficiary[${index}]`}>
                                  {b.CompanyBeneficiaryName
                                      ? getBeneficiaryCompany(b)
                                      : `${b.PersonBeneficiaryLastName} ${b.PersonBeneficiaryFirstName} (${b.SplitPct} %)`}
                              </div>
                          ))
                        : t('dashboard.common.accordingToLaw')}
                </ToggleSectionItem>
            </ToggleSection>

            <ToggleSection title={t('dashboard.common.dates')} data-test="dates">
                <ToggleSectionItem label={t('dashboard.common.createdDate')} data-test="createdDate">
                    {dayjs(policyData.BasicInfo.IllustrationDt).format(HUMAN_DATE_FORMAT)}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.beginningDate')} data-test="beginningDate">
                    {dayjs(policyData.BasicInfo.BeginningDt).format(HUMAN_DATE_FORMAT)}
                </ToggleSectionItem>
                {policyData.BasicInfo.PolicyStatusCode === 'A' && policyData.BasicInfo.PaidToDt && (
                    <ToggleSectionItem label={t('dashboard.common.paidToDate')} data-test="paidToDate">
                        {dayjs(policyData.BasicInfo.PaidToDt).format(HUMAN_DATE_FORMAT)}
                    </ToggleSectionItem>
                )}
                <ToggleSectionItem label={t('dashboard.common.totalMonthlyPremium')} data-test="totalMonthlyPremium">
                    {policyData.BasicInfo.TotalPremiumMonthly} PLN
                </ToggleSectionItem>
            </ToggleSection>

            <ToggleSection title={t('dashboard.common.contractDocumentation')} data-test="documents">
                {policyData.Documents.map((d) => (
                    <Document key={`document-${d.DocumentBaseExternalId}`} document={d} />
                ))}
            </ToggleSection>
        </Toggle>
    );
};

export default PolicyToggleForm;
