import { ReactElement } from 'react';
import { FieldProps, FormikProps, FormikValues } from 'formik';
import cn from 'classnames';
import { getDataTest } from '../../utils';

interface RadioToggleProps {
    field: FieldProps['field'];
    id: string;
    label: string;
    className?: string;
    onClick?: () => void;
    form: FormikProps<FormikValues>;
    isDisabled?: boolean;
}

export const RadioToggle = ({
    field,
    label,
    form,
    onClick,
    isDisabled,
    className,
    ...props
}: RadioToggleProps): ReactElement => (
    <div className={cn(className, 'flex select-none')} {...props}>
        <label
            className={cn(
                'btn-base btn-primary-outlined px-6 py-2 md:py-3',
                field.checked && 'bg-purple text-white',
                field?.name && form?.errors[field.name] && form?.touched[field.name] && 'border-red text-red',
                isDisabled && 'pointer-events-none opacity-50'
            )}
            data-test={getDataTest(props, 'Label')}
        >
            <input
                className="input-hidden"
                type="radio"
                onClick={!isDisabled ? onClick : undefined}
                {...field}
                data-test={getDataTest(props, 'Input')}
            />
            {label}
        </label>
    </div>
);
