const sports: { [key: string]: string } = {
    AEROBICS: 'aerobik (včetně aquaerobiku)',
    AIKIDO: 'aikido',
    AIRSOFT: 'airsoft',
    SKI_FREEST: 'akrobatické lyžování',
    ACRO_RKARL: 'akrobatický rock and roll',
    MNT_CLM_SF: 'alpinismus, horolezectví a skalní lezení (nerizikové)',
    MNT_CLM_RS: 'alpinismus, horolezectví a skalní lezení (rizikové)',
    SKI_ALPINE: 'alpské lyžování',
    AM_FOOBALL: 'americký fotbal',
    ATH_MLT_DS: 'atletický víceboj (včetně moderního pětiboje)',
    AUTOCROSS: 'autokros',
    AUTO_OR_CM: 'automobilové orientační soutěže',
    BADMINTON: 'badminton',
    BASE_JUMP: 'base jumping',
    BASEBALL: 'baseball',
    BASKETBALL: 'basketbal',
    RUNNING: 'běh (včetně orientačního běhu)',
    SKI_CS_CNT: 'běh na lyžích',
    BIATHLON: 'biatlon',
    BIKETRIAL: 'biketrial',
    BMX: 'BMX (bikros)',
    BOBSLEIGH: 'boby',
    BOULDER_RS: 'bouldering (bez přítomnosti další osoby)',
    BOULDER_SF: 'bouldering (s přítomností další osoby)',
    BOWLING: 'bowling',
    BOXING: 'box',
    BREAKDANCE: 'breakdance',
    INL_SKTING: 'bruslení inline',
    BUILDERING: 'buildering',
    BNGEE_JUMP: 'bungee jumping',
    CANYONING: 'canyoning',
    CAPOEIRA: 'capoeira',
    CLFFDIVING: 'cliffdiving',
    MT_CS_CNT: 'cross country (motorová vozidla)',
    CURLING: 'curling (metaná)',
    CYCLOCROSS: 'cyklokros',
    QUAD_BIKE: 'čtyřkolky (side)',
    SWIM_LONG: 'dálkové plavání',
    DEMO_DERBY: 'demolition derby',
    HRS_RACING: 'dostihový sport (včetně vytrvalostních závodů, kromě klusáckých dostihů a závodů spřežení)',
    MTB_DWNHIL: 'downhill (MTB)',
    DRGON_BOAT: 'dračí loď',
    DRAGSTER: 'dragster',
    TRACK_CYCL: 'dráhová cyklistika',
    DRESSAGE: 'drezura (koně)',
    DRIFTING: 'drift',
    JODO: 'džódó',
    JUJUTSU: 'džú-džucu',
    ENDURO: 'enduro',
    FITBOXING: 'fitbox',
    FLOORBALL: 'florbal',
    FLYBOARD: 'flyboarding',
    FORMULA_1: 'formule 1',
    FORMULA_2: 'formule 2',
    FORMULA_3: 'formule 3',
    SOCCER: 'fotbal (včetně futsalu a sálové kopané)',
    FREE_FIGHT: 'free fighting (včetně MMA a ultimate fighting)',
    FRISBEE: 'frisbee',
    GOLF: 'golf',
    HANDBALL: 'házená',
    BALL_HCKEY: 'hokejbal',
    HT_AR_BALN: 'horkovzdušný balón',
    MTB_SF: 'horská kola (MTB, kromě biketrialu, BMX, cyklokrosu a downhillu)',
    HWA_RNG_DO: 'hwa rang do',
    CHEERLEAD: 'cheerleading (roztleskávačky)',
    WALK_RC: 'chůze',
    IAIDO: 'iaidó',
    INTERCRSSE: 'intercross',
    YACHTING: 'jachting',
    HRS_TRIAL: 'jezdecká všestrannost',
    MT_HIST_CM: 'jízda historickými motorovými vozidly',
    YOGA: 'jóga',
    JUDO: 'judo',
    CANOE_POLO: 'kanoepolo',
    KARATE_NKT: 'karate (fudokan, goyu ryu, JKA a shito ryu)',
    KARATE_KT: 'karate (kata)',
    KARTING: 'karting (motokáry)',
    KENDO: 'kendo',
    KICKBOXING: 'kickbox (včetně thajského boxu, barmského boxu, savate a K1)',
    KIEN_SWING: 'kienova houpačka',
    KITING: 'kiting',
    HARNESS_RC: 'klusácké dostihy',
    SCOOTERING: 'koloběžky',
    KORFBALL: 'korfbal',
    FGR_SKTING: 'krasobruslení',
    KRAV_MAGA: 'krav maga (kapap)',
    CRICKET: 'kriket',
    CROQUET: 'kroket',
    BILLIARDS: 'kulečník (biliár)',
    BODY_BUILD: 'kulturistika',
    KUNG_FU: 'kung-fu',
    SKITTLES: 'kuželky',
    LACROSSE: 'lakros',
    ROPE_COURS: 'lanové překážky',
    ICE_HOCKEY: 'lední hokej',
    ATHLETICS: 'lehká atletika (kromě běhu a chůze)',
    ACRO_FLYNG: 'letecká akrobacie',
    WLL_CLM_RS: 'lezení po umělých stěnách (bez jištění)',
    WLL_CLM_SF: 'lezení po umělých stěnách (s jištěním)',
    ARCHERY: 'lukostřelba',
    MAJORETTES: 'mažoretky',
    PARKOUR: 'městský parkour',
    MT_SR_MINI: 'mini (moto, GP a skútr)',
    RHYTHM_GYM: 'moderní gymnastika',
    MOTOCROSS: 'motokros',
    MOTOR_FLY: 'motorové létání',
    PWRD_PGLID: 'motorový paragliding (krosny)',
    MOTO_TRIAL: 'mototrial',
    MUSHING: 'mushing',
    NASCAR: 'NASCAR',
    FTB_TENNIS: 'nohejbal',
    OFF_ROADNG: 'off-road',
    ROPE_CLIMB: 'olympijský šplh',
    PDLLBRDING: 'paddleboarding',
    PAINTBALL: 'paintball',
    PARGLIDING: 'paragliding',
    PARCHUTING: 'parašutismus',
    SHOW_JUMP: 'parkurové skákání',
    PETANQUE: 'petanque',
    SAILING: 'plachtění (na plachetnici)',
    SWIMMING: 'plavání',
    SPEEDWAY: 'plochá dráha',
    POLE_DANCE: 'pole dance',
    POLO: 'pólo (koně)',
    PONY: 'pony',
    DIVING_RS: 'potápění (rizikové)',
    DIVING_SF: 'potápění (nerizikové)',
    FLD_HOCKEY: 'pozemní hokej',
    FIRE_SPORT: 'požární sport',
    HURDLE_RC: 'překážkové závody (včetně Spartan Race, Predator Race)',
    MT_CSCT_RC: 'přírodní okruhy (motorová vozidla)',
    RAFTING_RS: 'rafting a sjezd na divoké vodě (stupeň obtížnosti WW III nebo vyšší)',
    RAFTING_SF: 'rafting a sjezd na divoké vodě (stupeň obtížnosti ZW, WW I nebo WW II)',
    RUGBY: 'ragby',
    RALLYING: 'rallye',
    RALLYCROSS: 'rallyekros',
    RICOCHET: 'ricochet',
    FISHING: 'rybářský sport',
    SPD_SKTING: 'rychlobruslení',
    POWERBOAT: 'rychlostní čluny',
    CANOE_SPD: 'rychlostní kanoistika',
    INDOR_CYCL: 'sálová cyklistika (kolová, krasojízda)',
    SAMBO: 'sambo',
    LUGE: 'sáně',
    ROAD_CYCL: 'silniční cyklistika',
    MT_ROAD_RC: 'silniční okruhy (motorová vozidla)',
    POWER_LIFT: 'silový trojboj',
    SHOES_JUMP: 'skákací boty',
    SKATBRDING: 'skatebording',
    SKELETON: 'skeleton',
    SKI_MNTNR: 'skialpinismus',
    SKIBOBBING: 'skiboby',
    SKIJORING: 'skijöring',
    SKI_CROSS: 'skikros',
    DIVING: 'skoky do vody',
    SKI_JUMP: 'skoky na lyžích',
    TRAMPINING: 'skoky na trampolíně',
    SLCKLN_UP2: 'slackline (výška do 2 m)',
    SLCKLN_AV2: 'slackline (výška nad 2 m)',
    SNOWMOBING: 'sněžné skútry',
    SNOWBRDING: 'snowboarding',
    SNOWSKTING: 'snowskating',
    SNOWTUBING: 'snowtubing',
    SOFTBALL: 'softbal',
    SPINNING: 'spinning',
    BALLRM_DNC: 'společenský tanec',
    MTBOAT_SPT: 'sportovní čluny',
    ARTIST_GYM: 'sportovní gymnastika',
    SHOOT_SPT: 'sportovní střelba',
    SQUASH: 'squash',
    BOARD_GAME: 'stolní hry',
    TBL_TENNIS: 'stolní tenis',
    SUMO: 'sumo',
    SUPERMOTO: 'supermoto',
    SWIM_SYNCH: 'synchronizované plavání',
    FENCING: 'šerm',
    DARTS: 'šipky',
    TAEKWONDO: 'taekwondo',
    TAI_CHI: 'tai-chi',
    TANDEM_SDV: 'tandemový seskok',
    TEAM_GYM: 'teamgym',
    TENNIS: 'tenis',
    SKI_GRASS: 'travní lyžování',
    TRIATHLON: 'triatlon',
    TRCK_TRIAL: 'truck trial',
    ULTRAL_AVI: 'ultralehké létání (letouny, kluzáky, vrtulníky, vírníky)',
    ROWING: 'veslování',
    VIA_FER_SF: 'via ferrata – zajištěné cesty (nerizikové)',
    VIA_FER_RS: 'via ferrata – zajištěné cesty (rizikové)',
    SKI_WATER: 'vodní lyžování',
    WATER_POLO: 'vodní pólo',
    PN_WTRCRFT: 'vodní skútry',
    CANOE_SLOM: 'vodní slalom',
    VOLLEYBALL: 'volejbal (včetně plážového volejbalu)',
    EQST_VAULT: 'voltiž',
    GENER_GYM: 'všeobecná gymnastika',
    WEIGT_LIFT: 'vzpírání',
    WAKEBRDING: 'wakeboarding',
    WESTERN: 'western ',
    WINDSRFING: 'windsurfing (surfing)',
    EXTRM_POGO: 'xpogo',
    WRESTLING: 'zápas (včetně řecko-římského a volného stylu)',
    HANG_GLID: 'závěsné létání (kluzáky)',
    MT_UPHL_RC: 'závody do vrchu (motorová vozidla)',
    HRS_DRVING: 'závody spřežení (koně)',
    ZORBING: 'zorbing',
    ZUMBA: 'zumba',
    EXTRM_FREE: 'jakýkoli sport v extrémních podmínkách nebo terénech, bez dodržování pravidel (freeride a freestyle)',
    CROSSFIT: 'crossfit',
    FITNESS: 'fitness',
};

const sportLevels: { [key: string]: string } = {
    1: 'Rekreační',
    2: 'Amatérská',
    3: 'Nejvyšší',
};

const employedTypes: { [key: string]: { label: string; occupationCategory: string } } = {
    1: { label: 'Bez příjmu', occupationCategory: '2' },
    2: { label: 'Zaměstnanec', occupationCategory: '1' },
    3: { label: 'OSVČ bez nemocenského pojištění', occupationCategory: '1' },
    4: { label: 'OSVČ s nemocenským pojištěním', occupationCategory: '1' },
    5: { label: 'Jiný příjem', occupationCategory: '3' },
};

const occupations: { [category: string]: { [key: string]: string } } = {
    '1': {
        ADMIN_OFF: 'Administrativní pracovník',
        ADVOCATE: 'Advokát',
        AGRONOMIST: 'Agronom',
        ANALYST: 'Analytik',
        ANIMATOR: 'Animátor (cestovní ruch)',
        WIND_TRIM: 'Aranžér',
        ARCHAEOLOG: 'Archeolog',
        ARCHITECT: 'Architekt',
        ARCHIVIST: 'Archivář',
        CIRC_PERF: 'Artista',
        SANIT_SERV: 'Asanátor',
        ASPHALTER: 'Asfaltér',
        ASTROLOG: 'Astrolog',
        ASTRONOMER: 'Astronom',
        AUDITOR: 'Auditor',
        CAR_TINSM: 'Autoklempíř',
        CAR_MECH: 'Automechanik',
        EXCAV_OPER: 'Bagrista',
        PACKER: 'Balič',
        BARMAN: 'Barman',
        DYER: 'Barvíř',
        COOPER: 'Bednář',
        CONCR_WRK: 'Betonář',
        BIOCHEMIST: 'Biochemik',
        BOTANIST: 'Botanik',
        BAG_MAKER: 'Brašnář',
        CUTTER: 'Brusič',
        CUSTOM_OFF: 'Celník',
        CONFECTION: 'Cukrář',
        ANIM_TRAIN: 'Cvičitel zvířat',
        UPHOLSTER: 'Čalouník',
        FAC_CLEAN: 'Čistič fasád',
        CLTH_CLEAN: 'Čistič oděvů',
        WAITER: 'Číšník',
        TAX_ADVISE: 'Daňový poradce',
        DELEGATE: 'Delegát',
        WRK_ATTBL: 'Dělník - práce u stolu',
        WRK_PRODLN: 'Dělník - práce u výrobního pásu',
        WRK_HEAVY: 'Dělník - práce v hutním, těžebním, keramickém, stavebním, chemickém nebo gumárenském průmyslu',
        WRK_FIELDW: 'Dělník - práce v terénu',
        PSTCN_TECH: 'Deratizér',
        DESIGNER: 'Designér',
        DETECTIVE: 'Detektiv',
        DIPLOMAT: 'Diplomat',
        MUSIC_DIR: 'Dirigent',
        DJ: 'Diskžokej',
        CONTROLLER: 'Dispečer',
        PAVER: 'Dlaždič',
        POSTMAN: 'Doručovatel',
        LUMBERJACK: 'Dřevorubec',
        CLERIC: 'Duchovní',
        ECOLOGIST: 'Ekolog',
        ECONOMIST: 'Ekonom',
        ELEC_HAEVY: 'Elektrikář - silnoproud',
        ELEC_LOW: 'Elektrikář - slaboproud',
        ELEC_ENG: 'Elektroinženýr',
        ELEC_MECH: 'Elektromechanik',
        ELEC_TECH: 'Elektrotechnik',
        EXECUTOR: 'Exekutor',
        PHARMACIST: 'Farmaceut',
        PLASTERER: 'Fasádník',
        FIN_ADVISE: 'Finanční poradce',
        MOLDER: 'Formovač',
        PHOTOGRAPH: 'Fotograf',
        MILL_OPER: 'Frézař',
        PHYSIOTERP: 'Fyzioterapeut',
        GALVANIZER: 'Galvanizér',
        GEOGRAPHER: 'Geograf',
        GEOLOGIST: 'Geolog',
        GRAPH_DSGN: 'Grafik',
        FIREMAN: 'Hasič',
        ACTOR: 'Herec',
        HISTORIAN: 'Historik',
        BROADCSTR: 'Hlasatel',
        WATCH_NWPN: 'Hlídač - beze zbraně',
        WATCH_WPN: 'Hlídač - se zbraní',
        WATCHMAKER: 'Hodinář',
        BARBER: 'Holič',
        MINER: 'Horník',
        MT_CLIMBER: 'Horolezec',
        HOUSEKEEP: 'Hospodyně',
        HOSTESS: 'Hosteska',
        INNKEEPER: 'Hostinský',
        HOTELIER: 'Hoteliér',
        CAST_GUARD: 'Hradní stráž',
        DAM_KEEPER: 'Hrázný',
        POTTER: 'Hrnčíř',
        GRAVEDGGER: 'Hrobník',
        LT_CD_INST: 'Hromosvodář',
        MUSICIAN: 'Hudebník',
        STEELWRK: 'Hutník',
        HYGIENIST: 'Hygienik',
        CHEMIST: 'Chemik',
        CHOREOGRPH: 'Choreograf',
        BRDR_SMALL: 'Chovatel drobných zvířat',
        BRDR_EXOT: 'Chovatel exotických zvířat',
        BRDR_FARM: 'Chovatel hospodářských zvířat',
        BABYMINDER: 'Chůva',
        INSPECTOR: 'Inspektor',
        PIPE_FITR: 'Instalatér',
        SKI_INSTR: 'Instruktor lyžování',
        SURG_TECH: 'Instrumentář',
        MINING_ENG: 'Inženýr důlní',
        CIVIL_ENG: 'Inženýr stavební',
        CRANE_OPER: 'Jeřábník',
        JUD_GUARD: 'Justiční stráž',
        HAIRDRESS: 'Kadeřník',
        STONEMASON: 'Kameník',
        STOVE_FITR: 'Kamnář',
        SKIPPER: 'Kapitán lodi',
        STUNTMAN: 'Kaskadér',
        TINSMITH: 'Klempíř',
        JEWELLER: 'Klenotník',
        BOOKBINDER: 'Knihař',
        LIBRARIAN: 'Knihovník',
        CHMN_SWEEP: 'Kominík',
        CONSTRCTOR: 'Konstruktér',
        CHECKER: 'Kontrolor',
        HELMSMAN: 'Kormidelník',
        BEAUTICIAN: 'Kosmetička',
        BLACKSMITH: 'Kovář',
        FURRIER: 'Kožešník',
        TAILOR: 'Krejčí',
        INVESTIGTR: 'Kriminalista',
        CROUPIER: 'Krupiér',
        COOK: 'Kuchař',
        SCEN_SHIFT: 'Kulisář',
        MESSENGER: 'Kurýr',
        FLORIST: 'Květinář',
        LABOR_TECH: 'Laborant',
        PAINTER: 'Lakýrník',
        CLWAY_OPER: 'Lanovkář (obsluha lanovky)',
        APOTHECARY: 'Lékárník',
        DOCTOR: 'Lékař',
        LNG_ASSIST: 'Lektor',
        BILLPOSTER: 'Lepič plakátů',
        FORESTER: 'Lesník (lesní správce)',
        SCAFFOLDER: 'Lešenář',
        AIR_MECH: 'Letecký mechanik',
        STEWARDESS: 'Letuška',
        PRESS_OPER: 'Lisař',
        SAILOR: 'Lodník',
        LOGISCIAN: 'Logistik',
        BROKER: 'Makléř',
        PAINTER_IN: 'Malíř interiérů',
        MANAGER: 'Manažer',
        MANICURIST: 'Manikér',
        MASSEUR: 'Masér',
        MECH_SMALL: 'Mechanik - malé přístroje',
        MECH_HEAVY: 'Mechanik - velké přístroje',
        METEORLG: 'Meteorolog',
        MILKMAN: 'Mlékař',
        PRESS_AGNT: 'Mluvčí',
        MILLER: 'Mlynář',
        MODEL: 'Model',
        PRESENTER: 'Moderátor',
        FASH_DSGNR: 'Módní návrhář',
        FITTER: 'Montér',
        HUNTER: 'Myslivec',
        TOOLMAKER: 'Nástrojař',
        HALL_PORTR: 'Nosič zavazadel',
        NOTARY: 'Notář',
        JOURNALIST: 'Novinář',
        SALE_REPRE: 'Obchodní zástupce',
        TILE_LAYER: 'Obkladač',
        TOOLER: 'Obráběč',
        CNCM_OPER: 'Obsluha a údržba CNC strojů',
        NCNCM_OPER: 'Obsluha a údržba jiných než CNC strojů',
        PET_ST_ATT: 'Obsluha čerpací stanice',
        SHOEMAKER: 'Obuvník',
        OPTICIAN: 'Oční optik',
        VALUER: 'Odhadce',
        WINDW_INST: 'Okenář (montáž oken)',
        LIGHT_ELEC: 'Osvětlovač',
        KPER_SMALL: 'Ošetřovatel drobných zvířat',
        KPER_EXOT: 'Ošetřovatel exotických zvířat',
        KPER_FARM: 'Ošetřovatel hospodářských zvířat',
        PRESERVIST: 'Památkář',
        CARER: 'Pečovatel',
        PEDICURIST: 'Pedikér',
        BAKER: 'Pekař',
        GROWER: 'Pěstitel',
        PILOT_NSCF: 'Pilot - jiné než pravidelné civilní lety (např. vyhlídkové)',
        PILOT_SCF: 'Pilot - pravidelné civilní lety',
        BREWER: 'Pivovarský sládek',
        LIFEGUARD: 'Plavčík',
        GAS_FITTER: 'Plynař',
        FLOOR_INST: 'Podlahář',
        INS_AGENT: 'Pojišťovací zprostředkovatel',
        CASHIER: 'Pokladní',
        CHAMBRMAID: 'Pokojská',
        ROOFER: 'Pokrývač',
        PLCM_NASST: 'Policista - jiný než zásahová jednotka',
        PLCM_OOFFC: 'Policista - pouze práce v kanceláři',
        PLCM_ASST: 'Policista - zásahová jednotka',
        POLITICIAN: 'Politik',
        PARAMD_STF: 'Pomocný zdravotnický personál',
        DUSTMAN: 'Popelář',
        WR_BAILIFF: 'Porybný',
        SHIFTER: 'Posunovač',
        SCUB_DIVER: 'Potápěč',
        GR_AVI_PSN: 'Pozemní letecký personál',
        SECUR_GRD: 'Pracovník bezpečnostní agentury',
        FILM_CREW: 'Pracovník filmového štábu',
        MTN_RSC_SC: 'Pracovník horské služby',
        WRK_SAWMIL: 'Pracovník na pile',
        PC_OFF: 'Pracovník pouze na počítači',
        ADM_OFF: 'Pracovník pouze v kanceláři',
        WRK_ROAD: 'Pracovník silniční údržby',
        REMOV_MAN: 'Pracovník stěhovací služby',
        WRK_TECHEC: 'Pracovník technicko-hospodářský',
        LAWYER: 'Právník',
        PREPARATOR: 'Preparátor',
        SHOPASS_0: 'Prodavač - manipulace se zbožím do 10 kg',
        SHOPASS_11: 'Prodavač - manipulace se zbožím nad 10 kg',
        EXEC_PROD: 'Producent',
        PROGRAMMER: 'Programátor',
        PROJ_ARCH: 'Projektant',
        PROSTHETST: 'Protetik',
        TOUR_GUIDE: 'Průvodce',
        CONDUCTOR: 'Průvodčí',
        TRANSLATOR: 'Překladatel',
        BOATMAN: 'Převozník',
        PSYCHOGIST: 'Psycholog',
        PSYCHOTHER: 'Psychoterapeut',
        GUNSMITH: 'Puškař',
        BOMB_EXPT: 'Pyrotechnik',
        RADIOLGIST: 'Radiolog',
        RECEPTNIST: 'Recepční',
        EDITOR: 'Redaktor',
        REPORTER: 'Reportér',
        RESTORER: 'Restaurátor',
        RESTOR_OAT: 'Restaurátor - pouze práce v ateliéru',
        TCT_INSPEC: 'Revizor',
        REFEREE: 'Rozhodčí',
        FISHERMAN: 'Rybář',
        ENGRAVER: 'Rytec',
        DIRECTOR: 'Ředitel',
        WOODCARVER: 'Řezbář',
        BUTCHER: 'Řezník',
        DRIVER: 'Řidič',
        PLST_BOARD: 'Sádrokartonář',
        TYPESETTER: 'Sazeč',
        SECRETARY: 'Sekretářka',
        WAITRESS: 'Servírka',
        MCH_ADJSTR: 'Seřizovač strojů',
        COMPOSER: 'Skladatel',
        WARHOUSMAN: 'Skladník',
        GLASSMAKER: 'Sklář',
        GLAZIER: 'Sklenář',
        FOUNDER: 'Slévač',
        EXCHANGER: 'Směnárník',
        SCULPTOR: 'Sochař',
        SOCIAL_WRK: 'Sociální pracovník',
        SOMMELIER: 'Someliér',
        JUDGE: 'Soudce',
        LATHE_OPER: 'Soustružník',
        SPELEOGIST: 'Speleolog',
        WRITER: 'Spisovatel',
        SPORTSMAN: 'Sportovec',
        BUILD_MNGR: 'Správce budovy',
        NTWK_ADMIN: 'Správce počítačové sítě',
        STRCT_ANLT: 'Statik',
        CONST_MNGR: 'Stavbyvedoucí',
        STEWARD: 'Stevard',
        CABINETMKR: 'Stolař',
        MACHN_OPER: 'Strojník',
        ENGINE_DVR: 'Strojvedoucí',
        ARBORIST: 'Stromolezec',
        BLASTER: 'Střelmistr',
        WELL_DIGGR: 'Studnař',
        STYLIST: 'Stylista',
        WELDER: 'Svářeč',
        CLOAKR_ATT: 'Šatnář',
        SEAMSTRESS: 'Šička',
        SCHL_CRTKR: 'Školník',
        JEWEL_MAKR: 'Šperkař',
        STUCCO_WRK: 'Štukatér',
        DRESSMAKER: 'Švadlena',
        DANCER: 'Tanečník',
        WPR_HANGER: 'Tapetář',
        TATTOO_ART: 'Tatér',
        SMELTER: 'Tavič',
        TAXI_DRIVR: 'Taxikář',
        SAFETY_OFF: 'Technik bezpečnostní',
        COMPUT_ENG: 'Technik informační',
        FR_SFT_OFF: 'Technik požární',
        ENG_INSPCT: 'Technik revizní',
        SERVIC_ENG: 'Technik servisní',
        TELECM_ENG: 'Technik telekomunikační',
        CARPENTER: 'Tesař',
        PRINTER: 'Tiskař',
        WEAVER: 'Tkadlec',
        INTERPRETR: 'Tlumočník',
        HEATNG_ENG: 'Topenář',
        STOKER: 'Topič',
        COACH: 'Trenér',
        JOINER: 'Truhlář',
        TNL_BUILDR: 'Tunelář',
        ACCOUNTANT: 'Účetní',
        TEACHER: 'Učitel (jinde než v autoškole)',
        DRIV_INSTR: 'Učitel v autoškole',
        MAINTN_MAN: 'Údržbář',
        CLEANER: 'Uklízečka',
        WINDW_WASH: 'Umývač oken',
        OFFICER: 'Úředník',
        USHER: 'Uvaděč',
        SAUSAG_MKR: 'Uzenář',
        RESEARCHER: 'Vědecký pracovník',
        VETERINARN: 'Veterinář',
        CORRCT_OFF: 'Vězeňská služba',
        WINE_GROWR: 'Vinař',
        MAKEUP_ART: 'Vizážista',
        TOW_OPER: 'Vlekař (obsluha vleku)',
        WATER_MNGR: 'Vodohospodář',
        PSOLD_SPEC: 'Voják z povolání - člen speciální jednotky',
        PSOLD_CHEM: 'Voják z povolání - chemik',
        PSOLD_COOK: 'Voják z povolání - kuchař',
        PSOLD_DOCT: 'Voják z povolání - lékař',
        PSOLD_PLOT: 'Voják z povolání - pilot',
        PSOLD_OOFC: 'Voják z povolání - pouze práce v kanceláři',
        PSOLD_SCOT: 'Voják z povolání - průzkumník',
        PSOLD_HNLR: 'Voják z povolání - psovod',
        PSOLD_DRVR: 'Voják z povolání - řidič',
        PSOLD_TECH: 'Voják z povolání - technik',
        PSOLD_PRCH: 'Voják z povolání - výsadkář',
        PSOLD_SAPR: 'Voják z povolání - ženista',
        PSOLD_PLCM: 'Vojenský policista',
        GATEKEEPER: 'Vrátný',
        DRLRG_OPER: 'Vrtač (obsluha vrtné soupravy)',
        POINT_TRNR: 'Výhybkář',
        CARETAKER: 'Vychovatel',
        DISPATCHER: 'Výpravčí',
        ELEVR_REPR: 'Výtahář',
        ARTIST: 'Výtvarník',
        NUTRITNIST: 'Výživový poradce',
        GARDENER: 'Zahradník',
        RESCUER: 'Záchranář (posádka sanitního vozu nebo vrtulníku)',
        LOCKSMITH: 'Zámečník (odborník na zámky)',
        BLD_IRONMG: 'Zámečník stavební',
        MCH_FITTER: 'Zámečník strojní',
        NURSE: 'Zdravotní sestra',
        BRICKLAYER: 'Zedník',
        FARMER: 'Zemědělec',
        SURVEYOR: 'Zeměměřič',
        GOLDSMITH: 'Zlatník',
        LVSTCK_SPC: 'Zootechnik',
        SINGER: 'Zpěvák',
        SOUND_ENG: 'Zvukař',
    },
    '2': {
        CARER_CLSP: 'Osoba pečující o osobu blízkou',
        UNEMPLOYED: 'Nezaměstnaný',
        MAT_PRN_LV: 'Mateřská/rodičovská dovolená',
        OLD_PENS: 'Starobní důchodce',
        DISAB_PENS: 'Invalidní důchodce',
        STUDENT: 'Dítě/student',
    },
    '3': {
        RENTIER: 'Akcionář',
        SUP_BOARD: 'Člen dozorčí rady',
        BOARD_DIRC: 'Člen představenstva',
        EXEC_DIRTR: 'Jednatel',
        SHAREHOLDR: 'Rentiér',
        PARTNER: 'Společník',
    },
};

const banks: { [key: number]: string } = {
    100: 'Komerční banka, a.s.',
    300: 'Československá obchodní banka, a. s.',
    600: 'MONETA Money Bank, a.s.',
    710: 'ČESKÁ NÁRODNÍ BANKA',
    800: 'Česká spořitelna, a.s.',
    2010: 'Fio banka, a.s.',
    2060: 'Citfin, spořitelní družstvo',
    2070: 'TRINITY BANK a.s.',
    2100: 'Hypoteční banka, a.s.',
    2200: 'Peněžní dům, spořitelní družstvo',
    2220: 'Artesa, spořitelní družstvo',
    2250: 'Banka CREDITAS a.s.',
    2260: 'NEY spořitelní družstvo',
    2275: 'Podnikatelská družstevní záložna',
    2600: 'Citibank Europe plc, organizační složka',
    2700: 'UniCredit Bank Czech Republic and Slovakia, a.s.',
    3030: 'Air Bank a.s.',
    3050: 'BNP Paribas Personal Finance SA, odštěpný závod',
    3060: 'PKO BP S.A., Czech Branch',
    3500: 'ING Bank N.V.',
    4000: 'Max banka a.s.',
    4300: 'Národní rozvojová banka, a.s.',
    5500: 'Raiffeisenbank a.s.',
    5800: 'J&amp;T BANKA, a.s.',
    6000: 'PPF banka a.s.',
    6200: 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
    6210: 'mBank S.A., organizační složka',
    6300: 'BNP Paribas S.A., pobočka Česká republika',
    6363: 'Partners Banka, a.s.',
    6700: 'Všeobecná úverová banka a.s., pobočka Praha',
    6800: 'Sberbank CZ, a.s. v likvidaci',
    7910: 'Deutsche Bank Aktiengesellschaft Filiale Prag, organizační složka',
    7950: 'Raiffeisen stavební spořitelna a.s.',
    7960: 'ČSOB Stavební spořitelna, a.s.',
    7970: 'MONETA Stavební Spořitelna, a.s.',
    7990: 'Modrá pyramida stavební spořitelna, a.s.',
    8030: 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
    8040: 'Oberbank AG pobočka Česká republika',
    8060: 'Stavební spořitelna České spořitelny, a.s.',
    8090: 'Česká exportní banka, a.s.',
    8150: 'HSBC Continental Europe, Czech Republic',
    8190: 'Sparkasse Oberlausitz-Niederschlesien',
    8198: 'FAS finance company s.r.o.',
    8220: 'Payment execution s.r.o.',
    8250: 'Bank of China (CEE) Ltd. Prague Branch',
    8255: 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
    8265: 'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
    8270: 'Fairplay Pay s.r.o.',
    8280: 'B-Efekt a.s.',
    8299: 'BESTPAY s.r.o.',
    8500: 'Multitude Bank p.l.c.',
};

export { sports, sportLevels, employedTypes, occupations, banks };
