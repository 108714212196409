import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/Table';
import { AppContext } from '../../components/Context';
import { PL_CURRENCY } from '../../constants';

const TableCoverage = () => {
    const { t } = useTranslation();
    const { initData, userData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;

    const groups = useMemo(() => data?.InsuredPersons[0]?.SuggestedInsurance?.ProtectionGroups ?? null, [data]);

    const categories = useMemo(
        () =>
            initData?.RiderGroupFamilies.map((group: any) => ({
                name: `Category[${group.Code}]`,
                label: t(`pages.packageAdjustment.groups.${group.Code}`),
                subCategories: group.RiderGroups.sort((a: any, b: any) => a.OrderNo - b.OrderNo)
                    .map((rider: any) => {
                        const currentGroup = groups?.find(
                            (g) => g.SuggestedProtections[0].Variants[0].RiderGroup.Code === rider.Code
                        );
                        const currentGroupData = currentGroup?.SuggestedProtections[0].Variants[0];
                        const currentChosen = currentGroupData?.SumInsuredChosen;
                        const currentIsActive = currentGroupData?.IsChosen;
                        const currentMonthly = currentGroupData?.PremiumChosen.Monthly;

                        return {
                            name: `Cover[${rider.Code}]`,
                            label: t(`pages.packageAdjustment.cover.${rider.Code}.label`),
                            data: [
                                currentChosen && currentChosen > 0 && currentIsActive
                                    ? `${currentChosen.toLocaleString('pl')} ${PL_CURRENCY}`
                                    : t('common.notConcluded'),
                                `${
                                    currentMonthly && currentChosen && currentIsActive ? currentMonthly : '0'
                                } ${PL_CURRENCY}`,
                            ],
                            IsChosen: currentIsActive || false,
                        };
                    })
                    .filter((f: any) => f.IsChosen === true),
            })).filter((f: any) => f.subCategories.length > 0),
        [initData, t, groups]
    );

    return (
        <Table
            className="mb-12 mdmax:mb-4"
            data={{
                head: [t('common.faceAmount'), t('common.monthlyPremium')],
                categories,
            }}
            isSummaryCoverage
            data-test="coverageTable"
        />
    );
};

export default TableCoverage;
