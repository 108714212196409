import React from 'react';
import { getDataTest } from '../../utils';
import { RiderGroupFamiliesProps } from '../../pages/PackageAdjustment';
import { Help } from '../common/Help';
import { useTranslation } from 'react-i18next';
import { PL_CURRENCY } from '../../constants';
import { thousandSeparator } from '../../utils/hooks';
import { SuggestedProtectionProps } from '../../types/model';

interface TotalBenefitProps {
    group: RiderGroupFamiliesProps;
    item: SuggestedProtectionProps;
    currentRiderCode: string;
    coverChangedCategories: Array<string>;
    isSelected: boolean;
}

const TotalBenefit = ({
    group,
    item,
    currentRiderCode,
    coverChangedCategories,
    isSelected,
    ...props
}: TotalBenefitProps) => {
    const { t } = useTranslation();

    const dataTest = getDataTest(props, 'TotalBenefit');

    if (currentRiderCode === 'CHDTHACC_1_0') {
        return <div data-test={dataTest}>-</div>;
    }

    if (coverChangedCategories?.includes(group.Code)) {
        return (
            <Help
                isPlain
                text={t('pages.packageAdjustment.helpTotalBenefit')}
                data-test={getDataTest(props, 'TotalBenefitUnknown')}
            />
        );
    }

    return (
        <div data-test={dataTest}>
            {isSelected
                ? `${thousandSeparator(item.SumInsuredDetail?.SumInsuredReal?.toString() ?? '')} ${PL_CURRENCY}`
                : `0 ${PL_CURRENCY}`}
        </div>
    );
};

export default TotalBenefit;
