import React from 'react';
import { Button } from '../../components/common/Button';

interface ButtonsProps {
    submitDisabled?: boolean;
}

const Buttons = ({ submitDisabled = false }: ButtonsProps) => {
    const handleCancel = () => {};

    const handleSubmit = () => {};

    return (
        <div className="mt-10 flex justify-center gap-x-4">
            <Button isOutlined onClick={handleCancel}>
                Zrušit žádost
            </Button>
            <Button isDisabled={submitDisabled} onClick={handleSubmit}>
                Odeslat pojišťovně
            </Button>
        </div>
    );
};

export default Buttons;
