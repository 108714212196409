import { useEffect, useState, useContext, useRef, useCallback, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormikProps, FormikValues } from 'formik';
import { v4 as uuid } from 'uuid';

import { Head } from '../../components/Head';
import { TableHeader } from '../../components/TableHeader';
import { CoverageBox } from '../../components/CoverageBox';
import { AppContext } from '../../components/Context';
import { CustomFormik } from '../../components/CustomFormik';
import {
    getInitialValues,
    getPartnerType,
    getPersonOfInsurableInterest,
    getPersonInsurableGroups,
    getHigherStep,
    isDevelopment,
    getValueInVariantRange,
} from '../../utils';
import { ParticipantRelatedProps } from '../../types/model';
import { ModelProps, SuggestedProtectionProps } from '../../types/model';
import { ErrorType } from '../../types';
import { callValidateCalculations, callValidateRiderCategoryUwrCoverageLimit } from '../../apis/validations';
import GlobalError from '../../components/GlobalError';
import { Title } from '../../components/common/Title';
import Loader from '../../components/common/Loader';
import { Input } from '../../components/common/Input';
import { ROUTE, STEP_CODE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import { Card } from '../../components/common/Card';
import { callValidateCoverageSumInsured } from '../../apis/validations/validateCoverageSumInsured';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import FooterContent from './FooterContent';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import { removePartnerParticipant, updateParticipants, updatePersonOfInsurableInterest } from './hooks';
import { hasErrors } from './helpers';

interface RiderGroupChildrenProps {
    Code: string;
    Desc: string | null;
    DescDefault: string | null;
    DescTranslated: string | null;
    OrderNo: number;
    IsValid: boolean;
    Name: string;
    NameDefault: string;
    NameTranslated: string;
    Riders: Array<RiderProps>;
}

interface RiderProps {
    Code: string;
    Desc: string | null;
    DescDefault: string | null;
    DescTranslated: string | null;
    InitDataRiderDecreasingTypes: any;
    InitDataRiderVersion: any;
    IsBeneficiaryAllowed: boolean;
    IsWithPersonOfInsurableInterest: boolean;
    IsDeathAnyCause: boolean;
    IsValid: boolean;
    Name: string;
    NameDefault: string;
    NameTranslated: string;
}

export interface RiderGroupFamiliesProps {
    Code: string;
    Desc: string | null;
    DescDefault: string | null;
    DescTranslated: string | null;
    FamilyOrderNo: number;
    Name: string;
    NameDefault: string;
    NameTranslated: string;
    RiderGroups: Array<RiderGroupChildrenProps>;
}

export interface PackageAdjustmentErrors {
    [key: string]: ErrorType[];
}

export const PackageAdjustment = () => {
    const { coverChangedCategories, coverChangedVariants, initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const [loading, setLoading] = useState(false);
    const [blockReset, setBlockReset] = useState(false);
    const [errors, setErrors] = useState<PackageAdjustmentErrors | null>(null);
    const token = localStorage.getItem('token');
    const packageName = data?.InsuredPersons?.[0]?.InsurancePackage?.PackageName;

    const myFormikRef = useRef<FormikProps<any>>(null);
    const [groups, setGroups] = useState<Array<RiderGroupFamiliesProps> | undefined>();
    const [blockContinue, setBlockContinue] = useState<Array<string>>([]);

    const partnerByModel = data ? getPartnerType(data) : '';

    const apiProtectionGroups = data?.InsuredPersons[0]?.SuggestedInsurance?.ProtectionGroups;

    const mustRecalculate = useMemo(
        () => coverChangedCategories.length > 0 || coverChangedVariants.length > 0,
        [coverChangedCategories, coverChangedVariants]
    );

    const id = useMemo(() => uuid(), []);

    const isPartnerError = useMemo(() => {
        return blockContinue.length > 0 && !myFormikRef.current?.values.partnerType;
    }, [blockContinue]);

    useEffect(() => {
        document.body.classList.add('force-scrollbar');
        setGroups(initData?.RiderGroupFamilies);
        // eslint-disable-next-line
    }, [initData]);

    const callValidations = async (data: ModelProps): Promise<boolean> => {
        return new Promise(async (resolve, _) => {
            if (token) {
                setErrors(null);

                const callModel = callValidateCalculations({ data, token });

                const callCoverage = callValidateCoverageSumInsured({
                    data: {
                        JsonOnlineModel: JSON.stringify(data),
                        CheckSuggestedInsurance: true,
                        CheckAcceptedCoverage: false,
                    },
                    token,
                });

                const callRider = callValidateRiderCategoryUwrCoverageLimit({
                    data: {
                        JsonOnlineModel: JSON.stringify(data),
                        CheckSuggestedInsurance: true,
                        CheckAcceptedCoverage: false,
                    },
                    token,
                });

                Promise.all([callModel, callCoverage, callRider]).then(
                    ([{ data: resultModel }, { data: resultCoverage }, { data: resultRider }]) => {
                        const resultErrors = {
                            partner: isPartnerError
                                ? [{ code: '', message: t('pages.packageAdjustment.pickPartnerType') }]
                                : [],
                            sum: [...parseValidationErrors(resultCoverage), ...parseValidationErrors(resultRider)],
                            model: parseValidationErrors(resultModel),
                        };

                        setErrors(resultErrors);

                        resolve(
                            resultModel.Passed &&
                                resultCoverage.Passed &&
                                resultRider.Passed &&
                                !hasErrors(resultErrors)
                        );
                    }
                );
            } else {
                resolve(false);
            }
        });
    };

    const handleContinueToNextStep = () => {
        if (data) {
            const newData = {
                ...data,
                Settings: {
                    ...data.Settings,
                    CurrentStepCode: getHigherStep(data?.Settings?.CurrentStepCode, STEP_CODE.COVER_ADJUST),
                },
            };

            ctx.setCurrentModel(newData);
            ctx.saveCurrentModel(newData);

            navigateTo(ROUTE.CONTACTS_CHECK);
        }
    };

    const handleContinue = async (values: FormikValues) => {
        if (data) {
            const newModelData = handleUpdateModel(values);
            if (newModelData) {
                ctx.setCurrentModel(newModelData);
                ctx.saveCurrentModel(newModelData);

                if (token) {
                    setLoading(true);
                    setErrors(null);
                    try {
                        if (await callValidations(newModelData)) {
                            handleContinueToNextStep();
                        } else if (isDevelopment()) {
                            setTimeout(() => handleContinueToNextStep(), 5000);
                        }
                    } finally {
                        setLoading(false);
                    }
                }
            }
        }
    };

    const onChangeCalculationName = (e: ChangeEvent<HTMLInputElement>) => {
        if (data) {
            data.Settings.IllustrationCustomName = e.target.value ?? null;
            ctx.setCurrentModel(data);
        }
    };

    const parseValidationErrors = (result: any): ErrorType[] => {
        if (result?.ValidationResults) {
            return result?.ValidationResults?.map((err: { Path: string; Message: string }) => ({
                code: err.Path,
                message: err.Message,
            }));
        }

        return [];
    };

    const getSuggestedProtections = useCallback(
        (familyGroup: RiderGroupFamiliesProps): SuggestedProtectionProps[] =>
            apiProtectionGroups
                ?.filter((group) => group.RiderCategoryCode === familyGroup.Code)
                ?.reduce((acc: SuggestedProtectionProps[], group) => {
                    if (group.SuggestedProtections?.length > 0) {
                        group.SuggestedProtections?.forEach((protection) => {
                            const newProtection = protection;
                            newProtection['IsMandatory'] = group.IsMandatory;
                            acc.push(newProtection);
                        });
                    }
                    return acc;
                }, []) || [],
        [apiProtectionGroups]
    );

    const getInitialPackageValues = useCallback(
        () => getInitialValues(apiProtectionGroups ?? []),
        [apiProtectionGroups]
    );

    const personInsurableGroups = getPersonInsurableGroups(groups);

    const updateInsuredPerson = (currentData: ModelProps, values: FormikValues) => {
        return {
            ...currentData,
            InsuredPersons: currentData.InsuredPersons.map((insuredPerson) => ({
                ...insuredPerson,
                SuggestedInsurance: {
                    ...insuredPerson.SuggestedInsurance,
                    ProtectionGroups: insuredPerson.SuggestedInsurance.ProtectionGroups.map((pg) => ({
                        ...pg,
                        SuggestedProtections: pg.SuggestedProtections.map((sp) => ({
                            ...sp,
                            Variants: sp.Variants.map((variant) => {
                                let groupCode = variant.RiderGroup.Code;
                                // nahrádní kódy group u individuálního produktu
                                if (['CHBOP_1_0', 'PADTHOP_1_0', 'PASPDTHOP_1_0'].includes(variant.RiderGroup.Code)) {
                                    groupCode = groupCode.replace('OP_', '_');
                                }

                                return {
                                    ...variant,
                                    IsChosen: !!values[groupCode],
                                    SumInsuredChosen: !!values[groupCode]
                                        ? getValueInVariantRange(values[`${groupCode}Slider`] ?? 0, variant)
                                        : values[`${groupCode}Slider`] ?? 0,
                                };
                            }),
                        })),
                    })),
                },
            })),
        };
    };

    const countPartnersGroups = useCallback(
        (values: any) => {
            if (personInsurableGroups && values) {
                return groups?.reduce((acc: string[], familyGroup: RiderGroupFamiliesProps) => {
                    if (personInsurableGroups?.includes(familyGroup.Code)) {
                        familyGroup.RiderGroups.forEach((g) => {
                            if (values[g.Code]) {
                                acc.push(g.Code);
                            }
                        });
                    }
                    return acc;
                }, []);
            }

            return null;
        },
        [personInsurableGroups, groups]
    );

    const handleUpdateModel = (values: FormikValues): ModelProps | null => {
        if (data && values && personInsurableGroups) {
            // Update all suggested insurence data
            let newModelData = updateInsuredPerson(data, values);

            // Update illustration name
            newModelData.Settings.IllustrationCustomName = values?.calculationSaveName ?? null;

            // zjistím, zda v modelu je osoba pojistného zájmu
            const existingPOII = getPersonOfInsurableInterest(newModelData);
            // objekt osoby poj. zájmu
            const existingPOIIParticipant = existingPOII?.ParticipantExternalId
                ? (newModelData.Participants.find(
                      (p: any) => p.ExternalId === existingPOII?.ParticipantExternalId
                  ) as ParticipantRelatedProps)
                : undefined;
            const existingPOIIParticipantId = existingPOIIParticipant?.ExternalId || id;

            // krytí u partnera
            const partnerInsurances = countPartnersGroups(values);
            const partnerMustExists = partnerInsurances && partnerInsurances.length > 0;

            // je zadán typ partnera, ale nejsou žádná krytí pro partnera
            // tzn. krytí byla odebrány, typ partnera zůstal
            if (!partnerMustExists && partnerByModel && values.partnerType) {
                if (existingPOIIParticipant) {
                    removePartnerParticipant(newModelData, existingPOIIParticipant, personInsurableGroups);
                }
            }

            // Continue only if not blocked by unselected partner value (when at least one checbox checked) or it has formik value for partner
            if (partnerMustExists && values.partnerType) {
                // osoba pojistneho zajmu neexistuje
                // ani participant k osobe pojistneho zajmu
                if (!existingPOII?.ParticipantExternalId && !existingPOIIParticipant) {
                    updatePersonOfInsurableInterest(
                        personInsurableGroups,
                        newModelData,
                        existingPOIIParticipantId,
                        values
                    );
                }

                // aktualizace partnera
                updateParticipants(
                    newModelData,
                    values,
                    existingPOIIParticipantId,
                    existingPOII?.ParticipantExternalId,
                    existingPOIIParticipant
                );
            }

            return newModelData;
        }

        return data;
    };

    const getStartGroupItemsIndex = useCallback(
        (group: RiderGroupFamiliesProps): number => {
            let found = false;
            return (groups || []).reduce((acc: number, g: RiderGroupFamiliesProps) => {
                if (g.Code === group.Code) {
                    found = true;
                }
                if (!found) {
                    acc += getSuggestedProtections(g).length;
                }
                return acc;
            }, 0);
        },
        [groups, getSuggestedProtections]
    );

    return (
        <Layout
            hideContinue={mustRecalculate}
            continueDisabled={loading || blockReset || !data}
            footerContent={
                <FooterContent
                    loading={loading || !data}
                    errors={errors}
                    mustRecalculate={mustRecalculate}
                    formValues={myFormikRef?.current?.values}
                    blockReset={blockReset || !data}
                    setLoading={setLoading}
                    onUpdateModel={handleUpdateModel}
                    onCallValidations={callValidations}
                />
            }
        >
            {data && apiProtectionGroups ? (
                <LimitedAccess minStep={STEP_CODE.BASIC_DATA}>
                    <Head
                        heading1={`${t(`common.packageNames.${packageName}`)} ${t('common.package')}`}
                        heading2={t('pages.packageAdjustment.subtitle')}
                    />

                    <GlobalError errors={errors} />

                    <CustomFormik
                        initialValues={{
                            ...getInitialPackageValues(),
                            partnerType: partnerByModel,
                            calculationSaveName: data?.Settings?.IllustrationCustomName ?? '',
                        }}
                        onSubmit={(values: any) => {
                            setErrors(null);
                            handleContinue(values);
                        }}
                        customRender
                        passedRef={myFormikRef}
                    >
                        {({ values, handleChange }: FormikValues) => (
                            <Form noValidate>
                                {initData && groups ? (
                                    <>
                                        <TableHeader className="mb-2" />
                                        <div className="flex flex-col gap-y-4">
                                            {groups?.map((familyGroup: RiderGroupFamiliesProps) => (
                                                <CoverageBox
                                                    key={familyGroup.Code}
                                                    group={familyGroup}
                                                    items={getSuggestedProtections(familyGroup)}
                                                    values={values}
                                                    startIndex={getStartGroupItemsIndex(familyGroup)}
                                                    isDisabled={loading}
                                                    isEnterAllowed={!blockReset}
                                                    onChange={handleChange}
                                                    isWithPersonOfInsurableInterest={personInsurableGroups?.includes(
                                                        familyGroup.Code
                                                    )}
                                                    setBlockContinue={setBlockContinue}
                                                    blockContinue={blockContinue}
                                                    setBlockReset={setBlockReset}
                                                />
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <Title tag="strong" size="md" className="justify-center" data-test="coversLoading">
                                        {t('common.loadingData')}...
                                    </Title>
                                )}

                                <DetectFormikChanged
                                    onChange={(v: FormikValues) => {
                                        if (data && v) {
                                            ctx.setCurrentModel(handleUpdateModel(v));
                                        }
                                    }}
                                />

                                <Card
                                    className="mt-4 p-4 px-5 lg:hidden"
                                    hasPadding={false}
                                    data-test="coversTotalMonthlyPremium[Mobile]"
                                >
                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="font-medium">{t('common.totalMonthlyPremium')}</div>
                                        <strong className="whitespace-nowrap lg:text-lg">
                                            {mustRecalculate ? (
                                                '???'
                                            ) : (
                                                <>
                                                    {data?.InsuredPersons[0]?.SuggestedInsurance?.PremiumTotal?.Monthly}{' '}
                                                    {data?.Payment.CurrencyCode}
                                                </>
                                            )}
                                        </strong>
                                    </div>
                                </Card>

                                <Field
                                    component={Input}
                                    type="text"
                                    name="calculationSaveName"
                                    label={t('pages.packageAdjustment.calculationSaveName')}
                                    helpText={t('pages.packageAdjustment.calculationSaveNameHelp')}
                                    helpLocation="label"
                                    className="mt-6"
                                    fieldClassName="font-medium"
                                    maxLength={120}
                                    onChange={onChangeCalculationName}
                                    data-test="calculationSaveName"
                                />
                            </Form>
                        )}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
