import React, { useContext } from 'react';
import { Select, SelectProps } from './common/Select';
import { AppContext } from './Context';
import { getCountriesSelectList } from '../utils';

const SelectCountry = (props: SelectProps) => {
    const { initData } = useContext(AppContext);
    const countryList = getCountriesSelectList(initData) || [];
    return <Select {...props} options={countryList || []} />;
};

export default SelectCountry;
