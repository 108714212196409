import React from 'react';
import { getDataTest } from '../../utils';
import { SuggestedProtectionVariantProps } from '../../types/model';
import { Help } from '../common/Help';
import { useTranslation } from 'react-i18next';
import { PL_CURRENCY } from '../../constants';

interface MonthlyPremiumProps {
    variant: SuggestedProtectionVariantProps;
    currentRiderCode: string;
    coverChangedVariants: Array<string>;
    isSelected: boolean;
}

const MonthlyPremium = ({
    variant,
    currentRiderCode,
    coverChangedVariants,
    isSelected,
    ...props
}: MonthlyPremiumProps) => {
    const { t } = useTranslation();

    const value = variant.PremiumChosen.Monthly && isSelected ? variant.PremiumChosen.Monthly : '0';

    return (
        <div data-test={getDataTest(props, 'MonthlyPremium')}>
            {coverChangedVariants?.includes(currentRiderCode) ? (
                <Help
                    isPlain
                    text={t('pages.packageAdjustment.helpPremium')}
                    data-test={getDataTest(props, 'MonthlyPremiumUnknown')}
                />
            ) : (
                `${value} ${PL_CURRENCY}`
            )}
        </div>
    );
};

export default MonthlyPremium;
