import React from 'react';
import { Card } from '../../components/common/Card';
import CardTitle from './CardTitle';
import { Grid } from '../../components/Grid';
import { Field } from 'formik';
import { Input } from '../../components/common/Input';
import { Select } from '../../components/common/Select';
import { banks } from './constants';
import { REGEX } from '../../constants';

const BankCard = () => {
    return (
        <Card hasPadding={false} className="p-5 px-7">
            <CardTitle>Účet pro výplatu pojistného plnění</CardTitle>
            <div className="grid gap-y-2">
                <Grid>
                    <Field
                        component={Input}
                        name="accountNumber"
                        label="Číslo účtu"
                        placeholder="Číslo účtu nebo IBAN"
                        mask={REGEX.POLISH_IBAN_REGEX}
                    />
                    <Field
                        component={Select}
                        name="bankCode"
                        label="Kód banky"
                        options={Object.keys(banks).map((b) => ({
                            label: `${b.padStart(4, '0')}: ${banks[parseInt(b)]}`,
                            value: b.padStart(4, '0'),
                        }))}
                    />
                </Grid>
            </div>
        </Card>
    );
};

export default BankCard;
