import React from 'react';
import cn from 'classnames';
import { Trans } from 'react-i18next';

export const RecordsOnPageList: Array<number> = [20, 40, 60];

interface RecordsOnPageProps {
    recordsOnPage: number;
    handleChange: (value: number) => void;
}

const RecordsOnPage = ({ recordsOnPage, handleChange }: RecordsOnPageProps) => {
    return (
        <div className="recordsOnPage mt-1.5" data-test="recordsOnPage">
            <span>
                <Trans>dashboard.common.recordsOnPage</Trans>
            </span>
            {RecordsOnPageList.map((v: number) => (
                <span
                    key={`records-${v}`}
                    className={cn(
                        'ml-2 cursor-pointer underline underline-offset-1',
                        recordsOnPage === v && 'font-bold'
                    )}
                    onClick={() => handleChange(v)}
                >
                    {v}
                </span>
            ))}
        </div>
    );
};

export default RecordsOnPage;
