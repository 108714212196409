import React, { Fragment, useEffect } from 'react';
import { useFormikContext } from 'formik';

interface DetectFormikChangedProps {
    onChange: (data: any) => void;
}

export const DetectFormikChanged = ({ onChange }: DetectFormikChangedProps) => {
    const { values } = useFormikContext();

    useEffect(() => {
        onChange(values);
        // eslint-disable-next-line
    }, [values]);

    return <Fragment />;
};
