import React, { Fragment } from 'react';
import { Grid } from '../../components/Grid';
import { Field } from 'formik';
import DateInput from '../../components/common/DateInput';
import { Checkbox } from '../../components/common/Checkbox';
import { Select } from '../../components/common/Select';
import HelperText from '../../components/HelperText';
import CardDivier from './CardDivier';
import Question from './Question';
import { employedTypes, occupations } from './constants';
import OpenSection from './OpenSection';

interface IncapacityFormProps {
    question: string;
    values: any;
}

const IncapacityForm = ({ question, values }: IncapacityFormProps) => {
    const occupationCategory = employedTypes[values.employmentType?.value]?.occupationCategory;
    const currentOccupation = occupations[occupationCategory || 0];
    return (
        <Fragment>
            <Question name="incapacityEl" text={question} />
            {values.incapacityEl === 'yes' && (
                <OpenSection>
                    <Grid className="mb-4">
                        <Field
                            component={DateInput}
                            name="incapacity-0-date_incapacity_from"
                            label="Datum začátku pracovní neschopnosti"
                        />
                        <div>
                            <Field
                                component={DateInput}
                                name="incapacity-0-date_incapacity_to"
                                label="Datum konce pracovní neschopnosti"
                                isDisabled={values['incapacity-0-incapacity_still_proceed'] === true}
                            />
                            <Field
                                component={Checkbox}
                                name="incapacity-0-incapacity_still_proceed"
                                label="Pracovní neschopnost stále trvá"
                                className="ml-4"
                            />
                        </div>
                    </Grid>
                    <Grid>
                        <Field
                            component={Select}
                            name="employmentType"
                            label="Typ pracovního poměru v době škodní události"
                            options={Object.keys(employedTypes).map((s: string) => ({
                                label: employedTypes[s].label,
                                value: s,
                            }))}
                        />
                        <Field
                            component={Select}
                            name="occupation"
                            label="Povolání v době škodní události"
                            options={Object.keys(currentOccupation || {}).map((s: string) => ({
                                label: currentOccupation[s],
                                value: s,
                            }))}
                        />
                    </Grid>
                    <HelperText className="mt-2">
                        Přiložte prosím kopii Potvrzení pracovní neschopnosti nebo Rozhodnutí o potřebě ošetřování
                        (péče) o pojištěného.
                    </HelperText>
                    <CardDivier />
                    <Question
                        name="isDepositRequested"
                        text="Požaduji vyplatit zálohové plnění z pojištění pracovní neschopnosti / ošetřovného"
                    />
                </OpenSection>
            )}
        </Fragment>
    );
};

export default IncapacityForm;
