import cn from 'classnames';
import { FieldProps } from 'formik';
import { twMerge } from 'tailwind-merge';

import { FormError } from './FormError';
import { getDataTest } from '../../utils';

interface TextareaProps {
    label?: string;
    field?: FieldProps['field'];
    error?: string;
    className?: string;
    placeholder?: string;
    withoutHelperText?: boolean;
}

export const Textarea = ({
    field,
    placeholder,
    label,
    error,
    withoutHelperText = false,
    className,
    ...props
}: TextareaProps) => {
    return (
        <div className={className}>
            {label && (
                <label
                    className={cn('mb-2 block cursor-pointer pl-4 text-sm text-darkPurple')}
                    htmlFor={field?.name}
                    data-test={getDataTest(props, 'Label')}
                >
                    {label}
                </label>
            )}
            <textarea
                id={field?.name}
                name={field?.name}
                placeholder={placeholder}
                value={field?.value}
                onChange={field?.onChange}
                className={cn(
                    twMerge(
                        'h-[10rem] min-h-[10rem] w-full shrink-0 grow rounded-lg border p-2 outline-none md:h-[3.25rem] md:p-4 [&:focus]:border-darkPurple',
                        !!error ? 'border-red' : 'border-purple'
                    )
                )}
                data-test={getDataTest(props, 'Input')}
            />
            {!withoutHelperText && (
                <FormError name={field?.name} className={cn('mt-2 pl-4')} data-test={getDataTest(props, 'Error')} />
            )}
        </div>
    );
};
