import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallCreateEmailVerificationProps {
    data: {
        onlinePolicyExternalId: string;
        confirmationEmail: string | null;
    };
    token: string;
}

export const callCreateEmailVerification = ({
    data,
    token,
}: CallCreateEmailVerificationProps): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/create-email-verification`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
