import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button } from '../common/Button';
import { AppContext } from '../Context';
import { Icon } from '../Icon';

const SaveButton = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const token = localStorage.getItem('token');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);

    const handleSave = () => {
        if (token && data) {
            setIsSaving(true);
            setFinished(false);
            ctx.saveCurrentModel(data).finally(() => {
                setFinished(true);
                setIsSaving(false);
                setTimeout(() => setFinished(false), 2000);
            });
        }
    };

    return (
        <Button
            isDisabled={isSaving || !token || !data}
            isDark
            onClick={() => handleSave()}
            size="sm"
            isOutlined
            className={cn('lgmax:hidden')}
            data-test="buttonSaveModel"
        >
            <Icon name="spinner" className={cn(isSaving ? 'block' : 'hidden')} stroke="#433063" />
            <Icon name="check" className={cn('mr-2 h-6 w-6', finished ? 'block' : 'hidden')} />
            {t('common.save')}
        </Button>
    );
};

export default SaveButton;
