import { ReactNode } from 'react';
import { getDataTest } from '../../utils';

export type FormInputPlaceholderProps = {
    title: string;
    children: ReactNode | string;
};

export const FormInputPlaceholder = ({ title, children, ...props }: FormInputPlaceholderProps) => (
    <div className="flex flex-col gap-2 border-b pb-3">
        <small className="text-xs" data-test={getDataTest(props, 'Placeholder')}>
            {title}
        </small>
        <span className="text-sm">{children || '-'}</span>
    </div>
);
