import { useTranslation } from 'react-i18next';

import Puzzle from '../../assets/images/puzzle.png';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Layout } from '../../components/Layout';

export const VerifyIdentityFinal = () => {
    const { t } = useTranslation();

    return (
        <Layout
            hideProgressBar
            hideSaveButton
            hideUserMenu
            continueText={t<string>('common.continueSimplea')}
            continueCallback={() => {
                window.location.replace('http://simplea.pl/');
            }}
        >
            <Card className="flex flex-col items-center md:flex-row" data-test="verifyFinalCard">
                <img src={Puzzle} alt="" className="w-full max-w-[24rem]" data-test="image" />
                <div className="flex flex-col items-center gap-y-6 text-center md:gap-y-16">
                    <Title isCenter tag="h2" size="md" fontWeight="medium" data-test="verifyFinalTitle">
                        {t('pages.clientVerifyIdentityFinal.title')}
                    </Title>

                    <p data-test="verifyFinalText">{t('pages.clientVerifyIdentityFinal.text')}</p>
                </div>
            </Card>
        </Layout>
    );
};
