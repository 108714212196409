import React, { Fragment } from 'react';
import Question from './Question';
import HelperText from '../../components/HelperText';

interface InvalidityFormProps {
    values: any;
}

const InvalidityForm = ({ values }: InvalidityFormProps) => {
    return (
        <Fragment>
            <Question name="invalidity" text="Byla Vám přiznána invalidita Českou správou sociálního zabezpečení?" />
            {values.invalidity === 'yes' && (
                <Fragment>
                    <HelperText className="mt-4">
                        Přiložte prosím kopii rozhodnutí o přiznání stupně invalidity, stupně nezávislosti, nároku na
                        invalidní důchod a/nebo nároku na příspěvek na péči, vydaného ČSSZ.
                    </HelperText>
                </Fragment>
            )}
        </Fragment>
    );
};

export default InvalidityForm;
