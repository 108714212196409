import React from 'react';
import { Trans } from 'react-i18next';
import { WaitingCase } from '../types';
import { Button } from '../../../../components/common/Button';
import { ROUTE } from '../../../../constants';

interface WaitingCaseToggleProps {
    data: WaitingCase;
}

const WaitingCaseToggle = ({ data }: WaitingCaseToggleProps) => {
    return (
        <div>
            <Button
                size="sm"
                className="m-0 ml-auto w-fit"
                href={ROUTE.DASHBOARD_WAITING_CASE_DETAIL.replace(':caseId', data.CaseExternalId)}
                data-test="waitingCaseDetailButton"
            >
                {data.ClientIdentificationVerificationTypeId === 1 ? (
                    <Trans>dashboard.common.insertAttachments</Trans>
                ) : (
                    <Trans>dashboard.common.insertData</Trans>
                )}
            </Button>
        </div>
    );
};

export default WaitingCaseToggle;
