import cn from 'classnames';
import { ReactElement, ReactNode } from 'react';

interface LabelProps {
    children?: ReactNode;
    text?: string;
    name: string;
    isCenter?: boolean;
    childrenClassName?: string;
    className?: string;
}

export const Label = ({
    children,
    childrenClassName,
    text,
    name,
    isCenter,
    className,
    ...props
}: LabelProps): ReactElement => {
    return (
        <label className={cn(className, 'cursor-pointer text-sm text-darkPurple')} htmlFor={name} {...props}>
            {text && <div className={cn('mb-2', isCenter ? 'text-center' : 'pl-4')}>{text}</div>}
            {children && <div className={childrenClassName}>{children}</div>}
        </label>
    );
};
