import { useEffect, useRef, useState } from 'react';

import { callMojeIdCreateSaml } from '../apis/moje-id';
import { Layout } from '../components/Layout';
import Loader from '../components/common/Loader';

export const MojeIdAuth = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const [response, setResponse] = useState<any>(null);

    useEffect(() => {
        setResponse(null);
        callMojeIdCreateSaml()
            .then((response) => response.data)
            .then((data) => setResponse(data));
    }, []);

    useEffect(() => {
        if (response && formRef && formRef.current) {
            formRef?.current?.submit();
        }
    }, [response, formRef]);

    return (
        <Layout hideProgressBar hideSaveButton hideUserMenu hideFooter continueDisabled continueCallback={() => {}}>
            <Loader />
            {response && (
                <div className="hidden">
                    <form
                        ref={formRef}
                        method="post"
                        action={response.MojeIdRedirectUrl}
                        encType="application/x-www-form-urlencoded"
                    >
                        <input type="hidden" name={response.FormParamName} value={response.FormParamValueBase64} />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            )}
        </Layout>
    );
};
