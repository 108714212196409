import { ReactElement, useContext, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTE } from '../../constants';
import { AppContext } from '../Context';
import { hasPermission, useAuth } from '../../utils/hooks';
import Loader from './Loader';
import { isTokenExpired } from '../../utils';

interface ProtectedRouteProps {
    isAuthorizationProtected: boolean;
    isDataProtected?: boolean;
    permission?: string;
    retrievingUserData?: boolean;
    redirectPath?: string;
    children: ReactElement;
}

export const ProtectedRoute = ({
    isAuthorizationProtected,
    isDataProtected,
    permission,
    retrievingUserData = false,
    redirectPath = ROUTE.UNACCESSIBLE,
    children,
}: ProtectedRouteProps): ReactElement => {
    const ctx = useContext(AppContext);
    const { userData, userRights } = ctx;
    const token = localStorage.getItem('token');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const hasAccess = useMemo(
        () => (permission ? hasPermission(permission, userRights) : true),
        [permission, userRights]
    );

    useEffect(() => {
        if (token && isTokenExpired(token)) {
            logout();
        }
        // omezený přístup, nemám token
        if (isAuthorizationProtected && !token) {
            logout().then(() => navigate(ROUTE.UNACCESSIBLE, { replace: true }));
        }
        // omezený přístup agenta
        if (isAuthorizationProtected && userRights) {
            // neznám data uživatele, a už jsem dokončil jejich načtení
            if (!retrievingUserData && (!userData || !hasAccess)) {
                navigate(redirectPath, { replace: true });
            }
        }
        // omezený přístup na smlouvu
        if (isDataProtected) {
            const id = searchParams.get('id');
            // není v url parametr id
            if (!id) {
                navigate(ROUTE.MAIN_MENU, { replace: true });
            }
        }
        // eslint-disable-next-line
    }, [
        isAuthorizationProtected,
        isDataProtected,
        token,
        retrievingUserData,
        userData,
        userRights,
        hasAccess,
        navigate,
    ]);

    if (!isAuthorizationProtected || (token && isAuthorizationProtected && userData && hasAccess)) {
        return children ? children : <Outlet />;
    }

    return <Loader />;
};
