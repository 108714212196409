import { ChangeEvent } from 'react';
import { FieldProps } from 'formik';
import cn from 'classnames';

import { FormError } from './FormError';
import { getDataTest } from '../../utils';

interface CheckboxProps {
    field: FieldProps['field'];
    label: string;
    labelClassName?: string;
    name: string;
    error?: string;
    disabled?: boolean;
    className?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
    name,
    field,
    label,
    labelClassName,
    error,
    disabled = false,
    className,
    onChange,
    ...props
}: CheckboxProps) => {
    return (
        <div className={cn(className, 'relative')}>
            <label
                className={cn('checkbox z-0', disabled && 'checkbox__disabled', labelClassName)}
                data-test={getDataTest(props, 'Label')}
            >
                <input
                    className="input-hidden"
                    id={name}
                    type="checkbox"
                    {...field}
                    disabled={disabled}
                    checked={field.value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange && onChange(e);
                        field?.onChange(e);
                    }}
                    data-test={getDataTest(props, 'Input')}
                />
                <span className={cn('checkbox__mark', !!error && '!border-redLight')}></span>
                {label && <>{label}</>}
            </label>

            <FormError name={name} className="mt-2" data-test={getDataTest(props, 'Error')} />
        </div>
    );
};
