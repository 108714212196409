import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetDashboardRightsProps {
    token: string;
}

export const callGetDashboardRights = (props: CallGetDashboardRightsProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/get-dashboard-rights`,
        data: {},
        headers: {
            'X-Simplea-Auth-Token': props.token,
        },
    });
