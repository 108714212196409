import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import * as Yup from 'yup';

import PopupWrapper from '../common/PopupWrapper';
import { Title } from '../common/Title';
import { Button } from '../common/Button';
import { REGEX } from '../../constants';
import { CustomFormik } from '../CustomFormik';
import { callReportBug } from '../../apis/support';
import { AppContext } from '../Context';
import { Input } from '../common/Input';
import { Textarea } from '../common/Textarea';
import PopupButtons from '../common/PopupButtons';

interface ReportBugFormData {
    reportName: string;
    reportEmail: string;
    reportMessage: string;
}

const ReportBugPopup = () => {
    const { t } = useTranslation();
    const { showPopup } = useContext(AppContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const formSchema = Yup.object().shape({
        reportName: Yup.string().required(),
        reportEmail: Yup.string()
            .matches(new RegExp(REGEX.EMAIL_REGEX), t('common.formErrors.wrongEmailFormat') || '')
            .required(),
        reportMessage: Yup.string().required(),
    });

    const handleClose = () => {
        showPopup(null);
    };

    return (
        <PopupWrapper popupType="report-bug" color="lightPurple">
            <div className="flex flex-col items-center justify-center gap-y-4">
                <Title tag="h2" size="lg" fontWeight="medium" isCenter className="mb-0" data-test="title">
                    {t('pages.reportBug.title')}
                </Title>
                <Title tag="h3" size="md" fontWeight="regular" isCenter className="mb-3" data-test="subtitle">
                    {t('pages.reportBug.subtitle')}
                </Title>

                {isSubmitted ? (
                    <>
                        <div className="mb-4" data-test="submited">
                            {t('pages.reportBug.submitted')}
                        </div>
                        <PopupButtons>
                            <Button isOutlined className="ml-auto" data-test="buttonClose" onClick={handleClose}>
                                {t('common.close')}
                            </Button>
                        </PopupButtons>
                    </>
                ) : (
                    <CustomFormik<ReportBugFormData>
                        initialValues={{ reportName: '', reportEmail: '', reportMessage: '' }}
                        validationSchema={formSchema}
                        customRender
                        disableScrollToErrors
                        onSubmit={(v) => {
                            setLoading(true);
                            callReportBug({
                                data: {
                                    name: v?.reportName,
                                    email: v?.reportEmail,
                                    bugText: v?.reportMessage,
                                    onlinePolicyJson: '',
                                },
                            })
                                .then(() => setIsSubmitted(true))
                                .finally(() => setLoading(false));
                        }}
                    >
                        <Form className="flex w-3/4 flex-col gap-y-6" noValidate data-test="reportBugForm">
                            <Field
                                component={Input}
                                label={`${t('pages.reportBug.name')}:`}
                                name="reportName"
                                data-test="reportName"
                            />
                            <Field
                                component={Input}
                                type="email"
                                label={`${t('common.email')}:`}
                                name="reportEmail"
                                data-test="reportEmail"
                            />
                            <Field
                                component={Textarea}
                                type="message"
                                label={`${t('common.bugMessage')}:`}
                                name="reportMessage"
                                data-test="reportMessage"
                            />

                            <PopupButtons>
                                <Button size="md" isOutlined onClick={handleClose} data-test="buttonClose">
                                    {t('common.close')}
                                </Button>
                                <Button size="md" isDisabled={loading} isSubmit data-test="buttonSend">
                                    {t('common.send')}
                                </Button>
                            </PopupButtons>
                        </Form>
                    </CustomFormik>
                )}
            </div>
        </PopupWrapper>
    );
};

export default ReportBugPopup;
