import React from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';

interface UploadedFileProps {
    name: string;
}

const UploadedFile = ({ name }: UploadedFileProps) => {
    return (
        <div className="flex rounded border-1 bg-white p-2 px-4">
            {name}
            <span className="ml-auto flex cursor-pointer justify-end gap-x-2 underline">
                <DeleteIcon />
                Delete
            </span>
        </div>
    );
};

export default UploadedFile;
