import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '../components/common/Title';
import { useAppNavigate, useContextHelpers, useCountdown } from '../utils/hooks';
import { config } from '../config';
import { callGetOnlinePolicyEvaluationResult } from '../apis/support';
import { AppContext } from '../components/Context';
import { callFinishPrevaluation } from '../apis/modelData';
import { ROUTE, STEP_CODE } from '../constants';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';
import Loader from '../components/common/Loader';

export const SummaryPreparing = () => {
    const { t } = useTranslation();
    const time = useCountdown(config.SUMMARY_COUNTDOWN);
    const { navigateTo } = useAppNavigate();
    const ctx = useContext(AppContext);
    const { isPep, isRemote, isF2F } = useContextHelpers();
    const data = ctx.currentModel;
    const token = localStorage.getItem('token');
    const [fetchResultInProgress, setFetchResultInProgress] = useState(false);
    const MAX_CALL_COUNT = Math.round(config.MAX_SUMMARY_COUNTDOWN / config.SUMMARY_FETCH_INTERVAL);

    const nextStep = (route: string, queryParameters?: any) => navigateTo(route, { queryParameters });

    useEffect(() => {
        if (token && data) {
            callFinishPrevaluation({
                data: {
                    jsonOnlineModel: JSON.stringify(data),
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => console.log('API call result', result))
                .catch((err) => console.error('API call error', err));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (token && !fetchResultInProgress && data) {
            let counter = 0;

            const interval = setInterval(() => {
                if (counter > MAX_CALL_COUNT) {
                    clearInterval(interval);
                    nextStep(ROUTE.SUMMARY_FAIL);
                }

                callGetOnlinePolicyEvaluationResult({
                    data: {
                        onlineModelExternalId: data.ExternalId,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then((result) => {
                        switch (result.ResultType) {
                            // Evaluation in progress
                            case 1:
                                break;
                            // Waiting for the policy to be paid
                            case 2:
                                clearInterval(interval);
                                // Remote nebo F2F
                                nextStep(isRemote ? ROUTE.FINISH_REMOTE : ROUTE.SUMMARY_DOCUMENTS);
                                break;
                            // Waiting for policy document to be created
                            case 3:
                                // F2F
                                if (isF2F) {
                                    clearInterval(interval);
                                    nextStep(ROUTE.SUMMARY_DOCUMENTS);
                                }
                                break;
                            // Finished
                            case 4:
                                // F2F
                                if (isF2F) {
                                    clearInterval(interval);
                                    nextStep(ROUTE.SUMMARY_DOCUMENTS);
                                }
                                break;
                            // Waiting for client identification to complete
                            // Waiting for confirmation from the insurance company operator
                            case 5:
                            case 6:
                                // Remote nebo PEP a F2F varianta
                                if (isRemote || (isPep && isF2F)) {
                                    clearInterval(interval);
                                    nextStep(ROUTE.FINISH_REMOTE);
                                }
                                break;
                            // Waiting for documents to be created
                            // case 7:
                            //     break;
                            // Declined by the insurance company operator
                            // Existing client
                            // Client data does not match
                            // Incorrect data
                            // Other error
                            // Expired
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                                clearInterval(interval);
                                nextStep(ROUTE.SUMMARY_FAIL, {
                                    status: result.ResultType,
                                });
                                break;
                            // Workflow does not exist
                            case 14:
                                break;
                            // Decline due to sanction/risk country
                            case 15:
                                clearInterval(interval);
                                nextStep(ROUTE.SUMMARY_FAIL, {
                                    status: result.ResultType,
                                });
                                break;
                        }
                    })
                    .catch((err) => {
                        console.error('API call error', err);
                        clearInterval(interval);
                        nextStep(ROUTE.SUMMARY_FAIL);
                    });

                counter++;
            }, config.SUMMARY_FETCH_INTERVAL * 1000);
            setFetchResultInProgress(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchResultInProgress, data]);

    return (
        <Layout hideProgressBar hideSaveButton disableUserMenu hideFooter>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.SUMMARY}>
                    <div className="flex flex-col" data-test="summaryPreparing">
                        <Title tag="h1" size="lg" isCenter className="mb-3" data-test="title">
                            {t('pages.summaryLoading.title')}
                        </Title>

                        <Title tag="h2" size="md" isCenter fontWeight="medium" data-test="subtitle">
                            {t('pages.summaryLoading.subtitle')}...
                            <span className="ml-2 inline-block text-purple">
                                {time > 0
                                    ? t('pages.summaryLoading.seconds', { time })
                                    : t('pages.summaryLoading.aWhile')}
                            </span>
                        </Title>

                        <div
                            className="relative mt-12 flex w-full items-center justify-center overflow-hidden rounded-sm"
                            data-test="animation"
                        >
                            <video className="h-auto w-[calc(100%+4px)] max-w-[none]" autoPlay loop muted>
                                <source src="/videos/long-1170x.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
