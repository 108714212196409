import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallValidateClientDataProps {
    data: { clientPersonDtoJson: string };
    token: string;
}

interface CallValidateClientDataResponse {
    ValidatePeselByBirthDateAndGenderResult: {
        IsPeselValidByCheckSum: boolean;
        IsPeselValidByBirthDate: boolean;
        IsPeselValidByGender: boolean;
    };
    HasClientByPeselAnyActivePolicyResult: { HasAnyActivePolicy: boolean; ExistingPolicyNumbers: [] };
    ValidateClientPersonalInformationExistResult: {
        IsValidByPesel: boolean;
        IsValidByEmailAndPhone: boolean;
    };
    ValidateSanctionResult: { IsValid: boolean; ReasonText: string };
}

export const callValidateClientData = ({
    data,
    token,
}: CallValidateClientDataProps): Promise<AxiosResponse<CallValidateClientDataResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}validations/validate-client-data`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
