import React, { Fragment, ReactNode } from 'react';
import { Button } from './Button';

interface ButtonMobileMoreProps {
    caption?: ReactNode;
    isDisabled?: boolean;
}

const ButtonMobileMore = ({ caption, isDisabled = false, ...props }: ButtonMobileMoreProps) => {
    return (
        <Fragment>
            <Button isOutlined isDisabled={isDisabled} icon="dots" className="ml-auto hidden md:flex" {...props}>
                {caption}
            </Button>
            <Button isOutlined isDisabled={isDisabled} icon="dots" className="ml-auto flex px-4 md:hidden" {...props} />
        </Fragment>
    );
};

export default ButtonMobileMore;
