import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Carousel as CarouselComponent } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Card } from './Card';
import { Title } from './Title';
import { checkArray } from '../../utils';
import { Icon } from '../Icon';

interface CarouselProps {
    data: Array<{
        title: string;
        text: string;
    }>;
    className?: string;
}

const renderArrow = (clickHandler: () => void, type: 'prev' | 'next') => (
    <button className={cn('slider-arrow', type === 'prev' ? 'right-full' : 'left-full')} onClick={clickHandler}>
        <Icon name="chevron-up" className={cn('-rotate-90', type === 'next' && 'rotate-90')} />
    </button>
);

export const Carousel = ({ data, className }: CarouselProps) => {
    const [initialised, setInitialised] = useState(false);
    const [maxHeight, setMaxHeight] = useState<number | null>(null);
    const itemRefs = useRef<Array<HTMLDivElement>>([]);
    const INTERVAL = 5000; // If you change interval, you have to change .carousel-indicator-selected duration!

    useEffect(() => {
        // Workaround to animate progressbar on load
        setInitialised(true);
    }, []);

    useEffect(() => {
        // Carousel npm package is stupid and expects fixed height
        let h = 0;
        if (itemRefs && checkArray(itemRefs?.current)) {
            itemRefs?.current.forEach((item) => {
                if (item.clientHeight > h) {
                    h = item.clientHeight;
                }
            });
            setMaxHeight(h);
        }
    }, [itemRefs]);

    return (
        <div className={cn(className, '')}>
            <Card className="px-12 pb-7 pt-4 md:px-12 md:pb-7 md:pt-4">
                <CarouselComponent
                    showThumbs={false}
                    showStatus={false}
                    interval={INTERVAL}
                    autoPlay
                    infiniteLoop
                    dynamicHeight
                    renderArrowPrev={(clickHandler) => renderArrow(clickHandler, 'prev')}
                    renderArrowNext={(clickHandler) => renderArrow(clickHandler, 'next')}
                    renderIndicator={(onClickHandler, isSelected, index, label) => (
                        <li
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`}
                            className={cn(
                                'carousel-indicator',
                                initialised && (isSelected ? 'carousel-indicator-selected' : '[&:after]:w-0')
                            )}
                        />
                    )}
                >
                    {data.map((item, i) => (
                        <div
                            style={maxHeight ? { height: maxHeight } : {}}
                            key={i}
                            ref={(element) => {
                                if (element) {
                                    itemRefs.current[i] = element;
                                }
                            }}
                            className="flex flex-col"
                        >
                            <Title tag="strong" size="sm" fontWeight="medium" className="mb-2">
                                {item.title}
                            </Title>

                            <p className="text-xs">{item.text}</p>
                        </div>
                    ))}
                </CarouselComponent>
            </Card>
        </div>
    );
};
