import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCountdown } from 'usehooks-ts';
import { Field } from 'formik';
import * as Yup from 'yup';

import Image from '../assets/images/person-pc.png';
import { Button } from '../components/common/Button';
import { Card } from '../components/common/Card';
import { Input } from '../components/common/Input';
import { Title } from '../components/common/Title';
import { Head } from '../components/Head';
import { CustomFormik } from '../components/CustomFormik';
import { formatTime, translateErrorCode } from '../utils';
import { callResetAgentPasswordConfirmed } from '../apis/authentications';
import { config } from '../config';
import { AppContext } from '../components/Context';
import { ROUTE, SMS_CODE_LENGTH } from '../constants';
import { BaseErrorType } from '../types';
import { Layout } from '../components/Layout';

interface ResetPasswordFormData {
    password: string;
    confirmPassword: string;
    smsCode: string;
}

export const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const ctx = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<BaseErrorType[]>([]);

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], t<string>('common.formErrors.passwordDontMatch'))
            .required(),
        smsCode: Yup.string()
            .required()
            .matches(/^[0-9]+$/, t<string>('pages.contactConfirmation.smsCodeOnlyDigits'))
            .length(SMS_CODE_LENGTH, t<string>('pages.contactConfirmation.smsCodeLength', { length: SMS_CODE_LENGTH })),
    });

    const [count, { startCountdown }] = useCountdown({
        countStart: config.PASSWORD_RESET_LIMIT,
    });

    useEffect(() => {
        // v url nejsou požadované hodnoty
        if (!searchParams.get('tokenId') || !searchParams.get('tokenData')) {
            navigate(ROUTE.ERROR_404);
        }
        // start timer
        startCountdown();
        // eslint-disable-next-line
    }, []);

    // Navigate to login page after config.PASSWORD_RESET_LIMIT seconds
    useEffect(() => {
        if (count <= 0) {
            navigate('/');
        }
    }, [count, navigate]);

    // is errpr for new password input
    const errorCodeForNewPassword = (errorCode: string): boolean => {
        return (
            errorCode !== 'err_auth_code_invalid' &&
            errorCode !== 'err_auth_code_expired' &&
            errorCode !== 'err_auth_code_used' &&
            errorCode !== 'err_auth_code_too_many_attempts'
        );
    };

    const newPasswordErrorMessage = useMemo(() => {
        const errorCode = errors?.find((err) => errorCodeForNewPassword(err.ErrorCode))?.ErrorCode;
        return errorCode ? t(translateErrorCode(errorCode, 'common.formErrors.invalidPassword')) : null;
    }, [errors, t]);

    const codeErrorMessage = useMemo(() => {
        const errorCode = errors?.find((err) => !errorCodeForNewPassword(err.ErrorCode))?.ErrorCode;
        return errorCode ? t(translateErrorCode(errorCode)) : null;
    }, [errors, t]);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <div className="flex w-full flex-col items-center">
                <Head heading1={t('pages.resetPassword.title')} heading2={t('pages.resetPassword.subtitle')} />

                <div className="flex w-full flex-col justify-center md:flex-row">
                    <img
                        src={Image}
                        alt=""
                        className="h-auto w-[9.375rem] grow-0 self-center md:w-[16rem] lg:w-[30rem]"
                    />

                    <Card className="w-full max-w-[30rem] p-4 md:w-full md:p-8 mdmax:mx-auto">
                        <Title tag="h3" size="lg" fontWeight="medium" className="mb-6 ml-4">
                            {t('pages.resetPassword.card.title')}
                        </Title>

                        <CustomFormik<ResetPasswordFormData>
                            initialValues={{
                                password: '',
                                confirmPassword: '',
                                smsCode: '',
                            }}
                            onSubmit={(values, actions) => {
                                setErrors([]);
                                setLoading(true);
                                callResetAgentPasswordConfirmed({
                                    data: {
                                        tokenExternalId: searchParams.get('tokenId')!,
                                        tokenData: searchParams.get('tokenData')!,
                                        authorizationCode: values?.smsCode,
                                        newPassword: values?.password,
                                    },
                                })
                                    .then(() => ctx.showPopup('reset-password-done'))
                                    .catch((err) => {
                                        setErrors(err.response.data.Errors);
                                        if (err.response.data.Errors.length === 0) {
                                            ctx.showPopup('reset-password-error');
                                            actions?.resetForm();
                                        }
                                    })
                                    .finally(() => setLoading(false));
                            }}
                            validationSchema={validationSchema}
                        >
                            <div className="flex flex-col gap-y-4">
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.password')}:`}
                                    name="password"
                                />
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.confirmPassword')}:`}
                                    name="confirmPassword"
                                    errorMessage={newPasswordErrorMessage}
                                />

                                <div>
                                    <Field
                                        component={Input}
                                        type="smsCode"
                                        name="smsCode"
                                        maxLength={SMS_CODE_LENGTH}
                                        label={t('common.codeFromSms')}
                                        errorMessage={codeErrorMessage}
                                    />

                                    {count > 0 && (
                                        <div className="mt-4 pl-4">
                                            {t('common.resendSmsIn')} {formatTime(count).minutes}:
                                            {formatTime(count).seconds}
                                        </div>
                                    )}
                                </div>

                                <Button className="mx-auto" isSubmit isDisabled={loading} onClick={() => setErrors([])}>
                                    {t('common.resetPassword')}
                                </Button>
                            </div>
                        </CustomFormik>
                    </Card>
                </div>
            </div>
        </Layout>
    );
};
