import { ContactPage } from './ContactPage';
import { Context } from './ContactContext';

export const ContactsCheck = (props: any) => {
    return (
        <Context>
            <ContactPage {...props} />
        </Context>
    );
};
