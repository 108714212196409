import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { AgentPersonalDataType } from '../../types';

interface CallGetAgentPersonalDataProps {
    data: {
        agentIdentityLoginExternalId: string;
    };
    token: string;
}

interface CallGetAgentPersonalDataResponse {
    AgentPersonalDataPlDto: AgentPersonalDataType;
}

export const callGetAgentPersonalData = ({
    data,
    token,
}: CallGetAgentPersonalDataProps): Promise<AxiosResponse<CallGetAgentPersonalDataResponse, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}support/get-agent-personal-data/${data.agentIdentityLoginExternalId}`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
