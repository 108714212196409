import { Dispatch, SetStateAction } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from './Icon';

interface MenuItemProps {
    text: string;
    icon?: string;
    link?: string;
    setMenuActive: Dispatch<SetStateAction<boolean>>;
    onClick?: () => void;
    className?: string;
}

export const MenuItem = ({ icon, text, link, onClick, setMenuActive, className, ...props }: MenuItemProps) => {
    const Tag = onClick && !link ? 'div' : RouterLink;
    const newLink = link || '/';

    return (
        <li
            className={className}
            onClick={() => {
                onClick && onClick();
                setMenuActive(false);
            }}
            {...props}
        >
            <Tag
                className={cn(
                    'flex items-center gap-x-3 rounded-[0.25rem] p-2 text-sm text-darkPurple [&:hover]:bg-lightPurple'
                )}
                to={newLink}
            >
                {icon && <Icon className="w-6 text-gray" name={icon} />}
                {text}
            </Tag>
        </li>
    );
};
