import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Link } from './Link';
import { Icon } from '../Icon';

interface DownloadItemProps {
    name: string;
    url: string;
}

interface DownloadListProps {
    items: Array<DownloadItemProps>;
    className?: string;
}

export const DownloadList = ({ items, className }: DownloadListProps) => {
    const { t } = useTranslation();

    const onClick = (url: string) => {
        window.open(url, '_blank');
    };

    if (items && Array.isArray(items) && items.length > 0) {
        return (
            <ul className={cn(className, 'm-0 flex w-full list-none flex-col p-0')} data-test="documentsList">
                {items.map((item, i) => (
                    <li key={i} className="flex items-center gap-x-4" data-test="documentsItem">
                        <Icon name="file" className="smmax:hidden" data-test="documentIcon" />

                        <div
                            className={cn(
                                'flex flex-1 justify-between gap-x-2 border-b-purple py-5',
                                i < items.length - 1 && 'border-b-1'
                            )}
                        >
                            <span className="block" data-test="documentName">
                                {item.name}
                            </span>
                            <Link
                                variant="dark"
                                className="ml-auto"
                                onClick={() => onClick(item.url)}
                                data-test="documentLink"
                            >
                                {t('common.download')}
                            </Link>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
    return null;
};
