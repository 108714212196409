import React from 'react';
import { Field } from 'formik';
import { Card } from '../../components/common/Card';
import { Checkbox } from '../../components/common/Checkbox';
import CardTitle from './CardTitle';

const CorrectCard = () => {
    return (
        <Card hasPadding={false} className="p-5 px-7 pb-8">
            <CardTitle>Pravdivost informace</CardTitle>
            <div className="grid gap-y-2">
                <Field
                    component={Checkbox}
                    label="Potvrzuji, že vyplněné údaje jsou pravdivé, úplné a správné."
                    name="correct"
                />
            </div>
        </Card>
    );
};

export default CorrectCard;
