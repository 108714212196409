import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

import { SuggestedProtectionProps, SuggestedProtectionVariantProps } from '../../types/model';
import { RiderGroupFamiliesProps } from '../../pages/PackageAdjustment/PackageAdjustment';
import { Field } from 'formik';
import { Checkbox } from '../common/Checkbox';
import { Help } from '../common/Help';
import { Input } from '../common/Input';
import { PL_CURRENCY, ROUTE } from '../../constants';
import { Slider } from '../common/Slider';
import { AppContext } from '../Context';
import { getValueInVariantRange } from '../../utils';
import { thousandSeparator } from '../../utils/hooks';
import ChevronIndicator from './ChevronIndicator';
import { SectionCode } from '../../pages/consultant/Dashboard/components/Knowledge/types';
import { Link } from '../common/Link';
import Period from './Period';
import TotalBenefit from './TotalBenefit';
import MonthlyPremium from './MonthlyPremium';

interface CoverageBoxItemProps {
    group: RiderGroupFamiliesProps;
    item: SuggestedProtectionProps;
    values?: any;
    variant: SuggestedProtectionVariantProps;
    index: number;
    isLast?: boolean;
    isDisabled?: boolean;
    isEnterAllowed?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    setBlockReset: Dispatch<SetStateAction<boolean>>;
}

const CoverageBoxItem = ({
    group,
    item,
    values,
    variant,
    index,
    isLast = false,
    isDisabled = false,
    isEnterAllowed = true,
    onChange,
    setBlockReset,
}: CoverageBoxItemProps) => {
    const { t, i18n } = useTranslation();
    const itemRef = useRef<HTMLDivElement | null>(null);
    const [sliderOpened, setSliderOpened] = useState(false);
    const { coverChangedCategories, coverChangedVariants, setCoversChanged } = useContext(AppContext);

    const currentRiderCode = variant.SuggestedRiderVersion.Code;
    const { SumInsuredMin, SumInsuredMax } = variant;

    const isSelected = values[currentRiderCode];
    const value = values?.[`${currentRiderCode}Slider`] ?? '0';

    const [isOpen, setIsOpen] = useState<{ [key: string]: boolean } | undefined>();

    const canSliderOpen = SumInsuredMin !== SumInsuredMax;

    const [sliderValue, setSliderValue] = useState(value);

    useEffect(() => {
        if (!sliderOpened) {
            setSliderValue(() => value);
        }
    }, [value, sliderOpened]);

    const closeSlider = useCallback(() => {
        // nová hodnota
        const newValue = getValueInVariantRange(sliderValue, variant);
        if (sliderValue !== newValue) {
            setSliderValue(newValue);
        }
        // validace hodnoty
        onChange({
            target: {
                name: `${currentRiderCode}Slider`,
                value: newValue.toString(),
            },
        } as any);
        // zavření slideru
        setSliderOpened(false);
        // eslint-disable-next-line
    }, [values, sliderValue, SumInsuredMin, SumInsuredMax]);

    const onChangeCover = useCallback(
        () =>
            setCoversChanged(
                !coverChangedCategories.includes(group.Code)
                    ? [...coverChangedCategories, group.Code]
                    : coverChangedCategories,
                !coverChangedVariants.includes(currentRiderCode)
                    ? [...coverChangedVariants, currentRiderCode]
                    : coverChangedVariants
            ),
        // eslint-disable-next-line
        [coverChangedCategories, coverChangedVariants, group.Code, currentRiderCode]
    );

    const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
            setSliderValue(() => getValueInVariantRange(sliderValue, variant));
        }
        if (!checked && sliderOpened) {
            closeSlider();
        }
        onChangeCover();
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSliderValue(e.target.value);
        onChangeCover();
    };

    const sliderValueContent = useMemo(() => {
        if (!canSliderOpen || isDisabled || !isEnterAllowed) {
            return <div className="flex items-center">{thousandSeparator(sliderValue)}</div>;
        }

        return (
            <div className={'cursor-pointer underline'} onClick={() => setSliderOpened(true)}>
                {thousandSeparator(sliderValue)}
            </div>
        );
    }, [sliderValue, canSliderOpen, isDisabled, isEnterAllowed]);

    useEffect(() => {
        if (itemRef && sliderOpened) {
            const handleClickOutside = (event: Event) => {
                if (itemRef.current && !itemRef.current.contains(event.target as Node)) {
                    setTimeout(closeSlider, 130);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [itemRef, sliderOpened, closeSlider]);

    useEffect(() => {
        setBlockReset(sliderOpened);
        // eslint-disable-next-line
    }, [sliderOpened]);

    const dataTestTag = `coverBox[${group.Code}]Item[${currentRiderCode}]`;

    const getGroupOrderNo = useCallback(
        (code: string) => group.RiderGroups.find((g) => g.Code === code)?.OrderNo,
        [group]
    );

    const onClickOpenProductSpecification = (i: number) => {
        window.open(
            `${ROUTE.DASHBOARD_KNOWLEDGE.replace(':section', SectionCode.PRODUCT_SPECIFICATION)}#${i}`,
            '_blank'
        );
    };

    const isOpened = useMemo(
        () => isOpen?.[currentRiderCode] === undefined || isOpen[currentRiderCode],
        [isOpen, currentRiderCode]
    );

    return (
        <div ref={itemRef} data-test={dataTestTag}>
            {/* Togleable variant title */}
            {item.Variants.length > 1 && (
                <div
                    className="flex cursor-pointer select-none items-center gap-x-4 px-6 py-4 font-medium last:rounded-b-sm"
                    onClick={() =>
                        setIsOpen((prevState) => {
                            const currentCode = currentRiderCode;
                            const val = prevState?.[currentCode];

                            return {
                                ...prevState,
                                [currentCode]: val === undefined ? false : !val,
                            };
                        })
                    }
                    data-test={`coverBox[${group.Code}][${currentRiderCode}]`}
                >
                    <ChevronIndicator isActive={isOpened} />
                    {currentRiderCode}:
                </div>
            )}
            <AnimatePresence>
                {isOpened && (
                    <motion.div
                        initial={{
                            height: 0,
                            opacity: 0,
                        }}
                        animate={{
                            height: 'auto',
                            opacity: 1,
                        }}
                        exit={{
                            height: 0,
                            opacity: 0,
                        }}
                    >
                        <div className={cn('border-b-2 border-white ', !values?.[currentRiderCode] && 'bg-lightGray')}>
                            <div
                                className={cn(
                                    'grid grid-cols-4 items-center px-4 pb-3 text-center text-xs lg:grid-cols-7 lg:px-6 lg:pb-0 lg:text-sm',
                                    isLast && 'rounded-b-sm'
                                )}
                            >
                                <div
                                    className={cn(
                                        item.Variants.length > 1 && 'pl-8',
                                        'col-span-4 my-4 flex flex-row justify-between gap-x-2 text-left lg:col-span-3 lg:justify-start'
                                    )}
                                    data-test={`${dataTestTag}Cover`}
                                >
                                    <Field
                                        component={Checkbox}
                                        name={currentRiderCode}
                                        label={`${getGroupOrderNo(currentRiderCode)}. ${t(
                                            `pages.packageAdjustment.cover.${currentRiderCode}.label`
                                        )}`}
                                        disabled={isDisabled || item.IsMandatory}
                                        onChange={onChangeCheckbox}
                                        data-test={`${dataTestTag}Cover`}
                                    />
                                    {i18n.exists(`pages.packageAdjustment.cover.${currentRiderCode}.tooltip`) && (
                                        <Help
                                            text={
                                                <ul className="m-1 p-4 py-1">
                                                    <Trans
                                                        i18nKey={`pages.packageAdjustment.cover.${currentRiderCode}.tooltip`}
                                                        components={{
                                                            ul: <ul>.</ul>,
                                                            li: <li>.</li>,
                                                        }}
                                                    />
                                                    <li>
                                                        <Trans
                                                            i18nKey="pages.packageAdjustment.tooltipsProductSpecificationLink"
                                                            components={{
                                                                link1: (
                                                                    <Link
                                                                        className="font-medium text-black underline"
                                                                        onClick={() =>
                                                                            onClickOpenProductSpecification(index)
                                                                        }
                                                                    >
                                                                        .
                                                                    </Link>
                                                                ),
                                                            }}
                                                        />
                                                    </li>
                                                </ul>
                                            }
                                            width="large"
                                            data-test={`${dataTestTag}Help`}
                                        />
                                    )}
                                    <small className="hidden">{currentRiderCode}</small>
                                </div>

                                <Period variant={variant} data-test={dataTestTag} />

                                <div data-test={`${dataTestTag}Amount`}>
                                    {values?.[currentRiderCode] && variant.SumInsuredChosen >= 0 ? (
                                        <div className="flex items-center justify-center font-medium">
                                            {sliderOpened ? (
                                                <Field
                                                    component={Input}
                                                    type="number"
                                                    name={`${currentRiderCode}Slider`}
                                                    value={sliderValue}
                                                    fieldClassName="w-full min-w-[5rem] lg:min-w-[6rem]"
                                                    max={SumInsuredMax * 10}
                                                    isCenter
                                                    withoutHelperText
                                                    thousandSeparator
                                                    staticValue
                                                    size="small"
                                                    onChange={onChangeInput}
                                                    data-test={`${dataTestTag}Amount`}
                                                />
                                            ) : (
                                                sliderValueContent
                                            )}
                                            <div className="ml-2 mdmax:hidden lg:ml-4">{PL_CURRENCY}</div>
                                        </div>
                                    ) : (
                                        t('common.notConcluded')
                                    )}
                                </div>

                                <TotalBenefit
                                    group={group}
                                    item={item}
                                    currentRiderCode={currentRiderCode}
                                    coverChangedCategories={coverChangedCategories}
                                    isSelected={isSelected}
                                    data-test={dataTestTag}
                                />

                                <MonthlyPremium
                                    variant={variant}
                                    currentRiderCode={currentRiderCode}
                                    coverChangedVariants={coverChangedVariants}
                                    isSelected={isSelected}
                                    data-test={dataTestTag}
                                />

                                {sliderOpened && (
                                    <Slider
                                        className="col-span-4 mt-3 lg:col-span-7"
                                        name={`${currentRiderCode}Slider`}
                                        initialValue={(sliderValue as number) ?? variant.SumInsuredChosen}
                                        minValue={SumInsuredMin}
                                        maxValue={SumInsuredMax}
                                        parent={group.NameTranslated}
                                        child={currentRiderCode}
                                        onChange={onChangeInput}
                                        data-test={`${dataTestTag}Slider`}
                                    />
                                )}
                            </div>
                            {sliderOpened &&
                                i18n.exists(`pages.packageAdjustment.cover.${currentRiderCode}.instructions`) && (
                                    <div className="p-2 text-center">
                                        <small
                                            dangerouslySetInnerHTML={{
                                                __html: t<string>(
                                                    `pages.packageAdjustment.cover.${currentRiderCode}.instructions`
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CoverageBoxItem;
