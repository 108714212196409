import React, { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CategoryIcon } from '../../../../../assets/images/category.svg';
import { ReactComponent as ContractsIcon } from '../../../../../assets/images/contracts.svg';
import { ReactComponent as DraftsIcon } from '../../../../../assets/images/edit.svg';
import { ReactComponent as ClientsIcon } from '../../../../../assets/images/clients.svg';
import { ReactComponent as ContactsIcon } from '../../../../../assets/images/contacts.svg';
import { ReactComponent as KnowledgeIcon } from '../../../../../assets/images/knowledge.svg';
import { ReactComponent as HospitalIcon } from '../../../../../assets/images/hospital.svg';
import { AGENT_PORTAL_RIGHTS, ROUTE } from '../../../../../constants';
import { AppContext } from '../../../../../components/Context';
import { hasPermission } from '../../../../../utils/hooks';
import DashboardMenuItem from './DashboardMenuItem';
import { SectionCode } from '../Knowledge/types';
import { isDevelopment } from '../../../../../utils';

export interface MenuItem {
    name: string;
    path: string;
    icon: ReactNode;
    label: string;
    isDisabled?: boolean;
    isHidden?: boolean;
}

const DashboardMenu = () => {
    const ctx = useContext(AppContext);
    const { userRights } = ctx;
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const links: MenuItem[] = [
        {
            name: 'main',
            path: ROUTE.DASHBOARD_MAIN,
            icon: <CategoryIcon />,
            label: t('dashboard.menu.main'),
        },
        {
            name: 'myPolicy',
            path: ROUTE.DASHBOARD_MY_POLICY,
            icon: <ContractsIcon />,
            label: t('dashboard.menu.myContracts'),
            isDisabled: !hasPermission(AGENT_PORTAL_RIGHTS.POLICY_LIST, userRights),
        },
        {
            name: 'agencyPolicy',
            path: ROUTE.DASHBOARD_AGENCY_POLICY,
            icon: <ContractsIcon />,
            label: t('dashboard.menu.agencyContracts'),
            isHidden: !hasPermission(AGENT_PORTAL_RIGHTS.POLICY_LIST_AGENCY, userRights),
        },
        {
            name: 'waitingCase',
            path: ROUTE.DASHBOARD_WAITING_CASE,
            icon: <DraftsIcon />,
            label: t('dashboard.menu.waitingCase'),
        },
        {
            name: 'drafts',
            path: ROUTE.DASHBOARD_DRAFTS,
            icon: <DraftsIcon />,
            label: t('dashboard.menu.drafts'),
            isDisabled: !hasPermission(AGENT_PORTAL_RIGHTS.DRAFT_LIST, userRights),
        },
        {
            name: 'myClients',
            path: ROUTE.DASHBOARD_MY_CLIENTS,
            icon: <ClientsIcon />,
            label: t('dashboard.menu.myClients'),
            isDisabled: !hasPermission(AGENT_PORTAL_RIGHTS.CLIENT_LIST, userRights),
        },
        {
            name: 'agencyClients',
            path: ROUTE.DASHBOARD_AGENCY_CLIENTS,
            icon: <ClientsIcon />,
            label: t('dashboard.menu.agencyClients'),
            isHidden: !hasPermission(AGENT_PORTAL_RIGHTS.CLIENT_LIST_AGENCY, userRights),
        },
        {
            name: 'damageEvent',
            path: ROUTE.DAMAGE_EVENT,
            icon: <HospitalIcon />,
            label: 'Škodní události',
            isHidden: !isDevelopment(),
        },
        {
            name: 'knowledge',
            path: ROUTE.DASHBOARD_KNOWLEDGE.replace(':section', SectionCode.PRODUCT_SPECIFICATION),
            icon: <KnowledgeIcon />,
            label: t('dashboard.menu.knowledge'),
        },
        {
            name: 'contacts',
            path: ROUTE.DASHBOARD_CONTACTS,
            icon: <ContactsIcon />,
            label: t('dashboard.menu.contacts'),
        },
    ];

    const isActive = (route: string): boolean =>
        pathname === route || (pathname.includes('/dashboard/knowledge/') && route.includes('/dashboard/knowledge/'));

    return (
        <ul className="dashboard__menu m-0 list-none p-0" data-test="dashboardMenu">
            {links
                .filter((link) => link.isHidden !== true)
                .map((link) => (
                    <DashboardMenuItem
                        key={link.path}
                        item={link}
                        isActive={isActive(link.path)}
                        data-test={`dashboardMenu-${link.name}`}
                    />
                ))}
        </ul>
    );
};

export default DashboardMenu;
