import React, { MouseEvent, ReactNode } from 'react';
import cn from 'classnames';

interface FooterMobileMenuItemProps {
    className?: string;
    disabled?: boolean;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
}

const FooterMobileMenuItem = ({ className, disabled = false, onClick, children }: FooterMobileMenuItemProps) => {
    return (
        <div
            className={cn(
                'cursor-pointer p-3 [&:hover]:bg-lightPurple',
                className,
                disabled && 'pointer-events-none opacity-50'
            )}
            onClick={(e: MouseEvent<HTMLDivElement>) => !disabled && onClick && onClick(e)}
            data-test="mobileMenuItem"
        >
            {children}
        </div>
    );
};

export default FooterMobileMenuItem;
