import React from 'react';

interface AddressProps {
    street: string | null;
    houseNumber: string | null;
    city: string | null;
    zipCode: string | null;
    countryCode: string | null;
}

const Address = ({ street, houseNumber, city, zipCode, countryCode }: AddressProps) => {
    const row1 = `${street || ''} ${houseNumber || ''}`.trim();
    const row2 = `${zipCode || ''} ${city || ''}`.trim();
    const row2x = `${countryCode && row2 ? ', ' : ''}${countryCode}`.trim();

    return <span dangerouslySetInnerHTML={{ __html: [row1, row2 + row2x].filter((r) => r !== '').join('<br />') }} />;
};

export default Address;
