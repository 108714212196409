import React, { Fragment, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import PopupWrapper from '../common/PopupWrapper';
import { Title } from '../common/Title';
import Ear from '../../assets/images/ear.png';
import { Card } from '../common/Card';
import { Radio } from '../common/Radio';
import { ReactComponent as SaveIcon } from '../../assets/images/save-2.svg';
import { Button } from '../common/Button';
import { AppContext } from '../Context';
import { onClickOpenDocument } from '../../pages/consultant/Dashboard/components/Knowledge/Documents';
import { Link } from '../common/Link';
import { KnowledgeDocumentFileName } from '../../pages/consultant/Dashboard/components/Knowledge/types';

const IsPepDialog = () => {
    const { t } = useTranslation();
    const { setPredefinedIsPep } = useContext(AppContext);

    return (
        <PopupWrapper
            popupType="is-pep-popup"
            color="yellow"
            data-test="isPepDialog"
            className="max-h-full overflow-auto"
        >
            <div className="flex flex-col items-center justify-center gap-y-6">
                <img src={Ear} alt="" className="mx-auto mb-3 h-auto w-[8.125rem]" data-test="popupImage" />

                <Title tag="h2" size="md" fontWeight="medium" isCenter className="mb-0" data-test="title">
                    {t('pages.isPep.title')}
                </Title>

                <p className="mb-2" data-test="isPepDialogDescription">
                    <Trans
                        i18nKey="pages.isPep.description"
                        components={{
                            link1: (
                                <a
                                    href="https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20210001381/O/D20211381.pdf"
                                    className="font-light text-purple underline"
                                    target="_blank"
                                    data-test="isPepDialogKnowledgeLink"
                                    rel="noreferrer"
                                >
                                    .
                                </a>
                            ),
                            link2: (
                                <Link
                                    onClick={onClickOpenDocument(KnowledgeDocumentFileName.DefinicjaPep)}
                                    className="font-light text-purple underline"
                                    data-test="definitionPep"
                                >
                                    .
                                </Link>
                            ),
                        }}
                    />
                </p>

                <Formik
                    initialValues={{ isPep: undefined }}
                    onSubmit={(v) => {
                        setPredefinedIsPep(v.isPep === 'yes');
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <Fragment>
                            <Card
                                className="w-full border-1 border-purple p-5 py-[10px]"
                                hasPadding={false}
                                data-test="isPepDialogCard"
                            >
                                <div className="flex justify-between">
                                    <span data-test="isPepSelect">{t('pages.isPep.isPep')}</span>
                                    <div className="flex flex-row gap-x-5">
                                        <Field
                                            component={Radio}
                                            type="radio"
                                            name="isPep"
                                            value="yes"
                                            label={t('common.yes')}
                                            data-test="isPepYes"
                                        />
                                        <Field
                                            component={Radio}
                                            type="radio"
                                            name="isPep"
                                            value="no"
                                            label={t('common.no')}
                                            data-test="isPepNo"
                                        />
                                    </div>
                                </div>
                            </Card>
                            <Card
                                className="w-full border-1 border-purple bg-white p-5 py-[10px]"
                                hasPadding={false}
                                data-test="isPepQuestionnaireFile"
                            >
                                <div className="flex justify-between">
                                    <span>{t('pages.isPep.questionnaireFile')}</span>
                                    <div className="flex flex-row gap-x-5">
                                        <div
                                            className="flex cursor-pointer items-center gap-x-1 text-xs hover:underline"
                                            onClick={onClickOpenDocument(KnowledgeDocumentFileName.PepKwestionariusz)}
                                            data-test="isPepQuestionnaireFileLink"
                                        >
                                            <SaveIcon className="w-5" />
                                            {t('common.download')}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <div className="w-full">
                                <Button
                                    size="md"
                                    isDisabled={values.isPep === undefined}
                                    className="ml-auto p-2 px-5 font-light"
                                    onClick={handleSubmit}
                                    data-test="isPepSubmit"
                                >
                                    {t('pages.isPep.submit')}
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </Formik>
            </div>
        </PopupWrapper>
    );
};

export default IsPepDialog;
