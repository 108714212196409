import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Head } from '../components/Head';
import { Card } from '../components/common/Card';
import Image from '../assets/images/alarm.png';
import { Link } from '../components/common/Link';
import { AppContext } from '../components/Context';
import { getHigherStep } from '../utils';
import { ROUTE, STEP_CODE } from '../constants';
import { useAppNavigate } from '../utils/hooks';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';
import AutopayPaymentMethods from '../assets/images/autopay-payment-methods.png';

export const FinishRemote = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const { navigateTo } = useAppNavigate();

    return (
        <Layout
            hideNavigation
            hideSaveButton
            continueText={t<string>('common.backToMainMenu')}
            continueCallback={() => {
                if (data) {
                    const newModel = {
                        ...data,
                        Settings: {
                            ...data.Settings,
                            CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.FINISH_REMOTE),
                        },
                    };
                    ctx.setCurrentModel(newModel);
                    ctx.saveCurrentModel(newModel);
                    navigateTo(ROUTE.DASHBOARD_MAIN);
                }
            }}
        >
            <LimitedAccess minStep={STEP_CODE.SUMMARY}>
                <Head heading1={t('pages.finalRemote.title')} heading2={t('pages.finalRemote.subtitle')} />

                <Card data-test="finishCard">
                    <p className="mb-8" data-test="finishText1">
                        {t('pages.finalRemote.card.clientInfo')}
                    </p>
                    <p className="mb-4" data-test="finishText1.1">
                        {t('pages.finalRemote.card.paymentInfo')}
                    </p>

                    <div className="mb-4 flex flex-row justify-center gap-x-5" data-test="finishAutopayMethodsBox">
                        <img src={AutopayPaymentMethods} alt="Autopay" data-test="finishAutopayMethods" />
                    </div>
                    <div className="mb-4 flex items-center justify-center" data-test="entityProvidingOnlinePayment">
                        <Trans
                            i18nKey="pages.autopayMethod.entityProvidingOnlinePayment"
                            components={{
                                link1: (
                                    <a
                                        href="https://autopay.pl/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="ml-1 underline"
                                    >
                                        .
                                    </a>
                                ),
                            }}
                        />
                    </div>
                    <div className="mb-8 flex items-center justify-center" data-test="entityProvidingOnlinePayment">
                        <Trans
                            i18nKey="pages.finalRemote.card.entityProvidingOnlinePayment"
                            components={{
                                link1: (
                                    <a
                                        href="https://www.simplea.pl/pl/informacje-dotyczace-platnosci/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="ml-1 underline"
                                    >
                                        .
                                    </a>
                                ),
                            }}
                        />
                    </div>

                    <p className="mb-8" data-test="finishText2">
                        {t('pages.finalRemote.card.noticeInfo')}
                    </p>
                    <p className="mb-8" data-test="finishText3">
                        <Trans
                            i18nKey="pages.finalRemote.card.statusInfo"
                            components={{
                                link1: (
                                    <Link to={ROUTE.DASHBOARD_MY_POLICY} data-test="finishLink">
                                        .
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <img src={Image} alt="" className="h-auto w-[10.8rem] grow-0 self-center" data-test="finishImage" />
                </Card>
            </LimitedAccess>
        </Layout>
    );
};
