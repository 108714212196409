import React from 'react';
import { ReactComponent as NumberIcon } from '../../assets/images/number.svg';
import { Field } from 'formik';
import { Radio } from '../../components/common/Radio';

interface QuestionProps {
    name: string;
    text: string;
}

const Question = ({ name, text }: QuestionProps) => {
    return (
        <div className="flex">
            <div className="flex w-[75%] items-center">
                <div className="mr-3 h-[20px] w-[20px]">
                    <NumberIcon />
                </div>
                <div className="w-[calc(100%-30px)]">{text}</div>
            </div>
            <div className="flex w-[25%] gap-x-5">
                <Field component={Radio} type="radio" name={name} label="Ano" value="yes" />
                <Field component={Radio} type="radio" name={name} label="Ne" value="no" />
            </div>
        </div>
    );
};

export default Question;
