import React, { ChangeEvent } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { BeneficiariesFormDataType, BeneficiaryPersonDataType } from './types';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Icon } from '../../components/Icon';
import { Grid } from '../../components/Grid';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import { Link } from '../../components/common/Link';

interface BeneficiariesListProps {
    outOfLimit: boolean;
}

const BeneficiariesList = ({ outOfLimit }: BeneficiariesListProps) => {
    const { t } = useTranslation();
    const formikContext = useFormikContext<BeneficiariesFormDataType>();
    const { values, setFieldValue } = formikContext;

    const handleAdd = () => {
        setFieldValue(
            'beneficiaries',
            [
                ...values.beneficiaries,
                {
                    name: '',
                    surName: '',
                    pesel: '',
                    percentageShare: 0,
                },
            ],
            false
        );

        if (values.beneficiariesType !== 'byExactDetails') {
            setFieldValue('beneficiariesType', 'byExactDetails');
        }
    };

    const handleRemove = (index: number) => {
        setFieldValue(
            'beneficiaries',
            values.beneficiaries.filter((b, i) => i !== index)
        );
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, name: string, index: number) => {
        const { value } = e.target;
        setFieldValue(
            'beneficiaries',
            values.beneficiaries.map((b, i) => (i === index ? { ...b, [name]: value } : b))
        );
    };

    return (
        <div className="mt-8 flex flex-col gap-y-8" data-test="beneficiariesList">
            {values.beneficiaries.map((b: BeneficiaryPersonDataType, index: number) => (
                <Card key={index} className="relative mdmax:pt-8" data-test={`beneficiariesListItem[${index}]`}>
                    <div className="mx-auto mb-8 flex gap-x-9">
                        <Icon
                            name="avatar-light"
                            className="text-purple"
                            data-test={`beneficiariesListItem[${index}]Icon`}
                        />
                        <Title
                            tag="h3"
                            size="md"
                            fontWeight="medium"
                            data-test={`beneficiariesListItem[${index}]Title`}
                        >
                            {index + 1}. {t('common.person')}
                        </Title>
                    </div>
                    <Grid>
                        <Field
                            isCenter
                            name={`name-${index}`}
                            label={t('common.formFields.name')}
                            component={Input}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'name', index)}
                            data-test={`beneficiariesListItem[${index}]Name`}
                        />
                        <Field
                            isCenter
                            name={`surName-${index}`}
                            label={t('common.formFields.surname')}
                            component={Input}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'surName', index)}
                            data-test={`beneficiariesListItem[${index}]SurName`}
                        />
                        <Field
                            isCenter
                            name={`pesel-${index}`}
                            label={t('common.formFields.pesel')}
                            component={Input}
                            maxLength={50}
                            isStringNumber
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'pesel', index)}
                            data-test={`beneficiariesListItem[${index}]Pesel`}
                        />
                        <Field
                            isCenter
                            name={`percentageShare-${index}`}
                            label={`${t('common.formFields.percentageShare')} (%)`}
                            component={Input}
                            min={0}
                            max={100}
                            type="number"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, 'percentageShare', index)}
                            data-test={`beneficiariesListItem[${index}]PercentageShare`}
                        />
                    </Grid>

                    <Link
                        className="absolute right-3 top-3 font-medium md:right-7 md:top-7"
                        variant="dark"
                        icon="trash"
                        onClick={() => {
                            handleRemove(index);
                        }}
                        data-test={`beneficiariesListItem[${index}]Remove`}
                    >
                        {t('common.remove')}
                    </Link>
                </Card>
            ))}
            {!outOfLimit && (
                <Button isOutlined onClick={() => handleAdd()} className="mr-auto" data-test="beneficiariesListAddItem">
                    {t('common.addBeneficiary')}
                </Button>
            )}
        </div>
    );
};

export default BeneficiariesList;
