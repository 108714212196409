import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Icon } from '../../components/Icon';
import { Grid } from '../../components/Grid';
import { Select } from '../../components/common/Select';
import { Input } from '../../components/common/Input';
import { getFormatedAddress, scrollTo } from '../../utils';
import { AddressProps } from '../../types/model';
import { Switch } from '../../components/common/Switch';
import { BeneficiariesFormDataType } from './types';
import { AppContext } from '../../components/Context';

export interface BankDataProps {
    Name: string;
    SwiftCode: string;
    Nip: string;
    Regon: string;
    OrderNo: number;
    ContactAddress: AddressProps;
}

type BankSelectMethodProps = 'regonNumber' | 'taxIdNumber' | 'companyName';

interface BankDataFormProps {
    outOfLimit: boolean;
}

const BankDataForm = ({ outOfLimit }: BankDataFormProps) => {
    const { t } = useTranslation();
    const refBankForm = useRef<HTMLDivElement>(null);
    const { initData } = useContext(AppContext);
    const [bankSelectMethod, setBankSelectMethod] = useState<BankSelectMethodProps | null>(null);

    const bankData = useMemo(() => initData.Banks as Array<BankDataProps>, [initData]);

    const formikContext = useFormikContext<BeneficiariesFormDataType>();
    const { values, setFieldValue } = formikContext;
    const { cession } = values || {};

    const visible = useMemo(() => cession === true, [cession]);

    const handleBankChange = (data: { name: BankSelectMethodProps; value: string }) => {
        const { name, value } = data;
        let bank: BankDataProps | undefined;

        switch (name) {
            case 'taxIdNumber':
                bank = bankData.find((b) => b.Nip === value);
                break;
            case 'companyName':
                bank = bankData.find((b) => b.Name === value);
                break;
            case 'regonNumber':
                bank = bankData.find((b) => b.Regon === value);
                break;
        }

        if (bank) {
            setFieldValue('bank', bank);
            setFieldValue('taxIdNumber', bank.Nip);
            setFieldValue('regonNumber', bank.Regon);
            setFieldValue('companyName', bank.Name);
            setFieldValue('companyAddress', getFormatedAddress(initData, bank.ContactAddress));
        }

        if (!bank) {
            setFieldValue('bank', null);
            setFieldValue('taxIdNumber', '');
            setFieldValue('regonNumber', '');
            setFieldValue('companyName', '');
            setFieldValue('companyAddress', '-');
        }

        setBankSelectMethod(bank && name ? name : null);
    };

    const onChangeSwitch = (e: ChangeEvent<HTMLInputElement>) => {
        if (values?.beneficiariesType !== 'byExactDetails') {
            if (e.target.checked) {
                setFieldValue('beneficiariesType', 'byExactDetails');
            }
        }

        if (!e.target.checked) {
            setFieldValue('taxIdNumber', '');
            setFieldValue('regonNumber', '');
            setFieldValue('companyName', '');
            setFieldValue('companyAddress', '-');
            setFieldValue('percentageShare', '');
            setFieldValue('freeText', '');
            setBankSelectMethod(null);
        }

        formikContext.handleChange(e);

        if (e.target.checked) {
            setTimeout(() => scrollTo(refBankForm.current), 100);
        }
    };

    useEffect(() => {
        if (bankSelectMethod !== null && !cession) {
            setBankSelectMethod(null);
        }
    }, [bankSelectMethod, cession]);

    return (
        <div className="mt-12 flex flex-col gap-y-3" data-test="bank">
            <Title tag="h3" size="lg" data-test="bankTitle">
                {t('pages.beneficiaries.cession.title')}
            </Title>
            <Field
                name="cession"
                label={t('pages.beneficiaries.cession.input')}
                component={Switch}
                isDisabled={outOfLimit && !cession}
                onChange={onChangeSwitch}
                data-test="bankCession"
            />

            <div ref={refBankForm}>
                <div className={cn(!visible && 'hidden')}>
                    <p className="mb-4 mt-4 flex gap-x-4 text-xs" data-test="bankInfoText">
                        <Icon name="bulb" className="text-purple" />
                        {t('pages.beneficiaries.cession.infoText')}
                    </p>
                    <Card data-test="bankCard">
                        <Title
                            tag="strong"
                            size="md"
                            fontWeight="regular"
                            isCenter
                            className="mb-5"
                            data-test="bankCardTitle"
                        >
                            {t('pages.beneficiaries.cession.cardTitle')}
                        </Title>

                        <Grid cols={2} className="mb-8">
                            <Field
                                component={Select}
                                name="companyName"
                                label={t('common.formFields.companyName')}
                                isCenter
                                options={bankData.map((b) => ({
                                    label: b.Name,
                                    value: b.Name,
                                }))}
                                value={
                                    values.companyName ? { label: values.companyName, value: values.companyName } : null
                                }
                                handleChange={handleBankChange}
                                isSearchable
                                isDisabled={bankSelectMethod && bankSelectMethod !== 'companyName'}
                                isClearable
                                data-test="bankCompanyName"
                            />

                            <Field
                                component={Select}
                                name="taxIdNumber"
                                label={t('common.formFields.taxIdNumber')}
                                isCenter
                                options={bankData.map((b) => ({
                                    label: b.Nip,
                                    value: b.Nip,
                                }))}
                                value={
                                    values.taxIdNumber ? { label: values.taxIdNumber, value: values.taxIdNumber } : null
                                }
                                handleChange={handleBankChange}
                                isSearchable
                                isDisabled={bankSelectMethod && bankSelectMethod !== 'taxIdNumber'}
                                isClearable
                                data-test="bankTaxIdNumber"
                            />

                            <Field
                                component={Select}
                                name="regonNumber"
                                label={t('common.formFields.regonNumber')}
                                isCenter
                                options={bankData.map((b) => ({
                                    label: b.Regon,
                                    value: b.Regon,
                                }))}
                                value={
                                    values.regonNumber ? { label: values.regonNumber, value: values.regonNumber } : null
                                }
                                handleChange={handleBankChange}
                                isSearchable
                                isDisabled={bankSelectMethod && bankSelectMethod !== 'regonNumber'}
                                isClearable
                                data-test="bankRegionNumber"
                            />

                            <Field
                                isCenter
                                name="percentageShare"
                                label={`${t('common.formFields.percentageShare')} (%)`}
                                component={Input}
                                min={10}
                                max={100}
                                type="number"
                                data-test="bankPercentageShare"
                            />
                        </Grid>

                        <Field
                            isDisabled
                            component={Input}
                            name="companyAddress"
                            label={t('common.formFields.companyAddress')}
                            isCenter
                            className="mb-8"
                            data-test="bankCompanyAddress"
                        />

                        <Field
                            component={Input}
                            name="freeText"
                            label={t('common.formFields.freeText')}
                            isCenter
                            helpText={t('common.formFields.up120chars')}
                            maxLength={120}
                            data-test="bankFreeText"
                        />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default BankDataForm;
