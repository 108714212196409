import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallResetAgentPasswordConfirmedProps {
    data: {
        tokenExternalId: string;
        tokenData: string;
        authorizationCode: string;
        newPassword: string;
    };
}

export const callResetAgentPasswordConfirmed = (
    props: CallResetAgentPasswordConfirmedProps
): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/reset-agent-password-confirmation`,
        data: props.data,
    });
