import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import { Header } from './Header';
import { Footer } from './Footer';
import { Popup } from './Popup';
import { Link } from './common/Link';
import { routes } from '../routes';
import { isDevelopment } from '../utils';
import { ROUTE } from '../constants';
import { callLoadModelData } from '../apis/modelData';
import { callGetOnlinePolicyEvaluationResult } from '../apis/support';
import { useAuth } from '../utils/hooks';
import { AppContext } from './Context';
import { Button } from './common/Button';
import CheckInactivity from './CheckInactivity';
import CheckInitData from './CheckInitData';

interface LayoutProps {
    withoutPolicy?: boolean;
    hideNavigation?: boolean;
    hideProgressBar?: boolean;
    hideSaveButton?: boolean;
    hideUserMenu?: boolean;
    disableUserMenu?: boolean;
    hideFooter?: boolean;
    hideContinue?: boolean;
    continueText?: string;
    continueTooltip?: ReactNode;
    continueDisabled?: boolean;
    continueCallback?: () => void;
    headerContent?: ReactNode;
    footerContent?: ReactNode;
    footerClassName?: string;
    children: ReactNode;
}

export const Layout = ({
    withoutPolicy = false,
    hideNavigation = false,
    hideProgressBar = false,
    hideSaveButton = false,
    hideUserMenu = false,
    disableUserMenu = false,
    hideFooter = false,
    hideContinue = false,
    continueText,
    continueTooltip = null,
    // TODO: doplnit výchozí = false,
    continueDisabled,
    continueCallback,
    headerContent = null,
    footerContent = null,
    footerClassName,
    children,
}: LayoutProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;
    const { logout } = useAuth();
    const { popupType, currentModel, setCurrentModel, clearCurrentModel, speedyLogout, setSpeedyLogout } =
        useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [showTmpMenu, setShowTmpMenu] = useState(false);
    const [showModel, setShowModel] = useState(false);

    const contractId = searchParams.get('id');

    const stringifyCurrentModel = useMemo(() => JSON.stringify(currentModel, null, 2), [currentModel]);

    const handleLoad = useCallback((id: string) => {
        const token = localStorage.getItem('token');
        if (token && id) {
            const load = callLoadModelData({ data: { onlinePolicyExternalId: id }, token });
            const evaluation = callGetOnlinePolicyEvaluationResult({
                data: { onlineModelExternalId: id },
                token,
            });
            Promise.all([load, evaluation])
                .then(([loadResult, evaluationResult]) => {
                    setCurrentModel(loadResult?.data);
                    if (evaluationResult?.data?.ResultType !== 14) {
                        clearCurrentModel();
                        navigate(ROUTE.DASHBOARD_MAIN);
                    }
                })
                .catch((err) => {
                    switch (err?.response?.status) {
                        case 403:
                            logout().then(() => navigate(ROUTE.LOGIN));
                            break;
                        case 406:
                            clearCurrentModel();
                            navigate(ROUTE.CONCLUSION);
                            break;
                    }
                });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!withoutPolicy) {
            if (currentModel?.ExternalId && !contractId) {
                setSearchParams({ id: currentModel?.ExternalId });
            }
            if (!currentModel && contractId) {
                handleLoad(contractId);
            }
        }
        // eslint-disable-next-line
    }, [withoutPolicy, location, currentModel]);

    return (
        <>
            <div className="w-full overflow-hidden">
                {path !== ROUTE.ERROR_500 && (
                    <Header
                        hideNavigation={hideNavigation}
                        hideProgressBar={hideProgressBar}
                        hideSaveButton={hideSaveButton}
                        hideUserMenu={hideUserMenu}
                        disableUserMenu={disableUserMenu}
                        headerContent={headerContent}
                    />
                )}
                <main className="flex min-h-screen flex-col py-[5.375rem] md:py-[6.125rem]">
                    <div
                        className={cn(
                            'ml-auto mr-auto flex w-full max-w-md2 grow flex-col p-4 lg:px-0 lg:py-12',
                            path !== ROUTE.ERROR_500 && path !== ROUTE.ERROR_404 && 'my-auto'
                        )}
                    >
                        {children}
                    </div>
                </main>
                {isDevelopment() && (
                    <>
                        <div
                            onClick={() => setShowTmpMenu(!showTmpMenu)}
                            className="fixed bottom-0 left-0 z-100 flex h-12 w-12 cursor-pointer items-center justify-center bg-red text-center text-xxs text-white mdmax:h-8 mdmax:w-8 mdmax:text-[8px]"
                        >
                            {showTmpMenu ? 'close' : 'all pages'}
                        </div>

                        {showTmpMenu && (
                            <div className="fixed bottom-12 left-0 z-100 max-h-[80vh] overflow-y-auto bg-darkPurple p-2 text-white">
                                <div className="flex h-full w-full flex-col gap-y-2">
                                    {routes.map((f, i) => (
                                        <Link
                                            key={i}
                                            to={f.path}
                                            className="text-xxs font-normal text-inherit no-underline"
                                        >
                                            {f.title}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div
                            onClick={() => setShowModel(!showModel)}
                            className="fixed bottom-0 left-12 z-100 ml-2 flex h-12 w-12 items-center justify-center bg-darkPurple text-xxs text-white mdmax:hidden"
                        >
                            {showModel ? 'close' : 'model'}
                        </div>

                        {showModel && (
                            <div className="scrollbar fixed bottom-12 left-0 z-100 max-h-[60vh] overflow-y-auto border border-darkPurple bg-white text-xxs">
                                <Button
                                    size="sm"
                                    className="fixed"
                                    onClick={() => {
                                        navigator.clipboard.writeText(stringifyCurrentModel);
                                        setShowModel(false);
                                    }}
                                >
                                    Copy to clipboard
                                </Button>
                                <pre className="mt-12">{stringifyCurrentModel}</pre>
                            </div>
                        )}

                        <div
                            onClick={() => setSpeedyLogout(!speedyLogout)}
                            className="fixed bottom-0 left-[6.5rem] z-100 ml-2 flex h-12 w-12 items-center justify-center bg-[#ff0] text-xxs text-black mdmax:left-0 mdmax:ml-0"
                            data-test="buttonSetAutoLogout"
                        >
                            {speedyLogout ? (
                                <img
                                    alt=""
                                    title="Set slow autologout (DEFAULT)"
                                    className="w-8"
                                    src="https://www.svgrepo.com/show/4219/snail.svg"
                                />
                            ) : (
                                <img
                                    alt=""
                                    title="Set speedy autologout"
                                    className="w-8"
                                    src="https://www.svgrepo.com/show/481306/rabbit-3.svg"
                                />
                            )}
                        </div>

                        {/* <div className="fixed bottom-10 z-100 ml-2 flex h-12 items-center justify-center text-xxs">
                            {initData ? 'has init data' : 'i don`t have init data'}
                        </div> */}
                    </>
                )}
                {!hideFooter && (
                    <Footer
                        hideContinue={hideContinue}
                        continueText={continueText}
                        continueTooltip={continueTooltip}
                        continueDisabled={continueDisabled}
                        continueCallback={continueCallback}
                        footerContent={footerContent}
                        footerClassName={footerClassName}
                    />
                )}
                {popupType && <Popup />}
                <CheckInactivity />
                <CheckInitData />
            </div>
        </>
    );
};
