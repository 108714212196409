import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallReportBugProps {
    data: {
        name: string;
        email: string;
        bugText: string;
        onlinePolicyJson: string;
    };
}

export const callReportBug = (props: CallReportBugProps): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/report-bug`,
        data: props.data,
    });
