import React, { Ref, forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { UnorderedList } from '../../components/UnorderedList';
import { TranslationObjectProps } from '../../types';
import { getDataTest } from '../../utils';
import { InitDataQuestionaires } from '../../types/initData';
import { OCCUPATION_QUESTION_CODE } from '../../constants';
import { Field } from 'formik';
import { RadioToggle } from '../../components/common/RadioToggle';
import { FormError } from '../../components/common/FormError';
import { AppContext } from '../../components/Context';

export interface CardType {
    title: string;
    list: Array<TranslationObjectProps>;
    text: string | null;
}

interface HealthCardProps {
    data: CardType;
    index: number;
    scrollToNext: () => void;
}

const HealthCard = forwardRef(({ data, index, scrollToNext, ...props }: HealthCardProps, ref: Ref<HTMLDivElement>) => {
    const { t, i18n } = useTranslation();
    const { initData } = useContext(AppContext);
    const { QuestionAnswerLists } = initData?.Lovs || {};
    const questionnaireData = initData?.Questionnaires as InitDataQuestionaires;
    const questionsData = questionnaireData?.Questions;
    const restQuestionData = questionsData.filter((q: any) => q.Code !== OCCUPATION_QUESTION_CODE);

    const findCurrentAnswer = (id: number) => QuestionAnswerLists?.find((qal: any) => qal.Id === id);

    const resolveBinaryLabel = (code: string) =>
        code === 'YN_YES' ? t('common.yes') : code === 'YN_NO' ? t('common.no') : code;

    return (
        <Card ref={ref} {...props}>
            <>
                {data.title && (
                    <Title
                        isCenter={false}
                        tag="h2"
                        size="md"
                        fontWeight="medium"
                        className="mb-4"
                        data-test={getDataTest(props, 'Title')}
                    >
                        {data.title}
                    </Title>
                )}

                {data?.text && (
                    <Title
                        isCenter={false}
                        tag="h3"
                        size="sm"
                        fontWeight="regular"
                        className="mb-8"
                        data-test={getDataTest(props, 'Text')}
                    >
                        {data?.text}
                    </Title>
                )}

                {i18n.exists(`pages.healthStatus.cards.${index}.list`) && (
                    <UnorderedList
                        data={t(`pages.healthStatus.cards.${index}.list`, { returnObjects: true })}
                        data-test={getDataTest(props, 'Texts')}
                    />
                )}

                <div className="flex justify-center gap-x-5">
                    {restQuestionData[index]?.PossibleAnswersIds?.map((item: any) => {
                        const questionAnswers = findCurrentAnswer(item);
                        return (
                            <Field
                                key={item}
                                component={RadioToggle}
                                type="radio"
                                name={restQuestionData[index].Code}
                                value={questionAnswers.Code}
                                label={resolveBinaryLabel(questionAnswers.Code)}
                                onClick={scrollToNext}
                                data-test={getDataTest(props, `Answer[${questionAnswers.Code}]`)}
                            />
                        );
                    })}
                </div>

                <FormError
                    name={restQuestionData[index].Code}
                    className="mt-4 text-center"
                    data-test={getDataTest(props, 'Error')}
                />
            </>
        </Card>
    );
});

export default HealthCard;
