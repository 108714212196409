import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';

interface ImagePreviewProps {
    image: string;
    withBorder?: boolean;
    style?: any;
}

const ImagePreview = ({ image, withBorder = false, style }: ImagePreviewProps) => {
    const { t } = useTranslation();

    const supported = useMemo(() => /^data:image\//.test(image || ''), [image]);

    return (
        <div className={`relative w-full shrink-0 pb-5 pt-5 ${config.CAPTURE_PHOTO_CLS}`} data-test="imagePreview">
            <div
                className={cn(
                    'align-center relative flex h-full w-full justify-center',
                    withBorder && 'rounded-xs border-2 border-purple p-5'
                )}
            >
                {!supported ? (
                    <div
                        className="flex place-items-center text-center text-sm font-bold uppercase text-purple"
                        data-test="previewNotSupported"
                    >
                        {t('pages.clientVerifyIdentitySnap.previewNotSupported')}
                    </div>
                ) : (
                    <img
                        src={image}
                        alt=""
                        className="rounded-xs"
                        style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
                        data-test="image"
                    />
                )}
            </div>
        </div>
    );
};

export default ImagePreview;
