import React, { ReactNode, useEffect } from 'react';
import { FormikProps } from 'formik';
import { scrollTo } from '../utils';

interface CustomFormProps extends FormikProps<any> {
    disableScrollToErrors: boolean;
    children: ReactNode;
}

const CustomForm = ({ errors, isSubmitting, disableScrollToErrors, children }: CustomFormProps) => {
    useEffect(() => {
        if (isSubmitting === false && errors) {
            if (Object.keys(errors).length > 0) {
                const element = document.getElementById(Object.keys(errors)[0]) as HTMLElement;
                if (element) {
                    try {
                        if (!disableScrollToErrors) {
                            scrollTo(element);
                        }
                        element.focus();
                    } catch {}
                }
            }
        }
    }, [errors, isSubmitting, disableScrollToErrors]);

    return <>{children}</>;
};

export default CustomForm;
