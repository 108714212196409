import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Layout } from '../../components/Layout';
import { Card } from '../../components/common/Card';
import { Grid } from '../../components/Grid';
import { OptionProps, Select } from '../../components/common/Select';
import HelperText from '../../components/HelperText';
import InjuryForm from './InjuryForm';
import CardTitle from './CardTitle';
import DiseaseForm from './DiseaseForm';
import SelectPerson from './SelectPerson';

interface DamageEventData {
    person?: OptionProps;
    reason?: OptionProps;
}

const DamageEventForm = () => {
    const handleSubmit = (d: DamageEventData) => {};
    return (
        <Layout hideProgressBar hideSaveButton hideFooter>
            <Formik<DamageEventData>
                initialValues={{
                    person: { value: 'myself', label: 'Sebe' },
                    reason: undefined,
                }}
                onSubmit={handleSubmit}
            >
                {({ values, setValues }) => (
                    <Form>
                        <div className="grid gap-y-4">
                            <p>Hlášení škodních událostí</p>
                            <Card hasPadding={false} className="p-5 px-7">
                                <CardTitle>Základní informace</CardTitle>
                                <Grid>
                                    <SelectPerson />
                                    <Field
                                        component={Select}
                                        name="reason"
                                        label="Příčina škodní události"
                                        isCenter
                                        options={[
                                            { value: 'injury', label: 'Úraz' },
                                            { value: 'disease', label: 'Nemoc' },
                                        ]}
                                        className="w-full"
                                        data-test="reason"
                                        onChange={(e: any) => {
                                            setValues({
                                                person: values.person,
                                                reason: e,
                                            });
                                        }}
                                    />
                                </Grid>
                                {values.reason?.value === 'injury' && (
                                    <HelperText>
                                        Přiložte prosím veškeré lékařské zprávy vztahující se k danému úrazu. Pokud jste
                                        byl/a v souvislosti s tímto úrazem hospitalizován/a, přiložte prosím závěrečnou
                                        propouštěcí zprávu.
                                    </HelperText>
                                )}
                                {values.reason?.value === 'disease' && (
                                    <HelperText>
                                        Přiložte prosím veškeré lékařské zprávy vztahující se k danému onemocnění. Pokud
                                        jste byl/a v souvislosti s tímto onemocněním hospitalizován/a, přiložte prosím
                                        závěrečnou propouštěcí zprávu.
                                    </HelperText>
                                )}
                            </Card>
                            {values.reason?.value === 'injury' && <InjuryForm values={values} />}
                            {values.reason?.value === 'disease' && <DiseaseForm values={values} />}
                        </div>
                    </Form>
                )}
            </Formik>
        </Layout>
    );
};

export default DamageEventForm;
