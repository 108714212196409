import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
    className?: string;
}

export const TableHeader = ({ className }: TableHeaderProps) => {
    const { t } = useTranslation();
    return (
        <div
            className={cn(
                className,
                'grid grid-cols-4 items-center gap-x-4 rounded-sm bg-purple px-4 py-3 text-center text-xs leading-none text-white md:grid-cols-7 md:px-6'
            )}
            data-test="tableHeader"
        >
            <div className="col-span-3 text-left mdmax:hidden" data-test="tableHeaderCover">
                {t('pages.packageAdjustment.agentAskClient')}
            </div>
            <div data-test="tableHeaderPeriod">{t('common.gracePeriod')}</div>
            <div data-test="tableHeaderAmount">{t('common.faceAmount')}</div>
            <div data-test="tableHeaderTotalBenefit">{t('common.totalBenefit')}</div>
            <div data-test="tableHeaderMonthlyPremium">{t('common.monthlyPremium')}</div>
        </div>
    );
};
