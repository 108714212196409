import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Accordion from '../../../../../components/common/Accordion';
import AccordionItem from '../../../../../components/common/AccordionItem';
import FullTextSearch from '../../../../../components/FullTextSearch';
import { fullTextSearch } from './helpers';

interface GlossaryItemType {
    title: string;
    content: string;
}

const Glossary = () => {
    const { i18n } = useTranslation();

    const [fullText, setFullText] = useState('');

    const handleFullTextSearchChange = (v: string) => {
        setFullText(v);
    };

    const items = i18n.t('knowledgeBase.glossary', {
        returnObjects: true,
    }) as GlossaryItemType[];

    const filterFullTextSearch = useCallback(
        (item: GlossaryItemType) => {
            if (fullText) {
                return fullTextSearch(item.title, fullText) || fullTextSearch(item.content, fullText);
            }
            return true;
        },
        [fullText]
    );

    const finalItems = useMemo(() => {
        return items ? items.filter(filterFullTextSearch) : items;
    }, [items, filterFullTextSearch]);

    if (!(items && Array.isArray(items))) {
        return null;
    }

    return (
        <>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            <Accordion data-test="glossaryItems">
                {finalItems?.map((item: GlossaryItemType, index: number) => (
                    <AccordionItem
                        key={`glossary-item-${index}`}
                        title={item.title}
                        data-test={`glossaryItem[${index}]`}
                    >
                        <Trans
                            i18nKey={item.content}
                            components={{
                                ul: <ol className="list-decimal">.</ol>,
                                ol: <ol className="lower-alpha">.</ol>,
                                li: <li>.</li>,
                            }}
                        />
                    </AccordionItem>
                ))}
            </Accordion>
            {fullText && finalItems?.length === 0 && (
                <p className="text-center" data-test="noRecordsFound">
                    <Trans>common.noRecordsFound</Trans>
                </p>
            )}
        </>
    );
};

export default Glossary;
