import React from 'react';
import cn from 'classnames';
import { Policy } from '../types';
import { isPolicyOverdue } from '../../../../utils/hooks';

interface PolicyPaymentSymbolsProps {
    data: Policy;
}

const PolicyPaymentSymbols = ({ data }: PolicyPaymentSymbolsProps) => {
    const overdue = isPolicyOverdue(data);

    let color = null;
    if (overdue !== null) {
        color = 'bg-green-500';
    }
    if (overdue !== null && overdue < 0) {
        color = 'bg-orange-400';
    }
    if (overdue !== null && overdue === 99) {
        color = 'bg-red';
    }

    if (data.TerminationDt) {
        return null;
    }

    return <span className={cn(`mr-2 inline-block h-2 w-2 rounded`, color)} />;
};

export default PolicyPaymentSymbols;
