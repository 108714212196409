import { Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { CustomFormik } from '../../components/CustomFormik';
import { Radio } from '../../components/common/Radio';
import { UnorderedList } from '../../components/UnorderedList';
import { FormError } from '../../components/common/FormError';
import { callConfirmEmailVerification, callSaveClientConsentByCode } from '../../apis/support';
import { ClientConsentType } from '../../types';
import { ConsentPurposeType } from '../../types/enums/ConsentPurposeType';
import { ROUTE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';

export const ConfirmationData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { navigateTo } = useAppNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const pageTranslation: any = t('pages.confirmationData', { returnObjects: true });
    const myForm = useRef<FormikProps<any>>(null);

    const tokenId = searchParams.get('tokenId');
    const tokenData = searchParams.get('tokenData')?.replace(new RegExp('"$'), '');
    const verificationCode = searchParams.get('verificationCode');

    const formSchema = Yup.object().shape({
        agreement1: Yup.string().required(),
        agreement2: Yup.string().required(),
    });

    const saveConsent = (consents: ClientConsentType[]) => {
        // uložení ověření emailu vč. uložení souhlasů
        if (tokenId && tokenData) {
            setLoading(true);
            callConfirmEmailVerification({
                data: {
                    tokenExternalId: tokenId,
                    tokenData,
                    clientConsents: consents,
                },
            })
                .then((response) => response.data)
                .then(() => navigateTo(ROUTE.CONTACTS_CHECK_APPROVE))
                .catch((err) => console.error('API error', err))
                .finally(() => setLoading(false));
        }
        // uložení souhlasů ze strany klienta
        if (verificationCode) {
            callSaveClientConsentByCode({
                data: {
                    verificationCode,
                    clientConsents: consents,
                },
            })
                .then((response) => response.data)
                .then(() => navigate(ROUTE.CLIENT_VERIFY_IDENTITY_FINAL.replace(':identityId', verificationCode || '')))
                .catch((err) => console.error('API error', err))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (!((tokenId && tokenData) || verificationCode)) {
            navigate(ROUTE.ERROR_404);
        }
        // eslint-disable-next-line
    }, [tokenId, tokenData]);

    return (
        <Layout
            hideNavigation
            hideProgressBar
            hideSaveButton
            hideUserMenu
            continueDisabled={continueDisabled || loading}
        >
            <Head heading1={t('pages.confirmationData.title')} />

            <CustomFormik
                initialValues={{ agreement1: '', agreement2: '' }}
                onSubmit={(v: any) => {
                    saveConsent([
                        {
                            consentPurposeTypeCode: ConsentPurposeType.Marketing,
                            consentForCompanyCode: 'Simplea',
                            consentOperationTypeCode: v.agreement1,
                        },
                        {
                            consentPurposeTypeCode: ConsentPurposeType.Marketing,
                            consentForCompanyCode: 'UNEXT',
                            consentOperationTypeCode: v.agreement2,
                        },
                    ]);
                }}
                validationSchema={formSchema}
                passedRef={myForm}
                customRender
            >
                <div className="flex flex-col gap-y-8">
                    <DetectFormikChanged
                        onChange={(v) => {
                            const isDisabled = loading || v.agreement1 === '' || v.agreement2 === '';
                            if (continueDisabled !== isDisabled) {
                                setContinueDisabled(isDisabled);
                            }
                        }}
                    />
                    <Card data-test="confirmationDataCard">
                        <UnorderedList
                            data={pageTranslation.cards[0].list}
                            className="mb-16"
                            data-test="agreement1Texts"
                        />

                        <Field
                            component={Radio}
                            type="radio"
                            name="agreement1"
                            value="CONSENT"
                            label={t('common.formFields.iAgree')}
                            isCenter
                            className="mb-4"
                            data-test="agreement1[CONSENT]"
                        />
                        <Field
                            component={Radio}
                            type="radio"
                            name="agreement1"
                            value="DISSENT"
                            label={t('common.formFields.iDisagree')}
                            isCenter
                            data-test="agreement1[DISSENT]"
                        />

                        <FormError name="agreement1" className="mt-2" data-test="agreement1Error" />
                    </Card>

                    <Card>
                        <UnorderedList
                            data={pageTranslation.cards[1].list}
                            className="mb-16"
                            data-test="agreement2Texts"
                        />

                        <Field
                            component={Radio}
                            type="radio"
                            name="agreement2"
                            value="CONSENT"
                            label={t('common.formFields.iAgree')}
                            isCenter
                            className="mb-4"
                            data-test="agreement2[CONSENT]"
                        />
                        <Field
                            component={Radio}
                            type="radio"
                            name="agreement2"
                            value="DISSENT"
                            label={t('common.formFields.iDisagree')}
                            isCenter
                            data-test="agreement2[DISSENT]"
                        />

                        <FormError name="agreement2" className="mt-2" data-test="agreement2Error" />
                    </Card>
                </div>
            </CustomFormik>
        </Layout>
    );
};
