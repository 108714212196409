import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallLoadModelDataProps {
    data: {
        onlinePolicyExternalId: string;
    };
    token: string;
}

export const callLoadModelData = ({ data, token }: CallLoadModelDataProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}model-data/load`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
