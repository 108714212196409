import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { ClientDetail } from '../../pages/consultant/Dashboard/types';

interface CallAgencyClientDetailProps {
    data: {
        clientExternalId: string;
    };
    token: string;
}

export const callAgencyClientDetail = ({
    data,
    token,
}: CallAgencyClientDetailProps): Promise<AxiosResponse<ClientDetail, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-client-detail-with-agency-pl/${data.clientExternalId}`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
