import React, { useContext } from 'react';
import { AppContext } from '../../components/Context';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import { callGenerateOnlinePolicyAttachmentCode } from '../../apis/documents';
import { Button } from '../../components/common/Button';
import { ROUTE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import { getOwner } from '../../utils';
import { useTranslation } from 'react-i18next';
import FooterMobileMenu from '../../components/FooterMobileMenu';
import FooterMobileMenuItem from '../../components/FooterMobileMenuItem';
import ButtonMobileMore from '../../components/common/ButtonMobileMore';

interface FooterContentProps {
    loading: boolean;
    imagesAlreadyUploaded: boolean;
    setLoading: (value: boolean) => void;
}

const FooterContent = ({ loading, imagesAlreadyUploaded, setLoading }: FooterContentProps) => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const token = localStorage.getItem('token');
    const { navigateTo } = useAppNavigate();

    const policyOwner = data ? getOwner(data) : null;
    const confirmatorType = policyOwner?.IdentityConfirmation?.IdentityConfirmatorType;

    const handleRepeatSmsSend = (type: IdentityConfirmatorType, externalId: string, token: string) => {
        setLoading(true);

        callGenerateOnlinePolicyAttachmentCode({
            data: {
                onlinePolicyExternalId: externalId,
                generateOnlinePolicyAttachmentCodePlType: type,
            },
            token,
        })
            .then((response) => response.data)
            .finally(() => {
                setLoading(false);
                navigateTo(ROUTE.DOCUMENT_PHOTO);
            });
    };

    return (
        <>
            {data && !imagesAlreadyUploaded ? (
                <>
                    <div className="flex grow flex-row flex-nowrap justify-end gap-x-4 lgmax:hidden">
                        <Button
                            isOutlined
                            isDisabled={loading}
                            onClick={() =>
                                confirmatorType && token && handleRepeatSmsSend(confirmatorType, data.ExternalId, token)
                            }
                            innerSpan
                            data-test="resendSmsButton"
                        >
                            {t('common.sendSmsAgain')}
                        </Button>

                        <Button
                            isOutlined
                            isDisabled={loading}
                            onClick={() => navigateTo(ROUTE.IDENTITY_VERIFICATION)}
                            innerSpan
                            data-test="selectDifferentIdentificationButton"
                        >
                            {t('common.selectDifferentIdentification')}
                        </Button>
                    </div>

                    <div className="relative ml-auto lg:hidden">
                        <FooterMobileMenu
                            control={
                                <ButtonMobileMore
                                    caption={t('common.more')}
                                    isDisabled={loading}
                                    data-test="moreButton"
                                />
                            }
                        >
                            <FooterMobileMenuItem
                                onClick={() =>
                                    confirmatorType &&
                                    token &&
                                    handleRepeatSmsSend(confirmatorType, data.ExternalId, token)
                                }
                            >
                                {t('common.sendSmsAgain')}
                            </FooterMobileMenuItem>
                            <FooterMobileMenuItem onClick={() => navigateTo(ROUTE.IDENTITY_VERIFICATION)}>
                                {t('common.selectDifferentIdentification')}
                            </FooterMobileMenuItem>
                        </FooterMobileMenu>
                    </div>
                </>
            ) : (
                <div className="mr-auto"></div>
            )}
        </>
    );
};

export default FooterContent;
