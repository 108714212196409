import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from './Button';
import { Icon } from '../Icon';

interface BackButtonProps {
    onClick: () => void;
    className?: string;
    isHidden?: boolean;
    text?: string;
}

export const BackButton = ({ onClick, isHidden = false, className, text }: BackButtonProps) => {
    const { t } = useTranslation();
    return (
        <Button
            isDark
            onClick={() => onClick()}
            size="sm"
            isOutlined
            isHidden={isHidden}
            className={cn(
                className,
                'flex items-center justify-center mdmax:h-8 mdmax:w-8 mdmax:rounded-full mdmax:p-0'
            )}
            innerSpan={false}
            data-test="backButton"
        >
            <>
                <span className="hidden md:block">{text ?? t('common.back')}</span>
                <Icon name="chevron-up" className="-rotate-90 md:hidden" />
            </>
        </Button>
    );
};
