import cn from 'classnames';
import { ReactElement, ReactNode } from 'react';

import { Title } from './common/Title';
import { checkArray, getDataTest } from '../utils';

interface CategoryItem {
    name: string;
    label: string;
    data: Array<string>;
}

interface TableCategory {
    name: string;
    label: ReactNode;
    sum?: Array<string | ReactNode>;
    subCategories?: Array<CategoryItem>;
}

interface DataProps {
    head?: Array<string>;
    categories: Array<TableCategory | null>;
}

interface TableProps {
    data: DataProps;
    cols?: 2 | 3;
    isCondensed?: boolean;
    className?: string;
    mobileSimple?: boolean;
    isSummaryCoverage?: boolean;
}

export const Table = ({
    data,
    cols = 3,
    isCondensed,
    className,
    mobileSimple,
    isSummaryCoverage,
    ...props
}: TableProps): ReactElement => {
    const colsCls = cols === 3 ? 'grid-cols-2 md:grid-cols-3' : 'grid-cols-2 md:grid-cols-2';

    return (
        <div className={cn(className)}>
            {checkArray(data.head) && (
                <div
                    className={cn(
                        'grid border-b-1 border-purple py-1 text-right text-xs',
                        isSummaryCoverage ? 'grid-cols-2 md:grid-cols-4' : colsCls,
                        mobileSimple && 'mdmax:grid-cols-1'
                    )}
                >
                    {/* Add empty first cell on desktop */}
                    <div className={cn('mdmax:hidden', isSummaryCoverage && 'col-span-2')}></div>
                    {data.head && Array.isArray(data.head) && data.head.map((h, i) => <div key={i}>{h}</div>)}
                </div>
            )}

            {checkArray(data.categories) &&
                data.categories &&
                data.categories.map(
                    (cat, i) =>
                        cat && (
                            <div
                                key={i}
                                className={cn('mb-9 last:mb-0 md:mb-10', isCondensed && 'md:mb-0')}
                                data-test={getDataTest(props, cat.name)}
                            >
                                <div
                                    className={cn(
                                        'grid items-start border-b-1 border-purple py-1 md:pt-4',
                                        isSummaryCoverage ? 'grid-cols-2 md:grid-cols-4' : colsCls,
                                        mobileSimple && 'mdmax:grid-cols-1'
                                    )}
                                >
                                    {cat.label && (
                                        <Title
                                            isCenter={false}
                                            tag="h3"
                                            size="md"
                                            fontWeight="regular"
                                            className={cn(
                                                'whitespace-normal mdmax:text-sm',
                                                isSummaryCoverage ? 'col-span-2 md:col-span-4' : 'mdmax:col-span-2'
                                            )}
                                            data-test={getDataTest(props, `${cat.name}Title`)}
                                        >
                                            {cat.label}
                                        </Title>
                                    )}

                                    {checkArray(cat.sum) &&
                                        cat.sum?.map((s, j) => (
                                            <div key={j} className="text-right text-sm font-medium md:text-md">
                                                {s}
                                            </div>
                                        ))}
                                </div>

                                {checkArray(cat.subCategories) &&
                                    cat.subCategories?.map((subcat, j) => (
                                        <div
                                            key={j}
                                            className={cn(
                                                'grid pb-1 pt-2 md:pt-4',
                                                isSummaryCoverage ? 'grid-cols-2 pl-6 md:grid-cols-4 md:pl-12' : colsCls
                                            )}
                                            data-test={getDataTest(props, `${cat.name}SubCategory[${j}]`)}
                                        >
                                            <div
                                                className={cn(
                                                    'mdmax:col-span-2 mdmax:text-xs',
                                                    isSummaryCoverage ? 'col-span-2' : 'pl-6 md:pl-12'
                                                )}
                                            >
                                                <div
                                                    className={cn(
                                                        'overflow-hidden text-ellipsis border-purple md:h-full md:border-b-1'
                                                    )}
                                                    data-test={getDataTest(props, `${cat.name}SubCategory[${j}]Label`)}
                                                >
                                                    {subcat.label}
                                                </div>
                                            </div>

                                            {subcat.data.map((si, k) => (
                                                <div
                                                    key={k}
                                                    className="border-b-1 border-purple text-right text-sm font-medium md:text-md"
                                                    data-test={getDataTest(
                                                        props,
                                                        `${cat.name}SubCategory[${j}]Data[${k}]`
                                                    )}
                                                >
                                                    {si}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                            </div>
                        )
                )}
        </div>
    );
};
