import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Head } from '../components/Head';
import { Card } from '../components/common/Card';
import { Button } from '../components/common/Button';
import { Link } from '../components/common/Link';
import { AppContext } from '../components/Context';
import { getBaseUrl } from '../utils';
import { API_PATH, ROUTE, STEP_CODE } from '../constants';
import { callGetConfirmationScreenData, callGetOnlinePolicyEvaluationResult } from '../apis/support';
import { ArrangementKind } from '../types/enums/ArrangementKind';
import { useAppNavigate } from '../utils/hooks';
import LimitedAccess from '../components/LimitedAccess';
import Loader from '../components/common/Loader';
import { Layout } from '../components/Layout';
import AutopayPaymentMethods from '../assets/images/autopay-payment-methods.png';

export const Finish = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);

    const isRemote = data?.Settings?.ArrangementType === ArrangementKind.Remote;

    const openDocument = async () => {
        if (token && ctx?.currentModel?.ExternalId) {
            const screenData = await callGetConfirmationScreenData({
                data: { onlineModelExternalId: ctx?.currentModel?.ExternalId },
                token,
            });
            // only for 1 document from result
            if (screenData?.data?.Documents?.length === 1) {
                window.open(
                    `${getBaseUrl()}${API_PATH}documents/get-document-content/${
                        screenData?.data?.Documents[0].ExternalId
                    }`,
                    '_blank'
                );
            }
        }
    };

    const onClickTheDocument = async () => {
        if (token && ctx?.currentModel?.ExternalId) {
            setIsLoading(true);
            callGetOnlinePolicyEvaluationResult({
                data: {
                    onlineModelExternalId: ctx?.currentModel?.ExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then(async (result) => {
                    if (!isRemote) {
                        if (result.ResultType === 2) {
                            ctx.showPopup('waiting-payment');
                        } else if (result.ResultType === 3) {
                            ctx.showPopup('received-payment');
                        } else if (result.ResultType === 4) {
                            await openDocument();
                        } else {
                            ctx.showPopup('received-payment');
                        }
                    }
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <Layout
            hideSaveButton
            continueText={t<string>('common.backToMainMenu')}
            continueCallback={() => {
                navigateTo(ROUTE.DASHBOARD_MAIN);
            }}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.SUMMARY}>
                    <Head heading1={t('pages.final.title')} heading2={t('pages.final.subtitle')} />

                    <Card data-test="finishCard">
                        <p className="mb-8" data-test="finishClientInfo">
                            {t('pages.final.card.clientInfo')}
                        </p>

                        <div className="mb-4 flex flex-row justify-center gap-x-5" data-test="finishImageBox">
                            <img src={AutopayPaymentMethods} alt="Autopay" data-test="finishImage" />
                        </div>
                        <div className="mb-4 flex items-center justify-center" data-test="entityProvidingOnlinePayment">
                            <Trans
                                i18nKey="pages.autopayMethod.entityProvidingOnlinePayment"
                                components={{
                                    link1: (
                                        <a
                                            href="https://autopay.pl/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="ml-1 underline"
                                        >
                                            .
                                        </a>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mb-8 flex items-center justify-center" data-test="forMoreInformation">
                            <Trans
                                i18nKey="pages.autopayMethod.forMoreInformation"
                                components={{
                                    link1: (
                                        <a
                                            href="https://www.simplea.pl/pl/informacje-dotyczace-platnosci/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="ml-1 underline"
                                        >
                                            .
                                        </a>
                                    ),
                                }}
                            />
                        </div>

                        <p className="mb-8" data-test="finishProcessedAlready">
                            {t('pages.final.card.processedAlready')}
                        </p>

                        <Button
                            className="mb-8"
                            isDisabled={isLoading}
                            onClick={onClickTheDocument}
                            data-test="finishGetDocumentButton"
                        >
                            {t('common.getTheDocument')}
                        </Button>

                        <p className="mb-8" data-test="finishStatusInfo">
                            <Trans
                                i18nKey="pages.finalRemote.card.statusInfo"
                                components={{
                                    link1: <Link to={ROUTE.DASHBOARD_MY_POLICY}>.</Link>,
                                }}
                            />
                        </p>
                    </Card>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
