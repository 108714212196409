import React, { ReactNode } from 'react';

interface CardTitleProps {
    children: ReactNode;
}

const CardTitle = ({ children }: CardTitleProps) => {
    return <h1 className="bold m-auto mb-4 text-md">{children}</h1>;
};

export default CardTitle;
