import React from 'react';

interface PopupButtonsProps {
    children: React.ReactNode;
}

const PopupButtons = ({ children }: PopupButtonsProps) => {
    return <div className="flex flex-col gap-4 md:flex md:flex-row md:items-center md:justify-center">{children}</div>;
};

export default PopupButtons;
