import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';
import { getDataTest, validFileForUpload } from '../../utils';
import { FormError } from '../../components/common/FormError';
import { AttachmentMetaDataType } from '../../apis/documents';

interface UploadedFileProps {
    file: File | AttachmentMetaDataType;
    onDelete: () => void;
}

const UploadedFile = ({ file, onDelete, ...props }: UploadedFileProps) => {
    const { t } = useTranslation();

    const isValid = file instanceof File ? validFileForUpload(file) : null;
    const isInvalid = isValid !== null;

    return (
        <div>
            <div className="flex justify-between rounded border-1 bg-white p-2 px-4" {...props}>
                <div className="truncate pr-5 text-xs">{file instanceof File ? file.name : file.FileName}</div>
                {file instanceof File && (
                    <div
                        className="flex cursor-pointer gap-x-2 text-xs underline"
                        onClick={onDelete}
                        data-test={getDataTest(props, 'Delete')}
                    >
                        <DeleteIcon className="mt-[1px] h-[18px] w-[18px]" />
                        {t('common.delete')}
                    </div>
                )}
            </div>
            {isInvalid && (
                <FormError
                    error={t<string>(isValid)}
                    className="mt-2 text-center"
                    data-test={getDataTest(props, 'Error')}
                />
            )}
        </div>
    );
};

export default UploadedFile;
