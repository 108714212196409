import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Head } from '../../../../components/Head';
import { CardsWrapper } from '../../../../components/CardsWrapper';
import { PackageCard } from '../../../../components/PackageCard';
import { AppContext } from '../../../../components/Context';
import CalculatorImage from '../../../../assets/images/calculator.png';
import PaperImage from '../../../../assets/images/paper.png';
import FolderImage from '../../../../assets/images/folder.png';
import { AGENT_PORTAL_RIGHTS, ROUTE } from '../../../../constants';
import { hasPermission } from '../../../../utils/hooks';
import DashboardLayout from './DashboardLayout';

const Main = () => {
    const ctx = useContext(AppContext);
    const { userRights } = ctx;
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        ctx.clearCurrentModel();
        // eslint-disable-next-line
    }, []);

    return (
        <DashboardLayout>
            <Head heading1={t('pages.mainMenu.title')} heading2={t('pages.mainMenu.subtitle')} />

            <CardsWrapper className="main-cards">
                <PackageCard
                    className="package-card"
                    image={CalculatorImage}
                    title={t('pages.mainMenu.cards.0.title')}
                    hasButton
                    isDisabled={!hasPermission(AGENT_PORTAL_RIGHTS.CREATE_POLICY, userRights)}
                    data-test="cardCreatePolicy"
                    onClick={() => {
                        ctx.clearCurrentModel();
                        navigate(ROUTE.CONCLUSION);
                    }}
                />
                <PackageCard
                    className="package-card"
                    image={PaperImage}
                    title={t('pages.mainMenu.cards.1.title')}
                    hasButton
                    isDisabled={!hasPermission(AGENT_PORTAL_RIGHTS.DRAFT_LIST, userRights)}
                    data-test="cardDrafts"
                    onClick={() => {
                        ctx.clearCurrentModel();
                        navigate(ROUTE.DASHBOARD_DRAFTS);
                    }}
                />
                <PackageCard
                    className="package-card"
                    image={FolderImage}
                    title={t('pages.mainMenu.cards.3.title')}
                    hasButton
                    isDisabled={!hasPermission(AGENT_PORTAL_RIGHTS.POLICY_LIST, userRights)}
                    data-test="cardPolicyList"
                    onClick={() => {
                        ctx.clearCurrentModel();
                        navigate(ROUTE.DASHBOARD_MY_POLICY);
                    }}
                />
            </CardsWrapper>
        </DashboardLayout>
    );
};

export default Main;
