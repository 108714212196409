import React, { ReactNode } from 'react';
import cn from 'classnames';
import { getDataTest } from '../../../../../utils';

interface ToggleSectionItemProps {
    label: string;
    disableTruncate?: boolean;
    className?: string;
    children?: ReactNode;
}

export const ToggleSectionItem = ({
    label,
    disableTruncate = false,
    className,
    children,
    ...props
}: ToggleSectionItemProps) => {
    return (
        <div className={cn('col-4 border-current border-b-1 border-solid pb-2 pt-5', className)} {...props}>
            <label className="font-lighter" data-test={getDataTest(props, 'Label')}>
                {label}
            </label>
            <div
                className={cn(!disableTruncate && 'truncate', 'pt-1 text-sm')}
                data-test={getDataTest(props, 'Content')}
            >
                {children}
            </div>
        </div>
    );
};
