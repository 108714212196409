import React, { Ref, forwardRef, useCallback, useContext } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { UnorderedList } from '../../components/UnorderedList';
import { TranslationObjectProps } from '../../types';
import { InitDataQuestionaires } from '../../types/initData';
import { OCCUPATION_QUESTION_CODE } from '../../constants';
import { RadioToggle } from '../../components/common/RadioToggle';
import { FormError } from '../../components/common/FormError';
import { AppContext } from '../../components/Context';

interface OccupationCardProps {
    scrollToNext: () => void;
}

const OccupationCard = forwardRef(({ scrollToNext }: OccupationCardProps, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation();
    const { initData } = useContext(AppContext);

    const listObject: TranslationObjectProps = t('pages.occupation.card.list', { returnObjects: true });

    const lovsData = initData?.Lovs;
    const questionnaireData = initData?.Questionnaires as InitDataQuestionaires;
    const questionsData = questionnaireData?.Questions;

    const occupationQuestionData = questionsData.find((q: any) => q.Code === OCCUPATION_QUESTION_CODE);

    const findCurrentAnswer = useCallback(
        (id: number) => lovsData.QuestionAnswerLists.find((qal: any) => qal.Id === id),
        [lovsData]
    );

    const resolveBinaryLabel = (code: string) =>
        code === 'YN_YES' ? t('common.yes') : code === 'YN_NO' ? t('common.no') : code;

    return (
        <Card ref={ref} className="mb-4 md:mb-12" data-test="occupationCard">
            <Title
                isCenter={false}
                tag="h2"
                size="md"
                fontWeight="medium"
                className="mb-4 md:mb-12"
                data-test="occupationCardTitle"
            >
                {t('pages.occupation.card.title')}
            </Title>

            <UnorderedList data={listObject} data-test="occupationCardTexts" />

            <div className="flex justify-center gap-x-5">
                {occupationQuestionData?.PossibleAnswersIds?.map((pai: number) => {
                    const questionAnswers = findCurrentAnswer(pai);
                    return (
                        <Field
                            key={pai}
                            component={RadioToggle}
                            type="radio"
                            name={occupationQuestionData.Code}
                            value={questionAnswers.Code}
                            label={resolveBinaryLabel(questionAnswers.Code)}
                            onClick={scrollToNext}
                            data-test={`occupationCardAnswer${questionAnswers.Code}`}
                        />
                    );
                })}
            </div>

            <FormError name={occupationQuestionData?.Code} className="mt-4 text-center" data-test="occupationError" />
        </Card>
    );
});

export default OccupationCard;
