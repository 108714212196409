import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { Draft } from '../../pages/consultant/Dashboard/types';

interface CallDraftListProps {
    data: {};
    token: string;
}

export const callDraftList = ({ data, token }: CallDraftListProps): Promise<AxiosResponse<Draft[], any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-illustration-draft-list`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
