import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallLoginAgentProps {
    loginName: string;
    password: string;
}

interface CallLoginAgentResponse {
    AccessToken: string;
    AccessTokenValidToDtt: string;
}

export const callLoginAgent = (data: CallLoginAgentProps): Promise<AxiosResponse<CallLoginAgentResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/login-agent`,
        data,
    });
