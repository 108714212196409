import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetOnlinePolicyEvaluationResultProps {
    data: {
        onlineModelExternalId: string;
    };
    token: string;
}

interface CallGetOnlinePolicyEvaluationResultResponse {
    Text: string;
    ResultType: number;
}

export const callGetOnlinePolicyEvaluationResult = (
    props: CallGetOnlinePolicyEvaluationResultProps
): Promise<AxiosResponse<CallGetOnlinePolicyEvaluationResultResponse, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}support/get-online-policy-evaluation-result/${
            props.data.onlineModelExternalId
        }`,
        headers: {
            'X-Simplea-Auth-Token': props.token,
        },
    });
