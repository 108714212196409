import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallHasClientByContactAnyActivePolicyProps {
    data: {
        personBirthDate: string;
        personEmail: string;
        personPhone: string;
        personPhonePrefix: string;
    };
    token: string;
}

export interface CallHasClientByContactAnyActivePolicyResponse {
    HasPersonBirthDateAnyActivePolicy: boolean;
    ExistAnyActivePolicyWithSameContact: boolean;
    ExistingPolicyNumbers: Array<string>;
}

export const callHasClientByContactAnyActivePolicy = ({
    data,
    token,
}: CallHasClientByContactAnyActivePolicyProps): Promise<
    AxiosResponse<CallHasClientByContactAnyActivePolicyResponse, any>
> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/has-client-by-contact-any-active-policy`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
