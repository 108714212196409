import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';

import { Progress } from '../common/Progress';
import { AppContext } from '../Context';
import { Icon } from '../Icon';
import { HeaderMenu } from '../HeaderMenu';
import { Button } from '../common/Button';
import { BackButton } from '../common/BackButton';
import { Link } from '../common/Link';
import metadata from '../../metadata.json';
import { isDevelopment } from '../../utils';
import { useAppNavigate, useAuth, useOutsideClickChecker, useProcessRoutes } from '../../utils/hooks';
import { ROUTE } from '../../constants';
import SaveButton from './SaveButton';
import Loader from '../common/Loader';

interface HeaderProps {
    hideNavigation: boolean;
    hideProgressBar: boolean;
    hideSaveButton: boolean;
    hideUserMenu: boolean;
    disableUserMenu: boolean;
    headerContent: ReactNode;
    className?: string;
}

export const Header = ({
    hideNavigation,
    hideProgressBar,
    hideSaveButton,
    hideUserMenu,
    disableUserMenu,
    headerContent,
    className,
}: HeaderProps) => {
    const [menuActive, setMenuActive] = useState(false);
    const { logout } = useAuth();
    const { userData } = useContext(AppContext);
    const navigate = useNavigate();
    const { navigateTo } = useAppNavigate();
    const [userInitials, setUserInitials] = useState('');
    const [logoutProcessing, setLogoutProcessing] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const { processRoutes, currentProcessPosition } = useProcessRoutes();

    useOutsideClickChecker(menuRef, () => setMenuActive(false));

    const handleLogout = () => {
        setLogoutProcessing(true);
        logout()
            .then(() => navigate(ROUTE.LOGIN))
            .finally(() => setLogoutProcessing(false));
    };

    const onClickBackButton = () => {
        const index = currentProcessPosition;
        if (index !== null) {
            const prevRoute = processRoutes.filter((r) => r.showInProgressbar)[index - 1];
            prevRoute && navigateTo(prevRoute.path);
        }
    };

    useEffect(() => {
        if (userData?.name && !userInitials) {
            const name = userData.name;
            const splittedName = name.split(' ');
            const first = splittedName[0]?.charAt(0);
            const second = splittedName[1]?.charAt(0);
            setUserInitials(`${first ?? first}${second ?? second}`);
        }

        if (!userData?.name) {
            setUserInitials('');
        }
    }, [userInitials, userData]);

    return (
        <header
            className={cn(
                className,
                'fixed left-0 right-0 top-0 z-50 flex h-[4.375rem] w-full items-center justify-between bg-white px-4 py-5 shadow md:h-[6.125rem] md:px-8 md:py-0'
            )}
            data-test="header"
        >
            <Icon name="logo" className="mr-4 w-[5.625rem] shrink-0 sm:mr-6 md:w-[8.75rem]" data-test="simpleaLogo" />

            {headerContent}

            {!hideNavigation && (
                <div className="flex items-center gap-x-4">
                    <BackButton
                        onClick={onClickBackButton}
                        isHidden={!(!!currentProcessPosition && !hideProgressBar)}
                        className={cn(!(!!currentProcessPosition && !hideProgressBar) && 'mdmax:hidden')}
                    />

                    {!hideProgressBar && <Progress />}

                    {!hideSaveButton && <SaveButton />}
                </div>
            )}

            {!hideUserMenu && (
                <div
                    className={cn(
                        'relative ml-4 flex cursor-pointer items-center gap-x-3 whitespace-nowrap',
                        disableUserMenu && 'pointer-events-none opacity-30'
                    )}
                    ref={menuRef}
                    data-test="userButton"
                >
                    {userData ? (
                        <>
                            <Icon
                                name="chevron-up"
                                className={cn(
                                    'transition-transform mdmax:hidden',
                                    menuActive ? 'rotate-0' : 'rotate-180'
                                )}
                                data-test="userButton-icon"
                            />

                            {/* Desktop menu trigger */}
                            <div
                                onClick={() => !disableUserMenu && setMenuActive(!menuActive)}
                                className="flex h-8 w-8 select-none items-center justify-center rounded-full bg-purple text-sm font-normal uppercase text-white md:h-10 md:w-10 md:text-md mdmax:hidden"
                                data-test="userButton-desktop"
                            >
                                {userInitials}
                            </div>

                            {/* Mobile menu trigger */}
                            <Button
                                isDark
                                onClick={() => !disableUserMenu && setMenuActive(!menuActive)}
                                size="sm"
                                isOutlined
                                className={cn(
                                    className,
                                    'flex h-8 w-8 items-center justify-center rounded-full p-0 md:hidden'
                                )}
                                innerSpan={false}
                                data-test="userButton-mobile"
                            >
                                <Icon name="dots" />
                            </Button>

                            <AnimatePresence>
                                {menuActive && (
                                    <HeaderMenu
                                        hideSaveButton={hideSaveButton}
                                        setMenuActive={setMenuActive}
                                        handleLogout={handleLogout}
                                    />
                                )}
                            </AnimatePresence>
                        </>
                    ) : (
                        <Link
                            variant="dark"
                            className="flex h-8 w-8 items-center justify-center rounded bg-purple font-medium text-white md:h-10 md:w-10"
                            onClick={handleLogout}
                            data-test="logoutLink"
                        >
                            <Icon className="w-4" name="lock" />
                        </Link>
                    )}
                </div>
            )}

            {logoutProcessing && <Loader overflow />}

            {isDevelopment() && process.env.REACT_APP_ENV && (
                <div className="absolute right-1 top-0 text-[8px]">
                    <>
                        ({process.env.REACT_APP_ENV})
                        {window &&
                        window.location.origin &&
                        (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
                            ? window.location.hostname
                            : dayjs(metadata.lastBuildDtt).format('DD.MM.YYYY HH:mm:ss')}
                    </>
                </div>
            )}
        </header>
    );
};
