import cn from 'classnames';

interface LoaderProps {
    overflow?: boolean;
    className?: string;
}

const LoaderContent = ({ className }: LoaderProps) => <div className={cn('loader m-auto', className)} />;

const Loader = ({ overflow = false, ...props }: LoaderProps) => {
    if (overflow) {
        return (
            <div className={cn('loader-overflow', 'z-60 flex bg-white opacity-80')}>
                <LoaderContent {...props} />
            </div>
        );
    }

    return <LoaderContent {...props} />;
};

export default Loader;
