import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAttachDocumentProps {
    data: {
        onlinePolicyExternalId: string;
        attachmentData: string;
        externalId: string;
        fileName: string;
        displayName?: string;
        pageNumber: number;
        onlinePolicyAttachmentVerificatorTypeCode?: string;
        onlinePolicyAttachmentTypeCode: string;
    };
    token: string;
}

export const callAttachDocument = ({ data, token }: CallAttachDocumentProps): Promise<AxiosResponse<string, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}documents/attach-attachment`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
