import React from 'react';
import { SortBy } from '../../DataTable';

export interface ColumnProps {
    label?: string | React.ReactNode;
    dataIndex?: string;
    sortable?: boolean;
    defaultSortBy?: SortBy;
    className?: string;
    align?: 'left' | 'right' | 'center';
    width?: number | string;
    render?: (data: any, index: number) => void;
}

class Column extends React.Component<ColumnProps> {}

export default Column;
