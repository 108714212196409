import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Title } from './common/Title';
import { ErrorType } from '../types';
import { getDataTest, isDevelopment, scrollTo } from '../utils';

interface GlobalErrorProps {
    withoutTitle?: boolean;
    errors: ErrorType[] | { [key: string]: ErrorType[] } | null;
}

const GlobalErrorItems = ({ errors, ...props }: { errors: ErrorType[] }) => {
    if (errors.length === 0) {
        return null;
    }

    return (
        <ul className="m-0 mt-5 list-none p-0" {...props}>
            {errors.map((e: ErrorType, index: number) => (
                <li key={`error-${index}`} data-test={getDataTest(props, `Item${index}`)}>
                    &bull;{' '}
                    {e.code && isDevelopment() && (
                        <>
                            <span className="font-medium">{e.code}</span> -{' '}
                        </>
                    )}
                    {e.message}
                </li>
            ))}
        </ul>
    );
};

const GlobalError = ({ withoutTitle = false, errors, ...props }: GlobalErrorProps) => {
    const { t } = useTranslation();
    const ref = createRef<HTMLDivElement>();
    const [scrolledTo, setScrolledTo] = useState(false);

    const hasErrors = useMemo(() => {
        if (!errors) {
            return false;
        }
        if (Array.isArray(errors)) {
            return errors.length > 0;
        } else {
            return Object.values(errors).reduce((acc, err: ErrorType[]) => {
                if (err.length > 0) acc = true;
                return acc;
            }, false);
        }
    }, [errors]);

    useEffect(() => {
        if (hasErrors && ref.current && !scrolledTo) {
            scrollTo(ref.current);
            setScrolledTo(true);
        }
        if (!hasErrors) {
            setScrolledTo(false);
        }
    }, [hasErrors, ref, scrolledTo]);

    if (!hasErrors || !errors) {
        return null;
    }

    return (
        <div className={cn('mb-5 rounded-lg bg-orangeLight p-10', withoutTitle && 'pt-4')} ref={ref} {...props}>
            {!withoutTitle && (
                <Title tag="h3" size="md" data-test={getDataTest(props, 'Title')}>
                    {t('common.errorTitle')}
                </Title>
            )}
            {Array.isArray(errors) ? (
                <GlobalErrorItems errors={errors} data-test={getDataTest(props, 'List')} />
            ) : (
                <>
                    {Object.values(errors).map((err: ErrorType[], index: number) => (
                        <GlobalErrorItems key={index} errors={err} data-test={getDataTest(props, `List[${index}]`)} />
                    ))}
                </>
            )}
        </div>
    );
};

export default GlobalError;
