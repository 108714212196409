import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import * as Yup from 'yup';

import { Card } from '../../components/common/Card';
import { Input } from '../../components/common/Input';
import { Title } from '../../components/common/Title';
import { Head } from '../../components/Head';
import { CustomFormik } from '../../components/CustomFormik';
import { Grid } from '../../components/Grid';
import { REGEX } from '../../constants';
import { getCountriesSelectList, isDevelopment, subtractDate } from '../../utils';
import { Select } from '../../components/common/Select';
import { config } from '../../config';
import { Layout } from '../../components/Layout';
import { AppContext } from '../../components/Context';
import DateInput from '../../components/common/DateInput';

export const ConfirmPersonalData = () => {
    const { t } = useTranslation();
    const { initData } = useContext(AppContext);
    const countriesSelectData = getCountriesSelectList(initData) || [];

    const formSchema = Yup.object().shape({
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        identificatorType: Yup.object().required(),
        identificatorNumber: Yup.string().when('identificatorType', (x: Array<any>) =>
            x[0]?.value === 'pesel'
                ? Yup.string().peselFormat(t('common.formErrors.wrongPesel')).required()
                : Yup.string()
                      .required()
                      .matches(new RegExp(REGEX.POLISH_ID_REGEX), t('common.formErrors.wrongId') || '')
        ),
        sex: Yup.object().required(),
        birthDate: Yup.date()
            .required()
            .min(subtractDate(config.MAX_AGE, 'years'))
            .max(subtractDate(config.MIN_AGE, 'years')),
        birthState: Yup.object().required(),
        birthCity: Yup.string().required().min(2),
        citizenship: Yup.object().required(),
        secondCitizenship: Yup.object(),
    });

    const initialValues = isDevelopment()
        ? {
              name: 'Anna',
              surname: 'Nowak',
              identificatorType: { value: 'pesel', label: 'PESEL' },
              identificatorNumber: '74060368943',
              sex: { value: 'female', label: 'Female' },
              birthDate: '1990-01-01',
              birthState: countriesSelectData?.[0],
              birthCity: 'Warszawa',
              citizenship: countriesSelectData?.[0],
              secondCitizenship: '',
          }
        : {
              name: '',
              surname: '',
              identificatorType: { value: 'pesel', label: 'PESEL' },
              identificatorNumber: '',
              sex: { value: 'female', label: 'Female' },
              birthDate: '',
              birthState: countriesSelectData?.[0],
              birthCity: '',
              citizenship: countriesSelectData?.[0],
              secondCitizenship: '',
          };

    return (
        <Layout hideNavigation hideUserMenu>
            <Head heading1={t('pages.confirmPersonalData.title')} heading2={t('pages.confirmPersonalData.subtitle')} />

            <Card data-test="confirmPersonalDataCard">
                <Title
                    isCenter
                    tag="h2"
                    size="md"
                    fontWeight="medium"
                    className="mb-8 md:mb-12"
                    data-test="confirmPersonalDataTitle"
                >
                    {t('pages.confirmPersonalData.card.title')}
                </Title>

                <CustomFormik validationSchema={formSchema} initialValues={initialValues} customRender>
                    {({ errors }: any) => (
                        <Grid cols={2}>
                            <Field
                                isDisabled
                                component={Input}
                                name="name"
                                label={t('common.formFields.name')}
                                isCenter
                                data-test="name"
                            />
                            <Field
                                component={Input}
                                name="surname"
                                label={t('common.formFields.surname')}
                                isCenter
                                isDisabled
                                data-test="surname"
                            />
                            <Field
                                component={Select}
                                name="identificatorType"
                                label={t('common.formFields.identificatorType')}
                                isCenter
                                options={[
                                    { value: 'pesel', label: 'PESEL' },
                                    { value: 'id', label: 'ID' },
                                ]}
                                data-test="identificatorType"
                            />
                            <Field
                                component={Input}
                                name="identificatorNumber"
                                label={t('common.formFields.identificatorNumber')}
                                isCenter
                                isDisabled
                                data-test="identificatorNumber"
                            />
                            <Field
                                component={Select}
                                name="sex"
                                label={t('common.formFields.sex')}
                                isCenter
                                isDisabled
                                options={[
                                    { value: 'male', label: t('common.male') },
                                    { value: 'female', label: t('common.female') },
                                ]}
                                data-test="sex"
                            />
                            <Field
                                component={DateInput}
                                name="birthDate"
                                label={t('common.formFields.birthDate')}
                                isCenter
                                isDisabled
                                minDate={new Date(subtractDate(config.MAX_AGE, 'years'))}
                                maxDate={new Date(subtractDate(config.MIN_AGE, 'years'))}
                                data-test="birthDate"
                            />
                            <Field
                                component={Select}
                                name="birthState"
                                label={t('common.formFields.birthState')}
                                isCenter
                                options={countriesSelectData}
                                isDisabled
                                data-test="birthState"
                            />
                            <Field
                                component={Input}
                                name="birthCity"
                                label={t('common.formFields.birthCity')}
                                isCenter
                                isDisabled
                                data-test="birthCity"
                            />
                            <Field
                                component={Select}
                                name="citizenship"
                                label={t('common.formFields.citizenship')}
                                isCenter
                                options={countriesSelectData}
                                isDisabled
                                data-test="citizenship"
                            />
                            <Field
                                error={errors.secondCitizenship}
                                component={Select}
                                name="secondCitizenship"
                                label={t('common.formFields.secondCitizenship')}
                                isCenter
                                options={countriesSelectData}
                                isClearable
                                data-test="secondCitizenship"
                            />
                        </Grid>
                    )}
                </CustomFormik>
            </Card>
        </Layout>
    );
};
