import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Head } from '../../components/Head';
import { AppContext } from '../../components/Context';
import { STEP_CODE } from '../../constants';
import { validFileForUpload, getToken } from '../../utils';
import { MojeIdGetClientDataResponse } from '../../types/model';
import { useContextHelpers } from '../../utils/hooks';
import Loader from '../../components/common/Loader';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import { callGetAttachmentMetaData } from '../../apis/documents';
import { callMojeIdGetPersistedData, parseMojeIdPersistedClientDataResponse } from '../../apis/moje-id';
import PersonalDataForm from './PersonalDataForm';

const PersonalData = () => {
    const { t } = useTranslation();
    const token = getToken();
    const { initData, ...ctx } = useContext(AppContext);
    const { isPep, isRemote, isIdentificationMojeId, existsMojeIdExistence } = useContextHelpers();
    const [pepFiles, setPepFiles] = useState<any[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [errorSanctionRegion, setErrorSanctionRegion] = useState(false);
    const data = ctx.currentModel;
    const { ExternalId } = data || {};
    const ParticipantExternalId = data?.Participants[0].ExternalId;

    const [mojeIdConfirmedClientData, setMojeIdConfirmedClientData] = useState<MojeIdGetClientDataResponse | null>(
        null
    );
    const disabledFromMojeId = !!(isIdentificationMojeId && existsMojeIdExistence);

    const [loading, setLoading] = useState(disabledFromMojeId && !mojeIdConfirmedClientData);

    const isPepFilesValid = useMemo(() => {
        return (
            !isPep ||
            isRemote ||
            (isPep &&
                pepFiles.length > 0 &&
                pepFiles.filter((file) => {
                    return file instanceof File ? validFileForUpload(file) !== null : false;
                }).length === 0)
        );
    }, [isPep, isRemote, pepFiles]);

    useEffect(() => {
        if (token && data) {
            callGetAttachmentMetaData({ token, data: { onlinePolicyExternalId: data.ExternalId } })
                .then((response) => response.data)
                .then((result) => {
                    if (result?.DocumentMetadataList) {
                        setPepFiles(
                            result.DocumentMetadataList.filter(
                                (attachment) => attachment.OnlinePolicyAttachmentTypeCode === 'PepQuestionnaire'
                            )
                        );
                    }
                });
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (isIdentificationMojeId && existsMojeIdExistence) {
            if (ExternalId && ParticipantExternalId && token) {
                callMojeIdGetPersistedData({
                    data: {
                        onlinePolicyExternalId: ExternalId,
                        participantExternalId: ParticipantExternalId,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then((result) => setMojeIdConfirmedClientData(parseMojeIdPersistedClientDataResponse(result)));
            }
        }
    }, [ExternalId, ParticipantExternalId, token, isIdentificationMojeId, existsMojeIdExistence]);

    useEffect(() => {
        if (loading && mojeIdConfirmedClientData) {
            setLoading(false);
        }
    }, [loading, mojeIdConfirmedClientData]);

    if (loading) {
        return (
            <Layout continueDisabled>
                <LimitedAccess minStep={STEP_CODE.ID_DETAILS}>
                    <Head
                        heading1={t('pages.personalContactInformation.title')}
                        heading2={t('pages.personalContactInformation.subtitle')}
                    />
                    <Loader />
                </LimitedAccess>
            </Layout>
        );
    }

    return (
        <Layout
            continueDisabled={submitting || !isPepFilesValid || errorSanctionRegion}
            continueTooltip={errorSanctionRegion && t('pages.personalContactInformation.tooltipIsFromSanctionRegion')}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.ID_DETAILS}>
                    <Head
                        heading1={t('pages.personalContactInformation.title')}
                        heading2={t('pages.personalContactInformation.subtitle')}
                    />

                    <PersonalDataForm
                        mojeIdClientData={mojeIdConfirmedClientData}
                        pepFiles={pepFiles}
                        disabledFromMojeId={disabledFromMojeId}
                        onSubmitting={setSubmitting}
                        onSetErrorSanctionRegion={setErrorSanctionRegion}
                        onSetPepFiles={setPepFiles}
                    />
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};

export { PersonalData };
