import React, { Fragment } from 'react';
import { Card } from '../../components/common/Card';
import CardTitle from './CardTitle';
import { Field } from 'formik';
import DateInput from '../../components/common/DateInput';
import { Grid } from '../../components/Grid';
import { Input } from '../../components/common/Input';
import CardDivier from './CardDivier';
import IncapacityForm from './IncapacityForm';
import InvalidityForm from './InvalidityForm';
import BankCard from './BankCard';
import UploadFilesCard from './UploadFilesCard';
import CorrectCard from './CorrectCard';
import Buttons from './Buttons';
import LongTermCareForm from './LongTermCareForm';

interface DiseaseFormProps {
    values: any;
}

const DiseaseForm = ({ values }: DiseaseFormProps) => {
    return (
        <Fragment>
            <Card hasPadding={false} className="p-5 px-7">
                <CardTitle>Popis události</CardTitle>
                <div className="grid gap-y-2">
                    <Grid>
                        <Field component={DateInput} name="diseaseDate" label="Datum, kdy lékař stanovil diagnózu" />
                    </Grid>
                    <Field
                        component={Input}
                        name="diseaseDescription"
                        label="O jaké onemocnění se jedná?"
                        placeholder="Popište"
                    />
                    <CardDivier />
                    <IncapacityForm
                        question="Byl/a jste v souvislosti s touto nemocí v pracovní neschopnosti nebo bylo Vám vystaveno Rozhodnutí o potřebě ošetřování (péče) o pojištěného?"
                        values={values}
                    />
                    <CardDivier />
                    <InvalidityForm values={values} />
                    <CardDivier />
                    <LongTermCareForm values={values} />
                </div>
            </Card>
            <BankCard />
            <UploadFilesCard />
            <CorrectCard />
            <Buttons submitDisabled={values.correct !== true} />
        </Fragment>
    );
};

export default DiseaseForm;
