import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetAttachmentMetaDataProps {
    data: {
        onlinePolicyExternalId: string;
    };
    token: string;
}

export interface AttachmentMetaDataType {
    ExternalId: string;
    FileName: string | null;
    DisplayName: string | null;
    PageNumber: number | null;
    OnlinePolicyAttachmentVerificatorTypeCode: string | null;
    OnlinePolicyAttachmentTypeCode: string | null;
}

interface CallGetAttachmentMetaDataResponse {
    DocumentMetadataList: Array<AttachmentMetaDataType>;
}

export const callGetAttachmentMetaData = ({
    data,
    token,
}: CallGetAttachmentMetaDataProps): Promise<AxiosResponse<CallGetAttachmentMetaDataResponse, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}documents/get-attachment-metadata/${data.onlinePolicyExternalId}`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
