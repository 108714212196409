import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Accordion from '../../../../../components/common/Accordion';
import AccordionItem from '../../../../../components/common/AccordionItem';
import { Link } from '../../../../../components/common/Link';
import { onClickOpenDocument } from './Documents';
import { KnowledgeDocumentFileName } from './types';
import { fullTextSearch, getFaqItemsOfGroup } from './helpers';
import FullTextSearch from '../../../../../components/FullTextSearch';

interface FaqItemType {
    title: string;
    content: string;
}

interface FaqGroupType {
    title: string;
    items: FaqItemType[];
}

const FAQ = () => {
    const { i18n } = useTranslation();

    const [faqGroups, setFaqGroups] = useState<FaqGroupType[]>([]);
    const [fullText, setFullText] = useState('');

    const handleFullTextSearchChange = (v: string) => {
        setFullText(v);
    };

    const items = i18n.t('knowledgeBase.faq', {
        returnObjects: true,
    }) as FaqItemType[];

    const groups = i18n.t('knowledgeBase.faqGroups', {
        returnObjects: true,
    }) as string[];

    const filterFullTextSearch = useCallback(
        (item: FaqItemType) => {
            if (fullText) {
                return fullTextSearch(item.title, fullText) || fullTextSearch(item.content, fullText);
            }
            return true;
        },
        [fullText]
    );

    useEffect(() => {
        if (groups && items) {
            setFaqGroups(
                groups.map((group, index) => ({
                    title: group,
                    items: getFaqItemsOfGroup(items, index).filter(filterFullTextSearch),
                }))
            );
        }
    }, [groups, items, filterFullTextSearch]);

    if (!(items && Array.isArray(items))) {
        return null;
    }

    return (
        <>
            <FullTextSearch value={fullText} handleSubmit={handleFullTextSearchChange} />
            {faqGroups
                ?.filter((group) => group.items.length > 0)
                .map((group, groupIndex) => (
                    <div key={`group-${groupIndex}`}>
                        <p className="mb-2 text-sm font-[500]">{group.title}</p>
                        <Accordion data-test="faqItems" className="mb-5">
                            {group.items.map((item: FaqItemType, index: number) => (
                                <AccordionItem
                                    key={`faq-item-${index}`}
                                    title={item.title}
                                    data-test={`faqItem[${index}]`}
                                >
                                    <Trans
                                        i18nKey={item.content}
                                        components={{
                                            ul: <ol className="list-decimal">.</ol>,
                                            ol: <ol className="lower-alpha">.</ol>,
                                            pl: <ul className="list-disc">.</ul>,
                                            li: <li>.</li>,
                                            table: <table className="mt-3 border-1">.</table>,
                                            thead: <thead>.</thead>,
                                            tbody: <tbody>.</tbody>,
                                            tr: <tr>.</tr>,
                                            th: <th className="border-1 px-2 py-1 text-center first:text-left">.</th>,
                                            td: <td className="border-1 px-2 py-1 text-center first:text-left">.</td>,
                                            u: <u>.</u>,
                                            link1: (
                                                <Link
                                                    className="text-black inline-block font-medium underline"
                                                    onClick={onClickOpenDocument(
                                                        KnowledgeDocumentFileName.OkresKarencji
                                                    )}
                                                >
                                                    .
                                                </Link>
                                            ),
                                            link5: (
                                                <Link
                                                    className="text-black inline-block font-medium underline"
                                                    onClick={onClickOpenDocument(
                                                        KnowledgeDocumentFileName.ZalacznikNr5Assistance
                                                    )}
                                                >
                                                    .
                                                </Link>
                                            ),
                                            link13: (
                                                <Link
                                                    className="text-black inline-block contents font-medium underline"
                                                    onClick={onClickOpenDocument(
                                                        KnowledgeDocumentFileName.WylaczeniaOdpowiedzialnosci
                                                    )}
                                                >
                                                    .
                                                </Link>
                                            ),
                                            linkSimpleaPl: (
                                                <a
                                                    className="underline"
                                                    href="https://www.simplea.pl/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    .
                                                </a>
                                            ),
                                            linkHlaseni: (
                                                <a
                                                    className="underline"
                                                    href="https://www.simplea.pl/zgloszenie-zdarzenia/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    .
                                                </a>
                                            ),
                                            linkEmailMondialAssistance: (
                                                <a className="underline" href="mailto:u.life@mondial-assistance.pl">
                                                    .
                                                </a>
                                            ),
                                            linkConditions: (
                                                <a
                                                    className="underline"
                                                    href="https://www.knf.gov.pl/podmioty/Podmioty_rynku_ubezpieczeniowego/dzialalnosc_transgraniczna_zakladow_ubezpieczen"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    .
                                                </a>
                                            ),
                                        }}
                                    />
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                ))}
            {fullText && faqGroups.filter((g) => g.items.length > 0).length === 0 && (
                <p className="text-center" data-test="noRecordsFound">
                    <Trans>common.noRecordsFound</Trans>
                </p>
            )}
        </>
    );
};

export default FAQ;
