export enum IdCardKind {
    IdentityCardCZ = 1,
    PassportCZ = 2,
    DrivingLicenceCZ = 3,
    IdentityCardSK = 4,
    PassportSK = 5,
    DrivingLicenceSK = 6,
    Other = 7,
    ResidencePermit = 8,
    IdentityCardPL = 9,
    PassportPL = 10,
}
