import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import ImageCake from '../assets/images/cake.png';
import ImageDancing from '../assets/images/dancing.png';
import ImageFamily from '../assets/images/family.png';
import ImagePregnant from '../assets/images/pregnant.png';
import { Head } from '../components/Head';
import { PackageCard } from '../components/PackageCard';
import { CardsWrapper } from '../components/CardsWrapper';
import { AppContext } from '../components/Context';
import { InsurancePackageTypePl } from '../types/enums/InsurancePackageTypePl';
import { getHigherStep } from '../utils';
import Loader from '../components/common/Loader';
import { ROUTE, STEP_CODE } from '../constants';
import { useAppNavigate } from '../utils/hooks';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';

export const PackageSelection = () => {
    const { t } = useTranslation();
    const { initData, ...ctx } = useContext(AppContext);
    const { navigateTo } = useAppNavigate();
    const data = ctx.currentModel;
    const p = data?.InsuredPersons?.[0];

    const handleClick = (type: string) => {
        const indexOfPackage = Object.keys(InsurancePackageTypePl).indexOf(type);

        if (indexOfPackage >= 0) {
            if (p) {
                ctx.setCurrentModel({
                    ...data,
                    InsuredPersons: [
                        {
                            ...p,
                            InsurancePackage: {
                                ...p.InsurancePackage,
                                ChosenPackageType: Object.values(InsurancePackageTypePl)[
                                    indexOfPackage
                                ] as InsurancePackageTypePl,
                                PackageName: type,
                            },
                        },
                    ],
                    Settings: {
                        ...data.Settings,
                        CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.PACKAGE_CHOICE),
                    },
                });
            }
            navigateTo(ROUTE.BASIC_DATA);
        }
    };

    return (
        <Layout hideSaveButton hideFooter>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.QUESTIONNAIRE}>
                    <Head
                        heading1={t('pages.packageSelection.title', {
                            productName: initData?.Product?.ProductNameTranslated || 'Simplea',
                        })}
                        heading2={t('pages.packageSelection.subtitle')}
                    />

                    <CardsWrapper>
                        <PackageCard
                            image={ImageCake}
                            title={t('pages.packageSelection.cards.0.title')}
                            subtitle={t('pages.packageSelection.cards.0.subtitle')}
                            onClick={() => handleClick('Single')}
                            isSelected={p?.InsurancePackage?.PackageName === 'Single'}
                            hasButton
                            data-test="packageSingle"
                        />
                        <PackageCard
                            image={ImageDancing}
                            title={t('pages.packageSelection.cards.1.title')}
                            subtitle={t('pages.packageSelection.cards.1.subtitle')}
                            onClick={() => handleClick('Couple')}
                            isSelected={p?.InsurancePackage?.PackageName === 'Couple'}
                            hasButton
                            data-test="packageCouple"
                        />
                        <PackageCard
                            image={ImageFamily}
                            title={t('pages.packageSelection.cards.2.title')}
                            subtitle={t('pages.packageSelection.cards.2.subtitle')}
                            onClick={() => handleClick('Family')}
                            isSelected={p?.InsurancePackage?.PackageName === 'Family'}
                            hasButton
                            data-test="packageFamily"
                        />
                        <PackageCard
                            image={ImagePregnant}
                            title={t('pages.packageSelection.cards.3.title')}
                            subtitle={t('pages.packageSelection.cards.3.subtitle')}
                            onClick={() => handleClick('ParentWithChild')}
                            isSelected={p?.InsurancePackage?.PackageName === 'ParentWithChild'}
                            hasButton
                            data-test="packageParentWithChild"
                        />
                    </CardsWrapper>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
