import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAutopayConfirmationPaymentProps {
    data: {
        serviceID: string;
        orderID: string;
        hash: string;
    };
}

interface CallAutopayConfirmationPaymentResponse {
    IsHashValid: boolean;
}

export const callAutopayConfirmationPayment = ({
    data,
}: CallAutopayConfirmationPaymentProps): Promise<AxiosResponse<CallAutopayConfirmationPaymentResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}autopay/autopay-confirmation`,
        data,
    });
