import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { PolicyDetail } from '../../pages/consultant/Dashboard/types';

interface CallAgencyPolicyDetailProps {
    data: {
        policyExternalId: string;
    };
    token: string;
}

export const callAgencyPolicyDetail = ({
    data,
    token,
}: CallAgencyPolicyDetailProps): Promise<AxiosResponse<PolicyDetail, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-policy-detail-with-agency-pl/${data.policyExternalId}`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
