import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from '../../../../../components/common/Card';

const ForWhoIsProduct = () => {
    const { i18n } = useTranslation();

    const items = i18n.t('knowledgeBase.forWhoIsProduct', {
        returnObjects: true,
    }) as any;

    if (!items) {
        return null;
    }

    return (
        <Card hasPadding={false} className="p-5" data-test="forWhoIsProduct">
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: items.description }} />
            <p dangerouslySetInnerHTML={{ __html: items.productIsFor.title }} />
            <ol className="list-disc">
                {items.productIsFor.list.map((l: string, index: number) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: l }} />
                ))}
            </ol>

            <p dangerouslySetInnerHTML={{ __html: items.productIsNotFor.title }} />
            <ol className="mb-8 list-disc">
                {items.productIsNotFor.list.map((l: string, index: number) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: l }} />
                ))}
            </ol>

            <div className="border-1 p-4">
                <strong dangerouslySetInnerHTML={{ __html: items.box.title1 }} />
                <ol className="list-disc">
                    {items.box.list1.map((l: string, index: number) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: l }} />
                    ))}
                </ol>
                <strong dangerouslySetInnerHTML={{ __html: items.box.title2 }} />
                <ol className="list-disc">
                    {items.box.list2.map((l: string, index: number) => (
                        <li key={index} className="mb-4">
                            <Trans
                                i18nKey={l}
                                components={{
                                    ml: (
                                        <ul className="mb-0 mt-0" style={{ listStyleType: '"-"' }}>
                                            .
                                        </ul>
                                    ),
                                    li: <li className="pl-2">.</li>,
                                }}
                            />
                        </li>
                    ))}
                </ol>
            </div>
        </Card>
    );
};

export default ForWhoIsProduct;
