import React, { useContext } from 'react';
import { Select, SelectProps } from './common/Select';
import { getIdentificatorSelectList } from '../utils';
import { AppContext } from './Context';

const SelectIdentificatorType = (props: SelectProps) => {
    const { initData } = useContext(AppContext);
    const options = getIdentificatorSelectList(initData);
    return <Select {...props} options={options || []} />;
};

export default SelectIdentificatorType;
