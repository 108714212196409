import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallAutopayInitiatePaymentProps {
    data: {
        policyExternalId: string;
        gatewayId: number;
    };
}

interface CallAutopayInitiatePaymentResponse {
    InitiatePaymentRedirectUrl: string;
    ServiceID: string;
    OrderID: string;
    Amount: string;
    Description: string;
    GatewayID: string;
    CurrencyCode: string;
    CustomerNrb: string;
    Title: string;
    ReceiverName: string;
    CustomerEmail: string;
    ValidityTime: string;
    LinkValidityTime: string;
    Hash: string;
}

export const callAutopayInitiatePayment = ({
    data,
}: CallAutopayInitiatePaymentProps): Promise<AxiosResponse<CallAutopayInitiatePaymentResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}autopay/autopay-initiate-payment`,
        data,
    });
