import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Draft } from '../types';
import { Button } from '../../../../components/common/Button';
import { AppContext } from '../../../../components/Context';
import { ROUTE } from '../../../../constants';
import { callGetOnlinePolicyEvaluationResult } from '../../../../apis/support';

interface DraftsToggleProps {
    data: Draft;
}

const DraftsToggle = ({ data }: DraftsToggleProps) => {
    const ctx = useContext(AppContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    const onClick = () => {
        if (token) {
            setLoading(true);
            callGetOnlinePolicyEvaluationResult({
                data: {
                    onlineModelExternalId: data.OnlinePolicyExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => {
                    if (result?.ResultType !== 14) {
                        ctx.showPopup('draft-cannot-continue');
                    } else {
                        ctx.clearCurrentModel();
                        navigate(`${ROUTE.CONTINUE_DRAFT}?id=${data.OnlinePolicyExternalId}`);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <div>
            <Button
                size="sm"
                isOutlined
                className="m-0 ml-auto w-fit"
                isDisabled={loading}
                onClick={onClick}
                data-test="finaliseContract"
            >
                <Trans>dashboard.common.finaliseContract</Trans>
            </Button>
        </div>
    );
};

export default DraftsToggle;
