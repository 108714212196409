export const fullTextSearch = (sourceText: string, find: string) => {
    return sourceText.toLowerCase().includes(find.toLowerCase());
};

export const getFaqItemsOfGroup = (faqSource: any[], groupIndex: number) => {
    switch (groupIndex) {
        case 0:
            return [
                faqSource[0], //
                faqSource[5],
                faqSource[13],
                faqSource[1],
                faqSource[12],
            ];
        case 1:
            return [
                faqSource[2],
                faqSource[22],
                faqSource[23],
                faqSource[3],
                faqSource[24],
                faqSource[4],
                faqSource[18],
                faqSource[19],
            ];
        case 2:
            return [
                //
                faqSource[6],
                faqSource[25],
                faqSource[7],
                faqSource[8],
            ];
        case 3:
            return [
                faqSource[10], //
                faqSource[9],
                faqSource[14],
                faqSource[11],
                faqSource[20],
                faqSource[21],
            ];
        case 4:
            return [
                faqSource[15],
                faqSource[16],
                faqSource[17],
                faqSource[26],
                faqSource[27],
                faqSource[28],
                faqSource[29],
            ];
        case 5:
            return [
                faqSource[30], //
                faqSource[31],
                faqSource[32],
            ];
        default:
            return [];
    }
};
