import { Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useRef, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { Head } from '../../components/Head';
import { CustomFormik } from '../../components/CustomFormik';
import { AppContext } from '../../components/Context';
import { getHigherStep } from '../../utils';
import { DATE_FORMAT, ROUTE, STEP_CODE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import LimitedAccess from '../../components/LimitedAccess';
import Loader from '../../components/common/Loader';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import { AnswerItemProps } from '../../types/model';
import { InitDataQuestionaireQuestion, InitDataQuestionaires } from '../../types/initData';
import OccupationCard from './OccupationCard';
import HealthCard, { CardType } from './HealthCard';

export interface PageTranslationProps {
    title: string;
    subtitle: string | null;
    cards: Array<CardType>;
}

const getAnswerItems = (v: FormikValues, questionnaireData: any): Array<AnswerItemProps> => {
    const q: Array<InitDataQuestionaireQuestion> = questionnaireData.Questions;

    const res = q.map((item) => ({
        AnswerFormat: item.QuestionAnswerFormatId,
        AnswerValueListCode: v[item.Code],
        ExternalId: uuidv4(),
        ChildAnswerItems: [],
        QuestionExternalId: item.ExternalId,
    }));

    // @ts-ignore
    return res;
};

const Questionnaire = () => {
    const { t } = useTranslation();
    const { initData, ...ctx } = useContext(AppContext);
    const pageTranslation: PageTranslationProps = t('pages.healthStatus', { returnObjects: true });
    const { navigateTo } = useAppNavigate();
    const occupationRef = useRef<HTMLDivElement | null>(null);
    const healthRefs = useRef<Array<HTMLDivElement | null>>([]);
    const myFormikRef = useRef<FormikProps<any>>(null);
    const [isValid, setIsValid] = useState(false);

    const data = ctx.currentModel;
    const questionnaireData = initData?.Questionnaires as InitDataQuestionaires;
    const questionsData = questionnaireData?.Questions;
    const initialValuesObj: { [key: string]: string } = {};
    const answerItems = data?.InsuredPersons?.[0]?.Questionnaire?.Answers?.[0];

    const questionsSchema = () => {
        let obj: any = {};
        questionsData &&
            questionsData.forEach((q: any) => {
                obj[q.Code] = Yup.string().required();
            });
        return obj;
    };

    const formSchema = Yup.object().shape({
        ...questionsSchema(),
    });

    questionsData?.forEach((q: any) => {
        let currentValue = '';

        if (answerItems) {
            const currentQuestion = answerItems.Items.find((item) => item.QuestionExternalId === q.ExternalId);
            const currentListCode = currentQuestion?.AnswerValueListCode;

            if (currentListCode) {
                currentValue = currentListCode;
            }
        }
        initialValuesObj[q.Code] = currentValue;
    });

    const scrollToNext = (target: HTMLDivElement | null) => {
        if (target) {
            const y = target.getBoundingClientRect().top + window.scrollY - 100;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    if (!questionsData) {
        return (
            <Layout hideSaveButton>
                <LimitedAccess minStep={STEP_CODE.CONCLUSION}>
                    <Head heading1={t('pages.occupation.title')} heading2={null} hasMultipleHead />
                </LimitedAccess>
            </Layout>
        );
    }

    return (
        <Layout hideSaveButton continueDisabled={!isValid}>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.CONCLUSION}>
                    <Head heading1={t('pages.occupation.title')} heading2={null} hasMultipleHead />

                    <CustomFormik
                        passedRef={myFormikRef}
                        initialValues={initialValuesObj}
                        onSubmit={(v: any) => {
                            const everythingNo = Object.keys(v).reduce((acc, k) => {
                                if (v[k] === 'YN_YES') acc = false;
                                return acc;
                            }, true);

                            if (!everythingNo) {
                                ctx.showPopup('insurable');
                            } else {
                                const newModel = {
                                    ...data,
                                    Settings: {
                                        ...data.Settings,
                                        CurrentStepCode: getHigherStep(
                                            data.Settings.CurrentStepCode,
                                            STEP_CODE.QUESTIONNAIRE
                                        ),
                                    },
                                };
                                ctx.setCurrentModel(newModel);
                                navigateTo(ROUTE.PACKAGE_CHOICE);
                            }
                        }}
                        className="flex flex-col"
                        validationSchema={formSchema}
                        customRender
                    >
                        <Form>
                            <DetectFormikChanged
                                onChange={(v: FormikValues) => {
                                    if (formSchema.isValidSync(v) !== isValid) {
                                        setIsValid(formSchema.isValidSync(v));
                                    }

                                    ctx.setCurrentModel({
                                        ...data,
                                        InsuredPersons: [
                                            {
                                                ...data?.InsuredPersons?.[0],
                                                Questionnaire: {
                                                    Answers: [
                                                        {
                                                            AnswerDt: dayjs().format(DATE_FORMAT),
                                                            ExternalId: uuidv4(),
                                                            Items: getAnswerItems(v, questionnaireData),
                                                        },
                                                    ],
                                                    Code: questionnaireData.Code,
                                                    ExternalId: questionnaireData.ExternalId,
                                                    Name: questionnaireData.NameDefault,
                                                    QuestionnaireKind: questionnaireData.QuestionnaireTypeId,
                                                    Version: questionnaireData.Version,
                                                },
                                            },
                                        ],
                                    });
                                }}
                            />

                            <OccupationCard
                                ref={occupationRef}
                                scrollToNext={() => scrollToNext(healthRefs.current[0])}
                            />

                            <Head heading1={pageTranslation.title} heading2={pageTranslation.subtitle} />

                            {pageTranslation.cards && (
                                <div className="flex flex-col gap-y-4 md:gap-y-9">
                                    {pageTranslation.cards.map((card: CardType, index: number) => (
                                        <HealthCard
                                            ref={(el) => (healthRefs.current[index] = el)}
                                            key={`healthCard${index}`}
                                            data={card}
                                            index={index}
                                            data-test={`healthCard${index}`}
                                            scrollToNext={() => scrollToNext(healthRefs.current[index + 1])}
                                        />
                                    ))}
                                </div>
                            )}
                        </Form>
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};

export default Questionnaire;
