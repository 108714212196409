import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallFinishPrevaluationProps {
    data: {
        jsonOnlineModel: string;
    };
    token: string;
}

export const callFinishPrevaluation = ({
    data,
    token,
}: CallFinishPrevaluationProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}model-data/finish-prevaluation`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
