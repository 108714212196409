import { useContext } from 'react';
import { t } from 'i18next';

import { Title } from '../components/common/Title';
import Headache from '../assets/images/headache.png';
import { Button } from '../components/common/Button';
import { AppContext } from '../components/Context';
import { Layout } from '../components/Layout';

export const InvalidLink = () => {
    const { userData } = useContext(AppContext);
    const isClientSide = !userData;

    return (
        <Layout hideNavigation hideUserMenu hideFooter>
            <div className="text-center">
                <div
                    className="justify-flexend flex flex-col items-center gap-x-16 text-center lg:flex-row lg:text-left"
                    data-test="invalidLink"
                >
                    <img
                        src={Headache}
                        alt=""
                        className="w-full max-w-[32rem]"
                        style={{ margin: '0 -5rem' }}
                        data-test="image"
                    />
                    <div className="flex flex-col px-5 lg:mt-5" style={{ height: '100%' }}>
                        <Title
                            tag="h4"
                            size="lg"
                            fontWeight="regular"
                            className="mb-10 whitespace-normal mdmax:mx-auto"
                            isCenter={false}
                            data-test="title"
                        >
                            <p>
                                <strong className="mr-2">{t('pages.invalidLink.whoops')}</strong>
                                {t('pages.invalidLink.title')}
                            </p>
                        </Title>

                        {isClientSide && (
                            <p className="font-regular mb-10 text-md" data-test="description">
                                {t('pages.invalidLink.description')}
                            </p>
                        )}

                        <div style={{ marginTop: isClientSide ? '6%' : '20%' }} className="hidden lg:inline">
                            <Button
                                href="https://simplea.pl"
                                className="inline lg:mr-auto"
                                data-test="buttonContinue[desktop]"
                            >
                                {t('pages.invalidLink.continue')}
                            </Button>
                        </div>

                        <div className="mt-8 lg:hidden">
                            <Button
                                href="https://simplea.pl"
                                className="inline lg:mr-auto"
                                data-test="buttonContinue[tablet]"
                            >
                                {t('pages.invalidLink.continue')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
