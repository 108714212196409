import React, { useEffect, useRef, useState } from 'react';
import { AttachmentMetaDataType, callGetAttachmentContent } from '../apis/documents';
import { getToken } from '../utils';
import { LayerRenderStatus, Plugin, PluginOnCanvasLayerRender, Viewer } from '@react-pdf-viewer/core';
import Loader from './common/Loader';

interface AttachmentMetaDataViewProps {
    document: AttachmentMetaDataType;
}

const AttachmentMetaDataView = ({ document }: AttachmentMetaDataViewProps) => {
    const token = getToken();
    const refRes = useRef<HTMLDivElement>(null);
    const [file, setFile] = useState<string>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token) {
            setLoading(true);
            callGetAttachmentContent({
                data: { attachmentExternalId: document.ExternalId },
                token,
            })
                .then((response) => response.data)
                .then((result) => setFile(window.URL.createObjectURL(result)))
                .finally(() => setLoading(false));
        }
    }, [token, document]);

    const customCanvasPlugin = (): Plugin => {
        const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
            if (e.status !== LayerRenderStatus.DidRender) {
                return;
            }

            const canvas = e.ele;

            if (refRes.current) {
                const width = canvas.width;
                const height = canvas.height;
                const maxWidth = refRes?.current?.getBoundingClientRect().width;
                const maxHeight = refRes?.current?.getBoundingClientRect().height;
                let ratio = maxWidth / width;
                if (height * ratio > maxHeight) {
                    ratio = maxHeight / height;
                }

                canvas.style.width = `${width * ratio}px`;
                canvas.style.height = `${height * ratio}px`;
                canvas.style.marginLeft = `${(maxWidth - width * ratio) / 2}px`;
            }
        };

        return {
            onCanvasLayerRender,
        };
    };

    const customCanvasPluginInstance = customCanvasPlugin();

    return (
        <div ref={refRes} className="attachment-view mb-6 flex" style={{ height: '300px', maxHeight: '300px' }}>
            {loading && <Loader />}
            {file && <Viewer fileUrl={file} plugins={[customCanvasPluginInstance]} />}
        </div>
    );
};

export default AttachmentMetaDataView;
