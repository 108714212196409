import { useContext, useMemo, useState } from 'react';
import { Field, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Image from '../assets/images/person-pc.png';
import { Button } from '../components/common/Button';
import { Card } from '../components/common/Card';
import { Input } from '../components/common/Input';
import { Title } from '../components/common/Title';
import { Head } from '../components/Head';
import { CustomFormik } from '../components/CustomFormik';
import { callChangeAgentPassword } from '../apis/authentications';
import { AppContext } from '../components/Context';
import { getToken, translateErrorCode } from '../utils';
import { BaseErrorType } from '../types';
import { Layout } from '../components/Layout';

interface ChangePasswordFormData {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export const ChangePassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const ctx = useContext(AppContext);
    const { userData } = ctx;
    const [errors, setErrors] = useState<BaseErrorType[]>([]);

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required(),
        newPassword: Yup.string().required(),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), ''], t<string>('common.formErrors.passwordDontMatch'))
            .required(),
    });

    const onSubmit = (values: FormikValues | ChangePasswordFormData | undefined) => {
        const token = getToken();
        if (token && values) {
            setErrors([]);
            setLoading(true);
            callChangeAgentPassword({
                data: {
                    userExternalId: userData?.extId ?? '',
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                },
                token,
            })
                .then(() => ctx.showPopup('change-password-done'))
                .catch((err) => setErrors(err.response.data.Errors))
                .finally(() => setLoading(false));
        }
    };

    const oldPasswordErrorMessage = useMemo(() => {
        const errorCode = errors?.find((err) => err.ErrorCode === 'err_auth_user_or_credentials_invalid')?.ErrorCode;
        return errorCode ? t('common.formErrors.currentPasswordDoesntMatch') : null;
    }, [errors, t]);

    const newPasswordErrorMessage = useMemo(() => {
        const errorCode = errors?.find((err) => err.ErrorCode !== 'err_auth_user_or_credentials_invalid')?.ErrorCode;
        return errorCode ? t(translateErrorCode(errorCode, 'common.formErrors.invalidPassword')) : null;
    }, [errors, t]);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideFooter>
            <div className="flex w-full flex-col items-center">
                <Head heading1={t('pages.changePassword.title')} heading2={t('pages.changePassword.subtitle')} />

                <div className="flex w-full flex-col justify-center md:flex-row">
                    <img
                        src={Image}
                        alt=""
                        className="h-auto w-[9.375rem] grow-0 self-center md:w-[16rem] lg:w-[30rem]"
                        data-test="changePasswordImage"
                    />

                    <Card
                        className="w-full max-w-[30rem] p-4 md:w-full md:p-8 mdmax:mx-auto"
                        data-test="changePasswordCard"
                    >
                        <Title
                            tag="h3"
                            size="lg"
                            fontWeight="medium"
                            className="mb-6 ml-4"
                            data-test="changePasswordTitle"
                        >
                            {t('pages.changePassword.card.title')}
                        </Title>

                        <CustomFormik<ChangePasswordFormData>
                            initialValues={{
                                oldPassword: '',
                                newPassword: '',
                                confirmNewPassword: '',
                            }}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            <div className="flex flex-col gap-y-4">
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.oldPassword')}:`}
                                    name="oldPassword"
                                    errorMessage={oldPasswordErrorMessage}
                                    data-test="oldPassword"
                                />
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.newPassword')}:`}
                                    name="newPassword"
                                    errorMessage={newPasswordErrorMessage}
                                    data-test="newPassword"
                                />
                                <Field
                                    component={Input}
                                    type="password"
                                    label={`${t('common.formFields.confirmNewPassword')}:`}
                                    name="confirmNewPassword"
                                    data-test="confirmNewPassword"
                                />
                                <div className="flex justify-center gap-x-3">
                                    <Button
                                        isSubmit
                                        isDisabled={loading}
                                        onClick={() => setErrors([])}
                                        data-test="changePasswordButton"
                                    >
                                        {t('common.changePassword')}
                                    </Button>
                                </div>
                            </div>
                        </CustomFormik>
                    </Card>
                </div>
            </div>
        </Layout>
    );
};
