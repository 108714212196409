import { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';

import { Button } from '../../components/common/Button';
import { Card } from '../../components/common/Card';
import { Input } from '../../components/common/Input';
import { Link } from '../../components/common/Link';
import { Title } from '../../components/common/Title';
import { Checkbox } from '../../components/common/Checkbox';
import { DownloadList } from '../../components/common/DownloadList';
import { Switch } from '../../components/common/Switch';
import { Select } from '../../components/common/Select';
import { Slider } from '../../components/common/Slider';
import { Carousel } from '../../components/common/Carousel';
import { Grid } from '../../components/Grid';
import { BackButton } from '../../components/common/BackButton';
import { HorizontalRule } from '../../components/common/HorizontalRule';
import { AppContext } from '../../components/Context';
import { Head } from '../../components/Head';
import { PL_CURRENCY } from '../../constants';
import { Layout } from '../../components/Layout';
import DateInput from '../../components/common/DateInput';

export const Design = () => {
    const [val, setVal] = useState(2500);
    const [active, setActive] = useState(false);
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.showPopup('missing-data');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout withoutPolicy hideProgressBar hideSaveButton hideFooter>
            <Head heading1="Simplea Poland" heading2="Design System" />
            <div className="flex flex-col gap-y-4">
                <Title isCenter={false} tag="strong" size="lg">
                    Headings
                </Title>
                <HorizontalRule />

                <Title isCenter={false} tag="h1" size="lg">
                    Heading LG
                </Title>

                <Title isCenter={false} tag="h2" size="md">
                    Heading MD
                </Title>

                <Title isCenter={false} tag="h3" size="sm">
                    Heading SM
                </Title>

                <Title isCenter={false} tag="h4" size="xs">
                    Heading XS
                </Title>

                <Title isCenter={false} tag="h2" fontWeight="regular" size="md">
                    Secondary Heading
                </Title>

                <Title isCenter={false} tag="strong" size="lg">
                    Buttons
                </Title>
                <HorizontalRule />

                <div className="flex gap-x-4">
                    <Button isOutlined onClick={() => alert('How dare you')}>
                        Button Large Outlined
                    </Button>
                    <Button onClick={() => alert('How dare you')}>Button Large</Button>
                    <Button size="md" isOutlined onClick={() => alert('How dare you')}>
                        Button Small Outlined
                    </Button>
                    <Button size="md" onClick={() => alert('How dare you')}>
                        Button Small
                    </Button>
                </div>

                <Title isCenter={false} tag="strong" size="lg">
                    Link
                </Title>
                <HorizontalRule />

                <Link variant="dark" to="/">
                    Forgot password?
                </Link>

                <Title isCenter={false} tag="strong" size="lg">
                    Card
                </Title>
                <HorizontalRule />

                <Card>Card Content</Card>

                <Title isCenter={false} tag="strong" size="lg">
                    Form
                </Title>
                <HorizontalRule />

                <Formik initialValues={{ test: 'abc' }} onSubmit={(values) => console.log('submit', values)}>
                    <Form noValidate>
                        <Field component={Input} name="test" label="Login name:" error="Toto pole je povinné" />
                    </Form>
                </Formik>

                <Card>
                    <Formik
                        initialValues={{ email: '', phone: '', death: false, clientNoMailbox: true }}
                        onSubmit={(values) => console.log('submit', values)}
                    >
                        <Form noValidate>
                            <Grid className="mb-4">
                                <Field component={Input} name="email" label="E-mail" />
                                <Field
                                    component={Input}
                                    name="phone"
                                    label="Phone number"
                                    error="Toto pole je povinné"
                                />
                                <Field component={DateInput} name="date" label="Clients birth date" />
                                <Select
                                    label="Identification"
                                    options={[
                                        { label: 'ID', value: 'id' },
                                        { label: 'Passport', value: 'passport' },
                                    ]}
                                />
                                <Field
                                    component={Checkbox}
                                    name="death"
                                    label="Death (basic)"
                                    isCenter
                                    error="Toto pole je povinné"
                                />
                                <Field
                                    component={Switch}
                                    name="clientNoMailbox"
                                    label="Client does not have an access to mailbox at the moment"
                                    isCenter
                                />
                            </Grid>

                            <div className="mb-4 flex gap-x-16"></div>

                            <Button isSubmit className="mb-4">
                                Odeslat
                            </Button>
                        </Form>
                    </Formik>
                </Card>

                <Title isCenter={false} tag="strong" size="lg">
                    Download List
                </Title>
                <HorizontalRule />

                <Card>
                    <DownloadList
                        items={[
                            { name: 'Vaše smlouva.pdf', url: '/' },
                            { name: 'Informace o pojištění.pdf', url: '/' },
                        ]}
                    />
                </Card>

                <Title isCenter={false} tag="strong" size="lg">
                    Progress
                </Title>
                <HorizontalRule />

                {/* <Progress
                    items={[
                        { title: 'Úvodní volba' },
                        { title: 'Základní informace o vás' },
                        { title: 'Doporučené pojištění' },
                    ]}
                /> */}

                <Title isCenter={false} tag="strong" size="lg">
                    Coverage Table
                </Title>
                <HorizontalRule />

                {/* <Formik
                    initialValues={{ email: '', phone: '', death: false, clientNoMailbox: true }}
                    onSubmit={(values) => console.log('submit', values)}
                >
                    <Form noValidate>
                        <TableHeader className="mb-2" />

                        <CoverageBox
                            data={val}
                            setData={setVal}
                            title="Payment to beneficiaries in case of your death"
                            items={[
                                {
                                    name: 'Death (basic)',
                                    id: 'dth',
                                    description:
                                        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.',
                                    period: 3,
                                    defaultAmount: 40000,
                                    totalAmount: 40000,
                                    monthlyAmount: 5,
                                },
                                {
                                    name: 'Special Treatment',
                                    id: 'spectr',
                                    description:
                                        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.',
                                    period: 3,
                                },
                            ]}
                        />
                    </Form>
                </Formik> */}

                <Title isCenter={false} tag="strong" size="lg">
                    Back Button
                </Title>
                <HorizontalRule />

                <BackButton onClick={() => console.log('back')} />

                <Title isCenter={false} tag="strong" size="lg">
                    Input With Slider
                </Title>
                <HorizontalRule />

                <div>
                    <Formik
                        initialValues={{ sliderInput: val }}
                        onSubmit={(values) => console.log('submit', values)}
                        enableReinitialize
                    >
                        <Form noValidate>
                            <div className="flex items-center font-medium">
                                {active ? (
                                    <Field
                                        component={Input}
                                        type="number"
                                        name="sliderInput"
                                        initialValue={val}
                                        handleValue={setVal}
                                    />
                                ) : (
                                    <div className="cursor-pointer underline" onClick={() => setActive(true)}>
                                        {val}
                                    </div>
                                )}
                                <div className="ml-4">{PL_CURRENCY}</div>
                            </div>

                            <Slider
                                name="slidr"
                                initialValue={val}
                                minValue={1000}
                                maxValue={5000}
                                onChange={() => {}}
                            />
                        </Form>
                    </Formik>
                </div>

                <Title isCenter={false} tag="strong" size="lg">
                    Carousel
                </Title>
                <HorizontalRule />

                <Carousel
                    data={[
                        {
                            title: 'Úraz nehraje prim',
                            text: 'Víte, že úrazy jsou příčinou úmrtí lidí ve věku 30-34 let v méně než třetině případů? A u50letých je to pouze každá desátá příčina smrti. U pojištění invalidity je úraz příčinoujejího vzniku pouze u 1 případu z 20! Proto je potřeba mít pojištění uzavřeno jak na úrazy,tak především na nemoci. Ty ohrožují naše příjmy podstatně více.',
                        },
                        {
                            title: 'Úraz nehraje prim',
                            text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente dicta eos rerum beatae vel expedita, reprehenderit accusantium odio illum, iusto voluptate reiciendis? Ad beatae perspiciatis animi iusto, repudiandae asperiores dolorum.',
                        },
                        {
                            title: 'Úraz nehraje prim',
                            text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente dicta eos rerum beatae vel expedita, reprehenderit accusantium odio illum, iusto voluptate reiciendis?',
                        },
                    ]}
                />

                <Title isCenter={false} tag="strong" size="lg">
                    Popup Info
                </Title>
                <HorizontalRule />

                <Title isCenter={false} tag="strong" size="lg">
                    Summary Table
                </Title>
                <HorizontalRule />
            </div>
        </Layout>
    );
};
