import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DashboardContext } from './Context';
import { hasPermission, useTableContext } from '../../../../utils/hooks';
import { Policy } from '../types';
import DataTable from '../../../../components/DataTable/DataTable';
import { Column } from '../../../../components/DataTable';
import { callMyPolicyList } from '../../../../apis/dashboard';
import MyPolicyToggle from './MyPolicyToggle';
import { AGENT_PORTAL_RIGHTS, HUMAN_DATE_FORMAT } from '../../../../constants';
import PolicyStatus from './PolicyStatus';
import { AppContext } from '../../../../components/Context';
import PolicyFilter, { PolicyFilterType, initPolicyFilter } from './PolicyFilter';
import { filterPolicy } from '../helpers';
import PolicyPaymentSymbols from './PolicyPaymentSymbols';
import DashboardLayout from './DashboardLayout';

const MyPolicyList = () => {
    const { t } = useTranslation();
    const { myPolicy, setMyPolicy } = useContext(DashboardContext);
    const ctx = useContext(AppContext);
    const { userRights } = ctx;
    const [filter, setFilter] = useState<PolicyFilterType>(initPolicyFilter);

    const { fullText, handleFullTextSearchChange, ...tableProps } = useTableContext<Policy>(
        myPolicy,
        setMyPolicy,
        callMyPolicyList,
        filter,
        {
            completeFilter: (data: Policy) => filterPolicy(data, filter),
        }
    );

    return (
        <DashboardLayout>
            <PolicyFilter filter={filter} onChange={(f) => setFilter(f)} />
            <DataTable<Policy>
                // disablePagination
                // disableRecordsOnPage
                toggableRows={hasPermission(AGENT_PORTAL_RIGHTS.POLICY_DETAIL, userRights)}
                {...tableProps}
                onToggleContent={(data) => <MyPolicyToggle data={data} />}
                data-test="myPolicyList"
            >
                <Column
                    // sortable
                    dataIndex="PolicyNo"
                    label={t('dashboard.common.policyNumber')}
                />
                <Column
                    // sortable
                    dataIndex="Policyholder"
                    label={t('dashboard.common.policyholder')}
                    render={(data: Policy) => `${data.OwnerLastName} ${data.OwnerFirstName}`}
                />
                <Column
                    // sortable
                    dataIndex="IllustrationDt"
                    label={t('dashboard.common.createdDate')}
                    render={(data) => dayjs(data.IllustrationDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="BeginningDt"
                    label={t('dashboard.common.beginningDate')}
                    render={(data) => dayjs(data.BeginningDt).format(HUMAN_DATE_FORMAT)}
                />
                <Column
                    // sortable
                    dataIndex="PolicyStatusCode"
                    label={t('dashboard.common.currentStatus')}
                    render={(data: Policy) => <PolicyStatus statusCode={data.PolicyStatusCode} />}
                />
                <Column
                    // sortable
                    dataIndex="PaidToDt"
                    label={t('dashboard.common.paidToDt')}
                    render={(data: Policy) => (
                        <div className="whitespace-nowrap">
                            {data.PaidToDt ? (
                                <Fragment>
                                    <PolicyPaymentSymbols data={data} />
                                    {dayjs(data.PaidToDt).format(HUMAN_DATE_FORMAT)}
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                />
                <Column
                    // sortable
                    dataIndex="TerminationDt"
                    label={t('dashboard.common.terminationDt')}
                    render={(data: Policy) => (
                        <div className="whitespace-nowrap">
                            {data.TerminationDt ? dayjs(data.TerminationDt).format(HUMAN_DATE_FORMAT) : ''}
                        </div>
                    )}
                />
            </DataTable>
        </DashboardLayout>
    );
};

export default MyPolicyList;
