import { Fragment, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as AutopayLogo } from '../assets/images/autopay-logo.svg';
import {
    AutopayGateway,
    CallAutopayCheckPolicyPaymentResponse,
    callAutopayCheckPolicyPayment,
    callAutopayGetGatewayList,
    callAutopayInitiatePayment,
} from '../apis/autopay';
import Loader from '../components/common/Loader';
import { Layout } from '../components/Layout';
import { Head } from '../components/Head';
import { CardsWrapper } from '../components/CardsWrapper';
import { PackageCard } from '../components/PackageCard';
import { ROUTE } from '../constants';
import Alert from '../components/Alert';

export const AutopayPaymentMethod = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { externalId } = useParams();
    const formRef = useRef<HTMLFormElement>(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gateways, setGateways] = useState<AutopayGateway[] | null>(null);
    const [paymentResponse, setPaymentResponse] = useState<any>(null);
    const [policyPaymentResponse, setPolicyPaymentResponse] = useState<CallAutopayCheckPolicyPaymentResponse | null>(
        null
    );

    const handleChoicePayment = (gateway: AutopayGateway) => {
        if (externalId) {
            setLoading(true);
            setPaymentResponse(null);
            callAutopayInitiatePayment({
                data: {
                    policyExternalId: externalId,
                    gatewayId: gateway.GatewayId,
                },
            })
                .then((response) => response.data)
                .then((data) => setPaymentResponse(data))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (!externalId) {
            navigate(ROUTE.ERROR_404);
        }
        if (externalId) {
            setLoading(true);
            setGateways(null);
            callAutopayCheckPolicyPayment({
                data: {
                    policyExternalId: externalId,
                },
            })
                .then((response) => response.data)
                .then((data) => setPolicyPaymentResponse(data))
                .catch(() => navigate(ROUTE.ERROR_404))
                .finally(() => setLoading(false));
        }
    }, [externalId, navigate]);

    useEffect(() => {
        if (policyPaymentResponse && externalId) {
            if (policyPaymentResponse.IsPolicyExternalIdValid !== true) {
                navigate(ROUTE.ERROR_404);
            } else if (policyPaymentResponse.IsPolicyPaid === true) {
                navigate(ROUTE.AUTOPAY_POLICY_PAID);
            } else {
                setError(false);
                setLoading(true);
                callAutopayGetGatewayList({
                    data: {
                        policyExternalId: externalId,
                    },
                })
                    .then((response) => response.data?.Gateways)
                    .then((result) => {
                        setGateways(result);
                        if (result.length === 0) {
                            setError(true);
                        }
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false));
            }
        }
    }, [policyPaymentResponse, externalId, navigate]);

    useEffect(() => {
        if (paymentResponse && formRef && formRef.current) {
            formRef?.current?.submit();
        }
    }, [paymentResponse, formRef]);

    return (
        <Layout hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <Head heading1={t('pages.autopayMethod.title')} heading2={t('pages.autopayMethod.subtitle')} />
            {loading ? (
                <Loader overflow />
            ) : (
                <Fragment>
                    <CardsWrapper className="mb-12 text-center" data-test="paymentMethods">
                        {gateways?.map((gateway, index) => (
                            <PackageCard
                                key={index}
                                image={gateway.IconUrl}
                                title={gateway.GatewayName}
                                onClick={() => handleChoicePayment(gateway)}
                                className="h-[18rem] pb-5"
                                brokenTooltipContent={t('pages.autopayMethod.paymentBroken')}
                                data-test={`paymentMethod${gateway.GatewayId}`}
                            />
                        ))}
                    </CardsWrapper>

                    {paymentResponse && (
                        <div className="hidden" data-test="paymentMethodsHiddenForm">
                            <form
                                ref={formRef}
                                method="post"
                                action={paymentResponse.InitiatePaymentRedirectUrl}
                                encType="application/x-www-form-urlencoded"
                            >
                                <input type="hidden" name="ServiceID" value={paymentResponse.ServiceId} />
                                <input type="hidden" name="OrderID" value={paymentResponse.OrderId} />
                                <input type="hidden" name="Amount" value={paymentResponse.Amount} />
                                <input type="hidden" name="Description" value={paymentResponse.Description} />
                                <input type="hidden" name="GatewayID" value={paymentResponse.GatewayId} />
                                <input type="hidden" name="Currency" value={paymentResponse.CurrencyCode} />
                                <input type="hidden" name="CustomerNRB" value={paymentResponse.CustomerNrb} />
                                <input type="hidden" name="Title" value={paymentResponse.Title} />
                                <input type="hidden" name="ReceiverName" value={paymentResponse.ReceiverName} />
                                <input type="hidden" name="CustomerEmail" value={paymentResponse.CustomerEmail} />
                                <input type="hidden" name="ValidityTime" value={paymentResponse.ValidityTime} />
                                <input type="hidden" name="LinkValidityTime" value={paymentResponse.LinkValidityTime} />
                                <input type="hidden" name="Hash" value={paymentResponse.Hash} />
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    )}
                </Fragment>
            )}
            {error && (
                <Alert
                    title={t<string>('pages.autopayMethod.error')}
                    className="mb-10 pb-5"
                    data-test="paymentMethodsError"
                />
            )}
            <div className="mt-auto" data-test="paymentProvidedByAutopay">
                <div className="mt-auto flex items-center justify-center" data-test="paymentProvidedByAutopay">
                    {t('pages.autopayMethod.paymentProvided')}
                    <a href="https://autopay.pl/" target="_blank" rel="noreferrer">
                        <AutopayLogo className="ml-3 h-[2rem]" />
                    </a>
                </div>
                <div className="mt-10 flex items-center justify-center" data-test="forMoreInformation">
                    <Trans
                        i18nKey="pages.autopayMethod.forMoreInformation"
                        components={{
                            link1: (
                                <a
                                    href="https://www.simplea.pl/pl/informacje-dotyczace-platnosci/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ml-1 underline"
                                >
                                    .
                                </a>
                            ),
                        }}
                    />
                </div>
            </div>
        </Layout>
    );
};
