import React, { useContext, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Input } from '../../../../components/common/Input';
import { OptionProps, Select } from '../../../../components/common/Select';
import { getPolicyStatusesSelectList } from '../../../../utils';
import { Button } from '../../../../components/common/Button';
import { Icon } from '../../../../components/Icon';
import { Link } from '../../../../components/common/Link';
import { DetectFormikChanged } from '../../../../components/common/DetectFormikChanged';
import { AppContext } from '../../../../components/Context';
import DateInput from '../../../../components/common/DateInput';

export interface PolicyFilterType {
    fulltext: string;
    status: OptionProps[];
    policyNumber: string;
    policyholder: string;
    createdDateFrom: string;
    createdDateTo: string;
    beginningDateFrom: string;
    beginningDateTo: string;
    writingAgent: OptionProps[];
    servicingAgent: OptionProps[];
    overdue: OptionProps | null;
}

export const initPolicyFilter: PolicyFilterType = {
    fulltext: '',
    status: [],
    policyNumber: '',
    policyholder: '',
    createdDateFrom: '',
    createdDateTo: '',
    beginningDateFrom: '',
    beginningDateTo: '',
    writingAgent: [],
    servicingAgent: [],
    overdue: null,
};

interface PolicyFilterProps {
    filter: PolicyFilterType;
    withAgents?: boolean;
    writingAgents?: string[];
    servisingAgents?: string[];
    servisingAgencies?: string[];
    onChange: (filter: PolicyFilterType) => void;
}

const PolicyFilter = ({
    filter,
    withAgents = false,
    writingAgents = [],
    servisingAgents = [],
    servisingAgencies = [],
    onChange,
}: PolicyFilterProps) => {
    const { t } = useTranslation();
    const { initData } = useContext(AppContext);
    const [showMore, setShowMore] = useState(false);

    const statusOptions = getPolicyStatusesSelectList(initData).filter(
        (s) => !['PPV', 'PR', 'WA'].includes(s.value.toString())
    );

    const writingAgentsOptions = useMemo(
        () => writingAgents.map((agent) => ({ label: agent, value: agent })),
        [writingAgents]
    );

    const servisingAgentsOptions = useMemo(
        () => servisingAgents.map((agent) => ({ label: agent, value: agent })),
        [servisingAgents]
    );

    // eslint-disable-next-line
    const servisingAgenciesOptions = useMemo(
        () => servisingAgencies.map((agency) => ({ label: agency, value: agency })),
        [servisingAgencies]
    );

    const overdueOptions = [
        {
            label: t('common.yes'),
            value: '1',
        },
        {
            label: t('common.no'),
            value: '0',
        },
    ];

    return (
        <div
            className={cn('mb-8 flex flex-col rounded-sm bg-lightPurple p-4', showMore && 'pb-0')}
            data-test="policyFilter"
        >
            <Formik<PolicyFilterType> initialValues={filter} onSubmit={(v) => {}}>
                {({ setValues }) => {
                    return (
                        <>
                            <DetectFormikChanged onChange={onChange} />
                            <Form>
                                <div className="relative pb-3">
                                    <div className="grid grid-cols-12 gap-x-4">
                                        <div className="col-span-12 md:col-span-8">
                                            <Field
                                                component={Input}
                                                name="fulltext"
                                                size="small"
                                                label={t('dashboard.common.searchTerm')}
                                                data-test="filterFulltext"
                                            />
                                        </div>
                                        <div className="col-span-12 md:col-span-4">
                                            <Field
                                                component={Select}
                                                name="status"
                                                size="small"
                                                isMulti
                                                options={statusOptions}
                                                label={t('dashboard.common.status')}
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                data-test="filterStatus"
                                            />
                                        </div>
                                    </div>
                                    {showMore && (
                                        <>
                                            <div className="grid grid-cols-12 gap-x-4">
                                                <div className="col-span-12 md:col-span-4">
                                                    <Field
                                                        component={Input}
                                                        name="policyNumber"
                                                        size="small"
                                                        label={t('dashboard.common.policyNumber')}
                                                        data-test="filterPolicyNumber"
                                                    />
                                                </div>
                                                <div className="col-span-12 md:col-span-4">
                                                    <Field
                                                        component={Input}
                                                        name="policyholder"
                                                        size="small"
                                                        label={t('dashboard.common.policyholder')}
                                                        data-test="filterPolicyholder"
                                                    />
                                                </div>
                                                <div className="col-span-12 md:col-span-4">
                                                    <Field
                                                        component={Select}
                                                        options={overdueOptions}
                                                        isClearable
                                                        name="overdue"
                                                        size="small"
                                                        label={t('dashboard.common.overdue')}
                                                        data-test="filterOverdue"
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-12 gap-x-4">
                                                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                    <Field
                                                        component={DateInput}
                                                        name="createdDateFrom"
                                                        size="small"
                                                        label={t('dashboard.common.createdDateFrom')}
                                                        data-test="filterCreatedDateFrom"
                                                    />
                                                </div>
                                                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                    <Field
                                                        component={DateInput}
                                                        name="createdDateTo"
                                                        size="small"
                                                        label={t('dashboard.common.createdDateTo')}
                                                        data-test="filterCreatedDateTo"
                                                    />
                                                </div>
                                                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                    <Field
                                                        component={DateInput}
                                                        name="beginningDateFrom"
                                                        size="small"
                                                        label={t('dashboard.common.beginningDateFrom')}
                                                        data-test="filterBeginningDateFrom"
                                                    />
                                                </div>
                                                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                                    <Field
                                                        component={DateInput}
                                                        name="beginningDateTo"
                                                        size="small"
                                                        label={t('dashboard.common.beginningDateTo')}
                                                        data-test="filterBeginningDateTo"
                                                    />
                                                </div>
                                            </div>
                                            {withAgents && (
                                                <div className="grid grid-cols-12 gap-x-4">
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Field
                                                            component={Select}
                                                            name="writingAgent"
                                                            isMulti
                                                            options={writingAgentsOptions}
                                                            size="small"
                                                            label={t('dashboard.common.writingAgent')}
                                                            hideSelectedOptions={false}
                                                            closeMenuOnSelect={false}
                                                            data-test="filterWritingAgent"
                                                        />
                                                    </div>
                                                    <div className="col-span-12 md:col-span-6">
                                                        <Field
                                                            component={Select}
                                                            name="servicingAgent"
                                                            isMulti
                                                            options={servisingAgentsOptions}
                                                            size="small"
                                                            label={t('dashboard.common.servicingAgent')}
                                                            hideSelectedOptions={false}
                                                            closeMenuOnSelect={false}
                                                            data-test="filterServicingAgent"
                                                        />
                                                    </div>
                                                    {/* <div className="col-span-1">
                                                        <Field
                                                            component={Select}
                                                            name="servicingAgency"
                                                            isMulti
                                                            options={servisingAgenciesOptions}
                                                            size="small"
                                                            label={t('dashboard.common.servicingAgency')}
                                                            hideSelectedOptions={false}
                                                            closeMenuOnSelect={false}
                                                            data-test="filterServicingAgency"
                                                        />
                                                    </div> */}
                                                </div>
                                            )}
                                            <div className="text-right mdmax:mb-4">
                                                <Link
                                                    className="ml-auto gap-x-1 font-light text-darkPurple"
                                                    onClick={() => {
                                                        setValues(initPolicyFilter);
                                                        onChange(initPolicyFilter);
                                                    }}
                                                    data-test="resetFilter"
                                                >
                                                    {t('dashboard.common.resetFilter')}
                                                    <Icon name="close3" className="font-light" />
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        className="absolute left-0 right-0 justify-center"
                                        style={{ bottom: !showMore ? -32 : -15 }}
                                    >
                                        <Button
                                            size="sm"
                                            isOutlined
                                            isFixedSize={false}
                                            className="m-auto cursor-pointer justify-center text-xs"
                                            onClick={() => setShowMore(!showMore)}
                                            data-test="moreLessFilter"
                                        >
                                            <>
                                                {showMore
                                                    ? t('dashboard.common.lessFilter')
                                                    : t('dashboard.common.moreFilter')}
                                                <Icon
                                                    name="chevron-up"
                                                    className={cn('ml-3', !showMore && 'rotate-180')}
                                                />
                                            </>
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

export default PolicyFilter;
