import React from 'react';

interface BadgeProps {
    value: string;
}

const Badge = ({ value }: BadgeProps) => (
    <span className="rounded-sm bg-purple px-1.5 text-xxs text-white" style={{ paddingTop: '1px' }}>
        {value}
    </span>
);

export default Badge;
