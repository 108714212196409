import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SaveIcon } from '../../../../../assets/images/save-2.svg';
import { Link } from '../../../../../components/common/Link';

export const onClickOpenDocument = (fileName: string) => () => {
    window.open(`/assets/${fileName}`, '_blank');
};

const Documents = () => {
    const { t } = useTranslation();

    const documents: { [key: string]: string } = {
        GTC: 'GTC_15-01-2024_2.pdf',
        Uposazony: 'Uposazony.pdf',
        DefinicjeZOWU: 'definicje-z-OWU.pdf',
        MojaSimpleaManual: 'mojaSimplea1.0-sciezka-sprzedazy.pdf',
        OkresKarencji: 'Okres-karencji.pdf',
        FaQ: 'QA.pdf',
        RynekDocelowy: 'Rynek-docelowy.pdf',
        WylaczeniaOdpowiedzialnosci: 'Wylaczenia-odpowiedzialnosci.pdf',
        ZalacznikNr1OWU: 'Zalacznik-nr-1-OWU.pdf',
        ZalacznikNr2OWU: 'Zalacznik-nr-2-OWU.pdf',
        ZalacznikNr3OWU: 'Zalacznik-nr-3-OWU.pdf',
        ZalacznikNr4OWU: 'Zalacznik-nr-4-OWU.pdf',
        ZalacznikNr5Assistance: 'Zalacznik-nr-5-Assistance.pdf',
        WykazFunkcjiPep: 'Wykaz-funkcji-PEP.pdf',
        PepKwestionariusz: 'PEP_kwestionariusz_06-2024.pdf',
        DefinicjaPep: 'Definicja-Czlonkowie-rodziny-oraz-Bliscy-wspolpracownicy-PEP.pdf',
        SimpleaSalesPath: 'Simplea_Sales_Path-IT_security.pdf',
        RegulacjeAML: 'AML_agents_PL_1-10-2024.pdf',
    };

    return (
        <table className="styled-table" data-test="documentList">
            <tbody>
                <tr>
                    <td>
                        {Object.keys(documents).map((key: string) => (
                            <div key={`document-item-${key}`} className="flex pb-1 pt-3" data-test="documentItem">
                                <div className="w-9">
                                    <SaveIcon data-test="saveIcon" />
                                </div>
                                <div className="w-full border-b-1 pb-2">
                                    <Link
                                        className="font-medium text-black no-underline"
                                        onClick={onClickOpenDocument(documents[key])}
                                        data-test="documentLink"
                                    >
                                        {t(`knowledgeBase.documents.${key}`)}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default Documents;
