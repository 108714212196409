import { ReactNode } from 'react';
import cn from 'classnames';

import { SizesType } from '../../types';
import { getDataTest, getTextClassname } from '../../utils';
import { Help } from './Help';

interface TitleProps {
    children: ReactNode;
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'strong';
    size: SizesType;
    isCenter?: boolean;
    help?: string;
    fontWeight?: 'regular' | 'medium' | 'bold';
    className?: string;
}

export const Title = ({
    children,
    tag,
    size,
    help,
    isCenter = true,
    fontWeight = 'bold',
    className,
    ...props
}: TitleProps) => {
    const Tag = tag;
    return (
        <Tag
            className={cn(
                className,
                'flex items-center',
                getTextClassname(size),
                fontWeight === 'bold' ? 'font-bold' : fontWeight === 'medium' ? 'font-medium' : 'font-normal',
                isCenter && 'mx-auto text-center'
            )}
            {...props}
        >
            {children}
            {help && <Help text={help} className="ml-4" data-test={getDataTest(props, 'Help')} />}
        </Tag>
    );
};
