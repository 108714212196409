import { ChangeEvent, Fragment, useMemo, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

import Dog from '../../assets/images/dog.png';
import Ghost from '../../assets/images/ghost.png';
import Person from '../../assets/images/person-pc.png';
import MojeId from '../../assets/images/mojeid-logo.svg';
import Alarm from '../../assets/images/alarm.png';
import Bicycle from '../../assets/images/bicycle.png';
import Glasses from '../../assets/images/glasses.png';
import Zorbing from '../../assets/images/zorbing.svg';
import Hear from '../../assets/images/hear.svg';
import Paper from '../../assets/images/paper.png';
import { Title } from '../common/Title';
import { Button } from '../common/Button';
import { Input } from '../common/Input';
import { PopupType } from '../../types';
import { callResetAgentPasswordRequested } from '../../apis/authentications';
import { ROUTE } from '../../constants';
import PopupButtons from '../common/PopupButtons';
import { AppContextProps } from '../Context';
import { useAppNavigate } from '../../utils/hooks';
import { getStepPositionByPathName } from '../../utils';
import { ReactComponent as CloseIcon } from '../../assets/images/close3.svg';

export const renderTextContent = (title: string | null, text: string | null, type?: PopupType) => (
    <div className="mb-6 flex flex-col text-center">
        <img
            src={type === 'pep' ? Ghost : type === 'lost-data-warning' ? Person : Dog}
            alt=""
            className="mb-8 ml-auto mr-auto w-[10rem]"
            data-test="popupImage"
        />
        {title && (
            <Title
                isCenter
                size="md"
                tag="strong"
                className="mb-2 md:text-md"
                fontWeight="medium"
                data-test="popupTitle"
            >
                {title}
            </Title>
        )}
        {text && <p data-test="popupDescription">{text}</p>}
    </div>
);

export const MissingDataContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => (
    <>
        {renderTextContent(
            t('pages.packageAdjustment.popupMissingData.title'),
            t('pages.packageAdjustment.popupMissingData.subtitle')
        )}

        <Button onClick={() => ctx.showPopup(null)} className="ml-auto" size="md" data-test="understandButton">
            {t('common.understand')}
        </Button>
    </>
);

export const LostPasswordContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => {
    const [emailSent, setEmailSent] = useState(false);

    const validationSchema = Yup.object().shape({
        loginName: Yup.string().required(),
    });

    return (
        <>
            {renderTextContent(
                t('pages.login.lostPasswordPopup.title'),
                !emailSent ? t('pages.login.lostPasswordPopup.subtitle') : null
            )}

            <Formik
                initialValues={{ loginName: '' }}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnMount={false}
                onSubmit={(values) => {
                    callResetAgentPasswordRequested({
                        data: {
                            loginName: values.loginName,
                        },
                    }).finally(() => setEmailSent(true));
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="flex flex-col" noValidate>
                        {emailSent ? (
                            <p className="mb-8 text-center" data-test="messageSended">
                                {t('pages.login.lostPasswordPopup.sended')}
                            </p>
                        ) : (
                            <Field
                                component={Input}
                                name="loginName"
                                autoFocus
                                pattern={/[0-9]/}
                                placeholder={t('pages.login.lostPasswordPopup.enterYourLoginName')}
                                className="mx-auto mb-8 w-full sm:max-w-[20rem]"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(e.target.name, e.target.value.replace(/\D/g, ''));
                                }}
                                data-test="inputYourLoginName"
                            />
                        )}

                        <div className="ml-auto flex gap-x-2">
                            <Button
                                isOutlined
                                size="md"
                                className="self-stretch bg-transparent"
                                onClick={() => ctx.showPopup(null)}
                                data-test="buttonClose"
                            >
                                {t('common.close')}
                            </Button>

                            <Button
                                size="md"
                                isSubmit
                                className="self-stretch"
                                isDisabled={emailSent}
                                data-test="buttonSendLink"
                            >
                                {t('common.sendLink')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export const PepContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => (
    <>
        {renderTextContent(
            t('pages.personalContactInformation.popup.title'),
            t('pages.personalContactInformation.popup.subtitle'),
            'pep'
        )}

        <PopupButtons>
            <Button onClick={() => ctx.showPopup(null)} className="ml-auto" data-test="understandButton">
                {t('common.understand')}
            </Button>
        </PopupButtons>
    </>
);

export const InsurablePersonContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps,
    navigate: NavigateFunction
) => {
    const [, setSearchParams] = useSearchParams();
    return (
        <>
            {renderTextContent(t('pages.occupation.popupDenied.title'), null)}

            <PopupButtons>
                <Button
                    isOutlined
                    size="md"
                    className="bg-transparent"
                    onClick={() => {
                        ctx.clearCurrentModel();
                        ctx.showPopup(null);
                        setSearchParams({});

                        setTimeout(() => {
                            navigate(ROUTE.CONCLUSION);
                        }, 100);
                    }}
                    data-test="startFromBeginningButton"
                >
                    {t('common.startFromBeginning')}
                </Button>

                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                        // TODO: otestovat, zda se to při zobrazení tohoto popupu chová vše jak má
                        // ctx.setContinueDisabled(false);
                    }}
                    data-test="backButton"
                >
                    {t('common.back')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const LostDataContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => (
    <>
        {renderTextContent(
            t('pages.beneficiaries.popupLostData.title'),
            t('pages.beneficiaries.popupLostData.subtitle')
        )}

        <PopupButtons>
            <Button
                isOutlined
                size="md"
                onClick={() => {
                    ctx.popupCallback && ctx.popupCallback();
                    ctx.showPopup(null);
                }}
                data-test="continueButton"
            >
                {t('common.continue')}
            </Button>
            <Button size="md" onClick={() => ctx.showPopup(null)} data-test="backButton">
                {t('common.back')}
            </Button>
        </PopupButtons>
    </>
);

export const MojeIdContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => {
    const popupItems: any = t('pages.mojeId.card.popupHint.items', {
        returnObjects: true,
    });

    return (
        <>
            <CloseIcon
                className="absolute right-0 top-0 cursor-pointer"
                onClick={() => ctx.showPopup(null)}
                data-test="closeIcon"
            />

            <img src={MojeId} alt="" className="mx-auto h-auto w-[14rem]" data-test="popupImage" />

            <div className="mb-8 flex flex-col gap-y-6">
                {popupItems.map((p: { title: string; text: string }, i: number) => (
                    <div key={i}>
                        <Title tag="strong" size="sm" className="mb-2 font-medium" data-test={`popupContentTitle${i}`}>
                            {p.title}
                        </Title>
                        <p data-test={`popupContentText${i}`}>
                            <Trans
                                i18nKey={`pages.mojeId.card.popupHint.items.${i}.text`}
                                components={{
                                    link1: (
                                        <a
                                            href="https://www.mojeid.pl/#dostawcy-uslug"
                                            className="font-medium underline"
                                            target="_blank"
                                            rel="noreferrer"
                                            data-test="mojeIdLink"
                                        >
                                            .
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                ))}
            </div>

            <PopupButtons>
                <Button onClick={() => ctx.showPopup(null)} data-test="closeButton">
                    {t('common.close')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const WaitingPaymentContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => {
    const texts: any = t('pages.final.waitingPopup.texts', { returnObjects: true });
    return (
        <>
            <img src={Alarm} alt="" className="mx-auto mb-10 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-2" data-test="popupDescription">
                {t('pages.final.waitingPopup.title')}
            </Title>

            {texts.map((p: string, i: number) => (
                <p key={i} className="mb-8 text-center text-sm">
                    {p}
                </p>
            ))}

            <PopupButtons>
                <Button size="md" className="ml-auto" onClick={() => ctx.showPopup(null)} data-test="understandButton">
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ReceivedPaymentContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => {
    const texts: any = t('pages.final.receivedPopup.texts', { returnObjects: true });
    return (
        <>
            <img src={Bicycle} alt="" className="mx-auto mb-10 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-2" data-test="popupDescription">
                {t('pages.final.receivedPopup.title')}
            </Title>

            {texts.map((p: string, i: number) => (
                <p key={i} className="mb-8 text-center text-sm">
                    {p}
                </p>
            ))}

            <PopupButtons>
                <Button
                    size="md"
                    className="ml-auto"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ChangePasswordDoneContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    const navigate = useNavigate();

    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-8" data-test="popupDescription">
                {t('pages.changePassword.done')}
            </Title>

            <PopupButtons>
                <Button
                    size="md"
                    className="ml-auto"
                    onClick={() => {
                        ctx.showPopup(null);
                        navigate(ROUTE.LOGIN);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const InitPasswordDoneContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    const navigate = useNavigate();

    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-8" data-test="popupDescription">
                {t('pages.initPassword.done')}
            </Title>

            <PopupButtons>
                <Button
                    size="md"
                    className="ml-auto"
                    onClick={() => {
                        ctx.showPopup(null);
                        navigate(ROUTE.LOGIN);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ResetPasswordDoneContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    const navigate = useNavigate();

    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-8" data-test="popupDescription">
                {t('pages.resetPassword.done')}
            </Title>

            <PopupButtons>
                <Button
                    size="md"
                    className="ml-auto"
                    onClick={() => {
                        ctx.showPopup(null);
                        navigate(ROUTE.LOGIN);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ResetPasswordErrorContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Dog} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-8" data-test="popupDescription">
                {t('pages.resetPassword.error')}
            </Title>

            <PopupButtons>
                <Button
                    size="md"
                    className="ml-auto"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const DistributorErrorContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('pages.summary.popupError')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const LimitedAccessFromBasicInfoContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Dog} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('limitedAccess.fromBasicInfo.description')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const LimitedAccessToPublicContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    const navigate = useNavigate();
    const { currentStep } = useAppNavigate();

    const isInCoverageSummary = useMemo(
        () =>
            currentStep >= getStepPositionByPathName(ROUTE.COVER_ADJUST) &&
            currentStep <= getStepPositionByPathName(ROUTE.SUMMARY),
        [currentStep]
    );

    const isAfterSummary = useMemo(() => currentStep > getStepPositionByPathName(ROUTE.SUMMARY), [currentStep]);

    return (
        <>
            <img src={Dog} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {isAfterSummary ? (
                    t('limitedAccess.toConclusionOrPublic.descriptionAfterSummary')
                ) : isInCoverageSummary ? (
                    <Trans
                        i18nKey="limitedAccess.toConclusionOrPublic.descriptionInCoverateAndSummary"
                        components={{
                            link1: (
                                <Link
                                    to={ROUTE.DASHBOARD_DRAFTS}
                                    data-test="link"
                                    onClick={() => {
                                        ctx.clearCurrentModel(true);
                                        ctx.showPopup(null);
                                    }}
                                    className="underline"
                                >
                                    .
                                </Link>
                            ),
                        }}
                    />
                ) : (
                    t('limitedAccess.toConclusionOrPublic.description')
                )}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.clearCurrentModel(true);
                        ctx.showPopup(null);

                        setTimeout(() => {
                            if (ctx.popupCallback) {
                                ctx.popupCallback();
                            } else {
                                navigate(ROUTE.DASHBOARD_MAIN);
                            }
                        }, 100);
                    }}
                    data-test="proceedAndResetDataButton"
                >
                    {t(isAfterSummary ? 'common.goToMainMenu' : 'common.proceedAndResetData')}
                </Button>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="stayHereButton"
                >
                    {t('common.stayHere')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ManualUploadResetContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Dog} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('pages.documentPhoto.resetDataPopup.text')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.popupCallback && ctx.popupCallback();
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="stayHereButton"
                >
                    {t('common.stayHere')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const DraftCannotContinueContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Dog} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('dashboard.common.draftCannotContinue')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const NavigationButtonsDisabledContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('common.navigationButtonsAreDisabled')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.showPopup(null);
                        ctx.popupCallback && ctx.popupCallback();
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const SmsSendedContent = (t: TFunction<'translation', undefined, 'translation'>, ctx: AppContextProps) => {
    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('common.smsWithLinkWasSended')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.showPopup(null);
                        ctx.popupCallback && ctx.popupCallback();
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const ChangeIdentityVerificationTypeWarningContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <>
            <img src={Glasses} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('pages.identityVerification.changeTypeWarning.description')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.popupCallback && ctx.popupCallback();
                        ctx.showPopup(null);
                    }}
                    data-test="understandButton"
                >
                    {t('common.yes')}
                </Button>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="stayHereButton"
                >
                    {t('common.stayHere')}
                </Button>
            </PopupButtons>
        </>
    );
};

export const CancelWaitingCaseContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <Fragment>
            <img src={Zorbing} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-4" data-test="popupTitle">
                {t('dashboard.waitingCase.cancel.title')}
            </Title>

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('dashboard.waitingCase.cancel.description')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    isOutlined
                    onClick={() => {
                        ctx.popupCallback && ctx.popupCallback();
                        ctx.showPopup(null);
                    }}
                    data-test="yesCancelButton"
                >
                    {t('common.yesCancel')}
                </Button>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                    }}
                    data-test="stayHereButton"
                >
                    {t('common.stayHere')}
                </Button>
            </PopupButtons>
        </Fragment>
    );
};

export const WaitingCaseSubmitContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <Fragment>
            <img src={Paper} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-4" data-test="popupTitle">
                {t('dashboard.waitingCase.submit.title')}
            </Title>

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('dashboard.waitingCase.submit.description')}
            </p>

            <PopupButtons>
                <Button size="md" onClick={() => ctx.showPopup(null)} isOutlined data-test="backButton">
                    {t('common.back')}
                </Button>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                        ctx.popupCallback && ctx.popupCallback();
                    }}
                    data-test="yesContinueButton"
                >
                    {t('common.yesContinue')}
                </Button>
            </PopupButtons>
        </Fragment>
    );
};

export const WaitingCaseSavedContent = (
    t: TFunction<'translation', undefined, 'translation'>,
    ctx: AppContextProps
) => {
    return (
        <Fragment>
            <img src={Hear} alt="" className="mx-auto mb-8 h-auto w-[8.125rem]" data-test="popupImage" />

            <Title tag="strong" size="md" fontWeight="medium" className="mb-4" data-test="popupTitle">
                {t('dashboard.waitingCase.success.title')}
            </Title>

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('dashboard.waitingCase.success.description')}
            </p>

            <PopupButtons>
                <Button
                    size="md"
                    onClick={() => {
                        ctx.showPopup(null);
                        ctx.popupCallback && ctx.popupCallback();
                    }}
                    data-test="understandButton"
                >
                    {t('common.understand')}
                </Button>
            </PopupButtons>
        </Fragment>
    );
};
