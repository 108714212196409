import React, { Fragment } from 'react';
import { Card } from '../../components/common/Card';
import CardTitle from './CardTitle';
import { Field } from 'formik';
import DateInput from '../../components/common/DateInput';
import { Grid } from '../../components/Grid';
import { Input } from '../../components/common/Input';
import CardDivier from './CardDivier';
import Question from './Question';
import HelperText from '../../components/HelperText';
import { Textarea } from '../../components/common/Textarea';
import IncapacityForm from './IncapacityForm';
import InvalidityForm from './InvalidityForm';
import BankCard from './BankCard';
import UploadFilesCard from './UploadFilesCard';
import CorrectCard from './CorrectCard';
import Buttons from './Buttons';
import LongTermCareForm from './LongTermCareForm';
import DuringSportsForm from './DuringSportsForm';
import OpenSection from './OpenSection';

interface InjuryFormProps {
    values: any;
}

const InjuryForm = ({ values }: InjuryFormProps) => {
    return (
        <Fragment>
            <Card hasPadding={false} className="p-5 px-7">
                <CardTitle>Popis události</CardTitle>
                <div className="grid gap-y-2">
                    <Grid>
                        <Field component={DateInput} name="injuryDate" label="Datum úrazu" />
                        <Field
                            component={DateInput}
                            label="Datum první návštěvy lekáře"
                            name="injuryDateFirstDoctorVisit"
                        />
                    </Grid>
                    <Field component={Input} name="injuryDescription" label="Jak k úrazu došlo" placeholder="Popište" />
                    <CardDivier />
                    <Question
                        name="alcoholOrNarcotics"
                        text="Došlo k úrazu pod vlivem alkoholu či omamné nebo psychotropní látky?"
                    />
                    <CardDivier />
                    <Question name="policeInvestigation" text="Vyšetřovala událost policie?" />
                    {values.policeInvestigation === 'yes' && (
                        <OpenSection>
                            <Field
                                component={Textarea}
                                name="policeInfo"
                                label="Bližší informace"
                                placeholder="Uveďte adresu policie, která věc šetřila, číslo jednací, jméno vyšetřovatele a telefon."
                                withoutHelperText
                                className="mb-3"
                            />
                            <HelperText>Přiložte prosím Protokol od policie.</HelperText>
                        </OpenSection>
                    )}
                    <CardDivier />
                    <DuringSportsForm values={values} />

                    <CardDivier />
                    <Question name="permanentConsequences" text="Zanechal Váš úraz trvalé následky?" />
                    {values.permanentConsequences === 'yes' && (
                        <OpenSection>
                            <Field
                                component={Textarea}
                                name="permanentConsequencesDescription"
                                label="Jaké trvalé následky úraz zanechal?"
                                placeholder="Popište"
                            />
                            <CardDivier />
                            <Question name="treatmentTerminated" text="Byla léčba úrazu ukončena?" />
                            {values.treatmentTerminated === 'yes' && (
                                <OpenSection>
                                    <Grid>
                                        <Field
                                            component={DateInput}
                                            name="treatment_termination_dt"
                                            label="Datum ukončení léčení úrazu"
                                        />
                                    </Grid>
                                </OpenSection>
                            )}
                        </OpenSection>
                    )}

                    <CardDivier />
                    <IncapacityForm
                        question="Byl/a jste v souvislosti s tímto úrazem v pracovní neschopnosti nebo bylo Vám vystaveno Rozhodnutí o potřebě ošetřování (péče) o pojištěného?"
                        values={values}
                    />
                    <CardDivier />
                    <InvalidityForm values={values} />
                    <CardDivier />
                    <LongTermCareForm values={values} />
                </div>
            </Card>
            <BankCard />
            <UploadFilesCard />
            <CorrectCard />
            <Buttons submitDisabled={values.correct !== true} />
        </Fragment>
    );
};

export default InjuryForm;
