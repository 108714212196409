import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import RipImage from '../../assets/images/rip.png';
import CrashImage from '../../assets/images/crash.png';
import { Title } from '../../components/common/Title';
import { Button } from '../../components/common/Button';
import { ROUTE } from '../../constants';
import { AppContext } from '../../components/Context';
import { Layout } from '../../components/Layout';

interface ErrorProps {
    type: 'server' | 'not-found' | 'forbidden';
}

export const Error = ({ type }: ErrorProps) => {
    const ctx = useContext(AppContext);
    const { t, i18n } = useTranslation();

    const page = type === 'server' ? 'code500' : type === 'not-found' ? 'code404' : 'code403';

    const clientSide = !ctx.userData;

    return (
        <Layout hideNavigation hideProgressBar hideSaveButton hideUserMenu hideFooter>
            <div
                className="flex flex-col items-center gap-x-16 text-center md:flex-row md:text-left"
                data-test="errorScreen"
            >
                <img src={type === 'server' ? RipImage : CrashImage} alt="" data-test="image" />
                <div className="flex flex-col">
                    <Title tag="strong" size="lg" className="mb-8 mdmax:mx-auto" isCenter={false} data-test="title">
                        {t(`pages.error.${page}.title`)}
                    </Title>

                    <p className="text-md font-bold" data-test="subTitle">
                        {clientSide && i18n.exists(`pages.error.${page}.clientSide.subtitle`)
                            ? t(`pages.error.${page}.clientSide.subtitle`)
                            : t(`pages.error.${page}.subtitle`)}
                    </p>

                    {(type === 'not-found' || type === 'forbidden') && !clientSide && (
                        <Button href={ROUTE.DASHBOARD_MAIN} className="mt-8 md:self-start" data-test="button">
                            {t('common.homepage')}
                        </Button>
                    )}
                </div>
            </div>
        </Layout>
    );
};
