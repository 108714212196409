import React, { useState } from 'react';
import { Field } from 'formik';
import { Select } from '../../components/common/Select';

const SelectPerson = () => {
    const [options] = useState(
        Math.round(Math.random()) === 0
            ? [{ value: 'myself', label: 'Sebe' }]
            : [
                  { value: 'myself', label: 'Sebe' },
                  { value: 'client1', label: 'Klient 1' },
                  { value: 'client2', label: 'Klient 2' },
              ]
    );

    return (
        <Field
            component={Select}
            name="person"
            label="Událost hlásím za"
            isCenter
            isDisabled={options.length === 1}
            options={options}
            className="w-full"
            data-test="person"
        />
    );
};

export default SelectPerson;
