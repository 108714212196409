import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetOnlinePolicyMetadataByCodeProps {
    data: {
        verificationCode: string;
    };
}

export interface CallGetOnlinePolicyMetadataByCodeResponse {
    IsOnlinePolicyOpened: boolean;
    OnlinePolicyExternalId: string;
    PurposeType: number;
    ClientConsents: Array<{
        ConsentPurposeTypeCode: string;
        ConsentForCompanyCode: string;
        ConsentOperationTypeCode: string;
    }>;
}

export const callGetOnlinePolicyMetadataByCode = ({
    data,
}: CallGetOnlinePolicyMetadataByCodeProps): Promise<AxiosResponse<CallGetOnlinePolicyMetadataByCodeResponse, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}support/get-online-policy-attachment-metadata-by-code/${data.verificationCode}`,
    });
