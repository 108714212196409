import React, { useContext } from 'react';
import { Select, SelectProps } from './common/Select';
import { AppContext } from './Context';
import { getSexSelectList } from '../utils';

const SelectSex = (props: SelectProps) => {
    const { initData } = useContext(AppContext);
    const options = getSexSelectList(initData);
    return <Select {...props} options={options || []} />;
};

export default SelectSex;
