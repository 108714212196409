import { ReactNode } from 'react';

import { Icon } from '../Icon';
import { Tooltip } from './Tooltip';

interface HelpProps {
    text: ReactNode;
    isPlain?: boolean;
    width?: 'small' | 'large';
    className?: string;
}

export const Help = ({ text, isPlain, ...props }: HelpProps) => {
    return (
        <Tooltip content={text} {...props}>
            {isPlain ? <span className="cursor-default font-bold text-purple">???</span> : <Icon name="question" />}
        </Tooltip>
    );
};
