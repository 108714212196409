import { ReactElement } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main';
import { Context } from './components/Context';

export const App = (): ReactElement => {
    return (
        <Router>
            <Context>
                <Main />
            </Context>
        </Router>
    );
};
