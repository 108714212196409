import React from 'react';
import { useTranslation } from 'react-i18next';

interface WaitingForClientConsentsProps {
    values: any;
    showErrorConsentsNotExists: boolean;
    phoneNumberRegistered?: boolean;
}

const WaitingForClientConsents = ({
    values,
    showErrorConsentsNotExists,
    phoneNumberRegistered = false,
}: WaitingForClientConsentsProps) => {
    const { t } = useTranslation();

    return (
        <div className="mb-8 mt-8 text-xs font-medium md:mb-12 md:text-sm" data-test="waitForConstentsBox">
            {values.noMailbox ? (
                <p
                    data-test="clientPhone"
                    dangerouslySetInnerHTML={{
                        __html: t<string>('pages.contactConfirmation.emailNotAccessibleText', {
                            interpolation: { escapeValue: false },
                            phoneNumberPrefix: values.phoneNumberPrefix.label,
                            phoneNumber: values.phoneNumber,
                        }),
                    }}
                />
            ) : (
                <>
                    <p>
                        {t('pages.contactConfirmation.emailText')} <span data-test="clientEmail">{values.email}</span>
                    </p>
                    {!phoneNumberRegistered && (
                        <p
                            data-test="clientPhone"
                            dangerouslySetInnerHTML={{
                                __html: t<string>('pages.contactConfirmation.waitingPhoneText', {
                                    interpolation: { escapeValue: false },
                                    phoneNumberPrefix: values.phoneNumberPrefix.label,
                                    phoneNumber: values.phoneNumber,
                                }),
                            }}
                        />
                    )}
                </>
            )}
            <p className="mt-8">{t('pages.contactConfirmation.pressContinue')}</p>
            {showErrorConsentsNotExists && (
                <p className="mt-2 text-red" data-test="weAreStillWaiting">
                    {t('pages.contactConfirmation.weAreStillWaiting')}
                </p>
            )}
        </div>
    );
};

export default WaitingForClientConsents;
