import React from 'react';
import cn from 'classnames';
import { ColumnProps } from '../Column';
import Actions, { ActionColumnClassName } from '../Actions';
import { SortProps } from '../../DataTable';
import { ReactComponent as ArrowSortDown } from '../../../../assets/images/sort-down.svg';
import { ReactComponent as ArrowSortUp } from '../../../../assets/images/sort-up.svg';

interface HeaderProps {
    column: React.Component<ColumnProps>;
    currentSort?: SortProps;
    children?: any;
    handleSortChange?: (sort: SortProps) => void;
}

const Header = ({ column, currentSort, children, handleSortChange, ...props }: HeaderProps) => {
    const { props: columnProps } = column;

    let content = children;

    if (columnProps.sortable && !!handleSortChange) {
        const sortBy =
            currentSort?.sortField === columnProps.dataIndex
                ? currentSort?.sortBy
                : columnProps.defaultSortBy === 'asc'
                ? 'desc'
                : 'asc';

        const icons = (
            <span className="sort-placeholder">
                {currentSort?.sortField === columnProps.dataIndex ? (
                    <>
                        {sortBy === 'desc' && <ArrowSortDown className="sort-icon" />}
                        {sortBy !== 'desc' && <ArrowSortUp className="sort-icon" />}
                    </>
                ) : null}
            </span>
        );

        const onClick = (_: React.MouseEvent<HTMLDivElement>) => {
            if (columnProps.dataIndex) {
                if (currentSort?.sortField === columnProps.dataIndex) {
                    handleSortChange({
                        sortField: columnProps.dataIndex,
                        sortBy: currentSort?.sortBy === 'asc' ? 'desc' : 'asc',
                    });
                } else {
                    handleSortChange({
                        sortField: columnProps.dataIndex,
                        sortBy: columnProps.defaultSortBy === 'asc' ? 'asc' : 'desc',
                    });
                }
            }
        };

        content = (
            <div className={`align-center flex justify-${columnProps.align} cursor-pointer`} onClick={onClick}>
                {columnProps.align === 'right' && icons}
                <u>{children}</u>
                {columnProps.align !== 'right' && icons}
            </div>
        );
    }

    return (
        <th {...props} className={cn(columnProps.className, column instanceof Actions && ActionColumnClassName)}>
            {content}
        </th>
    );
};

export default Header;
