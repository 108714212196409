import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Head } from '../components/Head';
import { Card } from '../components/common/Card';
import { Title } from '../components/common/Title';
import { DownloadList } from '../components/common/DownloadList';
import { AppContext } from '../components/Context';
import { DocumentMetaDataType, callGetConfirmationScreenData } from '../apis/support';
import { API_PATH, ROUTE, STEP_CODE } from '../constants';
import { getBaseUrl, getHigherStep } from '../utils';
import { AttachmentMetaDataType } from '../apis/documents';
import { ParticipantPersonProps, PersonalIdentificationFullProps } from '../types/model';
import Loader from '../components/common/Loader';
import { useAppNavigate } from '../utils/hooks';
import LimitedAccess from '../components/LimitedAccess';
import { Layout } from '../components/Layout';

interface SummaryDocumentData {
    externalId: string;
    fileName: string | null;
    displayName: string | null;
    type: 'Attachmet' | 'Document';
}

function convertDocumentToSummaryDocumentData(
    data: DocumentMetaDataType | AttachmentMetaDataType
): SummaryDocumentData {
    return {
        externalId: data.ExternalId,
        fileName: data.FileName,
        displayName: data.DisplayName,
        type: 'IsValid' in data ? 'Document' : 'Attachmet',
    };
}

function convertDocumentsToSummaryDocumentsData(
    collection: DocumentMetaDataType[] | AttachmentMetaDataType[]
): SummaryDocumentData[] {
    return collection.map(convertDocumentToSummaryDocumentData);
}

export const SummaryDocuments = () => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const { navigateTo } = useAppNavigate();
    const [documents, setDocuments] = useState<SummaryDocumentData[] | null>(null);
    const token = localStorage.getItem('token');
    const data = ctx.currentModel;
    const ownerId = data?.PolicyOwners[0]?.ParticipantExternalId;
    const ownerParticipant = data?.Participants?.find(
        (p) => p.ExternalId === ownerId
    ) as ParticipantPersonProps<PersonalIdentificationFullProps>;

    useEffect(() => {
        if (token && data) {
            callGetConfirmationScreenData({
                data: {
                    onlineModelExternalId: data?.ExternalId,
                },
                token,
            })
                .then((response) => {
                    if (response?.data?.Documents) {
                        setDocuments(convertDocumentsToSummaryDocumentsData(response?.data?.Documents));
                    }
                })
                .catch((err) => console.error('API call error', err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, data]);

    const getDocumentUrl = (summaryDocument: SummaryDocumentData): string =>
        `${getBaseUrl()}${API_PATH}documents/get-${
            summaryDocument.type === 'Document' ? 'document' : 'attachment'
        }-content/${summaryDocument.externalId}`;

    return (
        <Layout
            hideSaveButton
            continueCallback={() => {
                if (data) {
                    const newModel = {
                        ...data,
                        Settings: {
                            ...data.Settings,
                            CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.SUMMARY_DOCUMENTS),
                        },
                    };
                    ctx.setCurrentModel(newModel);
                    ctx.saveCurrentModel(newModel);
                    navigateTo(ROUTE.FINISH);
                }
            }}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.SUMMARY}>
                    <Head heading1={t('pages.documents.title')} heading2={null} />

                    <Card data-test="documentsCard">
                        <Title tag="h3" size="md" fontWeight="medium" className="mb-12" data-test="documentsTitle">
                            <div>
                                {t('pages.documents.card.title')} (
                                <span data-test="email">{ownerParticipant.Contact?.Email || '-'}</span>):
                            </div>
                        </Title>

                        <DownloadList
                            items={
                                documents?.map((document) => ({
                                    name: document.displayName || '',
                                    url: getDocumentUrl(document),
                                })) || []
                            }
                        />
                    </Card>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
