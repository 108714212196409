import React, { Fragment } from 'react';
import { Field } from 'formik';
import Question from './Question';
import { Grid } from '../../components/Grid';
import { Select } from '../../components/common/Select';
import HelperText from '../../components/HelperText';
import { sportLevels, sports } from './constants';
import OpenSection from './OpenSection';

interface DuringSportsFormProps {
    values: any;
}

const DuringSportsForm = ({ values }: DuringSportsFormProps) => {
    return (
        <Fragment>
            <Question name="duringSports" text="Došlo k úrazu při sportu?" />
            {values.duringSports === 'yes' && (
                <OpenSection>
                    <Grid>
                        <Field
                            component={Select}
                            name="sport"
                            label="O jaký sport se jedná"
                            options={Object.keys(sports).map((s: string) => ({
                                label: sports[s],
                                value: s,
                            }))}
                        />
                        <Field
                            component={Select}
                            name="sportLevel"
                            label="Úroveň sportu"
                            options={Object.keys(sportLevels).map((s: string) => ({
                                label: sportLevels[s],
                                value: s,
                            }))}
                        />
                    </Grid>
                    <HelperText>
                        <p className="mb-2">Úroveň sportu</p>
                        <p className="mb-2">
                            <strong className="font-medium">Rekreační</strong> - provozování daného sportu v průměrném
                            rozsahu maximálně 8 hodin měsíčně a současně bez účasti v soutěžích (zahrnuje rovněž obdobné
                            aktivity vykonávané příležitostně v rámci zaměstnání a zážitkové akce);
                        </p>
                        <p className="mb-2">
                            <strong className="font-medium">Amatérská</strong> - provozování daného sportu v průměrném
                            rozsahu víc než 8 hodin měsíčně nebo s účastí v soutěžích nejvýše na krajské úrovni nebo v
                            soutěžích pro širokou veřejnost;
                        </p>
                        <p>
                            <strong className="font-medium">Nejvyšší</strong> - provozování daného sportu s účastí v
                            soutěžích na vyšší než krajské úrovni nebo provozování daného sportu na jakékoli úrovni,
                            pokud je převážným zdrojem příjmu pojištěného.
                        </p>
                    </HelperText>
                </OpenSection>
            )}
        </Fragment>
    );
};

export default DuringSportsForm;
