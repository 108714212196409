import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallValidateClientPersonalInformationExistProps {
    data: {
        PersonFirstName: string;
        PersonLastName: string;
        PersonEmail: string;
        PersonPhone: string;
        PersonPhonePrefix: string;
        PersonPesel: string;
    };
    token: string;
}

export interface CallValidateClientPersonalInformationExistResponse {
    IsValidByPesel: boolean;
    IsValidByEmailAndPhone: boolean;
}

export const callValidateClientPersonalInformationExist = ({
    data,
    token,
}: CallValidateClientPersonalInformationExistProps): Promise<
    AxiosResponse<CallValidateClientPersonalInformationExistResponse, any>
> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}validations/validate-client-personal-information-exist`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
