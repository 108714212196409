import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetConfirmationScreenDataProps {
    data: {
        onlineModelExternalId: string;
    };
    token: string;
}

export interface DocumentMetaDataType {
    ExternalId: string;
    FileName: string;
    DisplayName: string;
    ContentType: string;
    Length: number;
    IsValid: boolean;
}

interface CallGetConfirmationScreenDataResponse {
    Documents: Array<DocumentMetaDataType>;
}

export const callGetConfirmationScreenData = ({
    data,
    token,
}: CallGetConfirmationScreenDataProps): Promise<AxiosResponse<CallGetConfirmationScreenDataResponse, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}support/get-confirmation-screen-data/${data.onlineModelExternalId}`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
