import { Formik, Form, FormikProps, FormikValues, FormikHelpers } from 'formik';
import { FormEventHandler, ReactElement, useRef } from 'react';

import { useSetCurrentFormik } from '../utils/hooks';
import CustomForm from './CustomForm';

interface CustomFormikProps<T> {
    children: any;
    enableReinitialize?: boolean;
    disableScrollToErrors?: boolean;
    initialValues?: T;
    onChange?: FormEventHandler<HTMLFormElement>;
    onSubmit?: (values?: T | FormikValues, actions?: FormikHelpers<FormikValues>) => void;
    customRender?: boolean;
    validationSchema?: any;
    validate?: (values: T | FormikValues) => void;
    className?: string;
    passedRef?: any;
}

export const CustomFormik = <T extends unknown>({
    initialValues,
    enableReinitialize = true,
    disableScrollToErrors = false,
    onSubmit,
    onChange,
    children,
    customRender,
    validationSchema,
    validate,
    className,
    passedRef,
}: CustomFormikProps<T>): ReactElement => {
    const myFormRef = useRef<FormikProps<any>>(null);
    useSetCurrentFormik(passedRef ?? myFormRef);

    const renderChildren = (props: any) => (children instanceof Function ? children({ ...props }) : children);

    return (
        <Formik
            enableReinitialize={enableReinitialize}
            initialValues={initialValues || {}}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={validationSchema}
            onSubmit={
                onSubmit ? (values, actions) => onSubmit(values, actions) : (values, _) => console.log('submit', values)
            }
            innerRef={passedRef ?? myFormRef}
            validate={validate}
        >
            {(formikProps) => (
                <CustomForm {...formikProps} disableScrollToErrors={disableScrollToErrors}>
                    {customRender ? (
                        renderChildren(formikProps)
                    ) : (
                        <Form onChange={onChange} className={className} noValidate>
                            {renderChildren(formikProps)}
                        </Form>
                    )}
                </CustomForm>
            )}
        </Formik>
    );
};
