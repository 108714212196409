import cn from 'classnames';
import { ReactNode, Ref, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CardProps {
    children: ReactNode;
    hasPadding?: boolean;
    variant?: 'primary' | 'secondary';
    className?: string;
}

export const Card = forwardRef(
    (
        { children, hasPadding = true, className, variant = 'primary', ...props }: CardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const backgroundColor = variant === 'secondary' ? 'bg-[#FDF3E7]' : 'bg-lightPurple';
        return (
            <div
                ref={ref}
                className={cn(
                    twMerge(
                        'flex flex-col rounded-sm',
                        backgroundColor,
                        hasPadding && 'p-4 md:px-16 md:py-12',
                        className
                    )
                )}
                {...props}
            >
                {children}
            </div>
        );
    }
);
