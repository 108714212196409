import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallRegisterClientPhoneNumberProps {
    data: {
        onlinePolicyExternalId: string;
        phoneNumber: string | null;
        phoneNumberPrefix: string | null;
    };
    token: string;
}

export interface CallRegisterClientPhoneNumberResponse {
    ConfirmationTokenId: string;
    ConfirmationChannelIdentificationValue: string | null;
    ConfirmationChannelType: number;
}

export const callRegisterClientPhoneNumber = ({
    data,
    token,
}: CallRegisterClientPhoneNumberProps): Promise<AxiosResponse<CallRegisterClientPhoneNumberResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/register-client-phone-number`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
