import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { Policy } from '../../pages/consultant/Dashboard/types';

interface CallMyPolicyListProps {
    data: {};
    token: string;
}

export const callMyPolicyList = ({ data, token }: CallMyPolicyListProps): Promise<AxiosResponse<Policy[], any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-policy-list`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
