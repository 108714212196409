import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Client, ClientDetail } from '../types';
import { useTranslation } from 'react-i18next';
import { Toggle, ToggleSection, ToggleSectionItem } from './Toggle';
import { HUMAN_DATE_FORMAT } from '../../../../constants';
import Loader from '../../../../components/common/Loader';
import { ToggleSectionFile } from './Toggle/ToggleSectionFile';
import { ToggleSectionFileItem } from './Toggle/ToggleSectionFileItem';
import { CountryType } from '../../../../types';
import PolicyStatus from './PolicyStatus';
import { AppContext } from '../../../../components/Context';
import Address from '../../../../components/Address';

interface ClientsToggleFormProps {
    data: Client;
    clientData: ClientDetail | null;
}

const ClientsToggleForm = ({ data, clientData }: ClientsToggleFormProps) => {
    const { t } = useTranslation();
    const { initData } = useContext(AppContext);
    const countries: Array<CountryType> = initData?.Lovs?.Countries || [];

    if (!clientData || !clientData.BasicInfo) {
        return (
            <Toggle>
                <div className="col-span-12 pt-2">
                    <Loader className="text-center" />
                </div>
            </Toggle>
        );
    }

    return (
        <Toggle>
            <ToggleSection title={t('dashboard.common.basicDates')} data-test="basiDates">
                <ToggleSectionItem label={t('dashboard.common.name')} data-test="clientName">
                    {clientData.BasicInfo.ClientLastName} {clientData.BasicInfo.ClientFirstName}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.birthDate')} data-test="birthDate">
                    {dayjs(data.ClientBirthDt).format(HUMAN_DATE_FORMAT)}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.pesel')} data-test="pesel">
                    {clientData.BasicInfo.IdentificationNo}
                </ToggleSectionItem>
                {clientData.BasicInfo.IBAN && (
                    <ToggleSectionItem label={t('dashboard.common.bankAccount')} data-test="iban">
                        {clientData.BasicInfo.IBAN}
                    </ToggleSectionItem>
                )}
                <ToggleSectionItem label={t('dashboard.common.pep')} data-test="pep">
                    {t(`common.${clientData.BasicInfo.IsPep ? 'yes' : 'no'}`)}
                </ToggleSectionItem>
            </ToggleSection>

            <ToggleSection title={t('dashboard.common.contacts')} data-test="contacts">
                <ToggleSectionItem label={t('dashboard.common.emailAddress')} data-test="email">
                    {clientData.ContactInfo.Email || '-'}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.phoneNumber')} data-test="phoneNumber">
                    {clientData.ContactInfo.PhonePrefix} {clientData.ContactInfo.Phone}
                </ToggleSectionItem>
                <ToggleSectionItem label={t('dashboard.common.permanentAddress')} data-test="permanentAddress">
                    {clientData.ContactInfo.PermanentAddressCountryCode ? (
                        <Address
                            street={clientData.ContactInfo.PermanentAddressCityStreetName}
                            houseNumber={clientData.ContactInfo.PermanentAddressCityHouseNo}
                            city={clientData.ContactInfo.PermanentAddressCityName}
                            zipCode={clientData.ContactInfo.PermanentAddressCityZip}
                            countryCode={clientData.ContactInfo.PermanentAddressCountryCode}
                        />
                    ) : (
                        '-'
                    )}
                </ToggleSectionItem>
                {clientData.ContactInfo.ContactAddressCityName && (
                    <ToggleSectionItem label={t('dashboard.common.contactAddress')} data-test="contactAddress">
                        <Address
                            street={clientData.ContactInfo.ContactAddressStreetName}
                            houseNumber={clientData.ContactInfo.ContactAddressHouseNo}
                            city={clientData.ContactInfo.ContactAddressCityName}
                            zipCode={clientData.ContactInfo.ContactAddressZip}
                            countryCode={clientData.ContactInfo.ContactAddressCountryCode}
                        />
                    </ToggleSectionItem>
                )}
                <ToggleSectionItem label={t('dashboard.common.stateOfBirth')} data-test="stateOfBirth">
                    {countries?.find((country: any) => country.Code === clientData.ContactInfo.BirthCountryCode)
                        ?.NameTranslated || '-'}
                </ToggleSectionItem>
            </ToggleSection>

            <ToggleSection title={t('dashboard.common.contracts')} data-test="documents">
                {clientData.Policies.map((p) => {
                    return (
                        <ToggleSectionFile key={`policy-${p.PolicyNo}`}>
                            <ToggleSectionFileItem
                                className="mb-3"
                                label={t('dashboard.common.policyNumber')}
                                data-test="documentPolicyNo"
                            >
                                {p.PolicyNo}
                            </ToggleSectionFileItem>
                            <ToggleSectionFileItem
                                className="mb-0 !border-0"
                                label={t('dashboard.common.currentStatus')}
                                data-test="documentCurrentStatus"
                            >
                                <PolicyStatus statusCode={p.PolicyStatusCode} />
                            </ToggleSectionFileItem>
                        </ToggleSectionFile>
                    );
                })}
            </ToggleSection>
        </Toggle>
    );
};

export default ClientsToggleForm;
