import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallConfirmClientPhoneNumberProps {
    data: {
        identificationValue: string;
        tokenExternalId: string;
        authCode: string;
    };
    token: string;
}

export interface CallConfirmClientPhoneNumberResponse {
    IsOk: boolean;
    ExceptionText: string;
}

export const callConfirmClientPhoneNumber = ({
    data,
    token,
}: CallConfirmClientPhoneNumberProps): Promise<AxiosResponse<CallConfirmClientPhoneNumberResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/confirm-client-phone-number`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
