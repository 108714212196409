import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetAttachmentContentProps {
    data: {
        attachmentExternalId: string;
    };
    token: string;
}

export const callGetAttachmentContent = ({
    data,
    token,
}: CallGetAttachmentContentProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}documents/get-attachment-content/${data.attachmentExternalId}`,
        responseType: 'blob',
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
