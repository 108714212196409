import { Form, FormikProps } from 'formik';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { Head } from '../../components/Head';
import { AppContext } from '../../components/Context';
import { CustomFormik } from '../../components/CustomFormik';
import { getCheckedPartnerGroups, getHigherStep, getPartnerType, getPersonOfInsurableInterest } from '../../utils';
import Loader from '../../components/common/Loader';
import { ROUTE, STEP_CODE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import BankDataForm from './BankDataForm';
import PartnerDataForm from './PartnerDataForm';
import TypeSwitch from './TypeSwitch';
import { BeneficiariesFormDataType, BeneficiaryPersonDataType } from './types';
import BeneficiariesList from './BeneficiariesList';
import { config } from '../../config';
import { createBankData, createBeneficiariesData, updatePartnerData } from './helpers';
import { BeneficiaryBaseProp, BeneficiaryCompanyProp } from '../../types/model';
import { RiderGroupFamiliesProps } from '../PackageAdjustment';

interface formSchemaObject {
    [key: string]: any;
}

export const Beneficiaries = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const { initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const formikRef = useRef<FormikProps<any>>(null);

    const illustrationSettings = useMemo(() => data?.Settings?.IllustrationSettings || {}, [data]);

    const ownerId = useMemo(() => data?.PolicyOwners[0]?.ParticipantExternalId, [data]);
    const ownerParticipant = useMemo(() => data?.Participants?.find((p) => p.ExternalId === ownerId), [data, ownerId]);

    // Finds riders with beneficiaries allowed
    const riderGroupsWithBeneficiaries = useMemo(
        () =>
            ((initData?.RiderGroupFamilies || []) as Array<RiderGroupFamiliesProps>).reduce<string[]>(
                (acc, rgf: RiderGroupFamiliesProps) => {
                    rgf.RiderGroups.forEach((rg) =>
                        rg.Riders.filter((r) => r.IsBeneficiaryAllowed && acc.push(rgf.Code))
                    );
                    return acc;
                },
                []
            ),
        [initData]
    );

    const personInsurableInterestId = data ? getPersonOfInsurableInterest(data)?.ParticipantExternalId : null;

    const partnerType = useMemo(() => (data ? getPartnerType(data) : null), [data]);

    const hasCheckedPartnerGroup = useMemo<boolean>(() => {
        if (data) {
            const res = getCheckedPartnerGroups(data, initData);
            return !!(res && Array.isArray(res) && res.length > 0);
        }

        return false;
    }, [data, initData]);

    const displayPartner = useMemo(
        () => partnerType === 'partner' && hasCheckedPartnerGroup,
        [partnerType, hasCheckedPartnerGroup]
    );

    const formSchema = Yup.object().shape({
        ...(illustrationSettings?.BeneficiariesList || []).reduce(
            (acc: formSchemaObject, b: BeneficiaryPersonDataType, index: number) => {
                return {
                    ...acc,
                    [`name-${index}`]: Yup.string().required().min(2),
                    [`surName-${index}`]: Yup.string().required().min(2),
                    [`pesel-${index}`]: Yup.string().peselFormat(t('common.formErrors.wrongPesel')).required(),
                    [`percentageShare-${index}`]: Yup.number()
                        .required()
                        .min(
                            config.MIN_BENEFICIARY_SHARE,
                            `${t('common.formErrors.minimalBeneficiaryValue')} ${config.MIN_BENEFICIARY_SHARE}` || ''
                        )
                        .max(100),
                };
            },
            {}
        ),
        cession: Yup.boolean(),
        companyName: Yup.object().when('cession', (x) => (x[0] === true ? Yup.string().required() : Yup.object())),
        taxIdNumber: Yup.object().when('cession', (x) => (x[0] === true ? Yup.string().required() : Yup.object())),
        regonNumber: Yup.object().when('cession', (x) => (x[0] === true ? Yup.string().required() : Yup.object())),
        companyAddress: Yup.string(),
        freeText: Yup.string(),
        percentageShare: Yup.number().when('cession', (x) =>
            x[0] === true
                ? Yup.number()
                      .required()
                      .min(
                          config.MIN_BENEFICIARY_SHARE,
                          `${t('common.formErrors.minimalBeneficiaryValue')} ${config.MIN_BENEFICIARY_SHARE}` || ''
                      )
                      .max(100)
                : Yup.number()
        ),
        partnerName: displayPartner ? Yup.string().required().min(2) : Yup.string(),
        partnerSurname: displayPartner ? Yup.string().required().min(2) : Yup.string(),
        partnerPesel: displayPartner
            ? Yup.string().peselFormat(t('common.formErrors.wrongPesel')).required()
            : Yup.string(),
        hundredPercentageShare: Yup.number().test(
            'hundredPercentageShare',
            t<string>('common.formErrors.wrongBeneficiariesSum'),
            (v, f: any) => {
                const formValues = f.parent;
                if (formValues.beneficiariesType === 'accordingToTheLaw') {
                    return true;
                }
                const sumShare = (formValues?.beneficiaries || []).reduce(
                    (acc: number, b: any) => acc + parseInt(b.percentageShare),
                    formValues?.percentageShare || 0
                );
                return sumShare === 100;
            }
        ),
    });

    const initialValues = useMemo(() => {
        return {
            beneficiariesType: illustrationSettings?.BeneficiariesType ?? 'accordingToTheLaw',
            cession: illustrationSettings?.BeneficiariesCession ?? false,
            ...(illustrationSettings?.BeneficiariesList || []).reduce(
                (acc: any, b: BeneficiaryPersonDataType, index: number) => ({
                    ...acc,
                    [`name-${index}`]: b.name,
                    [`surName-${index}`]: b.surName,
                    [`pesel-${index}`]: b.pesel,
                    [`percentageShare-${index}`]: b.percentageShare,
                }),
                {}
            ),
            beneficiaries: illustrationSettings?.BeneficiariesList || [],
            bank: illustrationSettings?.BeneficiariesBank || null,
            companyName: illustrationSettings?.BeneficiariesCompanyName ?? '',
            taxIdNumber: illustrationSettings?.BeneficiariesTaxIdNumber ?? '',
            regonNumber: illustrationSettings?.BeneficiariesRegonNumber ?? '',
            companyAddress: illustrationSettings?.BeneficiariesCompanyAddress ?? '-',
            percentageShare: illustrationSettings?.BeneficiariesPercentageShare ?? '',
            freeText: illustrationSettings?.BeneficiariesFreeText ?? '',
            partnerName: illustrationSettings?.BeneficiariesPartnerFirstName ?? '',
            partnerSurname: illustrationSettings?.BeneficiariesPartnerLastName ?? '',
            partnerPesel: illustrationSettings?.BeneficiariesPartnerPersonalIdentifier ?? '',
        };
    }, [illustrationSettings]);

    const outOfLimit = useMemo(() => {
        return (
            (illustrationSettings?.BeneficiariesList || []).length +
                (!!illustrationSettings?.BeneficiariesCession ? 1 : 0) >=
            config.BENEFICIARIES_LIMIT
        );
    }, [illustrationSettings]);

    return (
        <Layout>
            {data ? (
                <LimitedAccess minStep={STEP_CODE.PERSONAL_DATA}>
                    <Head heading1={t('pages.beneficiaries.title')} heading2={t('pages.beneficiaries.subtitle')} />

                    <CustomFormik
                        initialValues={initialValues}
                        className="flex flex-col"
                        onSubmit={(v: any) => {
                            const personParticipants = createBeneficiariesData(v);
                            const cessionParticipant = createBankData(v.bank);
                            const beneficiaries: Array<BeneficiaryBaseProp | BeneficiaryCompanyProp> = [];

                            // beneficiary je společnost
                            v?.cession &&
                                cessionParticipant &&
                                beneficiaries.push({
                                    $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.Insurance.BeneficiaryCompany, Eden.Paris.OnlineManagerService.SharedModels',
                                    ExternalId: uuid(),
                                    SplitPct: v?.percentageShare,
                                    ParticipantExternalId: cessionParticipant.ExternalId ?? '',
                                    Note: v?.freeText && v?.freeText.trim() !== '' ? v?.freeText : null,
                                });

                            // beneficiary je osoba
                            personParticipants &&
                                personParticipants.forEach(
                                    (pp, i) =>
                                        v[`percentageShare-${i}`] &&
                                        beneficiaries.push({
                                            $type: 'Eden.Paris.OnlineManagerService.SharedModels.Model.Insurance.BeneficiaryPerson, Eden.Paris.OnlineManagerService.SharedModels',
                                            ExternalId: uuid(),
                                            SplitPct: v[`percentageShare-${i}`] ?? 0,
                                            ParticipantExternalId: pp.ExternalId ?? '',
                                        })
                                );

                            // Fill beneficiaries data created above into every protection group, which is unlocked for beneficiaries (riderGroupsWithBeneficiaries)
                            data?.InsuredPersons?.[0].SuggestedInsurance.ProtectionGroups.forEach((pg) =>
                                pg.SuggestedProtections.forEach((sp) => {
                                    if (riderGroupsWithBeneficiaries.includes(pg.RiderCategoryCode)) {
                                        sp.Beneficiaries = beneficiaries.map((b) => ({
                                            ...b,
                                            ExternalId: uuid(),
                                        }));
                                    }
                                })
                            );

                            const partnerParticipant = personInsurableInterestId
                                ? updatePartnerData(v, data, personInsurableInterestId)
                                : null;

                            const allParticipants = [
                                ...(ownerParticipant ? [ownerParticipant] : []),
                                ...(partnerParticipant ? [partnerParticipant] : []),
                                ...(personParticipants ? personParticipants : []),
                                ...(v?.cession && cessionParticipant ? [cessionParticipant] : []),
                            ];

                            const newModel = {
                                ...data,
                                Participants: allParticipants,
                                Settings: {
                                    ...data.Settings,
                                    CurrentStepCode: getHigherStep(
                                        data.Settings.CurrentStepCode,
                                        STEP_CODE.BENEFICIARIES
                                    ),
                                },
                            };

                            ctx.setCurrentModel(newModel);
                            ctx.saveCurrentModel(newModel).then(() => navigateTo(ROUTE.SUMMARY));
                        }}
                        passedRef={formikRef}
                        validationSchema={formSchema}
                        customRender
                    >
                        {() => (
                            <Form noValidate onChange={(e: any) => {}}>
                                <DetectFormikChanged
                                    onChange={(v: BeneficiariesFormDataType) => {
                                        ctx.setCurrentModel({
                                            ...data,
                                            Settings: {
                                                ...data.Settings,
                                                IllustrationSettings: {
                                                    ...data.Settings.IllustrationSettings,
                                                    BeneficiariesType: v?.beneficiariesType,
                                                    BeneficiariesCession: v?.cession,
                                                    BeneficiariesList: v?.beneficiaries,
                                                    BeneficiariesBank: v?.bank,
                                                    BeneficiariesCompanyName: v?.companyName,
                                                    BeneficiariesTaxIdNumber: v?.taxIdNumber,
                                                    BeneficiariesRegonNumber: v?.regonNumber,
                                                    BeneficiariesCompanyAddress: v?.companyAddress,
                                                    BeneficiariesPercentageShare: v?.percentageShare,
                                                    BeneficiariesFreeText: v?.freeText,
                                                    BeneficiariesPartnerFirstName: v?.partnerName,
                                                    BeneficiariesPartnerLastName: v?.partnerSurname,
                                                    BeneficiariesPartnerPersonalIdentifier: v?.partnerPesel,
                                                },
                                            },
                                        });
                                    }}
                                />

                                <TypeSwitch />
                                <BeneficiariesList outOfLimit={outOfLimit} />
                                <BankDataForm outOfLimit={outOfLimit} />
                                <PartnerDataForm visible={displayPartner} />
                            </Form>
                        )}
                    </CustomFormik>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};
