import { ReactElement } from 'react';
import cn from 'classnames';
import { TranslationObjectProps } from '../types';
import { getDataTest } from '../utils';

interface UnorderedListProps {
    data: TranslationObjectProps;
    className?: string;
}

export const UnorderedList = ({ data, className, ...props }: UnorderedListProps): ReactElement | null => {
    if (typeof data === 'object') {
        return (
            <ul
                className={cn(className, 'm-0 mb-8 flex list-none flex-col gap-y-4 p-0')}
                data-test={getDataTest(props)}
            >
                {Object.keys(data).map((k: string, index: number) => (
                    <li key={k} className="bullet-list-item" data-test={getDataTest(props, `Row${index + 1}`)}>
                        {data[k]}
                    </li>
                ))}
            </ul>
        );
    }

    return null;
};
