import React, { ReactNode, useEffect, useRef } from 'react';
import Toggable from '../DataTable/components/Toggable';
import ToggleArrow from '../DataTable/components/ToggleArrow';
import { getDataTest, scrollTo } from '../../utils';

interface AccordionItemProps {
    title: string;
    opened?: boolean;
    canScrollTo?: boolean;
    onToggleOpen?: () => void;
    children: ReactNode;
}

const AccordionItem = ({
    title,
    opened = false,
    canScrollTo = false,
    onToggleOpen,
    children,
    ...props
}: AccordionItemProps) => {
    const itemRef = useRef<HTMLTableRowElement>(null);

    const handleToggleOpened = () => {
        onToggleOpen && onToggleOpen();
    };

    useEffect(() => {
        if (opened && canScrollTo) {
            scrollTo(itemRef.current);
        }
    }, [opened, canScrollTo]);

    return (
        <>
            <tr ref={itemRef}>
                <Toggable component="td" className="w-12">
                    <ToggleArrow down={!opened} onClick={handleToggleOpened} />
                </Toggable>
                <td
                    className="cursor-pointer py-4"
                    data-test={getDataTest(props, 'Title')}
                    onClick={handleToggleOpened}
                >
                    {title}
                </td>
            </tr>
            {opened && (
                <tr>
                    <td className="toggle-content" colSpan={2} data-test={getDataTest(props, 'ToggleContent')}>
                        {children}
                    </td>
                </tr>
            )}
        </>
    );
};

export default AccordionItem;
