import { useEffect, useRef, forwardRef, useCallback, ChangeEvent, useMemo } from 'react';
import cn from 'classnames';

import { scaleValues } from '../../utils';
import { PL_CURRENCY } from '../../constants';
import { thousandSeparator } from '../../utils/hooks';

interface SliderProps {
    name: string;
    minValue: number;
    maxValue: number;
    initialValue: number;
    className?: string;
    parent?: string;
    child?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Slider = forwardRef(
    (
        { className, initialValue, name, minValue, maxValue, parent, child, onChange, ...props }: SliderProps,
        ref: any
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        const sliderFillRef = useRef<HTMLInputElement | null>(null);

        const step = useMemo(() => {
            const maxStep = Math.pow(10, (maxValue / 100).toString().length);
            if (minValue % maxStep !== 0) {
                return minValue % maxStep;
            }
            return maxStep;
        }, [minValue, maxValue]);

        const setSliderFillWidth = useCallback(() => {
            if (sliderFillRef.current) {
                if (initialValue > maxValue) {
                    sliderFillRef.current.style.width = 'calc(100% - 2rem)';
                } else if (initialValue < minValue) {
                    sliderFillRef.current.style.width = 'calc(0% + 2rem)';
                } else {
                    const diff = maxValue - minValue;
                    const perc = (100 / (diff / (initialValue - minValue))).toFixed();
                    const shift = scaleValues(initialValue, minValue, maxValue, 2, -2).toFixed(2);
                    sliderFillRef.current.style.width = `calc(${perc}% + ${shift}rem)`;
                }
            }
        }, [initialValue, maxValue, minValue]);

        useEffect(() => {
            setSliderFillWidth();
        }, [setSliderFillWidth, initialValue]);

        return (
            <div
                className={cn(
                    className,
                    'flex w-full flex-row items-center gap-x-4 whitespace-nowrap bg-purple10 px-3 py-3 md:px-6 mdmax:flex-wrap'
                )}
                ref={ref}
                {...props}
            >
                <div
                    className="font-normal mdmax:order-2 mdmax:mr-auto"
                    data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}MinValue` : undefined}
                >
                    {thousandSeparator(minValue.toString())} {PL_CURRENCY}
                </div>

                <div className="slider-wrapper mdmax:order-1 mdmax:mb-2 mdmax:w-full">
                    <div className="slider-fill" ref={sliderFillRef}>
                        <div className="slider-fill-thumb">
                            <div className="slider-fill-line" />
                            <div className="slider-fill-line" />
                            <div className="slider-fill-line" />
                        </div>
                    </div>

                    <input
                        data-parent={parent}
                        data-child={child}
                        ref={inputRef}
                        id={name}
                        name={name}
                        type="range"
                        min={minValue}
                        max={maxValue}
                        step={step}
                        value={initialValue > maxValue ? maxValue : initialValue < minValue ? minValue : initialValue}
                        className="slider"
                        onChange={(e) => {
                            onChange(e);
                            setSliderFillWidth();
                        }}
                        data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Input` : undefined}
                    />
                </div>

                <div
                    className="font-normal mdmax:order-3 mdmax:ml-auto"
                    data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}MaxValue` : undefined}
                >
                    {thousandSeparator(maxValue.toString())} {PL_CURRENCY}
                </div>
            </div>
        );
    }
);
