import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../components/Context';
import { ArrangementKind } from '../../types/enums/ArrangementKind';
import FooterMobileMenu from '../../components/FooterMobileMenu';
import FooterMobileMenuItem from '../../components/FooterMobileMenuItem';
import { Button } from '../../components/common/Button';
import ButtonMobileMore from '../../components/common/ButtonMobileMore';

interface FooterContentProps {
    requestSent: boolean;
    isContactFormDisabled: boolean;
    handleChangeContacts: () => void;
}

const FooterContent = ({ requestSent, isContactFormDisabled, handleChangeContacts }: FooterContentProps) => {
    const { t } = useTranslation();
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;

    const isRemote = data?.Settings?.ArrangementType === ArrangementKind.Remote;

    return (
        <>
            <div className="relative ml-auto md:hidden">
                {requestSent && !isRemote && (
                    <FooterMobileMenu control={<ButtonMobileMore caption={t('common.more')} data-test="moreButton" />}>
                        <FooterMobileMenuItem onClick={handleChangeContacts}>
                            {t('common.changeContacts')}
                        </FooterMobileMenuItem>
                    </FooterMobileMenu>
                )}
            </div>

            <div className="flex grow items-center justify-between mdmax:hidden">
                {isContactFormDisabled && (
                    <Button
                        isOutlined
                        onClick={handleChangeContacts}
                        className="ml-auto"
                        data-test="changeContactsButton"
                    >
                        {t('common.changeContacts')}
                    </Button>
                )}
            </div>
        </>
    );
};

export default FooterContent;
