import React from 'react';
import cn from 'classnames';

const Corners = () => {
    const cornerCls = 'rounded-b-0 absolute h-[42px] w-[47.5px] rounded-tl-xs border-l-1 border-t-1 border-darkGray';
    return (
        <div data-test="corners">
            <div className={cn(cornerCls, 'left-0 top-0')} />
            <div className={cn(cornerCls, 'right-0 top-0 -scale-x-100')} />
            <div className={cn(cornerCls, 'bottom-0 left-0 -scale-y-100')} />
            <div className={cn(cornerCls, 'bottom-0 right-0 -scale-x-100 -scale-y-100')} />
        </div>
    );
};

export default Corners;
