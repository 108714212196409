import React from 'react';
import { Card } from '../../components/common/Card';
import CardTitle from './CardTitle';
import UploadedFile from './UploadedFile';
import DragAndDropUpload from '../../components/common/DragAndDropUpload';

const UploadFilesCard = () => {
    return (
        <Card hasPadding={false} className="p-5 px-7">
            <CardTitle>Přílohy</CardTitle>
            <div className="grid gap-y-2">
                <span>Vložte, prosím, všechny lékařské zprávy, které jste obdržel/a v souvislosti s tímto úrazem.</span>
                <DragAndDropUpload onUpload={(files) => console.log(files)} />
                <UploadedFile name="Uctenka.jpg" />
                <UploadedFile name="Lekarska zprava.pdf" />
            </div>
        </Card>
    );
};

export default UploadFilesCard;
