import React, { createRef, useEffect } from 'react';
import cn from 'classnames';

import { Title } from './common/Title';
import { getDataTest, scrollTo } from '../utils';

interface AlertProps {
    title?: string;
    description?: string;
    className?: string;
}

const Alert = ({ title, description, className, ...props }: AlertProps) => {
    const ref = createRef<HTMLDivElement>();

    const correct = title || description;

    useEffect(() => {
        if (ref.current) {
            scrollTo(ref.current);
        }
        // eslint-disable-next-line
    }, [correct, ref]);

    if (!correct) {
        return null;
    }

    return (
        <div className={cn('rounded-lg bg-orangeLight p-10', className)} ref={ref} {...props}>
            {title && (
                <Title tag="h3" size="md" className="mb-4" data-test={getDataTest(props, 'Title')}>
                    {title}
                </Title>
            )}
            {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} data-test={getDataTest(props, 'Description')} />
            )}
        </div>
    );
};

export default Alert;
