import React, { Children, Fragment, ReactElement, ReactNode, cloneElement, useCallback, useState } from 'react';
import cn from 'classnames';

interface AccordionProps {
    isMultiple?: boolean;
    className?: string;
    children: ReactNode;
}

const Accordion = ({ isMultiple = false, className, children, ...props }: AccordionProps) => {
    const { hash } = window.location;

    const [opened, setOpened] = useState<number[]>(
        hash
            ? hash
                  .replace('#', '')
                  .split(',')
                  .map((h) => parseInt(h, 10))
                  .filter((h) => !isNaN(h))
            : []
    );

    const isOpened = useCallback((index: number) => opened.includes(index) ?? false, [opened]);

    const handleToggleOpened = (index: number) => {
        const newOpened = !isMultiple ? [] : [...opened];
        if (isOpened(index)) {
            setOpened(newOpened.filter((v) => v !== index));
        } else {
            newOpened.push(index);
            setOpened(newOpened);
        }
    };

    const canScrollTo = useCallback(
        (index: number) => {
            return hash
                ? hash
                      .replace('#', '')
                      .split(',')
                      .map((h) => parseInt(h, 10))
                      .filter((h) => !isNaN(h))
                      .includes(index)
                : false;
        },
        [hash]
    );

    return (
        <table border={0} cellSpacing={0} cellPadding={0} className={cn('styled-table', className)} {...props}>
            <tbody>
                {(Children.toArray(children) as ReactElement[]).map((ch: ReactElement, index: number) => (
                    <Fragment key={`accordion-item-${index}`}>
                        {cloneElement(ch, {
                            opened: isOpened(index),
                            canScrollTo: canScrollTo(index),
                            onToggleOpen: () => handleToggleOpened(index),
                        })}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default Accordion;
