import React, { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';
import cn from 'classnames';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';

import { InputProps } from './Input';
import { getDataTest } from '../../utils';
import { FormError } from './FormError';
import { Help } from './Help';
import { DATE_FORMAT } from '../../constants';

require('dayjs/locale/es');

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface DateInputProps extends InputProps {
    excludeDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
}

const CustomDateInput = forwardRef((props, ref: any) => <input {...props} ref={ref} />);

const DateInput = ({
    label,
    className,
    field,
    isCenter,
    isDisabled = false,
    fieldClassName,
    form,
    helpText,
    helpLocation = 'input',
    withoutHelperText = false,
    errorMessage,
    minDate,
    maxDate,
    onChange,
    size = 'medium',
    ...props
}: DateInputProps) => {
    const { t } = useTranslation();
    const ref = useRef<any>(null);
    const [selected, setSelected] = useState<string | null>(field?.value || null);

    const days = [
        t('common.days.su'),
        t('common.days.mo'),
        t('common.days.tu'),
        t('common.days.we'),
        t('common.days.th'),
        t('common.days.fr'),
        t('common.days.sa'),
    ];

    const months = [
        t('common.months.january'),
        t('common.months.february'),
        t('common.months.march'),
        t('common.months.april'),
        t('common.months.may'),
        t('common.months.june'),
        t('common.months.july'),
        t('common.months.august'),
        t('common.months.september'),
        t('common.months.october'),
        t('common.months.november'),
        t('common.months.december'),
    ];

    const locale: any = {
        localize: {
            day: (n: number) => days[n],
            month: (n: number) => months[n],
        },
        formatLong: {
            date: () => 'dd.MM.yyyy',
        },
    };

    const inErrorMessage = useMemo(
        () => (field?.name && form.errors[field.name] ? form.errors[field.name]?.toString() : errorMessage),
        [field?.name, form.errors, errorMessage]
    );

    const changeDate = (date: string | null) => {
        const parsedDate = dayjs(date);
        const isValid = parsedDate.isValid();

        const newEvent = {
            target: {
                name: field?.name,
                value: isValid ? parsedDate.format(DATE_FORMAT) : null,
            },
        } as ChangeEvent<HTMLInputElement>;

        setSelected(newEvent.target.value);
        field?.onChange(newEvent);
        onChange && onChange(newEvent);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        const key = e.key.toLowerCase();
        const ctrlKey = e.metaKey || e.ctrlKey;
        if (ctrlKey && ['a', 'c', 'v', 'x', 'tab'].includes(key)) {
            return;
        }
        if (e.shiftKey && ['home'].includes(key)) {
            return;
        }
        const v = ref.current.input.value;
        const valueByPoints = v?.split(/\./g) || [];
        const pressContentKey = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key);
        const pressFnKey = ['.', 'delete', 'tab', 'backspace', 'arrowleft', 'arrowright'].includes(key);
        if (!pressContentKey && !pressFnKey) {
            e.preventDefault();
        } else if (pressContentKey && ref.current.input.value.length >= 10) {
            e.preventDefault();
        } else if (key === '.' && valueByPoints.length > 2) {
            e.preventDefault();
        } else {
            // stisk korektní klávesy
            if (pressContentKey && ref.current.input.selectionStart === v.length) {
                if (
                    (valueByPoints.length === 1 && valueByPoints[0].length === 2) ||
                    (valueByPoints.length === 2 && valueByPoints[1].length === 2)
                ) {
                    ref.current.input.value = ref.current.input.value + '.';
                }
            }
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsedDate = dayjs(value, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY'], 'es', true);
        const isValid = parsedDate.isValid();
        if (!isValid) {
            changeDate(null);
        } else {
            changeDate(parsedDate.format(DATE_FORMAT));
        }
    };

    useEffect(() => {
        if (field?.value !== selected) {
            setSelected(field?.value);
        }
    }, [field?.value, selected]);

    return (
        <div className={cn(className, 'flex w-full flex-col')}>
            {label && (
                <label
                    className={cn(
                        'mb-2 flex cursor-pointer flex-row text-sm text-darkPurple',
                        isCenter ? 'justify-center text-center' : 'pl-4',
                        isDisabled && 'pointer-events-auto cursor-auto text-gray',
                        size === 'small' && 'text-xs'
                    )}
                    htmlFor={isDisabled ? undefined : field?.name}
                    data-test={getDataTest(props, 'Label')}
                >
                    {label}
                    {helpText && helpLocation === 'label' && (
                        <Help text={helpText} data-test={getDataTest(props, 'Help')} />
                    )}
                </label>
            )}
            <div className={`relative flex w-full items-center input-size-${size}`}>
                <DatePicker
                    ref={ref}
                    closeOnScroll
                    selected={selected ? new Date(selected) : null}
                    isClearable
                    showIcon={!isDisabled}
                    toggleCalendarOnIconClick
                    disabled={isDisabled}
                    placeholderText="dd. mm. yyyy"
                    dateFormat="dd.MM.yyyy"
                    locale={locale}
                    calendarStartDay={1}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<CustomDateInput data-test={getDataTest(props)} />}
                    maxDate={maxDate}
                    minDate={minDate}
                    className={cn(
                        twMerge(
                            'inline-block w-full shrink-0 grow rounded-full border !bg-white px-4 [&:focus]:border-darkPurple',
                            size === 'medium' && 'h-[3rem] md:h-[3.25rem]',
                            size === 'small' && 'h-10',
                            'inline-flex items-center justify-start',
                            isCenter && 'text-center',
                            isCenter && 'justify-center',
                            inErrorMessage ? 'border-red' : 'border-purple',
                            isDisabled && 'pointer-events-none border-gray !text-gray',
                            fieldClassName
                        )
                    )}
                    onKeyDown={handleKeyDown}
                    onChangeRaw={(e: ChangeEvent<HTMLInputElement>) => {
                        const date = e.target.value;
                        if (!isDisabled) {
                            const parsedDate = dayjs(date, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY'], 'es', true);
                            const isValid = parsedDate.isValid();
                            changeDate(isValid ? parsedDate.format(DATE_FORMAT) : null);
                        }
                    }}
                    onChange={(date: any) => {
                        if (!isDisabled) {
                            if (date === null) {
                                changeDate(null);
                            } else {
                                changeDate(date);
                            }
                        }
                    }}
                    onBlur={handleBlur}
                    portalId="date-picker-pportal"
                    data-test={getDataTest(props, 'Input')}
                />
                {helpText && helpLocation === 'input' && (
                    <Help text={helpText} className="ml-4" data-test={getDataTest(props, 'Help')} />
                )}
            </div>
            {!withoutHelperText && (
                <FormError
                    name={field?.name}
                    className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                    data-test={getDataTest(props, 'Error')}
                />
            )}
            {errorMessage && (
                <FormError
                    error={errorMessage}
                    className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                    data-test={getDataTest(props, 'Error')}
                />
            )}
        </div>
    );
};

export default DateInput;
