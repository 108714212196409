import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from '../constants';

export const MainMenu = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(ROUTE.DASHBOARD_MAIN);
        // eslint-disable-next-line
    }, []);

    return null;
};
