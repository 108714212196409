import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PopupWrapper from '../common/PopupWrapper';
import { Title } from '../common/Title';
import PopupButtons from '../common/PopupButtons';
import { Button } from '../common/Button';
import Alarm from '../../assets/images/alarm.png';
import { formatTime } from '../../utils';
import { useAuth } from '../../utils/hooks';

interface InactivityPopupProps {
    timeToLogout: number;
    onSubmit: () => void;
}

const InactivityPopup = ({ timeToLogout, onSubmit }: InactivityPopupProps) => {
    const { logout } = useAuth();
    const { t } = useTranslation();

    const parsedTime = useMemo(() => formatTime(timeToLogout > 0 ? timeToLogout : 0), [timeToLogout]);

    useEffect(() => {
        if (timeToLogout <= 0) {
            logout();
        }
    }, [timeToLogout, logout]);

    return (
        <PopupWrapper popupType="inactivity">
            <div className="flex flex-col items-center justify-center gap-y-4">
                <img
                    src={Alarm}
                    alt=""
                    className="mx-auto mb-10 h-auto w-[8.125rem] smallheight:hidden"
                    data-test="popupImage"
                />

                <Title tag="strong" size="md" fontWeight="medium" data-test="popupTitle">
                    {t('common.inactivityPopup.title')}
                </Title>

                <div
                    className="flex min-w-[8rem] justify-center self-center rounded-[6px] border-2 border-purple p-2 text-purple"
                    data-test="popupTimeCountdown"
                >
                    <span className="flex flex-col text-center">
                        <span className="text-lg font-bold">{parsedTime.minutes}</span>
                        <span className="text-xxs">{t('common.minutes')}</span>
                    </span>

                    <span className="text-lg font-bold">&nbsp;:&nbsp;</span>

                    <span className="flex flex-col text-center">
                        <span className="text-lg font-bold">{parsedTime.seconds}</span>
                        <span className="text-xxs">{t('common.seconds')}</span>
                    </span>
                </div>

                <p className="text-sm" data-test="popupDescription">
                    {t('common.inactivityPopup.text')}
                </p>

                <PopupButtons>
                    <Button
                        className="ml-auto"
                        onClick={() => {
                            onSubmit();
                        }}
                        data-test="keepWorkingButton"
                    >
                        {t('common.keepWorking')}
                    </Button>
                </PopupButtons>
            </div>
        </PopupWrapper>
    );
};

export default InactivityPopup;
