import React, { ChangeEvent, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Grid } from '../../components/Grid';
import { Radio } from '../../components/common/Radio';
import { FormError } from '../../components/common/FormError';
import { scrollTo } from '../../utils';
import { AppContext } from '../../components/Context';

const TypeSwitch = () => {
    const { t } = useTranslation();
    const refCard = useRef<any>();
    const ctx = useContext(AppContext);

    const formikContext = useFormikContext<any>();
    const { values, errors, isSubmitting, setFieldValue } = formikContext;

    useEffect(() => {
        if (isSubmitting) {
            if (errors.hundredPercentageShare && Object.keys(errors).length === 1) {
                scrollTo(refCard.current);
            }
        }
    }, [errors, isSubmitting]);

    const beneficiariesCount = useMemo(() => values.beneficiaries.length + (values.cession ? 1 : 0), [values]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value === 'accordingToTheLaw' && beneficiariesCount > 0) {
            ctx.showPopup('lost-data-warning', () => {
                setFieldValue('beneficiariesType', value);
                setFieldValue('cession', false);
                setFieldValue('bank', null);
                setFieldValue('companyName', '');
                setFieldValue('taxIdNumber', '');
                setFieldValue('regonNumber', '');
                setFieldValue('companyAddress', '-');
                setFieldValue('percentageShare', '');
                setFieldValue('freeText', '');
                setFieldValue('beneficiaries', []);
            });
        } else {
            setFieldValue('beneficiariesType', value);
        }
    };

    return (
        <Card ref={refCard} data-test="typeSwitch">
            <Title tag="h3" size="md" fontWeight="medium" className="mb-8" data-test="typeSwitchTitle">
                {t('pages.beneficiaries.card.title')}
            </Title>

            <Grid>
                <Field
                    name="beneficiariesType"
                    value="accordingToTheLaw"
                    id="accordingToTheLaw"
                    label={t('common.formFields.accordingToTheLaw')}
                    component={Radio}
                    type="radio"
                    helpText={t('pages.beneficiaries.accordingToTheLawHelperText')}
                    onChange={handleChange}
                    data-test="typeAccordingToTheLaw"
                />
                <Field
                    name="beneficiariesType"
                    value="byExactDetails"
                    id="byExactDetails"
                    label={t('common.formFields.byExactDetails')}
                    component={Radio}
                    type="radio"
                    helpText={t('pages.beneficiaries.byExactDetailsHelperText')}
                    data-test="typeByExactDetails"
                />
            </Grid>

            <>
                {errors.hundredPercentageShare && (
                    <FormError
                        error={errors.hundredPercentageShare.toString()}
                        className="mt-8 text-center"
                        data-test="typeError"
                    />
                )}
            </>
        </Card>
    );
};

export default TypeSwitch;
