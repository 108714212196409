import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { Input } from '../../components/common/Input';
import { useTranslation } from 'react-i18next';

interface PartnerDataFormProps {
    visible: boolean;
}

const PartnerDataForm = ({ visible }: PartnerDataFormProps) => {
    const { t } = useTranslation();

    return (
        <div className={cn(!visible && 'hidden')} data-test="partner">
            <div className="mt-12 flex flex-col gap-y-3 md:mb-12">
                <Title tag="h3" size="lg" data-test="partnerTitle">
                    {t('pages.beneficiaries.partner.title')}
                </Title>
                <Title tag="h4" size="md" fontWeight="regular" isCenter data-test="partnerSubTitle">
                    {t('pages.beneficiaries.partner.subtitle')}
                </Title>
            </div>

            <Card data-test="partnerCard">
                <Title tag="strong" size="md" fontWeight="regular" isCenter data-test="partnerCardTitle">
                    {t('pages.beneficiaries.partner.cardTitle')}
                </Title>

                <div className="mt-6 flex flex-col gap-x-6 md:flex-row">
                    <Field
                        component={Input}
                        name="partnerName"
                        label={t('common.formFields.name')}
                        isCenter
                        data-test="partnerName"
                    />
                    <Field
                        component={Input}
                        name="partnerSurname"
                        label={t('common.formFields.surname')}
                        isCenter
                        data-test="partnerSurName"
                    />
                    <Field
                        component={Input}
                        name="partnerPesel"
                        label={t('common.formFields.pesel')}
                        isCenter
                        maxLength={50}
                        isStringNumber
                        data-test="partnerPesel"
                    />
                </div>
            </Card>
        </div>
    );
};

export default PartnerDataForm;
