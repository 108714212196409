import { FieldProps } from 'formik';
import cn from 'classnames';

import { FormError } from './FormError';
import { Help } from './Help';
import { ChangeEvent } from 'react';

interface SwitchProps {
    field: FieldProps['field'];
    label: string;
    checked?: boolean;
    helpText?: string;
    error?: string;
    isDisabled?: boolean;
    className?: string;
    onChange?: (e: ChangeEvent) => void;
}

export const Switch = ({
    field,
    label,
    checked,
    helpText,
    error,
    className,
    isDisabled,
    onChange,
    ...props
}: SwitchProps) => {
    return (
        <div className={cn(className, 'relative', isDisabled && 'pointer-events-none text-gray')}>
            <div className="flex items-center gap-x-2">
                <label
                    className={cn('switch')}
                    data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Label` : undefined}
                >
                    <input
                        {...field}
                        onChange={(e) => {
                            if (isDisabled) {
                                e.preventDefault();
                            } else {
                                onChange ? onChange(e) : field.onChange(e);
                            }
                        }}
                        className="input-hidden"
                        id={field.name}
                        type="checkbox"
                        checked={checked ?? field.value}
                        value={checked ?? field.value}
                        data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Input` : undefined}
                    />
                    <span
                        className={cn(
                            'switch__mark',
                            !!error && '!border-redLight',
                            isDisabled && 'border-gray !bg-gray',
                            isDisabled && !field.value && '!bg-lightGray'
                        )}
                    ></span>

                    {label && <>{label}</>}
                </label>

                {helpText && (
                    <Help
                        text={helpText}
                        data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}HelperText` : undefined}
                    />
                )}
            </div>

            <FormError
                className="mt-2"
                name={field.name}
                data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Error` : undefined}
            />
        </div>
    );
};
