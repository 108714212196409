import { Fragment, ReactNode } from 'react';
import { WaitingCase } from '../types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface WaitingCaseListCellProps {
    data: WaitingCase;
    displayAlert?: boolean;
    children?: ReactNode;
}

const WaitingCaseListCell = ({ data, displayAlert = false, children }: WaitingCaseListCellProps) => {
    const { t } = useTranslation();
    const expiredIn = dayjs(data.ExpirationDt).endOf('day').diff(dayjs().endOf('day'), 'days', false);

    if (expiredIn <= 7) {
        const color = expiredIn <= 1 ? 'bg-[#F23838]' : 'bg-[#FF7A00]';

        return (
            <div className="relative pt-8">
                {displayAlert && (
                    <div
                        className={`absolute left-0 top-[-8px] whitespace-nowrap ${color} px-2 py-1 text-xxs text-white`}
                    >
                        {t('dashboard.waitingCase.expireIn', { count: expiredIn >= 0 ? expiredIn : 0 })}
                    </div>
                )}
                {children}
            </div>
        );
    }

    return <Fragment>{children}</Fragment>;
};

export default WaitingCaseListCell;
