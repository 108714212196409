import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { Client } from '../../pages/consultant/Dashboard/types';

interface CallMyClientListProps {
    data: {};
    token: string;
}

export const callMyClientList = ({ data, token }: CallMyClientListProps): Promise<AxiosResponse<Client[], any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-client-list`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
