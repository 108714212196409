import cn from 'classnames';
import { getDataTest } from '../../utils';

interface ListProps {
    items: Array<string>;
    type: 'ordered' | 'unordered';
    className?: string;
}

export const List = ({ items, type, className, ...props }: ListProps) => {
    const Tag = type === 'unordered' ? 'ul' : 'ol';

    return (
        <Tag
            className={cn(className, 'flex flex-col gap-y-6', type === 'ordered' ? 'ordered-list' : 'unordered-list')}
            {...props}
        >
            {items.map((item, i) => (
                <li key={i} data-test={getDataTest(props, `Line${i + 1}`)}>
                    {item}
                </li>
            ))}
        </Tag>
    );
};
