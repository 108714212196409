import React from 'react';
import dayjs from 'dayjs';
import { getBaseUrl } from '../../../../utils';
import { API_PATH, HUMAN_DATE_FORMAT } from '../../../../constants';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';
import { Trans } from 'react-i18next';

interface DocumentProps {
    document: any;
}

const Document = ({ document }: DocumentProps) => (
    <a
        href={`${getBaseUrl()}${API_PATH}documents/get-document-content/${document.DocumentBaseExternalId}`}
        data-test="documentLink"
    >
        <div className="group mt-3 rounded-sm border-1 bg-white p-4 pb-3">
            <p data-test="documentName">{document.DisplayName}</p>
            <div className="mt-2 flex items-center justify-between">
                <small data-test="documentInsertedDate">{dayjs(document.InsertedDtt).format(HUMAN_DATE_FORMAT)}</small>
                <span className="flex items-center text-xxs group-hover:underline">
                    <DownloadIcon className="w-5" />
                    <Trans>common.download</Trans>
                </span>
            </div>
        </div>
    </a>
);

export default Document;
