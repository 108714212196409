import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components/common/Button';
import { sections } from './types';
import { ROUTE } from '../../../../../constants';
import { stringCapitalize } from '../../../../../utils';

interface SectionLinksProps {
    section: string;
}

const SectionLinks = ({ section }: SectionLinksProps) => {
    const { t } = useTranslation();

    return (
        <div className="full-width mb-3 gap-x-2" data-test="sections">
            {sections.map((link) => (
                <Button
                    key={`link-${link}`}
                    href={ROUTE.DASHBOARD_KNOWLEDGE.replace(':section', link)}
                    isOutlined={section !== link}
                    size="sm"
                    className="mb-0 mr-1 inline-block"
                    data-test={`section${stringCapitalize(link)}`}
                >
                    {t(`knowledgeBase.section.${link}`)}
                </Button>
            ))}
        </div>
    );
};

export default SectionLinks;
