import cn from 'classnames';

import { Icon } from '../Icon';

interface TipProps {
    text: string;
    className?: string;
}

export const Tip = ({ text, className, ...props }: TipProps) => {
    return (
        <div
            className={cn(
                className,
                'flex items-center gap-x-3 rounded-xs bg-yellow px-4 py-2 text-xxs text-darkPurple'
            )}
            {...props}
        >
            <Icon name="bulb" className="shrink-0 text-purple" />
            <div>{text}</div>
        </div>
    );
};
