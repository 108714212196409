import { ReactNode, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import cn from 'classnames';

import { getDataTest } from '../../utils';

interface TooltipProps {
    content: ReactNode;
    width?: 'small' | 'large';
    className?: string;
    children: ReactNode;
}

export const Tooltip = ({ content, width = 'small', className, children, ...props }: TooltipProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [positionX, setPositionX] = useState('center');
    const [positionY, setPositionY] = useState('top');
    const [contentVisible, setContentVisible] = useState(false);

    const onMouseEnter = () => {
        const refPosition = ref.current?.getBoundingClientRect();
        // x pozice
        setPositionX(refPosition && refPosition.right + 150 > window.innerWidth ? 'right' : 'center');
        // y pozice
        setPositionY(refPosition && refPosition.top - 300 < 0 ? 'bottom' : 'top');
        // zobrazení
        setContentVisible(true);
    };

    const onMouseLeave = () => setContentVisible(false);

    return (
        <div
            ref={ref}
            className={cn(className, 'group relative inline-block')}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-test={getDataTest(props, 'Icon')}
        >
            {children}
            <div
                className={cn(
                    twMerge(
                        'absolute z-50 hidden',
                        contentVisible && 'block',
                        width === 'small' ? 'w-[17.625rem]' : 'w-[17.625rem] md:w-[31.25rem]',
                        positionX === 'left'
                            ? 'left-0'
                            : positionX === 'right'
                            ? 'right-0'
                            : 'left-1/2 -translate-x-1/2',
                        positionY === 'top' ? 'bottom-full mb-[-27px]' : 'top-full mt-[-27px]'
                    )
                )}
                data-test={getDataTest(props, 'Content')}
            >
                {positionY !== 'top' && <div className="h-7" />}
                <div className="rounded-xs bg-darkPurple p-3 text-xs font-normal text-white">{content}</div>
                {positionY === 'top' && <div className="h-7" />}
            </div>
        </div>
    );
};
