import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { WaitingCaseDetail } from '../../pages/consultant/Dashboard/types';

interface CallWaitingCaseDetailProps {
    data: {
        caseExternalId: string;
    };
    token: string;
}

export const callWaitingCaseDetail = ({
    data,
    token,
}: CallWaitingCaseDetailProps): Promise<AxiosResponse<WaitingCaseDetail, any>> =>
    axios({
        method: 'get',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-waiting-for-client-ident-case-prevaluation-detail/${
            data.caseExternalId
        }`,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
