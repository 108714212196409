import { FormikValues } from 'formik';
import {
    ModelProps,
    ParticipantPersonProps,
    ParticipantRelatedProps,
    ParticipantsType,
    PersonalIdentificationFullProps,
} from '../../types/model';
import { v4 as uuid } from 'uuid';
import { RelationshipKind } from '../../types/enums/RelationshipKind';
import { SexKind } from '../../types/enums/SexKind';

// vrací objekt participant od partnera
const getPartnerParticipantModelData = (initialData: any, partnerType: string): ParticipantsType => {
    if (partnerType === 'married') {
        // manžel, manželka
        return {
            $type: `Eden.Paris.OnlineManagerService.SharedModels.Model.ParticipantRelated, Eden.Paris.OnlineManagerService.SharedModels`,
            ...initialData,
            Relationship: RelationshipKind.HusbandWifePartner,
            Contact: null,
        } as ParticipantRelatedProps;
    } else {
        // partner
        return {
            $type: `Eden.Paris.OnlineManagerService.SharedModels.Model.ParticipantPerson, Eden.Paris.OnlineManagerService.SharedModels`,
            ...initialData,
            Contact: null,
            Identification: {
                $type: 'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationFull, Eden.Paris.OnlineManagerService.SharedModels',
                BirthDt: null,
                CurrentAge: null,
                Sex: SexKind.NotSpecified,
            },
        } as ParticipantPersonProps<PersonalIdentificationFullProps>;
    }
};

export const updateParticipants = (
    model: ModelProps,
    values: FormikValues,
    generatedId: string,
    poiId?: string,
    participant?: ParticipantRelatedProps
) => {
    if (!poiId && !participant) {
        // vytvoření objektu participanta
        model.Participants = [
            ...model.Participants,
            getPartnerParticipantModelData(
                {
                    ExternalId: generatedId,
                    ClientExternalId: uuid(),
                },
                values.partnerType
            ),
        ];
    } else {
        // aktualizace objektu participanta
        if (participant) {
            model.Participants = [
                ...model.Participants.filter((p) => p.ExternalId !== participant.ExternalId),
                getPartnerParticipantModelData(
                    {
                        ExternalId: participant.ExternalId,
                        ClientExternalId: participant.ClientExternalId,
                    },
                    values.partnerType
                ),
            ];
        }
    }
};

export const removePartnerParticipant = (
    model: ModelProps,
    participant: ParticipantRelatedProps,
    pig: Array<string> | null
) => {
    if (participant) {
        model.Participants = [...model.Participants.filter((p) => p.ExternalId !== participant.ExternalId)];
        if (pig && Array.isArray(pig)) {
            pig?.forEach((group) => {
                model?.InsuredPersons[0].SuggestedInsurance.ProtectionGroups.forEach((pg) => {
                    if (pg.RiderCategoryCode === group) {
                        pg.SuggestedProtections.forEach((sp) => {
                            if (sp['PersonOfInsurableInterest']) {
                                sp['PersonOfInsurableInterest'] = null;
                            }
                        });
                    }
                });
            });
        }
    }
};

export const updatePersonOfInsurableInterest = (
    pig: Array<string> | null,
    currentData: ModelProps,
    currentId: string,
    values: FormikValues
) => {
    if (pig && Array.isArray(pig)) {
        pig?.forEach((group) => {
            currentData?.InsuredPersons[0]?.SuggestedInsurance?.ProtectionGroups?.forEach((pg) => {
                if (pg.RiderCategoryCode === group) {
                    pg.SuggestedProtections.forEach((sp) => {
                        if (!sp['PersonOfInsurableInterest']) {
                            sp['PersonOfInsurableInterest'] = {
                                $type: `Eden.Paris.OnlineManagerService.SharedModels.Model.Insurance.${
                                    values.partnerType === 'married'
                                        ? 'PersonOfInsurableInterestRelated'
                                        : 'PersonOfInsurableInterestPerson'
                                }, Eden.Paris.OnlineManagerService.SharedModels`,
                                ExternalId: uuid(),
                                ParticipantExternalId: currentId,
                            };
                        }
                    });
                }
            });
        });
    }
};
