import React from 'react';
import UploadedFile from './UploadedFile';

interface UploadedFilesProps {
    files: any[];
    onDelete: (file: any, index: number) => void;
}

const UploadedFiles = ({ files, onDelete }: UploadedFilesProps) => {
    return (
        <div className="mt-3 grid grid-cols-2 gap-2" data-test="uploadedFilesList">
            {files.map((file, index) => (
                <UploadedFile
                    key={`file-${index}`}
                    file={file}
                    onDelete={() => onDelete(file, index)}
                    data-test="uploadedFile"
                />
            ))}
        </div>
    );
};

export default UploadedFiles;
