import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallLoginAgentInitProps {
    data: {
        loginName: string;
        oldPassword: string;
        newPassword: string;
    };
}

export const callLoginAgentInit = (props: CallLoginAgentInitProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}authentications/login-agent-init`,
        data: props.data,
    });
