import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { Policy } from '../../pages/consultant/Dashboard/types';

interface CallAgencyPolicyListProps {
    data: {};
    token: string;
}

export const callAgencyPolicyList = ({
    data,
    token,
}: CallAgencyPolicyListProps): Promise<AxiosResponse<Policy[], any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}dashboard/get-policy-list-for-agent-with-agency-pl`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
