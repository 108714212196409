import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGetClientConsentProps {
    data: {
        onlinePolicyExternalId: string;
        participantExternalId: string;
    };
    token: string;
}

export const callGetClientConsent = ({ data, token }: CallGetClientConsentProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/get-client-consent`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
