import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';
import cn from 'classnames';

import { Button } from './common/Button';
import { Card } from './common/Card';
import { Title } from './common/Title';
import brokenImage from '../assets/images/broken-image.png';
import { Tooltip } from './common/Tooltip';

interface PackageCardProps {
    title: string;
    subtitle?: string | null;
    subtitlePosition?: 'top' | 'bottom';
    image: string;
    hasButton?: boolean;
    className?: string;
    onClick?: () => void;
    isDisabled?: boolean;
    isSelected?: boolean;
    brokenTooltipContent?: ReactNode;
}

export const PackageCard = ({
    image,
    title,
    subtitle,
    subtitlePosition = 'bottom',
    hasButton = true,
    className,
    onClick,
    isDisabled,
    isSelected,
    brokenTooltipContent,
    ...props
}: PackageCardProps): ReactElement => {
    const [broken, setBroken] = useState(false);
    const subtitleBottomPosition = subtitle && subtitlePosition === 'bottom';

    const cls = 'flex flex-col items-center px-4 py-6 md:px-4 md:py-6 grow cursor-pointer';

    const cardDisabled = useMemo(() => isDisabled || broken, [isDisabled, broken]);

    const handleImageError = (e: any) => {
        setBroken(true);
        e.target.src = brokenImage;
        e.target.className += ' opacity-30';
        e.stopPropagation();
        e.preventDefault();
    };

    const renderCardContent = useCallback(
        () => (
            <>
                <Title
                    tag="h3"
                    size={subtitleBottomPosition ? 'sm' : 'md'}
                    fontWeight="medium"
                    className={cn('mb-2', subtitleBottomPosition ? 'order-2 mt-4' : 'md:mb-3')}
                    data-test="cardTitle"
                >
                    {title}
                </Title>
                {subtitle && (
                    <p className={cn('text-xs', subtitleBottomPosition ? 'order-3' : 'mb-4')} data-test="cardSubtitle">
                        {subtitle}
                    </p>
                )}

                <img
                    src={image || 'without-image'}
                    alt=""
                    className={cn('h-auto', subtitleBottomPosition ? 'order-1 w-[10.875rem]' : 'mt-auto w-[8rem]')}
                    data-test="cardImage"
                    onError={handleImageError}
                />
            </>
        ),
        [image, subtitle, title, subtitleBottomPosition]
    );

    const renderCard = useCallback(() => {
        return (
            <Card
                className={cn(
                    'h-full p-0 text-center transition-colors duration-300 md:p-0 hover-hover:hover:bg-purple hover-hover:hover:text-white',
                    className,
                    cardDisabled && 'pointer-events-none opacity-50',
                    isSelected && 'border-2 border-purple'
                )}
                {...props}
            >
                {hasButton ? (
                    <div className={cls} onClick={onClick}>
                        {renderCardContent()}
                        <Button onClick={onClick} className="order-4 mt-4 md:hidden" data-test="cardButton">
                            {t('common.pickThisOne')}
                        </Button>
                    </div>
                ) : onClick ? (
                    <div className={cls} onClick={onClick}>
                        {renderCardContent()}
                    </div>
                ) : (
                    <></>
                )}
            </Card>
        );
    }, [cardDisabled, className, isSelected, hasButton, props, onClick, renderCardContent]);

    if (broken && brokenTooltipContent) {
        return (
            <Tooltip width="small" content={brokenTooltipContent} data-test="brokenCardTooltip">
                {renderCard()}
            </Tooltip>
        );
    }

    return renderCard();
};
