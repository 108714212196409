import { ReactNode } from 'react';
import cn from 'classnames';

interface CardsWrapperProps {
    children: ReactNode;
    className?: string;
}

export const CardsWrapper = ({ children, className }: CardsWrapperProps) => {
    return (
        <div
            className={cn(
                className,
                'grid w-full grid-cols-[repeat(auto-fit,minmax(10rem,15rem))] justify-center gap-4 md:gap-10'
            )}
        >
            {children}
        </div>
    );
};
