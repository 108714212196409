import React, { useEffect, useState } from 'react';
import { Policy, PolicyDetail } from '../types';
import { callMyPolicyDetail } from '../../../../apis/dashboard/myPolicyDetail';
import PolicyToggleForm from './PolicyToggleForm';

interface MyPolicyToggleProps {
    data: Policy;
}

const MyPolicyToggle = ({ data }: MyPolicyToggleProps) => {
    const token = localStorage.getItem('token');
    const [policyData, setPolicyData] = useState<PolicyDetail | null>(null);

    useEffect(() => {
        if (token) {
            callMyPolicyDetail({
                data: {
                    policyExternalId: data.PolicyExternalId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => setPolicyData(result));
        }
    }, [token, data]);

    return <PolicyToggleForm policyData={policyData} />;
};

export default MyPolicyToggle;
